import {
  Box,
  createStyles,
  Divider,
  Group,
  Stack,
  Tooltip,
} from '@mantine/core';
import React, { Dispatch, SetStateAction } from 'react';
import { contextMenu, Menu } from 'react-contexify';

import {
  SpaceType,
  useSpace,
  useUpdateSpaceMaintenance,
} from '@portals/api/organizations';
import { usePermissionAccess } from '@portals/framework';
import { ReactComponent as BoxAdd } from '@portals/icons/linear/box-add.svg';
import { ReactComponent as Edit } from '@portals/icons/linear/edit.svg';
import { ReactComponent as Lock } from '@portals/icons/linear/lock.svg';
import { ReactComponent as MonitorMobile } from '@portals/icons/linear/monitor-mobbile.svg';
import { ReactComponent as Settings } from '@portals/icons/linear/setting-2.svg';
import { ReactComponent as Snooze } from '@portals/icons/linear/snooze.svg';
import { ReactComponent as Trash } from '@portals/icons/linear/trash.svg';
import { useOpenModal } from '@portals/redux';
import { suppressPropagation } from '@portals/utils';

import { canAdmin } from '../../../../../../lib/access';
import { useOpenClaimDeviceModal } from '../../../../../components/ClaimDeviceButton';
import {
  CreateCustomerSpaceModalProps,
  DeleteCustomerSpaceModalProps,
  SpaceMaintenanceActivationModalProps,
} from '../../../../../modals';
import { useOverviewRouting } from '../../../overview-routing.hooks';
import { OverviewSettingsTabEnum } from '../../../overview-settings-drawer/settings-drawer.types';

interface CustomerNodeContextMenuProps {
  nodeId: SpaceType['id'];
  onEditToggle: () => void;
  onCreateSpace: () => void;
  setIsContextMenuOpen: Dispatch<SetStateAction<boolean>>;
  canDeleteCustomer: boolean;
}

export function CustomerNodeContextMenu({
  nodeId,
  onEditToggle,
  onCreateSpace,
  setIsContextMenuOpen,
  canDeleteCustomer,
}: CustomerNodeContextMenuProps) {
  const { classes, cx } = useStyles();
  const openModal = useOpenModal();

  const { isAdmin } = usePermissionAccess();
  const openClaimDeviceModal = useOpenClaimDeviceModal();

  const overviewRouting = useOverviewRouting();

  const space = useSpace({ spaceId: nodeId });
  const updateSpaceMaintenance = useUpdateSpaceMaintenance();
  const isUnderMaintenance = space?.state?.maintenance || false;

  if (!space) {
    return null;
  }

  const handleSetMaintenance = () => {
    if (!isUnderMaintenance) {
      openModal<SpaceMaintenanceActivationModalProps['data']>(
        'SpaceMaintenanceActivationModal',
        { spaceId: space.id }
      );
    } else {
      updateSpaceMaintenance.mutate({
        spaceId: space.id,
        enabled: false,
      });
    }
  };

  return (
    <Menu
      id={nodeId}
      onVisibilityChange={(isVisible) => setIsContextMenuOpen(isVisible)}
    >
      <>
        <Stack className={classes.container} spacing="xs" pt="xs">
          <Group
            className={classes.item}
            spacing="xs"
            onClick={suppressPropagation(() => {
              handleSetMaintenance();
              contextMenu.hideAll();
            })}
            data-testid="menu-item-set-maintenance-on"
          >
            <Snooze />
            Set maintenance {isUnderMaintenance ? 'OFF' : 'ON'}
          </Group>

          <Group
            className={classes.item}
            spacing="xs"
            onClick={suppressPropagation(() => {
              openClaimDeviceModal({ spaceId: space.id });
              contextMenu.hideAll();
            })}
            data-testid="menu-item-claim-device"
          >
            <MonitorMobile />
            Claim device
          </Group>

          <Group
            className={classes.item}
            spacing="xs"
            onClick={() => {
              onCreateSpace();
              contextMenu.hideAll();
            }}
            data-testid="menu-item-add-new-space"
          >
            <BoxAdd />
            Add new space
          </Group>

          <Divider color="gray.3" />

          <Group
            className={classes.item}
            spacing="xs"
            onClick={suppressPropagation(() => {
              openModal<CreateCustomerSpaceModalProps['data']>(
                'CreateCustomerSpaceModal',
                {
                  logoUrl: space.icon_url,
                  customerName: space.name,
                  type: 'edit',
                  spaceId: space.id,
                }
              );

              contextMenu.hideAll();
            })}
            data-testid="menu-item-rename-space"
          >
            <Edit />
            Edit customer
          </Group>

          <Group
            data-testid="menu-item-manage-access"
            className={classes.item}
            spacing="xs"
            onClick={suppressPropagation(() => {
              overviewRouting.navigateToSpaceSettings(
                OverviewSettingsTabEnum.Access
              );
              contextMenu.hideAll();
            })}
          >
            <Lock />
            Manage Access
          </Group>

          {isAdmin || canAdmin(space) ? (
            <Group
              data-testid="menu-item-settings"
              className={classes.item}
              spacing="xs"
              onClick={suppressPropagation(() => {
                overviewRouting.navigateToSpaceSettings(
                  OverviewSettingsTabEnum.General
                );
                contextMenu.hideAll();
              })}
            >
              <Settings />
              Settings
            </Group>
          ) : null}

          <Divider color="gray.3" />

          <Tooltip
            label="You must have at least one customer"
            disabled={canDeleteCustomer}
            position="bottom"
            withArrow
            offset={20}
            data-testid="deletion-message"
          >
            <Box>
              <Group
                className={cx(classes.item, classes.removeItem, {
                  [classes.disabled]: !canDeleteCustomer,
                })}
                spacing="xs"
                onClick={suppressPropagation(() => {
                  if (!canDeleteCustomer) {
                    return;
                  }

                  contextMenu.hideAll();

                  openModal<DeleteCustomerSpaceModalProps['data']>(
                    'DeleteCustomerSpaceModal',
                    { space }
                  );
                })}
                data-testid="menu-item-delete-customer"
              >
                <Trash />
                Delete customer
              </Group>
            </Box>
          </Tooltip>
        </Stack>
      </>
    </Menu>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    zIndex: 999,
    padding: '0 4px',
    width: 224,
  },

  item: {
    flexShrink: 0,
    height: 33,
    padding: `0 ${theme.spacing.lg}`,
    fontSize: theme.fontSizes.sm,
    color: theme.colors.gray[9],
    fontWeight: 300,
    transition: 'all 0.15s ease-in-out',
    borderRadius: theme.radius.sm,
    cursor: 'pointer',

    svg: {
      height: 18,
      width: 18,
    },

    ':hover': {
      backgroundColor: theme.colors.gray[1],
    },
  },

  removeItem: {
    color: theme.colors.red_accent[4],
  },

  disabled: {
    opacity: 0.5,
    cursor: 'not-allowed',
    pointerEvents: 'none',
    color: theme.colors.gray[6],
  },
}));
