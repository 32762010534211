import { ClassNames, createStyles, Flex, Selectors, Text } from '@mantine/core';
import React, { ComponentProps } from 'react';

import { NameAbbreviationAvatar } from '@portals/core';

interface CustomerAvatarAndNameProps
  extends Omit<ComponentProps<typeof NameAbbreviationAvatar>, 'classNames'> {
  classNames?: ClassNames<Selectors<typeof useStyles>>;
  avatarClassNames?: ComponentProps<
    typeof NameAbbreviationAvatar
  >['classNames'];
}

export function CustomerAvatarAndName({
  className,
  classNames,
  avatarClassNames,
  name,
  ...nameAbbreviationAvatarProps
}: CustomerAvatarAndNameProps) {
  const { classes, cx } = useStyles();

  return (
    <Flex className={cx(classes.container, className)}>
      <NameAbbreviationAvatar
        name={name}
        radius="xl"
        withToolTip={false}
        classNames={avatarClassNames}
        {...nameAbbreviationAvatarProps}
      />
      <Text className={cx(classes.customerName, classNames?.customerName)}>
        {name}
      </Text>
    </Flex>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    alignItems: 'center',
    gap: theme.spacing.xs,
  },
  customerName: {},
}));
