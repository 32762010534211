import {
  AccessLevelEnum,
  IncidentPriorityLevel,
  IncidentStatus,
} from '@portals/types';

export enum IncidentClosedMethodEnum {
  Manually = 'manually',
  Automatically = 'automatically',
  Device = 'device',
  Integration = 'integration',
}

export interface IncidentType {
  assignee?: {
    id: string;
    name: string;
    is_active: boolean;
  } | null;
  device_id: string;
  status: IncidentStatus;
  id: string;
  priority: IncidentPriorityLevel;
  title: string;
  description: string;
  issue: string;
  customer_name: string;
  customer_icon_url: string | null;
  created_at: string;
  closed_at: string | null;
  closed_reason: string | null;
  closed_method: IncidentClosedMethodEnum | null;
  closed_by?: null | {
    id: string;
    name: string;
    email: string;
  };
  device_oem: string;
  ttc: string;
  space_id: number;
  urls: Record<string, string>;
  updated_at: string | null;
  access_level: AccessLevelEnum;
  device_model: string;
  device_name: string;
  org_display_name: string;
  partner_display_name: string;

  short_id?: string;
  snoozed_until?: string | null;
  space_name: string;
  space_tree_path_name: string;
  integrations: {
    service_now?: {
      open?: boolean;
      number?: string;
    };
  };
}

export interface IncidentsCountType {
  tooltip_messages: Array<string>;
  total_count: number;
  highest_priority: IncidentPriorityLevel | null;
}

export enum IncidentPriorityEnum {
  Critical = 'critical',
  High = 'high',
  Moderate = 'moderate',
  Low = 'low',
  Planning = 'planning',
}
