import { ColumnState } from 'ag-grid-community';

import { TenantType, UserPermissions } from '@portals/types';

interface BaseAccessibleTenant {
  id: string;
  name: string;
  display_name: string;
  logo_url: string | null;
}

export interface PartnerAccessibleTenant extends BaseAccessibleTenant {
  is_internal: boolean;
}

export interface OrganizationAccessibleTenant extends BaseAccessibleTenant {
  partner_name: string;
  partner_display_name: string;
  lab: boolean;
  is_sandbox: boolean;
  partner_internal: boolean;
}

interface UserSettingsType {
  is_welcome_videos_played?: boolean;
  profile_image?: string;
  is_mfa_notification_dismissed?: boolean;
  is_mfa_enabled?: boolean;
  tables_layouts?: {
    [TenantType.Organization]?: ColumnState[];
    [TenantType.Partner]?: ColumnState[];
  };
}

export interface UserResponseType {
  id: string;
  name: string;
  organization_display_name: string | null;
  partner_display_name: string | null;
  partner_id: string | null;
  email: string;
  sign_in_count: number;
  last_sign_in_at: string | null;
  last_seen_at: string | null;
  last_ip: string | null;
  created_at: string;
  group_names: string[];
  has_support_seat: boolean;
  deactivated_at: string | null;
  settings: UserSettingsType;
}

export enum FeatureNotificationEnum {
  FourteenDaysTrial = 'trial_started',
}

export interface SelfUserResponseType extends UserResponseType {
  super_admin: boolean;
  local_admin: boolean;
  access: string[];
  permissions: UserPermissions;
  is_external: boolean;
  feature_notifications: FeatureNotificationEnum[];
  accessible_tenants: {
    organizations: Array<OrganizationAccessibleTenant>;
    partners: Array<PartnerAccessibleTenant>;
  };
}

export interface WelcomeDetailsType {
  name: string;
  email: string;
  tenant_name: string;
}

export interface OrganizationWithUserAccess {
  logo_url: string;
  organization_display_name: string;
  organization_id: string;
}
