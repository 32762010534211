import {
  AvatarProps,
  Box,
  createStyles,
  Indicator,
  IndicatorProps,
  Menu,
  Stack,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import React from 'react';

import { NameAbbreviationAvatar } from '@portals/core';
import { ReactComponent as Edit } from '@portals/icons/linear/edit-3.svg';
import { ReactComponent as AddGallery } from '@portals/icons/linear/gallery-add.svg';
import { ReactComponent as MoreIcon } from '@portals/icons/linear/more.svg';
import { ReactComponent as Trash } from '@portals/icons/linear/trash.svg';

import { CreateCustomerFormValues } from './create-customer.types';

interface CustomerLogoWrapperProps {
  onUploadLogo: () => void;
  form: UseFormReturnType<CreateCustomerFormValues>;
}

export function CustomerLogoWrapper({
  onUploadLogo,
  form,
}: CustomerLogoWrapperProps) {
  const { classes } = useStyles();

  return (
    <Box className={classes.dropzoneContainer}>
      <Indicator
        label={
          form.values.logoUrl ? (
            <IndicatorMenu
              onRemoveLogo={() => form.setFieldValue('logoUrl', null)}
              onEdit={onUploadLogo}
            />
          ) : (
            <Edit color="black" onClick={onUploadLogo} />
          )
        }
        size={40}
        color="white"
        withBorder
        offset={10}
        styles={indicatorStyles}
      >
        <CustomerLogo
          logoUrl={form.values.logoUrl}
          customerName={form.values.customerName}
        />
      </Indicator>
    </Box>
  );
}

interface CustomerLogoProps {
  logoUrl: string | null;
  customerName: string;
}
function CustomerLogo({ logoUrl, customerName }: CustomerLogoProps) {
  const { classes } = useStyles();

  if (!logoUrl) {
    return (
      <Stack className={classes.innerDropzone} align="center" justify="center">
        <AddGallery />
      </Stack>
    );
  }

  return (
    <NameAbbreviationAvatar
      name={customerName}
      radius={98}
      size={130}
      withToolTip={false}
      src={logoUrl}
      p="sm"
      styles={avatarStyles}
    />
  );
}

interface IndicatorMenuProps {
  onRemoveLogo: () => void;
  onEdit: () => void;
}

function IndicatorMenu({ onRemoveLogo, onEdit }: IndicatorMenuProps) {
  return (
    <Menu position="bottom-start">
      <Menu.Target>
        <MoreIcon color="black" />
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Item icon={<Edit />} onClick={onEdit}>
          Edit
        </Menu.Item>
        <Menu.Item icon={<Trash />} onClick={onRemoveLogo}>
          Delete
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}

const useStyles = createStyles((theme) => ({
  innerDropzone: {
    width: 130,
    height: 130,
    backgroundColor: theme.colors.indigo_accent[0],
    opacity: 0.2,
    borderRadius: 100,
  },

  dropzoneContainer: {
    marginTop: theme.spacing.sm,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const indicatorStyles: IndicatorProps['styles'] = (theme) => ({
  common: {
    border: `1px solid ${theme.colors.gray[4]}`,
    cursor: 'pointer',

    '&:hover': {
      border: `1px solid ${theme.colors.gray[7]}`,
    },
  },
});

const avatarStyles: AvatarProps['styles'] = (theme) => ({
  root: {
    border: `2px solid ${theme.colors.gray[2]}`,
  },
});
