import React from 'react';

import { ScrollToTopButton } from '@portals/core';

import { useVirtualizerStyles } from './infinite-virtualized-view.styles';
import { InfiniteVirtualizedViewProps } from './infinite-virtualized-view.types';
import { TilesSkeleton } from './TilesSkeleton';
import { useRowVirtualizer } from './use-row-virtualizer';

interface TilesViewProps<TItem = unknown>
  extends Pick<
    InfiniteVirtualizedViewProps<TItem>,
    | 'flatItems'
    | 'isLoading'
    | 'hasNextPage'
    | 'fetchNextPage'
    | 'isFetchingNextPage'
    | 'scrollableRef'
  > {
  itemRenderer: InfiniteVirtualizedViewProps<TItem>['tileItemRenderer'];
  itemSkeleton: InfiniteVirtualizedViewProps<TItem>['tileItemSkeleton'];

  gap: number;
  overscan: number;
  tileHeight: number;
}

export function TilesView<TItem = unknown>({
  isLoading,
  hasNextPage,
  isFetchingNextPage,
  fetchNextPage,
  flatItems,
  scrollableRef,
  itemRenderer,
  itemSkeleton,
  gap,
  overscan,
  tileHeight,
}: TilesViewProps<TItem>) {
  const { classes } = useVirtualizerStyles();

  const { rowVirtualizer, showScrollToTopButton } = useRowVirtualizer<TItem>({
    scrollableRef,
    gap,
    overscan,
    estimateSize: () => tileHeight,

    flatItems,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  });

  return (
    <>
      {isLoading && (
        <TilesSkeleton
          count={rowVirtualizer.options.overscan}
          gap={rowVirtualizer.options.gap}
          itemSkeleton={itemSkeleton}
        />
      )}

      <div
        className={classes.virtualizerContainer}
        style={{ height: rowVirtualizer.getTotalSize() }}
      >
        {rowVirtualizer.getVirtualItems().map((virtualRow) => {
          const isLoaderRow = virtualRow.index > flatItems.length - 1;
          const item = flatItems[virtualRow.index];

          return (
            <div
              key={virtualRow.index}
              className={classes.virtualRow}
              style={{
                height: virtualRow.size,
                transform: `translateY(${virtualRow.start}px)`,
              }}
            >
              {isLoaderRow && hasNextPage ? (
                <TilesSkeleton
                  count={rowVirtualizer.options.overscan}
                  gap={rowVirtualizer.options.gap}
                  itemSkeleton={itemSkeleton}
                />
              ) : (
                itemRenderer(item)
              )}
            </div>
          );
        })}
      </div>

      <ScrollToTopButton
        visible={showScrollToTopButton}
        scrollToTop={() =>
          rowVirtualizer.scrollToIndex(0, { behavior: 'smooth' })
        }
      />
    </>
  );
}
