import {
  Badge,
  Box,
  Checkbox,
  createStyles,
  getStylesRef,
  rem,
  Stack,
  Text,
  ThemeIcon,
} from '@mantine/core';
import React from 'react';

import { AssetType } from '@portals/api/organizations';
import { TextWithTooltip } from '@portals/core';

import { ASSETS_ICONS_MAP } from '../../../assets/assets-icons-map';
import { AssetMenu } from '../../asset-menu/AssetMenu';
import { useOverviewAssetsContext } from '../OverviewAssetsProvider';

interface AssetCardProps {
  asset: AssetType;
}

export function AssetCard({ asset }: AssetCardProps) {
  const { classes, cx } = useStyles();

  const {
    selectedAssetIds,
    toggleSelectedAssetId,
    space,
    setActiveAssetId,
    activeAssetId,
  } = useOverviewAssetsContext();

  const AssetIcon = ASSETS_ICONS_MAP[asset.icon_name];

  return (
    <div
      onClick={() => setActiveAssetId(asset.id)}
      className={cx(classes.container, {
        [classes.active]: activeAssetId === asset.id,
      })}
    >
      <header className={classes.header}>
        <Checkbox
          onClick={(e) => e.stopPropagation()}
          checked={selectedAssetIds.has(asset.id)}
          onChange={() => toggleSelectedAssetId(asset.id)}
          className={cx({
            [classes.showOnHover]: !selectedAssetIds.has(asset.id),
          })}
        />

        <div className={classes.manufacturer}>
          <TextWithTooltip size="xl" label={asset.manufacturer?.name}>
            {asset.manufacturer?.name}
          </TextWithTooltip>
        </div>

        <div className={classes.showOnHover}>
          <AssetMenu asset={asset} space={space.data} />
        </div>
      </header>

      <Stack className={classes.content}>
        <Box ta="center" w="100%">
          <Text c="blue_gray.8" mb={6} truncate>
            {asset.name}
          </Text>

          <Text className={classes.deviceModel} truncate>
            {asset.device_model?.name}
          </Text>
        </Box>

        <ThemeIcon size={100} bg="gray.2" c="gray" radius="md">
          <AssetIcon width="40" height="40" />
        </ThemeIcon>

        {asset.status?.name && (
          <Badge className={cx(classes.badge, classes.statusBadge)}>
            {asset.status.name}
          </Badge>
        )}
      </Stack>
    </div>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    overflow: 'hidden',
    position: 'relative',
    padding: theme.spacing.xxl,
    border: `1px solid ${theme.colors.gray[3]}`,
    borderRadius: 24,
    transition: 'box-shadow 200ms ease-in-out',
    cursor: 'pointer',
    backgroundColor: theme.white,

    ...theme.fn.hover({
      boxShadow: theme.shadows.lg,

      [`.${getStylesRef('showOnHover')}`]: {
        opacity: 1,
      },
    }),
  },
  active: {
    borderColor: theme.fn.primaryColor(),
  },
  showOnHover: {
    ref: getStylesRef('showOnHover'),
    opacity: 0,
    transition: 'opacity 200ms ease-in-out',
  },
  header: {
    overflow: 'hidden',
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    gap: theme.spacing.md,
    placeItems: 'center',
    marginBottom: 6,
  },
  manufacturer: {
    overflow: 'hidden',
    width: '100%',
    textAlign: 'center',
  },
  content: {
    overflow: 'hidden',
    alignItems: 'center',
  },
  deviceModel: {
    // When there's no device_model, the cards content is not aligned
    // So, we're giving a fixed height according to the font-size and line-height
    height: `calc(1em * ${theme.lineHeight})`,
    color: theme.colors.blue_gray[3],
  },
  badge: {
    fontSize: theme.fontSizes.xs,
    fontWeight: 400,
    height: rem(28),
  },
  deviceTypeBadge: {
    color: theme.colors.gray[5],
    borderColor: theme.colors.gray[3],
  },
  statusBadge: {
    color: theme.white,
    backgroundColor: theme.fn.rgba(theme.colors.gray[9], 0.7),
  },
}));
