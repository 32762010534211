import { SelectProps } from '@mantine/core';
import { useEffect } from 'react';

import { useDeviceModels } from '@portals/api/organizations';
import {
  useActionDispatch,
  useIntegrationByName,
  useIsPending,
} from '@portals/redux';
import { StateType } from '@portals/types';

import { reloadIntegration } from '../../../redux/actions/integrations';

interface UseCloud2CloudFormDataParams<
  IntegrationName extends keyof StateType['data']['integrations']
> {
  integrationName: IntegrationName;
  composeOptions: (
    integrations: StateType['data'][IntegrationName]
  ) => SelectProps['data'];
}
export function useCloud2CloudFormData<
  IntegrationName extends keyof StateType['data']['integrations']
>({
  integrationName,
  composeOptions,
}: UseCloud2CloudFormDataParams<IntegrationName>) {
  const integration = useIntegrationByName(integrationName);
  const fetchIntegration = useActionDispatch(reloadIntegration);
  const isLoading = useIsPending(`reloadIntegration${integrationName}`);
  const options = composeOptions(integration);
  const showConfigurationMissingMessage =
    !integration || integration?.state !== 'active';

  useEffect(() => {
    fetchIntegration({ type: integrationName, silent: true, withError: false });
  }, [fetchIntegration, integrationName]);

  return {
    integration,
    fetchIntegration,
    isLoading,
    options,
    showConfigurationMissingMessage,
  };
}

export function useClaimableDevicesModels() {
  const deviceModels = useDeviceModels();

  return (deviceModels.data ?? []).filter((deviceModel) => {
    // Automatically generated models are created by edges and need to be manually approved
    // before being available to everyone
    return !deviceModel.automatically_generated;
  });
}
