import { Badge, createStyles, Group, Paper, Stack, Text } from '@mantine/core';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ComingSoonIntegrationModalProps } from '@portals/framework/modals';
import { ReactComponent as Clock } from '@portals/icons/linear/clock-2.svg';
import { useOpenModal } from '@portals/redux';
import { IntegrationState } from '@portals/types';

import { IntegrationType } from './integrations.types';

interface IntegrationCardProps {
  integration: IntegrationType;
}

export function IntegrationCard({ integration }: IntegrationCardProps) {
  const { classes } = useStyles();

  const openModal = useOpenModal();
  const navigate = useNavigate();

  const onIntegrationClick = () => {
    if (integration.isComingSoon) {
      openModal<ComingSoonIntegrationModalProps['data']>(
        'ComingSoonIntegrationModal',
        {
          integrationName: integration.name,
          logo: integration.logo,
        }
      );

      return;
    }

    if (integration.link) {
      navigate(integration.link);
    }
  };

  return (
    <Paper
      p="xl"
      radius="lg"
      className={classes.container}
      withBorder
      onClick={onIntegrationClick}
      data-analyticsid={integration.name}
      data-testid={integration.name}
    >
      <Stack>
        <Group position="apart" align="flex-start">
          <img
            loading="lazy"
            src={integration.logo}
            width={50}
            height={50}
            className={classes.logo}
          />

          <IntegrationStatus
            status={integration.status}
            isComingSoon={integration.isComingSoon}
          />
        </Group>

        <Stack spacing={4}>
          <Text fz="lg" fw={500}>
            {integration.name}
          </Text>
        </Stack>

        <Text c="gray.7">{integration.description}</Text>

        {integration.isComingSoon ? (
          <Text underline fw={500} c="gray.9">
            Request this integration
          </Text>
        ) : null}
      </Stack>
    </Paper>
  );
}

interface IntegrationStatusProps {
  status: IntegrationState | undefined;
  isComingSoon: boolean;
}

function IntegrationStatus({ status, isComingSoon }: IntegrationStatusProps) {
  if (isComingSoon) {
    return (
      <Badge
        p="xs"
        fz={10}
        fw={400}
        bg="gray.2"
        size="xl"
        leftSection={<Clock height={16} width={16} />}
      >
        Coming soon
      </Badge>
    );
  }

  if (status === 'active') {
    return (
      <Badge p="xs" fz={10} fw={400} bg="teal.0" size="xl">
        Enabled
      </Badge>
    );
  }

  if (status === 'error') {
    return (
      <Badge p="xs" fz={10} fw={400} bg="red.1" size="xl">
        Error
      </Badge>
    );
  }

  return null;
}

const useStyles = createStyles((theme) => ({
  container: {
    cursor: 'pointer',

    '&:hover': {
      boxShadow: `0px 11px 18px rgba(38, 50, 56, 0.1)`,
    },

    '&[data-with-border]': {
      borderColor: theme.colors.gray[2],
    },
  },

  logo: {
    objectFit: 'contain',
  },
}));
