import { Box, createStyles, Paper, Text } from '@mantine/core';
import React, { FC, useMemo } from 'react';

import { useDevice } from '@portals/api/organizations';
import { NewWidgetType } from '@portals/device-widgets';
import { PremiumCommandLicenseModalProps } from '@portals/framework/modals';
import { ReactComponent as Crown2 } from '@portals/icons/bold/crown-2.svg';
import { useOpenModal } from '@portals/redux';

import { getDeviceWidgetWrapperRenderer } from './device-widgets.constants';
import { WidgetProps } from './device-widgets.types';
import { DeviceWidgetErrorBoundary } from './DeviceWidgetErrorBoundary';

interface WidgetWrapperProps {
  widget: NewWidgetType;
  deviceId: string;
  modelId: string;
}

export function DeviceWidgetWrapper({
  widget,
  deviceId,
  modelId,
}: WidgetWrapperProps) {
  const { classes, theme } = useStyles();

  const { config } = widget;

  const device = useDevice(deviceId, {
    staleTime: 5000,
    refetchInterval: 5000,
  });

  const isDeviceOffline =
    device.isFetched && device.data?.state?.status === 'offline';

  const Widget = getDeviceWidgetWrapperRenderer(config.id) as FC<
    WidgetProps<any>
  >;

  const openModal = useOpenModal();

  const adjustedState = useMemo(() => {
    if (!device.isFetched) return {};

    if (isDeviceOffline) {
      return {
        ...(device.data?.details || {}),
        ...device.data?.last_known_state,
      };
    } else {
      return {
        ...(device.data?.details || {}),
        ...device.data?.state,
      };
    }
  }, [device.data, device.isFetched, isDeviceOffline]);

  const isWidgetWithPremiumCommand = useMemo(
    () =>
      device.data?.supported_commands.some(
        (command) =>
          command.name === (config?.fields as any)?.command_name &&
          command.premium &&
          !command.has_license &&
          config.id !== 'grouped_switches'
      ),
    [config?.fields, config.id, device.data?.supported_commands]
  );

  const { commandId, commandName } = useMemo(() => {
    const command = device.data?.supported_commands.find(
      (command) => command.name === (config?.fields as any)?.command_name
    );

    return {
      commandId: command?.id,
      commandName: command?.name,
    };
  }, [config?.fields, device.data?.supported_commands]);

  if (device.isError) {
    return (
      <Paper p="md" radius="lg" h="100%">
        <Text weight={600} size="md" mb="xs">
          Oops...
        </Text>

        <Text>An error occurred while trying to fetch widget data</Text>
      </Paper>
    );
  }

  return (
    <DeviceWidgetErrorBoundary widgetId={widget.id}>
      <Widget
        widget={widget}
        deviceState={adjustedState}
        deviceId={deviceId}
        modelId={modelId}
        isDeviceOffline={isDeviceOffline}
        lastUpdateTimestamp={device?.data?.last_seen || ''}
      />

      {isWidgetWithPremiumCommand ? (
        <Box
          className={classes.iconContainer}
          onClick={() =>
            openModal<PremiumCommandLicenseModalProps['data']>(
              'PremiumCommandLicenseModal',
              { deviceId, commandId, commandName }
            )
          }
        >
          <Crown2 color={theme.colors.blue_accent[4]} width={18} height={18} />
        </Box>
      ) : null}
    </DeviceWidgetErrorBoundary>
  );
}

const useStyles = createStyles((theme) => ({
  iconContainer: {
    backgroundColor: theme.colors.blue_accent[0],
    padding: theme.spacing.xs,
    position: 'absolute',
    top: -12,
    right: -10,
    borderRadius: '50%',
    boxShadow: '0px 2px 8px 0px rgba(38, 50, 56, 0.13)',
    cursor: 'pointer',
  },
}));
