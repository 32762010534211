import {
  Box,
  Group,
  Indicator,
  IndicatorProps,
  Text,
  Tooltip,
} from '@mantine/core';
import React from 'react';

import { DeviceStatusType } from '@portals/types';

import { DeviceAvatar } from './DeviceAvatar';
import { getDeviceName, getDeviceStatusMetadata } from '../route-modals';

interface DeviceAvatarWithIndicatorProps
  extends React.ComponentProps<typeof DeviceAvatar> {
  deviceName: string;
  deviceStatus: DeviceStatusType | undefined;
}

export function DeviceAvatarWithIndicator({
  deviceStatus,
  deviceName,
  icon,
  ...deviceAvatarProps
}: DeviceAvatarWithIndicatorProps) {
  const deviceStatusMetadata = getDeviceStatusMetadata(deviceStatus);

  return (
    <Indicator
      inline
      withBorder
      size={20}
      styles={indicatorStyles}
      color={deviceStatusMetadata.indicator}
    >
      <Tooltip
        withArrow
        arrowSize={5}
        position="bottom"
        label={
          <Group noWrap>
            <Text size="xs">{getDeviceName(deviceName)}</Text>
            <Text size="xs" color="blue_gray.2" transform="capitalize">
              {deviceStatusMetadata.displayName}
            </Text>
          </Group>
        }
      >
        <Box>
          <DeviceAvatar icon={icon} {...deviceAvatarProps} />
        </Box>
      </Tooltip>
    </Indicator>
  );
}

const indicatorStyles: IndicatorProps['styles'] = () => ({
  root: { cursor: 'pointer', zIndex: 0 },
  indicator: { borderWidth: 5 },
});
