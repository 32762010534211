import {
  Alert,
  AlertProps,
  Anchor,
  createStyles,
  Group,
  Stack,
  Text,
} from '@mantine/core';
import React, { ReactNode } from 'react';

import { SpaceType } from '@portals/api/organizations';
import { useOpenModal } from '@portals/redux';

import { type ChangeSpaceModalProps } from '../../ChangeSpaceModal';

export interface ChangeSpaceFieldProps {
  title?: string;
  error?: ReactNode;
  space?: SpaceType | undefined;
  onSetSpaceId: (spaceId: SpaceType['id']) => void;
  allowAllSpaces?: boolean;
}

export function ChangeSpaceField({
  title = 'Add device to',
  space,
  onSetSpaceId,
  error,
  allowAllSpaces = false,
}: ChangeSpaceFieldProps) {
  const { classes, cx } = useStyles();
  const openModal = useOpenModal();

  return (
    <Stack spacing="xs">
      <Alert
        title={title}
        color="gray.0"
        styles={alertStyles}
        radius="lg"
        p="xl"
        className={cx({ [classes.error]: Boolean(error) })}
      >
        <Group position="apart">
          <Text color="gray.9" data-testid="customer-space-name">
            {space?.name || <Text color="gray.6">No space selected</Text>}
          </Text>

          <Anchor
            data-testid="change-space-button"
            onClick={() =>
              openModal<ChangeSpaceModalProps['data']>('ChangeSpaceModal', {
                onSetSpaceId,
                space,
                allowAllSpaces,
              })
            }
          >
            Change space
          </Anchor>
        </Group>
      </Alert>

      {error ? (
        <Text color="red" size="xs">
          {error}
        </Text>
      ) : null}
    </Stack>
  );
}

const alertStyles: AlertProps['styles'] = (theme) => ({
  title: {
    color: theme.colors.gray[6],
  },
  root: {
    border: `1px solid ${theme.colors.gray[2]}`,
  },
});

const useStyles = createStyles((theme) => ({
  error: {
    borderColor: theme.colors.red[4],
  },
}));
