import { useForm, UseFormReturnType } from '@mantine/form';
import { useSessionStorage } from '@mantine/hooks';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useEffectOnce } from 'react-use';

import {
  InfiniteVirtualizedViewDisplayType,
  useInfiniteVirtualizedViewDisplayType,
} from '@lib/infinite-virtualized-view';
import {
  AssetType,
  useAssetsInfiniteQuery,
  useSpaceDetails,
} from '@portals/api/organizations';
import { DebouncedSearchInputHandle } from '@portals/core';
import {
  PaginatedFilterTypeEnum,
  PaginatedQueryParamsType,
} from '@portals/types';

import { useOverviewRouteParams } from '../overview-routing.hooks';
import { useOverviewContext } from '../overview.context';

interface FormValues {
  searchTerm: string;

  statuses: string[];
  manufacturers: string[];
  deviceModels: string[];
  deviceTypes: string[];

  purchasedAt: [Date | null, Date | null];

  sortByField: string;
  sortByDirection: 'asc' | 'desc';
}

interface PersistedFilters {
  formValues: FormValues;
  displayType: InfiniteVirtualizedViewDisplayType;
}

interface OverviewAssetsContextType {
  space: ReturnType<typeof useSpaceDetails>;
  totalAssetsCount: number;

  form: UseFormReturnType<FormValues>;
  searchInputRef: React.Ref<DebouncedSearchInputHandle>;
  clearFilters: VoidFunction;

  isLoading: boolean;
  isFetchingNextPage: boolean;
  hasNextPage: ReturnType<typeof useAssetsInfiniteQuery>['hasNextPage'];
  fetchNextPage: ReturnType<typeof useAssetsInfiniteQuery>['fetchNextPage'];
  paginatedAssets: ReturnType<typeof useAssetsInfiniteQuery>['data'];
  allAssets: Array<AssetType>;

  selectedAssetIds: Set<string>;
  toggleSelectedAssetId: (assetId: string) => void;
  clearSelectedAssetIds: VoidFunction;

  displayType: InfiniteVirtualizedViewDisplayType;
  setDisplayType: (displayType: InfiniteVirtualizedViewDisplayType) => void;

  activeAssetId: string | null;
  setActiveAssetId: (assetId: string | null) => void;
}

const OverviewAssetsContext = createContext<OverviewAssetsContextType | null>(
  null
);

const FORM_INITIAL_VALUES: FormValues = {
  searchTerm: '',
  statuses: [],
  manufacturers: [],
  deviceModels: [],
  deviceTypes: [],
  purchasedAt: [null, null],
  sortByDirection: 'asc',
  sortByField: 'name',
};

interface OverviewAssetsProviderProps {
  children: ReactNode;
}

export function OverviewAssetsProvider({
  children,
}: OverviewAssetsProviderProps) {
  const searchInputRef = useRef<DebouncedSearchInputHandle>(null);

  const params = useOverviewRouteParams();

  const overview = useOverviewContext();
  const space = useSpaceDetails(Number(params.spaceId));

  /** Holds the active asset ID for the details panel */
  const [activeAssetId, setActiveAssetId] = useState<string | null>(null);

  const [persistedFilters, setPersistedFilters] =
    useSessionStorage<PersistedFilters>({
      key: 'overview_assets_filters',
      defaultValue: { formValues: FORM_INITIAL_VALUES, displayType: 'list' },
      getInitialValueInEffect: false,
    });

  const [displayType, setDisplayType] = useInfiniteVirtualizedViewDisplayType(
    persistedFilters.displayType
  );

  const form = useForm<FormValues>({
    initialValues: transformPersistedFormValues(persistedFilters.formValues),
  });

  useEffectOnce(function resetFormInitialDirtyValues() {
    form.resetDirty(FORM_INITIAL_VALUES);
  });

  useEffect(
    function syncPersistedFilters() {
      setPersistedFilters({ formValues: form.values, displayType });
    },
    [displayType, form.values, setPersistedFilters]
  );

  const composeFilters = useCallback(() => {
    if (!space.data) return;

    const filters: PaginatedQueryParamsType<AssetType>['filters'] = [];

    if (form.values.searchTerm) {
      filters.push({
        id: 'name_or_device_model_name_or_device_type_name_or_manufacturer_name',
        value: form.values.searchTerm,
        type: PaginatedFilterTypeEnum.Text,
      });
    }

    if (form.values.statuses.length > 0) {
      filters.push({
        id: 'status_id',
        value: form.values.statuses,
        type: PaginatedFilterTypeEnum.Select,
      });
    }

    if (form.values.manufacturers.length > 0) {
      filters.push({
        id: 'manufacturer_id',
        value: form.values.manufacturers,
        type: PaginatedFilterTypeEnum.Select,
      });
    }

    if (form.values.deviceModels.length > 0) {
      filters.push({
        id: 'device_model_id',
        value: form.values.deviceModels,
        type: PaginatedFilterTypeEnum.Select,
      });
    }

    if (form.values.deviceTypes.length > 0) {
      filters.push({
        id: 'device_type_id',
        value: form.values.deviceTypes,
        type: PaginatedFilterTypeEnum.Select,
      });
    }

    if (form.values.purchasedAt.every(Boolean)) {
      filters.push({
        id: 'purchased_at',
        value: {
          gte: form.values.purchasedAt[0],
          lte: form.values.purchasedAt[1],
        },
        type: PaginatedFilterTypeEnum.Date,
      });
    }

    if (overview.isLocalDataLevel) {
      filters.push({
        id: 'space_id',
        type: PaginatedFilterTypeEnum.Eq,
        value: space.data.id,
      });
    } else {
      filters.push({
        id: 'space_path',
        value: `"${encodeURIComponent(space.data.path.join('.'))}"`,
        type: PaginatedFilterTypeEnum.LtreeStartsWith,
      });
    }

    return filters;
  }, [
    form.values.deviceModels,
    form.values.deviceTypes,
    form.values.manufacturers,
    form.values.purchasedAt,
    form.values.searchTerm,
    form.values.statuses,
    overview.isLocalDataLevel,
    space.data,
  ]);

  const composeSort = useCallback(() => {
    return [
      {
        id: form.values.sortByField,
        desc: form.values.sortByDirection === 'desc',
      },
    ] satisfies PaginatedQueryParamsType<AssetType>['sorting'];
  }, [form.values.sortByDirection, form.values.sortByField]);

  const [selectedAssetIds, setSelectedAssetIds] = useState(new Set<string>());
  const toggleSelectedAssetId = useCallback((assetId: string) => {
    setSelectedAssetIds((prevSelectedAssetIds) => {
      const newSelectedAssetIds = new Set(prevSelectedAssetIds);

      if (newSelectedAssetIds.has(assetId)) {
        newSelectedAssetIds.delete(assetId);
      } else {
        newSelectedAssetIds.add(assetId);
      }

      return newSelectedAssetIds;
    });
  }, []);

  const clearSelectedAssetIds = useCallback(() => {
    setSelectedAssetIds(new Set());
  }, []);

  const clearFilters = useCallback(() => {
    form.setValues(FORM_INITIAL_VALUES);
    searchInputRef.current?.reset();
  }, [form]);

  useEffect(
    function resetSelectedAssetIds() {
      // Whenever the user changes the filters/sort/search, we reset the selected asset ids
      clearSelectedAssetIds();
    },
    [clearSelectedAssetIds, form.values]
  );

  const {
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    data: paginatedAssets,
    isInitialLoading,
  } = useAssetsInfiniteQuery(
    {
      pagination: { page: 0, pageSize: 10 },
      filters: composeFilters(),
      sorting: composeSort(),
    },
    { enabled: space.data !== undefined }
  );

  const allAssets = useMemo(() => {
    if (!paginatedAssets) return [];

    return paginatedAssets.pages.flatMap((assetsPage) => assetsPage.data);
  }, [paginatedAssets]);

  const totalAssetsCount =
    paginatedAssets?.pages?.[0]?.page_info?.total_count || 0;

  return (
    <OverviewAssetsContext.Provider
      value={{
        form,
        searchInputRef,
        clearFilters,
        space,
        totalAssetsCount,

        allAssets,
        paginatedAssets,
        hasNextPage,
        fetchNextPage,
        isFetchingNextPage,
        isLoading: isInitialLoading || space.isInitialLoading,

        selectedAssetIds,
        toggleSelectedAssetId,
        clearSelectedAssetIds,

        displayType,
        setDisplayType,

        activeAssetId,
        setActiveAssetId,
      }}
    >
      {children}
    </OverviewAssetsContext.Provider>
  );
}

export function useOverviewAssetsContext() {
  const context = useContext(OverviewAssetsContext);

  if (context === null) {
    throw new Error(
      'useOverviewAssetsContext must be used within OverviewAssetsProvider'
    );
  }

  return context;
}

function transformPersistedFormValues(
  persistedFormValues: PersistedFilters['formValues']
): FormValues {
  const purchasedAt: FormValues['purchasedAt'] = [null, null];

  if (
    persistedFormValues.purchasedAt[0] &&
    persistedFormValues.purchasedAt[1]
  ) {
    // Dates are saved as strings in the `sessionStorage`,
    // we need to transform them into `Date` objects for the DatePickerInput
    purchasedAt[0] = new Date(persistedFormValues.purchasedAt[0]);
    purchasedAt[1] = new Date(persistedFormValues.purchasedAt[1]);
  }

  return { ...persistedFormValues, purchasedAt };
}
