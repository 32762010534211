import { Divider, Flex, FlexProps, MantineColor } from '@mantine/core';
import React, { forwardRef } from 'react';

import { NameAbbreviationAvatar, TextWithTooltip } from '@portals/core';
import { suppressPropagation } from '@portals/utils';

import { NO_ASSIGNEE_OPTION } from './assignee-select.constants';

interface SelectItemProps extends FlexProps {
  value: string;
  label: string;
  avatarSize?: number;
  color: MantineColor;
  imageUrl: string;
}

export const AssigneeSelectItem = forwardRef<HTMLDivElement, SelectItemProps>(
  (
    {
      value,
      label,
      color,
      imageUrl,
      avatarSize = 18,
      onMouseDown,
      ...selectItemProps
    },
    ref
  ) => {
    const isUnassign = value === NO_ASSIGNEE_OPTION.value;

    return (
      <>
        <Flex
          ref={ref}
          align="center"
          gap="xs"
          px="md"
          py="xs"
          onMouseDown={suppressPropagation(onMouseDown)}
          {...selectItemProps}
        >
          <NameAbbreviationAvatar
            color={isUnassign ? 'gray.5' : color}
            name={isUnassign ? '' : label}
            src={imageUrl}
            radius="xl"
            size={avatarSize}
            withToolTip={false}
            mr="sm"
          />
          <TextWithTooltip c="gray.9">{label}</TextWithTooltip>
        </Flex>
        {isUnassign && <Divider />}
      </>
    );
  }
);
