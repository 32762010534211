import {
  Button,
  ButtonProps,
  CloseButton,
  Divider,
  Grid,
  Group,
  Indicator,
  Popover,
  ScrollArea,
  Select,
  Stack,
  Text,
} from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import React, { useState } from 'react';

import { EntityLabelMultiSelect } from '@portals/framework';
import { ReactComponent as Element3Icon } from '@portals/icons/bold/element-3.svg';
import { ReactComponent as CalendarIcon } from '@portals/icons/linear/calendar.svg';
import { ReactComponent as ListIcon } from '@portals/icons/linear/list.svg';
import { ReactComponent as Setting4Icon } from '@portals/icons/linear/setting-4.svg';

import { useOverviewAssetsContext } from './OverviewAssetsProvider';
import { DisplayTypeCard } from '../overview-devices/device-filters/DisplayTypeCard';

const SORT_BY_OPTIONS = [
  { value: 'name', label: 'Name' },
  { value: 'manufacturer_name', label: 'Manufacturer' },
  { value: 'device_model_name', label: 'Model' },
  { value: 'device_type_name', label: 'Type' },
  { value: 'status_name', label: 'Status' },
];

const SORT_DIRECTION_OPTIONS = [
  { value: 'asc', label: 'Ascending' },
  { value: 'desc', label: 'Descending' },
];

export function AssetFilters() {
  const [isOpen, setIsOpen] = useState(false);

  const { form, displayType, setDisplayType, clearFilters } =
    useOverviewAssetsContext();

  return (
    <Popover
      opened={isOpen}
      onChange={setIsOpen}
      withinPortal
      position="left-start"
      width={470}
      radius="lg"
      shadow="xl"
      trapFocus
    >
      <Popover.Target>
        <Indicator offset={2} disabled={!form.isDirty()}>
          <Button
            variant="default"
            leftIcon={<Setting4Icon width={16} height={16} />}
            onClick={() => setIsOpen((prev) => !prev)}
            data-testid="assets-filter-button"
          >
            Filters
          </Button>
        </Indicator>
      </Popover.Target>

      <Popover.Dropdown p={0}>
        <ScrollArea.Autosize mah="90dvh" type="auto" offsetScrollbars={true}>
          <Stack spacing="xl" py="xl" px="xxl">
            <Group position="apart">
              <Text fw={500} fz="md">
                Filters
              </Text>

              <CloseButton onClick={() => setIsOpen(false)} />
            </Group>

            <Group grow>
              <DisplayTypeCard
                icon={<ListIcon />}
                label="List"
                isActive={displayType === 'list'}
                onClick={() => setDisplayType('list')}
              />

              <DisplayTypeCard
                icon={<Element3Icon />}
                label="Grid"
                isActive={displayType === 'grid'}
                onClick={() => setDisplayType('grid')}
              />
            </Group>

            <EntityLabelMultiSelect
              label="Status"
              placeholder="Select statuses"
              category="asset_status"
              {...form.getInputProps('statuses')}
            />

            <EntityLabelMultiSelect
              label="Manufacturer"
              placeholder="Select manufacturers"
              category="asset_manufacturer"
              {...form.getInputProps('manufacturers')}
            />

            <EntityLabelMultiSelect
              label="Model"
              placeholder="Select models"
              category="asset_device_model"
              {...form.getInputProps('deviceModels')}
            />

            <EntityLabelMultiSelect
              label="Type"
              placeholder="Select types"
              category="asset_device_type"
              {...form.getInputProps('deviceTypes')}
            />

            <DatePickerInput<'range'>
              type="range"
              allowSingleDateInRange
              label="Purchased at"
              placeholder="Select a date range"
              icon={<CalendarIcon />}
              {...form.getInputProps('purchasedAt')}
            />

            <Grid columns={10} align="end">
              <Grid.Col span={6}>
                <Select
                  label="Sort by"
                  placeholder="Sort by"
                  data={SORT_BY_OPTIONS}
                  {...form.getInputProps('sortByField')}
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <Select
                  placeholder="Sort order"
                  data={SORT_DIRECTION_OPTIONS}
                  {...form.getInputProps('sortByDirection')}
                />
              </Grid.Col>
            </Grid>

            <Divider color="gray.3" />

            <Button
              variant="white"
              styles={resetButtonStyles}
              onClick={clearFilters}
            >
              Reset to default
            </Button>
          </Stack>
        </ScrollArea.Autosize>
      </Popover.Dropdown>
    </Popover>
  );
}

const resetButtonStyles: ButtonProps['styles'] = {
  root: {
    height: 20,
    padding: 0,
    alignSelf: 'flex-start',
  },
  label: {
    fontWeight: 500,
  },
};
