import {
  CloseButton,
  createStyles,
  Flex,
  Group,
  SegmentedControl,
  SegmentedControlProps,
  Stack,
} from '@mantine/core';
import React from 'react';

import { MapCoordinatesType } from '@portals/types';

import { DeviceLocationWidget, DeviceLocationWidgetProps } from '../widget';
import { SuperClusterPoint } from '../widget/device-location-widget.types';

interface DeviceLocationWidgetPreviewProps {
  mapWidgetId?: string;
  title: string;
  showLocationName: boolean;
  parentDeviceMarkerColor: string;
  isDeviceOffline: boolean;
  lastUpdateTimestamp?: string;
  points: SuperClusterPoint[];
  base: MapCoordinatesType;
  stackProps: DeviceLocationWidgetProps['stackProps'];
  shouldDisplaySegmentedControl: boolean;
  segmentedControlsDimensionsOptions?: { value: string; label: string }[];
  activeDimensionId?: string;
  setActiveDimensionId: (id: string) => void;
  onClose: () => void;
}

export function DeviceLocationWidgetPreview({
  mapWidgetId,
  title,
  showLocationName,
  parentDeviceMarkerColor,
  isDeviceOffline,
  lastUpdateTimestamp,
  points,
  base,
  stackProps,
  shouldDisplaySegmentedControl,
  segmentedControlsDimensionsOptions,
  activeDimensionId,
  setActiveDimensionId,
  onClose,
}: DeviceLocationWidgetPreviewProps) {
  const { classes } = useStyles();

  return (
    <Stack className={classes.preview} p={30} w="100%">
      <Group position="right">
        <CloseButton size="xl" onClick={onClose} />
      </Group>
      <Stack h="100%" justify="center">
        <Flex
          bg="white"
          direction="column"
          gap="sm"
          p="xl"
          className={classes.mapContainer}
        >
          {shouldDisplaySegmentedControl &&
            segmentedControlsDimensionsOptions && (
              <SegmentedControl
                key={segmentedControlsDimensionsOptions?.length}
                value={activeDimensionId}
                data={segmentedControlsDimensionsOptions}
                onChange={setActiveDimensionId}
                fullWidth
                styles={segmentedControlStyles}
              />
            )}

          <DeviceLocationWidget
            mapWidgetId={mapWidgetId}
            title={title}
            showLocationName={showLocationName}
            parentDeviceMarkerColor={parentDeviceMarkerColor}
            isDeviceOffline={isDeviceOffline}
            lastUpdateTimestamp={lastUpdateTimestamp}
            points={points}
            base={base}
            stackProps={{
              h: 387,
              w: '100%',
              p: 0,
              ...stackProps,
            }}
          />
        </Flex>
      </Stack>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  preview: {
    gridArea: 'preview',
    backgroundColor: theme.colors.gray[0],
  },
  mapContainer: {
    borderRadius: theme.radius.lg,
    flex: 1,
  },
}));

const segmentedControlStyles: SegmentedControlProps['styles'] = {
  root: {
    minHeight: '2rem',
  },
  control: {
    overflow: 'hidden',
  },
};
