import {
  ClassNames,
  createStyles,
  rem,
  Selectors,
  Stack,
  StackProps,
  Text,
} from '@mantine/core';
import React, { ReactNode } from 'react';

import emptyStateBox from './empty-state-box.svg';

interface EmptyStateProps extends StackProps {
  messageTitle?: ReactNode;
  messageDescription?: ReactNode;
  media?: ReactNode;
  action?: ReactNode;
  classNames?: ClassNames<Selectors<typeof useStyles>>;
}

export function EmptyState({
  messageTitle,
  messageDescription,
  action,
  media,
  className,
  classNames,
  ...stackProps
}: EmptyStateProps) {
  const { classes, cx } = useStyles();

  return (
    <Stack className={cx(classes.root, classNames?.root)} {...stackProps}>
      {media ?? (
        <img
          width={150}
          height="auto"
          src={emptyStateBox}
          alt="empty box illustration"
        />
      )}

      <Stack className={cx(classes.content, classNames?.content)}>
        {(messageTitle || messageDescription) && (
          <Stack className={cx(classes.textsWrapper, classNames?.textsWrapper)}>
            {messageTitle && (
              <Text
                data-testid="empty-page-title"
                className={cx(classes.title, classNames?.title)}
              >
                {messageTitle}
              </Text>
            )}

            {messageDescription && (
              <Text
                className={cx(classes.description, classNames?.description)}
              >
                {messageDescription}
              </Text>
            )}
          </Stack>
        )}

        {action}
      </Stack>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing.xl,
  },
  content: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  textsWrapper: {
    gap: rem(6),
    fontWeight: 500,
    textAlign: 'center',
  },
  title: {
    color: theme.colors.gray[8],
  },
  description: {
    fontSize: theme.fontSizes.xs,
    color: theme.colors.gray[5],
  },
}));
