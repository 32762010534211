import {
  ActionIcon,
  Divider,
  Menu,
  MenuProps,
  useMantineTheme,
} from '@mantine/core';
import React from 'react';

import {
  DeviceWarrantyType,
  useDeleteDeviceWarranty,
} from '@portals/api/organizations';
import { useConfirmationModal } from '@portals/framework';
import { ReactComponent as More1 } from '@portals/icons/bold/more-1.svg';
import { ReactComponent as DocumentText } from '@portals/icons/linear/document-text.svg';
import { ReactComponent as Edit3 } from '@portals/icons/linear/edit-3.svg';
import { ReactComponent as Trash } from '@portals/icons/linear/trash.svg';
import { useOpenModal } from '@portals/redux';

import { useOpenDocument } from '../../../../../hooks/upload-file';
import { UploadDeviceWarrantyModalData } from '../../../../modals';

interface WarrantyCardMenuProps {
  warranty: DeviceWarrantyType;
}

export function WarrantyCardMenu({ warranty }: WarrantyCardMenuProps) {
  const theme = useMantineTheme();
  const openModal = useOpenModal();

  const asyncConfirmation = useConfirmationModal();
  const deleteDeviceWarranty = useDeleteDeviceWarranty(warranty.device_id);

  const openDocument = useOpenDocument();

  const onDeleteWarranty = async () => {
    const isConfirmed = await asyncConfirmation({
      description: `Are you sure you want the delete "${warranty.name}"?`,
    });

    if (isConfirmed) {
      deleteDeviceWarranty.mutate(warranty.id);
    }
  };

  const onViewDocument = () => {
    openDocument({
      url: warranty.warranty_document_url,
      onConfirm: () => window.open(warranty.warranty_document_url, '_blank'),
    });
  };

  return (
    <Menu styles={menuStyles}>
      <Menu.Target>
        <ActionIcon color="gray">
          <More1 color={theme.colors.gray[5]} />
        </ActionIcon>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Item
          icon={<Edit3 />}
          onClick={() =>
            openModal<UploadDeviceWarrantyModalData>(
              'UploadDeviceWarrantyModal',
              {
                deviceId: warranty.device_id,
                warrantyToEdit: warranty,
              }
            )
          }
        >
          Edit
        </Menu.Item>

        <Menu.Item icon={<DocumentText />} onClick={onViewDocument}>
          View Document
        </Menu.Item>

        <Divider />

        <Menu.Item color="red" icon={<Trash />} onClick={onDeleteWarranty}>
          Delete
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}

const menuStyles: MenuProps['styles'] = (theme) => ({
  item: {
    paddingInline: theme.spacing.sm,
    paddingBlock: theme.spacing.xs,
  },
  itemIcon: {
    width: 16,
    height: 16,

    svg: {
      width: '100%',
      height: '100%',
    },
  },
  itemBody: {
    padding: 0,
    fontSize: theme.fontSizes.sm,
  },
});
