import {
  ActionIcon,
  Affix,
  AffixProps,
  createStyles,
  Transition,
} from '@mantine/core';
import React from 'react';

import { ReactComponent as ArrowUpIcon } from '@portals/icons/linear/arrow-up.svg';

interface ScrollToTopButtonProps {
  visible: boolean;
  scrollToTop: VoidFunction;
}

const defaultAffixPosition: AffixProps['position'] = {
  bottom: 16,
  right: 16,
};

export function ScrollToTopButton({
  visible,
  scrollToTop,
}: ScrollToTopButtonProps) {
  const { classes } = useStyles();

  return (
    <Affix position={defaultAffixPosition}>
      <Transition transition="slide-up" mounted={visible}>
        {(transitionStyles) => (
          <ActionIcon
            className={classes.actionIcon}
            size={40}
            style={transitionStyles}
            onClick={scrollToTop}
          >
            <ArrowUpIcon />
          </ActionIcon>
        )}
      </Transition>
    </Affix>
  );
}

const useStyles = createStyles((theme) => ({
  actionIcon: {
    borderRadius: theme.radius.xl,
    border: `1px solid ${theme.colors.gray[3]}`,
    color: theme.colors.gray[7],
    backgroundColor: theme.fn.rgba(theme.colors.gray[3], 0.25),
    boxShadow: '0px 7px 10.2px 0px rgba(0, 0, 0, 0.19)',
    backdropFilter: 'blur(2px)',
  },
}));
