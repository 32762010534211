import {
  Affix,
  AffixProps,
  CloseButton,
  createStyles,
  Group,
  Text,
  Transition,
} from '@mantine/core';
import React, { ReactNode } from 'react';

const affixPosition: AffixProps['position'] = {
  bottom: 40,
  left: '50%',
};

interface BulkActionsProps {
  selectedCount: number;
  onClearSelected: VoidFunction;
  children: ReactNode;
}

export function BulkActions({
  selectedCount,
  onClearSelected,
  children,
}: BulkActionsProps) {
  const { classes } = useStyles();

  return (
    <Affix position={affixPosition} className={classes.affixContainer}>
      <Transition transition="slide-up" mounted={selectedCount > 0}>
        {(transitionStyles) => (
          <Group
            style={transitionStyles}
            className={classes.container}
            spacing="sm"
          >
            <Text fw={500}>{selectedCount} Selected:</Text>

            <div className={classes.actions}>{children}</div>

            <CloseButton
              variant="filled"
              onClick={onClearSelected}
              iconSize={14}
            />
          </Group>
        )}
      </Transition>
    </Affix>
  );
}

function BulkAction({ children }: { children: ReactNode }) {
  const { classes } = useStyles();

  return <div className={classes.action}>{children}</div>;
}

const useStyles = createStyles((theme) => ({
  affixContainer: {
    transform: 'translateX(-50%)',
  },
  container: {
    paddingBlock: theme.spacing.xs,
    paddingInlineStart: theme.spacing.md,
    paddingInlineEnd: theme.spacing.xs,
    borderRadius: theme.radius.md,
    boxShadow: theme.shadows.md,
    color: theme.white,
    backgroundColor: theme.colors.gray[9],
    userSelect: 'none',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
  },
  action: {
    position: 'relative',

    '&:not(:last-child)': {
      paddingRight: theme.spacing.md,
      marginRight: theme.spacing.md,

      '&:after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        right: 0,
        transform: 'translateY(-50%)',
        display: 'block',
        height: 20,
        width: 1,
        backgroundColor: theme.colors.gray[6],
      },
    },
  },
}));

BulkActions.Action = BulkAction;
