import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { toastrError, toastrSuccess } from '@portals/redux/actions/toastr';
import { OrganizationFileResponseType } from '@portals/types';

import {
  DEVICE_MODELS_API_URL,
  deviceModelsQueryKeys,
  getDeviceModelApiUrl,
} from './device-models.constants';
import { CreateDeviceModelFileParams } from './device-models.types';
import { useApiQuery } from '../../hooks/query';
import { ServerError } from '../../types/common';
import { fetchApiRequest, useRequestOptions } from '../../utils/common';

function getApiUrl(deviceModelId: string) {
  return `${getDeviceModelApiUrl(deviceModelId)}/file_infos`;
}

export function useDeviceModelFiles(
  deviceModelId: string,
  fileType: string | null = null
) {
  let apiUrl = getApiUrl(deviceModelId);
  apiUrl += fileType ? `?type=${fileType}` : '';

  const queryKey = fileType
    ? deviceModelsQueryKeys.files.byFileType(deviceModelId, fileType)
    : deviceModelsQueryKeys.files.all(deviceModelId);

  return useApiQuery<OrganizationFileResponseType[]>(apiUrl, queryKey);
}

export function useCreateDeviceModelFile() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url: baseUrl, options } = useRequestOptions({
    url: '',
    method: 'POST',
  });

  return useMutation<
    OrganizationFileResponseType,
    ServerError,
    CreateDeviceModelFileParams
  >({
    mutationFn: ({ formData, deviceModelId }) => {
      const url = getApiUrl(deviceModelId);

      return fetchApiRequest(`${baseUrl}/${url}`, {
        ...options,
        body: JSON.stringify(formData),
      });
    },
    onSuccess: () => {
      dispatch(toastrSuccess('File uploaded successfully'));

      queryClient.invalidateQueries(deviceModelsQueryKeys.files.global);
    },
    onError: ({ error }: { error: string }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useCreateDeviceModelFile',
      baseUrl: `${DEVICE_MODELS_API_URL}/:id/file_infos`,
      method: 'POST',
    },
  });
}

interface UseUpdateDeviceModelFileParams {
  file: CreateDeviceModelFileParams['formData'];
  deviceModelId: string;
  fileId: string;
}

export function useUpdateDeviceModelFile() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url: baseUrl, options } = useRequestOptions({
    url: '',
    method: 'PUT',
  });

  return useMutation<
    OrganizationFileResponseType,
    ServerError,
    UseUpdateDeviceModelFileParams
  >({
    mutationFn: ({ file, deviceModelId, fileId }) => {
      const url = getApiUrl(deviceModelId);

      return fetchApiRequest(`${baseUrl}/${url}/${fileId}`, {
        ...options,
        body: JSON.stringify(file),
      });
    },
    onSuccess: () => {
      dispatch(toastrSuccess('File updated successfully'));

      queryClient.invalidateQueries(deviceModelsQueryKeys.files.global);
    },
    onError: ({ error }: { error: string }) => {
      dispatch(toastrError('Update file failed', error));
    },
    meta: {
      mutationName: 'useUpdateDeviceModelFile',
      baseUrl: `${DEVICE_MODELS_API_URL}/:id/file_infos/:id`,
      method: 'PUT',
    },
  });
}
