import GoogleMapReact, { Bounds } from 'google-map-react';
import SuperCluster from 'supercluster';

import {
  MapFeature,
  SuperClusterPoint,
  SuperClusterType,
} from './device-location-widget.types';

export function convertGoogleMapsReactBoundsToLatLngBounds(
  bounds?: GoogleMapReact.ChangeEventValue['bounds']
) {
  if (!bounds) return null;

  return {
    north: bounds.ne.lat || 0,
    south: bounds.sw.lat || 0,
    east: bounds.ne.lng || 0,
    west: bounds.sw.lng || 0,
  };
}

export function createClusterMap(
  bounds: Bounds,
  points: Array<SuperClusterPoint>,
  zoom: number
): [[], undefined] | [Array<MapFeature>, SuperClusterType] {
  if (!bounds) {
    return [[], undefined];
  }

  const index = new SuperCluster({});

  index.load(points);

  const boundsArrayFormat = [
    bounds.nw.lng,
    bounds.se.lat,
    bounds.se.lng,
    bounds.nw.lat,
  ] as [number, number, number, number];

  return [index.getClusters(boundsArrayFormat, zoom), index];
}
