import { useSessionStorage } from '@mantine/hooks';
import { isEqual, mapValues, reject } from 'lodash/fp';
import React, {
  createContext,
  Dispatch,
  MutableRefObject,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Layout, Layouts } from 'react-grid-layout';
import { generatePath, useMatch, useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import {
  DeviceType,
  useSpaceDashboard,
  useUpdateSpaceDashboard,
} from '@portals/api/organizations';
import { useConfirmationModal, usePermissionAccess } from '@portals/framework';
import { useOpenModal } from '@portals/redux';
import {
  DashboardLayout,
  DashboardLayoutListItem,
  TableState,
} from '@portals/types';

import {
  assignWidgetIdAndName,
  calculateNewWidgetPosition,
} from './overview-dashboard/overview-dashboard.utils';
import { DEFAULT_DASHBOARD, WIDGETS_MAP } from './overview.constants';
import { useCurrentSpace, useOverviewOrganizationTree } from './overview.hooks';
import {
  DataLevelEnum,
  HandleChangeWidgetFilterParams,
  OverviewWidgetsFilters,
  WidgetType,
} from './overview.types';
import { WidgetPreviewProps } from '../../modals/WidgetPreview/WidgetPreview';
import { OVERVIEW_PATHS, OverviewPathsType } from '../../routes';

interface OverviewContextType {
  isConfigMode: boolean;
  isDragging: boolean;
  setIsDragging: Dispatch<SetStateAction<boolean>>;
  isWidgetsConfigMode: boolean;
  isAdmin: boolean;
  isPristine: boolean;
  dashboard: DashboardLayout<WidgetType>;
  navigateToWidgetsPanel: () => void;
  onCloseWidgetsPanel: () => void;
  navigateToDashboardConfig: () => void;
  navigateToDashboard: () => void;
  navigateToAssets: () => void;
  navigateToDevices: () => void;
  onAddWidget: (
    params: { id: WidgetType; x?: number; y?: number },
    extraParams?: DashboardLayoutListItem['extraParams']
  ) => void;
  onEditWidget: (
    widgetId: WidgetType,
    itemId?: string,
    extraParams?: DashboardLayoutListItem['extraParams']
  ) => void;
  onRemoveWidget: (id: string) => void;
  onUpdateLayouts: (currentLayout: Array<Layout>, allLayouts: Layouts) => void;
  onSaveChanges: () => void;
  onDiscardChanges: () => void;
  onReset: () => Promise<void>;
  dataLevel: DataLevelEnum;
  onSetDataLevel: Dispatch<SetStateAction<DataLevelEnum>>;
  isLocalDataLevel: boolean;
  dashboardRef: MutableRefObject<HTMLDivElement | null>;
  spaceEditModeId: number | null;
  setSpaceEditModeId: Dispatch<SetStateAction<number | null>>;
  organizationTree: ReturnType<typeof useOverviewOrganizationTree>;
  isDevicesRoute: boolean;
  isAssetsRoute: boolean;
  scrollableRef: React.MutableRefObject<HTMLDivElement | null>;
  onChangeWidgetFilter: (params: HandleChangeWidgetFilterParams) => void;
  getWidgetFilters: (widgetId: string) => TableState<DeviceType>['filters'];
}

const OverviewContext = createContext<OverviewContextType | null>(null);

interface OverviewContextProviderProps {
  children: ReactNode;
  scrollableRef: React.MutableRefObject<HTMLDivElement | null>;
}

export function OverviewContextProvider({
  children,
  scrollableRef,
}: OverviewContextProviderProps) {
  const spaceDashboard = useSpaceDashboard();
  const navigate = useNavigate();
  const space = useCurrentSpace({ refetchInterval: 30000 });
  const asyncConfirmationCheck = useConfirmationModal();
  const updateSpaceDashboard = useUpdateSpaceDashboard();
  const { isAdmin } = usePermissionAccess();
  const openModal = useOpenModal();
  const dashboardRef = useRef<HTMLDivElement | null>(null);
  const organizationTree = useOverviewOrganizationTree();
  const shouldScrollToBottom = useRef(false);

  const [isDragging, setIsDragging] = useState(false);

  const [overviewWidgetsFilters, setOverviewWidgetsFilters] =
    useSessionStorage<OverviewWidgetsFilters>({
      key: 'overview_widgets_filters',
      defaultValue: {},
      getInitialValueInEffect: false,
    });

  const isConfigMode = !!useMatch({
    path: OVERVIEW_PATHS.dashboardConfig,
    end: false,
  });
  const isWidgetsConfigMode = !!useMatch(OVERVIEW_PATHS.dashboardWidgets);
  const isDevicesRoute = !!useMatch(OVERVIEW_PATHS.devices);
  const isAssetsRoute = !!useMatch(OVERVIEW_PATHS.assets);

  const dashboard = (spaceDashboard ||
    DEFAULT_DASHBOARD) as DashboardLayout<WidgetType>;
  const [localDashboard, setLocalDashboard] =
    useState<DashboardLayout<WidgetType>>(dashboard);
  const isPristine = isEqual(dashboard, localDashboard);

  const [dataLevel, setDataLevel] = useState(DataLevelEnum.All);
  const [spaceEditModeId, setSpaceEditModeId] = useState<number | null>(null);

  function navigateToOverviewPath(overviewPath: OverviewPathsType) {
    if (!space) return;

    const path = generatePath(overviewPath, {
      spaceId: String(space.id),
    });

    navigate(path);
  }

  function onChangeWidgetFilter({
    widgetId,
    filters,
  }: HandleChangeWidgetFilterParams) {
    setOverviewWidgetsFilters((prevFilters) => {
      if (!filters.length) {
        const widgetWithFiltersIds = Object.keys(prevFilters || {});

        return widgetWithFiltersIds.reduce(
          (acc: OverviewWidgetsFilters, id) => {
            if (id !== widgetId) {
              acc[id] = prevFilters?.[id];
            }

            return acc;
          },
          {}
        );
      }

      if (!prevFilters) {
        return { [widgetId]: filters };
      }

      if (prevFilters[widgetId]) {
        const newWidgetFilters = {
          ...prevFilters[widgetId],
          ...filters,
        };

        return {
          ...prevFilters,
          [widgetId]: newWidgetFilters,
        };
      } else {
        return {
          ...prevFilters,
          [widgetId]: filters,
        };
      }
    });
  }

  function getWidgetFilters(widgetId: string) {
    return overviewWidgetsFilters?.[widgetId];
  }

  function navigateToDashboard() {
    navigateToOverviewPath(OVERVIEW_PATHS.dashboard);
  }

  function navigateToDashboardConfig() {
    navigateToOverviewPath(OVERVIEW_PATHS.dashboardConfig);
  }

  function navigateToAssets() {
    navigateToOverviewPath(OVERVIEW_PATHS.assets);
  }

  function navigateToWidgetsPanel() {
    navigateToOverviewPath(OVERVIEW_PATHS.dashboardWidgets);
  }

  function onCloseWidgetsPanel() {
    navigateToDashboardConfig();
  }

  function navigateToDevices() {
    navigateToOverviewPath(OVERVIEW_PATHS.devices);
  }

  const onReset: OverviewContextType['onReset'] = async () => {
    const isConfirmed = await asyncConfirmationCheck({
      title: 'Are you sure?',
      description:
        'All custom changes will be lost, and the dashboard will be reset to default view',
    });

    if (isConfirmed) {
      setLocalDashboard(DEFAULT_DASHBOARD);
    }
  };

  const onAddWidget: OverviewContextType['onAddWidget'] = (
    { id },
    extraParams
  ) => {
    const { name, w, h, minW = 1, minH = 1, maxH } = WIDGETS_MAP[id];
    const uniqueId = uuid();

    const { x, y } = calculateNewWidgetPosition(localDashboard.list);

    const newWidget = {
      i: uniqueId,
      id,
      name,
      x,
      y,
      w,
      h,
      minW,
      minH,
      maxH,
      extraParams,
    };

    setLocalDashboard((curr) => ({
      ...curr,
      list: [...curr.list, newWidget],
    }));

    onCloseWidgetsPanel();
    shouldScrollToBottom.current = true;
  };

  const onRemoveWidget: OverviewContextType['onRemoveWidget'] = (id) => {
    const updatedList = reject({ i: id }, localDashboard.list);
    const updatedLayouts = mapValues(
      (v) => reject({ i: id }, v),
      localDashboard.layouts
    );

    setLocalDashboard({
      list: updatedList,
      layouts: updatedLayouts,
    });
  };

  const onEditWidget: OverviewContextType['onEditWidget'] = (
    widgetId,
    itemId?
  ) => {
    const widgetSettings = WIDGETS_MAP[widgetId];

    if (!widgetSettings) return;

    openModal<WidgetPreviewProps['data']>('WidgetPreview', {
      space,
      widget: widgetSettings,
      type: 'edit',
      localDashboard,
      dataLevel,
      widgetId: itemId,
      onSave: (params) => {
        setLocalDashboard((curr) => ({
          ...curr,
          list: curr.list.map((widget) =>
            widget.i === itemId ? { ...widget, extraParams: params } : widget
          ),
        }));
      },
    });
  };

  const onUpdateLayouts: OverviewContextType['onUpdateLayouts'] = (
    currentLayout,
    allLayouts
  ) => {
    if (
      !isEqual(allLayouts, localDashboard.layouts) ||
      !isEqual(currentLayout, localDashboard.list)
    ) {
      setLocalDashboard({
        // react-grid-layout `onUpdateLayouts` does not pass any additional props we store for
        // widgets, specifically their 'id' (widget type) and 'name' (widget title). Upon layout
        // update, we need to re-assign those props to the widgets.
        list: assignWidgetIdAndName(currentLayout, localDashboard.list),
        layouts: allLayouts,
      });
    }
  };

  useEffect(function scrollToBottom() {
    if (shouldScrollToBottom.current && dashboardRef.current) {
      shouldScrollToBottom.current = false;

      // Hacky way to scroll to bottom when new widget is added, as the layout is still not
      // updated at this point. Couldn't find any other way, as there's no other callback to
      // listen to
      setTimeout(() => {
        dashboardRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
        });
      }, 200);
    }
  });

  const onSaveChanges = () => {
    updateSpaceDashboard.mutate(localDashboard, {
      onSuccess: navigateToDashboard,
    });
  };

  const onDiscardChanges = async () => {
    if (!isPristine) {
      const isConfirmed = await asyncConfirmationCheck({
        description: 'All unsaved changes will be lost',
        title: 'Are you sure?',
      });

      if (isConfirmed) {
        setLocalDashboard(dashboard);
        navigateToDashboard();
      }
    } else {
      setLocalDashboard(dashboard);
      navigateToDashboard();
    }
  };

  return (
    <OverviewContext.Provider
      value={{
        dashboardRef,
        isConfigMode,
        isDragging,
        setIsDragging,
        isWidgetsConfigMode,
        navigateToWidgetsPanel,
        onCloseWidgetsPanel,
        navigateToDashboardConfig,
        navigateToDashboard,
        navigateToDevices,
        navigateToAssets,
        dashboard: isConfigMode ? localDashboard : dashboard,
        onAddWidget,
        onRemoveWidget,
        onEditWidget,
        onUpdateLayouts,
        onSaveChanges,
        onDiscardChanges,
        onReset,
        isAdmin,
        isPristine,
        dataLevel,
        onSetDataLevel: setDataLevel,
        isLocalDataLevel:
          space?.space_type !== 'root' && dataLevel === DataLevelEnum.Local,
        spaceEditModeId,
        setSpaceEditModeId,
        organizationTree,
        isDevicesRoute,
        isAssetsRoute,
        scrollableRef,
        onChangeWidgetFilter,
        getWidgetFilters,
      }}
    >
      {children}
    </OverviewContext.Provider>
  );
}

export function useOverviewContext() {
  const context = useContext(OverviewContext);

  if (context === null) {
    throw new Error(
      'useOverviewContext must be used within OverviewContextProvider'
    );
  }

  return context;
}
