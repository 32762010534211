import {
  Alert,
  Box,
  createStyles,
  LoadingOverlay,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { get, isEmpty } from 'lodash/fp';
import React from 'react';

import {
  DeviceDetailsType,
  useDevice,
  useDeviceLastKnownState,
  useDeviceWidgetsSections,
  useOrganizationConfig,
  useWidgetColors,
} from '@portals/api/organizations';
import { MantineThemeProvider } from '@portals/core';
import {
  DeviceWidgetsSectionType,
  NewWidgetType,
} from '@portals/device-widgets';
import { ReactComponent as InfoCircle } from '@portals/icons/bold/info-circle.svg';
import { formatDateTime } from '@portals/utils';

import { DeviceWidgetsSection } from './components/DeviceWidgetsSection';

interface OverviewWidgetsProps {
  modelId: string;
  deviceId: string;
}

export const OverviewWidgets = ({
  modelId,
  deviceId,
}: OverviewWidgetsProps) => {
  const theme = useMantineTheme();
  const colors = useWidgetColors();
  const style = useStyles();

  const deviceWidgetsSections = useDeviceWidgetsSections(modelId);
  const organizationConfig = useOrganizationConfig();

  const device = useDevice(deviceId, {
    staleTime: 5000,
    refetchInterval: 5000,
  });

  const shouldDisplaySection = (
    section: DeviceWidgetsSectionType<NewWidgetType>
  ) =>
    getShouldDisplaySection({
      section,
      device: device.data,
      isLabAccount: Boolean(organizationConfig?.lab),
    });

  const isDeviceOffline =
    device.isFetched && device.data?.state?.status === 'offline';

  const deviceLastKnownState = useDeviceLastKnownState(deviceId, {
    enabled: isDeviceOffline,
  });

  const hasDeviceData = device.data && !isEmpty(device.data);

  const sectionsToDisplay =
    deviceWidgetsSections.data?.filter(shouldDisplaySection);

  if (deviceWidgetsSections.isFetched && isEmpty(deviceWidgetsSections.data)) {
    return null;
  }

  if (!deviceWidgetsSections.isFetched || !device.isFetched)
    return (
      <Box
        sx={{
          position: 'relative',
        }}
      >
        <LoadingOverlay visible />
      </Box>
    );

  return (
    <>
      {isDeviceOffline && deviceLastKnownState.data && (
        <Alert
          icon={<InfoCircle className={style.classes.infoIcon} />}
          color="gray.9"
          variant="filled"
          radius="md"
          className={style.classes.alert}
        >
          <Text size="sm">
            {`Please note, the data displayed below corresponds to the device's
          previous state, captured at its last known online timestamp: ${formatDateTime(
            device.data?.last_seen
          )}`}
          </Text>
        </Alert>
      )}

      <MantineThemeProvider
        inherit
        theme={{ colors: { ...theme.colors, ...colors } }}
      >
        {hasDeviceData &&
          sectionsToDisplay?.map((section) => (
            <DeviceWidgetsSection
              key={section.id}
              device={device.data}
              modelId={modelId}
              section={section}
            />
          ))}
      </MantineThemeProvider>
    </>
  );
};

const useStyles = createStyles((theme) => ({
  alert: {
    position: 'sticky',
    zIndex: 999,
    top: theme.spacing.xs,
    boxShadow: `0 0.07rem 0.2rem rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.1) 0 2.25rem 1.75rem -0.5rem,
    rgba(0, 0, 0, 0.1) 0 1rem 1rem -0.1rem`,
  },
  infoIcon: {
    width: 20,
    height: 20,
    color: theme.colors.amber[7],
  },
}));

interface GetShouldDisplaySectionProps {
  section: DeviceWidgetsSectionType;
  device?: DeviceDetailsType;
  isLabAccount: boolean;
}

function getShouldDisplaySection({
  section,
  device,
  isLabAccount,
}: GetShouldDisplaySectionProps) {
  if (!section.condition) {
    return true;
  }

  const isLabOnlyCondition = Boolean(section.condition?.isLabOnly);

  const shouldDisplayBasedOnPath = section.condition?.path
    ? Boolean(get(section.condition.path, device))
    : true;

  if (isLabOnlyCondition) {
    return isLabAccount && shouldDisplayBasedOnPath;
  }

  return shouldDisplayBasedOnPath;
}
