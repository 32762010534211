import { createStyles, Group, Stack, Text } from '@mantine/core';
import React from 'react';

import { IncidentType } from '@portals/api/organizations';
import { servicenowSrc } from '@portals/assets';
import { CopyToClipboard } from '@portals/core';

import { IncidentDetailsPanelSection } from './IncidentDetailsPanelSection';

interface IncidentDetailsPanelIntegrationsProps {
  integrations: IncidentType['integrations'];
}

export function IncidentDetailsPanelIntegrations({
  integrations,
}: IncidentDetailsPanelIntegrationsProps) {
  if (!integrations.service_now?.number) return null;

  return (
    <IncidentDetailsPanelSection title="Integrations">
      <IncidentServiceNowIntegration
        serviceNowNumber={integrations.service_now.number}
      />
    </IncidentDetailsPanelSection>
  );
}

interface IncidentServiceNowIntegrationProps {
  serviceNowNumber: string;
}

function IncidentServiceNowIntegration({
  serviceNowNumber,
}: IncidentServiceNowIntegrationProps) {
  const { classes } = useStyles();

  return (
    <Stack spacing="xs">
      <Group spacing={4}>
        <img
          src={servicenowSrc}
          width={18}
          height="auto"
          alt="service now logo"
          className={classes.integrationLogo}
        />
        <Text>ServiceNow</Text>
      </Group>

      <Group position="apart">
        <Text c="gray.5">ID</Text>

        <Group spacing={4}>
          <Text>{serviceNowNumber}</Text>
          <CopyToClipboard value={serviceNowNumber} />
        </Group>
      </Group>
    </Stack>
  );
}

const useStyles = createStyles({
  integrationLogo: {
    aspectRatio: '1',
    objectFit: 'contain',
  },
});
