import { ActionIcon, Menu, MenuProps, Text } from '@mantine/core';
import React from 'react';

import {
  AssetType,
  SpaceType,
  useMoveAsset,
  useRemoveAsset,
} from '@portals/api/organizations';
import { useConfirmationModal } from '@portals/framework';
import { ReactComponent as Edit3Icon } from '@portals/icons/linear/edit-3.svg';
import { ReactComponent as LogoutIcon } from '@portals/icons/linear/logout-1.svg';
import { ReactComponent as MoreIcon } from '@portals/icons/linear/more.svg';
import { ReactComponent as TrashIcon } from '@portals/icons/linear/trash.svg';
import { useOpenModal } from '@portals/redux';
import { suppressPropagation } from '@portals/utils';

import { useOpenAddAssetsModal } from '../../assets/AddAssetButton';

import type { ChangeSpaceModalProps } from '../../../modals';

interface AssetMenuProps extends MenuProps {
  asset: AssetType;
  space: SpaceType | undefined;
  onDeleteSuccess?: VoidFunction;
}

export function AssetMenu({
  asset,
  space,
  onDeleteSuccess,
  ...menuProps
}: AssetMenuProps) {
  const openModal = useOpenModal();
  const asyncConfirm = useConfirmationModal();
  const openAddAssetModal = useOpenAddAssetsModal();

  const removeAsset = useRemoveAsset();
  const moveAsset = useMoveAsset();

  function onMoveAsset(spaceId: number) {
    if (!asset.id) return;

    moveAsset.mutate({ id: asset.id, space_id: spaceId });
  }

  async function onDeleteAsset() {
    const isConfirmed = await asyncConfirm({
      title: 'Delete asset?',
      description: (
        <Text>
          Are you sure you want to delete <strong>{asset.name}</strong>?
        </Text>
      ),
      confirmationLabel: 'Delete',
    });

    if (isConfirmed) {
      removeAsset.mutate(asset.id, { onSuccess: onDeleteSuccess });
    }
  }

  return (
    <Menu position="bottom-end" withinPortal {...menuProps}>
      <Menu.Target>
        <ActionIcon
          onClick={suppressPropagation()}
          data-testid="asset-menu-dropdown"
        >
          <MoreIcon />
        </ActionIcon>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Item
          data-testid="edit-asset-menu-button"
          icon={<Edit3Icon />}
          onClick={suppressPropagation(() =>
            openAddAssetModal({
              initialAsset: asset,
              initialSpaceId: space?.id,
            })
          )}
        >
          Edit asset
        </Menu.Item>

        <Menu.Item
          data-testid="move-asset-menu-button"
          icon={<LogoutIcon />}
          onClick={suppressPropagation(() =>
            openModal<ChangeSpaceModalProps['data']>('ChangeSpaceModal', {
              onSetSpaceId: onMoveAsset,
              space,
            })
          )}
        >
          Move asset
        </Menu.Item>

        <Menu.Divider />

        <Menu.Item
          data-testid="delete-asset-menu-button"
          color="red.4"
          icon={<TrashIcon />}
          onClick={suppressPropagation(onDeleteAsset)}
        >
          Delete asset
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}
