import { FilterValue, IdType } from 'react-table';

export enum PaginatedFilterTypeEnum {
  Text = 'text',
  Number = 'number',
  Select = 'select',
  Date = 'date',
  Boolean = 'boolean',
  SingleSelect = 'single_select',
  NotEq = 'not_eq',
  Eq = 'eq',
  Contains = 'contains',
  NotContains = 'not_contains',
  LtreeStartsWith = 'ltree_starts_with',
  Null = 'null',
}

export interface PaginationResponse<TData> {
  data: Array<TData>;
  page_info: {
    has_next: boolean;
    total_count?: number;
  };
}

export interface PaginatedQueryFilterType<TData extends object> {
  id: IdType<TData>;
  value: FilterValue;
  type: PaginatedFilterTypeEnum;
  groupKey?: IdType<TData>;
}

export interface PaginatedQuerySortType<TData extends object> {
  id: IdType<TData>;
  desc: boolean;
}

export interface PaginatedQueryPaginationType {
  page: number;
  pageSize: number;
}

export interface PaginatedQueryParamsType<TData extends object> {
  filters?: Array<PaginatedQueryFilterType<TData>>;
  sorting?: Array<PaginatedQuerySortType<TData>>;
  pagination: PaginatedQueryPaginationType;
}
