import {
  Button,
  createStyles,
  Group,
  Modal,
  Stack,
  Textarea,
  TextInput,
} from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { MIME_TYPES } from '@mantine/dropzone';
import { useForm, yupResolver } from '@mantine/form';
import React from 'react';
import { object, string } from 'yup';

import {
  AssetWarrantyType,
  useCreateAssetWarranty,
  useUpdateWarrantyOfAsset,
} from '@portals/api/organizations';
import { ModalFooter } from '@portals/core';
import { ModalProps } from '@portals/framework';
import { ReactComponent as ArrowRight } from '@portals/icons/linear/arrow-right.svg';
import { ReactComponent as Calendar } from '@portals/icons/linear/calendar.svg';

import { FileUploadSwitcher } from './FileUploadSwitcher';

interface UploadAssetWarrantyFormValues {
  name: string;
  description: string;
  startDate?: Date;
  endDate?: Date;
  fileUrl: string;
}

export interface UploadAssetWarrantyModalProps
  extends ModalProps<{
    assetId: string;
    warrantyToEdit?: AssetWarrantyType;
    title?: string;
    shouldRefetch?: boolean;
  }> {}

const schema = object({
  fileUrl: string().url('Invalid URL').required('File is required'),
  name: string().required('Name is required'),
  startDate: string().required('Start date is required'),
  endDate: string().required('End date is required'),
});

export function UploadAssetWarrantyModal({
  closeMe,
  data: { warrantyToEdit, title, assetId, shouldRefetch },
}: UploadAssetWarrantyModalProps) {
  const { classes } = useStyles();
  const isEditMode = warrantyToEdit !== undefined;

  const updateWarrantyOfAsset = useUpdateWarrantyOfAsset();
  const createAssetWarranty = useCreateAssetWarranty();

  const form = useForm<UploadAssetWarrantyFormValues>({
    initialValues: {
      name: isEditMode ? warrantyToEdit.name : '',
      description: warrantyToEdit?.description || '',
      startDate: isEditMode ? new Date(warrantyToEdit.start_date) : undefined,
      endDate: isEditMode ? new Date(warrantyToEdit.end_date) : undefined,
      fileUrl: warrantyToEdit?.warranty_document_url ?? '',
    },
    validate: yupResolver(schema),
  });

  const submitHandler = ({
    name,
    startDate,
    endDate,
    description,
    fileUrl,
  }: typeof form.values) => {
    if (!startDate || !endDate) return;

    if (isEditMode) {
      const updatedWarranty: AssetWarrantyType = {
        ...warrantyToEdit,
        name,
        description,
        start_date: startDate.toISOString(),
        end_date: endDate.toISOString(),
        warranty_document_url: fileUrl,
      };

      updateWarrantyOfAsset.mutate(
        {
          warranty: updatedWarranty,
          assetId,
          shouldRefetch,
        },
        {
          onSuccess: () => closeMe(),
        }
      );
    } else {
      createAssetWarranty.mutate(
        {
          warranty: {
            name,
            start_date: startDate.toISOString(),
            end_date: endDate.toISOString(),
            description,
            warranty_document_url: fileUrl,
          },
          assetId,
        },
        {
          onSuccess: () => closeMe(),
        }
      );
    }
  };

  const modalTitle =
    title || (isEditMode ? 'Update Warranty' : 'Upload Warranty');

  return (
    <Modal
      opened
      size={680}
      padding="xxl"
      onClose={closeMe}
      title={modalTitle}
      styles={(theme) => ({ header: { marginBottom: theme.spacing.xl } })}
    >
      <form noValidate onSubmit={form.onSubmit(submitHandler)}>
        <Stack spacing="xxl">
          <FileUploadSwitcher
            initialFileUrl={warrantyToEdit?.warranty_document_url}
            onChangeFileUrl={(fileUrl) =>
              form.setFieldValue('fileUrl', fileUrl)
            }
            fileUrlError={form.errors.fileUrl}
            acceptFileTypes={[MIME_TYPES.pdf, MIME_TYPES.jpeg, MIME_TYPES.png]}
          />

          <TextInput
            required
            label="Name"
            placeholder="Warranty name"
            {...form.getInputProps('name')}
            data-testid="input-warranty-name"
          />

          <Group align="flex-start">
            <DatePickerInput
              required
              label="Start date"
              placeholder="Start date"
              maxDate={form.values.endDate}
              icon={<Calendar width={18} height={18} />}
              sx={{ flexGrow: 1 }}
              popoverProps={{ withinPortal: true }}
              {...form.getInputProps('startDate')}
              data-testid="date-picker-input-warranty-start"
            />

            <ArrowRight
              className={classes.arrowContainer}
              width={16}
              height={16}
            />

            <DatePickerInput
              required
              label="End date"
              placeholder="End date"
              minDate={form.values.startDate}
              icon={<Calendar width={18} height={18} />}
              sx={{ flexGrow: 1 }}
              popoverProps={{ withinPortal: true }}
              {...form.getInputProps('endDate')}
              data-testid="date-picker-input-warranty-end"
            />
          </Group>

          <Textarea
            maxRows={3}
            minRows={3}
            label="Short description (optional)"
            placeholder="Short description (optional)"
            {...form.getInputProps('description')}
            data-testid="input-warranty-description"
          />

          <ModalFooter position="right">
            <Button variant="default" onClick={closeMe}>
              Cancel
            </Button>

            <Button
              type="submit"
              loading={
                createAssetWarranty.isLoading || updateWarrantyOfAsset.isLoading
              }
              data-testid="add-warranty-submit-button"
            >
              {isEditMode ? 'Update Warranty' : 'Add Warranty'}
            </Button>
          </ModalFooter>
        </Stack>
      </form>
    </Modal>
  );
}

const useStyles = createStyles((theme) => ({
  dropzone: {
    height: 260,
  },
  dropzoneError: {
    borderColor: theme.colors.red[7],
  },
  arrowContainer: {
    marginTop: '6.2%', // using percentage in order handle errors space
  },
}));
