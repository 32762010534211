import {
  Box,
  Button,
  createStyles,
  SimpleGrid,
  Stack,
  Text,
} from '@mantine/core';
import { compact, size } from 'lodash/fp';
import React, { ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useConfig } from '@portals/api/organizations';
import {
  isCustomerFeatureAll,
  isCustomerFeatureLabOnly,
  usePermissionAccess,
} from '@portals/framework';
import { ReactComponent as Book } from '@portals/icons/linear/book-1.svg';
import { ReactComponent as Glossary } from '@portals/icons/linear/book-a.svg';
import { ReactComponent as DocumentText } from '@portals/icons/linear/document-text.svg';
import { ReactComponent as LifeBuoy } from '@portals/icons/linear/lifebuoy.svg';
import { ReactComponent as Task } from '@portals/icons/linear/task.svg';
import { ReactComponent as VideoSquare } from '@portals/icons/linear/video-square.svg';
import { isGettingStartedToursDone } from '@portals/utils';

import { SuccessCenterPartnerManuals } from './SuccessCenterPartnerManuals';

interface SuccessCenterMenuItemType {
  icon: ReactNode;
  label: string;
  onClick: () => void;
}

interface SuccessCenterItemsProps {
  onCloseSuccessCenterMenu: () => void;
  onContactSupport: () => void;
}

export function SuccessCenterItems({
  onCloseSuccessCenterMenu,
  onContactSupport,
}: SuccessCenterItemsProps) {
  const { classes } = useStyles();

  const navigate = useNavigate();

  const [showPartnersManuals, setShowPartnersManuals] = useState(false);

  const config = useConfig();

  const { isAdmin } = usePermissionAccess();

  const showSetupOrganizationItem =
    isAdmin &&
    (config.data?.onboarding_tours?.dismiss ||
      isGettingStartedToursDone(config.data)) &&
    (isCustomerFeatureAll(config.data?.portal_capabilities.onboarding_guides) ||
      isCustomerFeatureLabOnly(
        config.data?.portal_capabilities.onboarding_guides,
        config.data?.organization.lab
      ));

  const successCenterMenuItems: Array<SuccessCenterMenuItemType> = compact([
    showSetupOrganizationItem && {
      label: 'Setup guides',
      icon: <Task />,
      onClick: () => {
        onCloseSuccessCenterMenu();
        navigate('/setup');
      },
    },
    {
      label: 'Documentation',
      icon: <DocumentText />,
      onClick: () => window.open('https://docs.xyte.io/', '_blank'),
    },
    showSetupOrganizationItem && {
      label: 'Videos',
      icon: <VideoSquare />,
      onClick: () =>
        window.open(
          'https://www.youtube.com/playlist?list=PLqmvZivrF9W77RL6DZTfrKtgtd-o1K5Ok',
          '_blank'
        ),
    },
    {
      label: 'Glossary',
      icon: <Glossary />,
      onClick: () => window.open('https://www.xyte.io/glossary', '_blank'),
    },
    size(config.data?.organization.partner_manuals) > 0 && {
      label: 'Manufacturer manuals',
      icon: <Book />,
      onClick: () => setShowPartnersManuals(true),
    },
  ]);

  if (showPartnersManuals) {
    return (
      <SuccessCenterPartnerManuals
        onClose={() => setShowPartnersManuals(false)}
      />
    );
  }

  return (
    <Stack spacing={4} h="100%">
      <Box pt="md" pb="xs">
        <Text color="gray.8" weight={600} ta="center">
          Success Center
        </Text>
      </Box>

      <Stack spacing="xl" h="100%" justify="space-between">
        <SimpleGrid cols={3} spacing="xs">
          {successCenterMenuItems.map(({ icon, label, onClick }) => (
            <Stack
              key={label}
              spacing="xs"
              align="center"
              className={classes.menuItem}
              onClick={onClick}
              data-testid={`success-center-${label}`}
            >
              <Box className={classes.icon}>{icon}</Box>

              <Text size="xs" color="gray.8" ta="center">
                {label}
              </Text>
            </Stack>
          ))}
        </SimpleGrid>

        <Button
          size="md"
          variant="default"
          onClick={onContactSupport}
          leftIcon={<LifeBuoy width={18} height={18} />}
        >
          Contact technical support
        </Button>
      </Stack>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  menuItem: {
    width: 120,
    height: 120,
    paddingInline: theme.spacing.md,
    paddingTop: theme.spacing.xxl,
    paddingBottom: theme.spacing.md,
    borderRadius: theme.radius.lg,
    cursor: 'pointer',

    ...theme.fn.hover({
      backgroundColor: theme.colors.gray[0],
    }),
  },

  icon: {
    svg: {
      height: 32,
      width: 32,
      color: theme.colors.indigo_accent[2],
    },
  },
}));
