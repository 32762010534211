import { useMemo } from 'react';

import { useCecPartners } from '@portals/api/organizations';
import { CecPartnerType } from '@portals/types';

import {
  OrgC2CIntegrationItem,
  useIntegrationsList,
} from '../../../hooks/c2c-integrations';

interface UseConnectRouteModalIntegrationGroupsParams {
  cecPartners: ReturnType<typeof useCecPartners>['data'];
  integrationsMetadata: ReturnType<typeof useIntegrationsList>;
  searchTerm: string;
}

export function useConnectRouteModalIntegrationGroups({
  cecPartners,
  integrationsMetadata,
  searchTerm,
}: UseConnectRouteModalIntegrationGroupsParams) {
  const filteredPartners = useMemo(() => {
    // List only partners that have `self_signup` enabled
    const partnersList = (cecPartners || []).filter(
      (partner) => partner.organization_signup_enabled || partner.connected
    );

    if (!searchTerm) return partnersList;

    const lowerSearchTerm = searchTerm.toLowerCase();

    return partnersList.filter((partner) => {
      const searchableContent =
        `${partner.display_name}__@@__${partner.about}`.toLowerCase();

      return searchableContent.includes(lowerSearchTerm);
    });
  }, [cecPartners, searchTerm]);

  const visibleIntegrations = useMemo(() => {
    return integrationsMetadata.filter(
      (integration) => !integration.isLegacyIntegration
    );
  }, [integrationsMetadata]);

  const filteredIntegrations = useMemo(() => {
    if (searchTerm === '') return visibleIntegrations;

    const lowerSearchTerm = searchTerm.toLowerCase();

    return visibleIntegrations.filter((integration) => {
      const searchableContent =
        `${integration.name}__@@__${integration.description}`.toLowerCase();

      return searchableContent.includes(lowerSearchTerm);
    });
  }, [searchTerm, visibleIntegrations]);

  const groups = useMemo(() => {
    const result: {
      prioritized: Array<OrgC2CIntegrationItem>;
      connectedDevices: Array<CecPartnerType | OrgC2CIntegrationItem>;
      comingSoon: Array<OrgC2CIntegrationItem>;
    } = { prioritized: [], connectedDevices: [], comingSoon: [] };

    // All partners should go into the `connectedDevices` group
    result.connectedDevices.push(...filteredPartners);

    // Loop through integrations and push to relevant groups
    filteredIntegrations.forEach((integration) => {
      if (integration.prioritized) {
        result.prioritized.push(integration);
      } else if (integration.isComingSoon) {
        result.comingSoon.push(integration);
      } else {
        result.connectedDevices.push(integration);
      }
    });

    // Sort each group
    result.prioritized.sort((a, b) =>
      a.isComingSoon ? 1 : a.name.localeCompare(b.name)
    );
    result.comingSoon.sort((a, b) => a.name.localeCompare(b.name));

    // Sorting the `connectedDevices` group requires a special "treatment"
    // because it consists of `CecPartnerType` and `OrgC2CIntegrationItem`
    result.connectedDevices.sort((a, b) => {
      const aName = 'display_name' in a ? a.display_name : a.name;
      const bName = 'display_name' in b ? b.display_name : b.name;

      return aName.localeCompare(bName);
    });

    return result;
  }, [filteredIntegrations, filteredPartners]);

  const areAllGroupsEmpty = useMemo(() => {
    return Object.values(groups).every((group) => group.length === 0);
  }, [groups]);

  return { groups, areAllGroupsEmpty };
}
