import { Button, ButtonProps } from '@mantine/core';
import React, { useCallback } from 'react';

import { useOpenModal } from '@portals/redux';

import { AddAssetModalProps } from '../../modals';

type AddAssetButtonProps = AddAssetModalProps['data'] & ButtonProps;

export function AddAssetButton({
  initialAsset,
  initialSpaceId,
  ...buttonProps
}: AddAssetButtonProps) {
  const openAddAssetModal = useOpenAddAssetsModal();

  return (
    <Button
      data-testid="add-asset-button"
      onClick={() => openAddAssetModal({ initialAsset, initialSpaceId })}
      {...buttonProps}
    />
  );
}

export function useOpenAddAssetsModal() {
  const openModal = useOpenModal();

  return useCallback(
    (params: AddAssetModalProps['data']) => {
      openModal<AddAssetModalProps['data']>('AddAssetModal', params);
    },
    [openModal]
  );
}
