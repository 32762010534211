import { createStyles } from '@mantine/core';

export const useVirtualizerStyles = createStyles({
  virtualizerContainer: {
    position: 'relative',
    width: '100%',
  },
  virtualRow: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
  },
});
