import { Point } from 'geojson';
import { Coords } from 'google-map-react';

import {
  DeviceStateType,
  DeviceType,
  RelatedDevice,
  SpaceType,
} from '@portals/api/organizations';
import { DeviceMapConfig, Dimension } from '@portals/device-widgets';

export function getDeviceMarkerCoordinates({
  deviceState,
  deviceLastKnowState,
  space,
}: {
  deviceState?: DeviceType['state'] | null;
  deviceLastKnowState?: DeviceStateType['state'];
  space?: SpaceType;
}): Coords | null {
  if (deviceState?.lat && deviceState?.lng) {
    return {
      lat: deviceState.lat,
      lng: deviceState.lng,
    };
  }

  if (deviceLastKnowState?.lat && deviceLastKnowState?.lng) {
    return {
      lat: deviceLastKnowState.lat,
      lng: deviceLastKnowState.lng,
    };
  }

  if (
    space?.config?.location &&
    Object.keys(space.config.location).length &&
    !(deviceState && Object.keys(deviceState).length)
  ) {
    return space.config.location.location ?? null;
  }

  return null;
}

export function childrenToPoints(devicesMapConfig: DeviceMapConfig[]) {
  return devicesMapConfig
    .map((deviceMapConfig): GeoJSON.Feature<Point> | null => {
      if (!deviceMapConfig?.coordinates) {
        return null;
      }

      return {
        type: 'Feature',
        id: deviceMapConfig.id,
        properties: { cluster: false, ...deviceMapConfig },
        geometry: {
          type: 'Point',
          coordinates: [
            deviceMapConfig.coordinates.lng,
            deviceMapConfig.coordinates.lat,
          ],
        },
      };
    })
    .filter((item) => item !== null);
}

/**
 * Creates a key for coordinates based on proximity
 * @param coordinates The coordinates to create key for
 * @param proximityTolerance Tolerance in map degrees (distance unit) - coordinates within this distance will have the
 *   same key
 * @returns A string key for grouping nearby coordinates
 */
export function getCoordinatesKey(
  coordinates: Coords,
  proximityTolerance: number = 0
) {
  // If no tolerance specified, use exact coordinates
  if (proximityTolerance === 0) {
    return `${coordinates.lat}-${coordinates.lng}`;
  }

  // First, determine which grid cell the coordinates belong to
  const latCellRounded = Math.floor(coordinates.lat / proximityTolerance);
  const lngCellRounded = Math.floor(coordinates.lng / proximityTolerance);

  // Convert cell back to a coordinate (use the cell's bottom-left corner)
  // This ensures all points within the same grid cell get the same coordinate-based key
  const latBase = latCellRounded * proximityTolerance;
  const lngBase = lngCellRounded * proximityTolerance;

  return `${latBase.toFixed(8)}-${lngBase.toFixed(8)}`;
}

function doesTelemetryKeyExistInState({
  state,
  telemetryKey,
}: {
  state: RelatedDevice['state'] | DeviceStateType['state'];
  telemetryKey: string;
}) {
  return !!(state && telemetryKey in state);
}

export function getChildMarkerColor({
  childDeviceData,
  activeDimension,
}: {
  childDeviceData?: RelatedDevice;
  activeDimension?: Dimension;
}) {
  if (!childDeviceData || !activeDimension) {
    return;
  }

  const childDeviceState = childDeviceData.state;

  const doesTelemetryKeyExistInDeviceState = doesTelemetryKeyExistInState({
    state: childDeviceState,
    telemetryKey: activeDimension.telemetry_key,
  });

  const childDeviceLastKnownState = childDeviceData.last_known_state?.state;

  const doesTelemetryKeyExistInDeviceLastKnownState =
    doesTelemetryKeyExistInState({
      state: childDeviceLastKnownState || null,
      telemetryKey: activeDimension.telemetry_key,
    });

  if (
    !doesTelemetryKeyExistInDeviceState &&
    !doesTelemetryKeyExistInDeviceLastKnownState
  ) {
    return;
  }

  const valueMet = activeDimension?.coloringRules?.find((rule) => {
    if (doesTelemetryKeyExistInDeviceState && childDeviceState) {
      return childDeviceState[activeDimension?.telemetry_key] === rule?.value;
    }

    if (
      doesTelemetryKeyExistInDeviceLastKnownState &&
      childDeviceLastKnownState
    ) {
      return (
        childDeviceLastKnownState[activeDimension?.telemetry_key] ===
        rule?.value
      );
    }

    return false;
  });

  return valueMet?.color;
}
