import { LoadingOverlay } from '@mantine/core';
import { isEmpty, uniq } from 'lodash/fp';
import React, { useCallback, useState } from 'react';

import {
  SpaceType,
  useCreateSpace,
  useMoveSpace,
} from '@portals/api/organizations';
import { useConfirmationModal } from '@portals/framework';
import {
  generateUniqueDefaultSpaceNameInSiblings,
  searchTree,
} from '@portals/utils';

import { Tree } from './Tree';
import { canEdit } from '../../../../../lib/access';
import { useOverviewRouting } from '../../overview-routing.hooks';
import { useOverviewContext } from '../../overview.context';
import { useCurrentSpace } from '../../overview.hooks';

interface SpaceTreeProps {
  readonly?: boolean;
  draggable?: boolean;
}

export function OrganizationTree({ readonly, draggable }: SpaceTreeProps) {
  const currentSpace = useCurrentSpace();

  const overviewRouting = useOverviewRouting();

  const {
    organizationTree: { tree, expandedNodes, setExpandedNodes },
  } = useOverviewContext();

  const asyncConfirmationCheck = useConfirmationModal();
  const moveSpace = useMoveSpace();
  const createSpace = useCreateSpace();

  const [searchTerm, setSearchTerm] = useState<string>('');

  const [editModeNodeId, setEditModeNodeId] = useState<number | null>(null);

  const handleMoveSpace = async (
    spaceId: number,
    targetSpaceId: number,
    position?: number
  ) => {
    const spaceToMove = searchTree(tree[0], spaceId);
    const targetSpace = searchTree(tree[0], targetSpaceId);

    if (
      !spaceToMove ||
      !targetSpace ||
      !canEdit(spaceToMove) ||
      !canEdit(targetSpace)
    ) {
      return;
    }

    if (position && spaceToMove.parent_id === targetSpace.id) {
      try {
        await moveSpace.mutateAsync({
          spaceId,
          targetSpaceId,
          position,
        });
      } catch (error) {
        console.error(error);
      }

      return;
    }

    const isConfirmed = await asyncConfirmationCheck({
      title: `Move Space`,
      description: `Move space "${spaceToMove.name}" under "${targetSpace.name}"?`,
      confirmationLabel: 'Move',
      cancelLabel: 'Cancel',
    });

    if (!isConfirmed) return;

    try {
      await moveSpace.mutateAsync({ spaceId, targetSpaceId, position });
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelected = useCallback(
    (spaceId: SpaceType['id']) => {
      overviewRouting.navigateToOverviewTab({ spaceId });
    },
    [overviewRouting]
  );

  const handleCreateSpace = useCallback(
    async (targetSpaceId: number) => {
      const parentSpace = searchTree(tree[0], targetSpaceId);

      if (!parentSpace || !canEdit(parentSpace)) return;

      try {
        const newSpace = await createSpace.mutateAsync({
          parentSpaceId: parentSpace.id,
          newSpace: {
            name: generateUniqueDefaultSpaceNameInSiblings(
              parentSpace.children
            ),
          },
        });

        handleSelected(newSpace.id);
        setEditModeNodeId(newSpace.id);
        setExpandedNodes((curr) => uniq([...curr, ...newSpace.path]));
      } catch (error: any) {
        console.error(error?.error);
      }
    },
    [setExpandedNodes, createSpace, handleSelected, tree]
  );

  return (
    <>
      <LoadingOverlay visible={createSpace.isLoading || moveSpace.isLoading} />

      {!isEmpty(tree) && (
        <Tree
          handleSelected={handleSelected}
          handleMoveSpace={handleMoveSpace}
          handleCreateSpace={handleCreateSpace}
          readonly={readonly}
          draggable={draggable}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          editModeNodeId={editModeNodeId}
          setEditModeNodeId={setEditModeNodeId}
          treeNodes={tree}
          selectedSpaceId={currentSpace?.id || null}
          expandedNodes={expandedNodes}
          setExpandedNodes={setExpandedNodes}
        />
      )}
    </>
  );
}
