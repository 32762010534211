import {
  Alert,
  Anchor,
  Button,
  ButtonProps,
  createStyles,
  Group,
  LoadingOverlay,
  Modal,
  Select,
  SimpleGrid,
  Space,
  Stack,
  Text,
} from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { toNumber, toString } from 'lodash/fp';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import {
  SpaceType,
  SUPPORTED_C2C_CONNECTORS,
  SupportedC2cConnectorName,
  useCreateNewConnector,
  useSpaces,
} from '@portals/api/organizations';
import { ModalProps } from '@portals/framework';
import { useOpenModal } from '@portals/redux';

import { C2cConnectorBadge } from './C2cConnectorBadge';
import { useIntegrationsList } from '../../../hooks/c2c-integrations';
import { useOpenTrialAnnouncementModal } from '../../../hooks/trial-announcement';
import { ChangeSpaceField } from '../add-device-v2/add-device-form/ChangeSpaceField';
import { CreateCustomerSpaceModalProps } from '../create-customer/CreateCustomerSpaceModal';
import { SuggestBrandModalProps } from '../SuggestBrandModal';

interface ConnectorSelectionProps {
  onSelectConnector: (connector: SupportedC2cConnectorName) => void;
}

function ConnectorSelection({ onSelectConnector }: ConnectorSelectionProps) {
  const openModal = useOpenModal();

  return (
    <Stack spacing="xxl">
      <SimpleGrid cols={3}>
        {SUPPORTED_C2C_CONNECTORS.map((connector) => {
          if (connector === 'zoom') return null;

          return (
            <C2cConnectorBadge
              key={connector}
              connectorName={connector}
              onClick={() => onSelectConnector(connector)}
            />
          );
        })}
      </SimpleGrid>

      <Space h="lg" />

      <Alert color="blue_gray">
        <Group position="center" align="center">
          <Text color="blue_gray.9">
            Missing a brand you need? Let us know, and we’ll work on adding it
          </Text>

          <Anchor
            data-testid="ask-for-integration-link"
            onClick={() =>
              openModal<SuggestBrandModalProps['data']>('SuggestBrandModal', {
                hiddenFields: ['deviceModel'],
              })
            }
          >
            Suggest a brand
          </Anchor>
        </Group>
      </Alert>
    </Stack>
  );
}

interface CustomerSpaceSelectionProps {
  closeMe: () => void;
  onCancel: () => void;
  connectorName: SupportedC2cConnectorName;
}

const schema = Yup.object().shape({
  customerSpaceId: Yup.string().required('Customer is required').nullable(),
  spaceId: Yup.string().required('Space is required').nullable(),
});

function CustomerSpaceSelection({
  closeMe,
  onCancel,
  connectorName,
}: CustomerSpaceSelectionProps) {
  const createNewIntegration = useCreateNewConnector();
  const navigate = useNavigate();
  const openModal = useOpenModal();

  const spaces = useSpaces();

  const form = useForm<{
    customerSpaceId: number | null;
    spaceId: number | null;
  }>({
    initialValues: {
      customerSpaceId: null,
      spaceId: null,
    },
    validate: yupResolver(schema),
  });

  const { setFieldValue, values } = form;

  function handleSubmit(values: typeof form.values) {
    createNewIntegration.mutate(
      {
        connectorName,
        spaceId: Number(values.spaceId),
      },
      {
        onSuccess: (response) => {
          closeMe();
          navigate(`/settings/c2c_connectors/${response.id}`);
        },
      }
    );
  }

  function createCustomerSpace() {
    openModal<CreateCustomerSpaceModalProps['data']>(
      'CreateCustomerSpaceModal',
      {
        type: 'add',
        handleCustomerCreation: (res) =>
          form.setFieldValue('customerSpaceId', res.id),
      }
    );
  }

  const customersList =
    spaces.data?.reduce(
      (acc: { label: string; value: string }[], space: SpaceType) => {
        if (space.space_type === 'customer') {
          acc.push({
            label: space.name,
            value: toString(space.id),
          });
        }

        return acc;
      },
      []
    ) || [];

  const selectedCustomerSpace =
    form.values.customerSpaceId && spaces.isFetched
      ? spaces.data?.find(
          ({ id }) => id === toNumber(form.values.customerSpaceId)
        )
      : undefined;

  const selectedSpace = spaces.data?.find(
    ({ id }) => id === toNumber(form.values.spaceId)
  );

  useEffect(
    function resetSelectedSpaceOnCustomerChange() {
      if (values.customerSpaceId) {
        setFieldValue('spaceId', values.customerSpaceId);
      }
    },
    [setFieldValue, values.customerSpaceId]
  );

  return (
    <>
      <LoadingOverlay visible={createNewIntegration.isLoading} />

      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Stack spacing="xxl">
          <Stack spacing="xl">
            <Group position="apart">
              <Select
                maw={300}
                {...form.getInputProps('customerSpaceId')}
                label="Customer"
                data-testid="select-customer"
                required
                data={customersList}
                disabled={!customersList}
                value={form.getInputProps('customerSpaceId').value?.toString()}
                placeholder="Select a customer"
              />

              <Text
                c="gray.5"
                h="2rem"
                pt="0.5rem"
                pb="0.5rem"
                sx={{ alignSelf: 'flex-end' }}
              >
                OR
              </Text>
              <Button
                variant="outline"
                styles={createCustomerSpaceButtonStyles}
                onClick={createCustomerSpace}
                data-testid="create-new-customer-button"
              >
                Create new customer
              </Button>
            </Group>

            {form.values.customerSpaceId ? (
              <ChangeSpaceField
                space={selectedSpace || selectedCustomerSpace}
                onSetSpaceId={(spaceId) =>
                  form.setFieldValue('spaceId', spaceId)
                }
              />
            ) : null}
          </Stack>

          <Group position="right">
            <Button variant="default" onClick={onCancel}>
              Cancel
            </Button>

            <Button type="submit" data-testid="add-connector-submit-button">
              Add connector
            </Button>
          </Group>
        </Stack>
      </form>
    </>
  );
}

export interface AddC2cConnectorModalProps
  extends ModalProps<{
    connectorName?: SupportedC2cConnectorName;
  }> {}

export function AddC2cConnectorModal({
  closeMe,
  data: { connectorName },
}: AddC2cConnectorModalProps) {
  const { classes } = useStyles();

  const availableIntegrations = useIntegrationsList();

  const [selectedConnector, setSelectedConnector] =
    useState<SupportedC2cConnectorName | null>(connectorName || null);

  const openTrialAnnouncementModal = useOpenTrialAnnouncementModal();

  function onCancel() {
    setSelectedConnector(null);
    closeMe();
  }

  const integrationMeta = (availableIntegrations || []).find(
    (integration) => integration.id === selectedConnector
  );

  return (
    <Modal
      radius="lg"
      onClose={closeMe}
      opened={true}
      size="xl"
      title="Add Connector"
      styles={(theme) => ({
        content: {
          padding: theme.spacing.xxl,
        },
      })}
    >
      <Stack spacing="xxl" pt="lg" pb="s">
        <Text color="blue_gray.9">
          {selectedConnector
            ? `Choose a customer and space to which new devices will be imported. New
          devices will always be imported to this space. Devices that were moved
          to another space will remain in that space.`
            : 'Choose a connector to import devices into Xyte.'}
        </Text>

        {selectedConnector ? (
          <Stack>
            {integrationMeta ? (
              <Group>
                <img
                  width={38}
                  height={38}
                  src={integrationMeta.logo}
                  className={classes.connectorLogo}
                />

                <Text>{integrationMeta.name}</Text>
              </Group>
            ) : null}
            <CustomerSpaceSelection
              connectorName={selectedConnector}
              closeMe={closeMe}
              onCancel={onCancel}
            />
          </Stack>
        ) : (
          <ConnectorSelection
            onSelectConnector={(type) =>
              openTrialAnnouncementModal({
                onContinueWithTrial: () => setSelectedConnector(type),
              })
            }
          />
        )}
      </Stack>
    </Modal>
  );
}

const useStyles = createStyles({
  connectorLogo: {
    objectFit: 'contain',
  },
});

const createCustomerSpaceButtonStyles: ButtonProps['styles'] = (theme) => ({
  root: {
    flex: 1,
    borderColor: theme.colors.blue_gray[3],
    alignSelf: 'flex-end',
  },
});
