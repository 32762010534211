import { Anchor, Text } from '@mantine/core';
import React, { useState } from 'react';

import {
  DeviceModelType,
  useSendPartnerSupportEmail,
} from '@portals/api/organizations';
import { useOpenModal } from '@portals/redux';

import { ContactSupportModalProps } from '../../../ContactSupportModal';
import { DeviceClaimTroubleshootingStepsModal } from '../../../device-troubleshooting-steps/DeviceClaimTroubleshootingStepsModal';
import { FormComponentCommonProps } from '../../add-device-modal.types';

interface TroubleShootAndSupportMessageProps {
  handleClose: FormComponentCommonProps['handleClose'];
  modelInfo: DeviceModelType | null;
}

export function TroubleshootAndSupportMessage({
  handleClose,
  modelInfo,
}: TroubleShootAndSupportMessageProps) {
  const [displayTroubleshootingGuide, setDisplayTroubleshootingGuide] =
    useState<boolean>(false);

  const openModal = useOpenModal();
  const sendPartnerSupportEmail = useSendPartnerSupportEmail();

  const hasTroubleshootingSteps = Boolean(
    modelInfo?.troubleshooting_steps?.length
  );

  function onClickTroubleshootingGuide() {
    setDisplayTroubleshootingGuide(true);
  }

  function onClickContactSupport() {
    openModal<ContactSupportModalProps['data']>('ContactSupportModal', {
      onSubmit: (values) => {
        sendPartnerSupportEmail.mutate(
          {
            title: values.title,
            message: values.message,
          },
          {
            onSuccess: () => {
              openModal('ContactSupportSuccessModal');
            },
            onSettled: () => {
              handleClose();
            },
          }
        );
      },
    });
  }

  if (displayTroubleshootingGuide) {
    return (
      <DeviceClaimTroubleshootingStepsModal
        opened={displayTroubleshootingGuide}
        deviceModel={modelInfo}
        onClose={() => setDisplayTroubleshootingGuide(false)}
      />
    );
  }

  return (
    <Text c="gray.6">
      Need help?{' '}
      {hasTroubleshootingSteps && (
        <>
          <Anchor
            c="inherit"
            fw="inherit"
            onClick={onClickTroubleshootingGuide}
          >
            See troubleshooting guide
          </Anchor>{' '}
          or{' '}
        </>
      )}
      <Anchor
        c="inherit"
        fw="inherit"
        onClick={onClickContactSupport}
        data-testid="contact-support-button"
      >
        {`${hasTroubleshootingSteps ? 'contact' : 'Contact'} support`}
      </Anchor>
    </Text>
  );
}
