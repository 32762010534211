import { px, Select, SelectProps, useMantineTheme } from '@mantine/core';
import React from 'react';

import {
  IncidentType,
  useUpdateIncidentAssignee,
} from '@portals/api/organizations';
import { NameAbbreviationAvatar } from '@portals/core';
import { useHasEntityAccess } from '@portals/framework/EntityAccess';
import { AccessLevelEnum } from '@portals/types';

import { NO_ASSIGNEE_OPTION } from './assignee-select.constants';
import { useAssigneeSelect } from './assignee-select.hooks';
import { AssigneeSelectItem } from './AssigneeSelectItem';

interface AssigneeSelectProps extends Omit<SelectProps, 'data' | 'value'> {
  incident: IncidentType;
  avatarSize?: number;
  value?: string | null;
  assignableUsers: ReturnType<typeof useAssigneeSelect>['assignableUsers'];
  assigneeOptions: ReturnType<typeof useAssigneeSelect>['assigneeOptions'];
}

export function AssigneeSelect({
  incident,
  avatarSize = 34,
  assignableUsers,
  assigneeOptions,
  ...selectProps
}: AssigneeSelectProps) {
  const theme = useMantineTheme();

  const hasEditAccess = useHasEntityAccess(incident, AccessLevelEnum.Edit);

  const updateIncidentAssignee = useUpdateIncidentAssignee();

  const handleSelectAssignee = (selectedAssigneeId: string) => {
    if (selectedAssigneeId === NO_ASSIGNEE_OPTION.value && !incident.assignee) {
      return;
    }

    if (selectedAssigneeId === incident.assignee?.id) return;

    const selectedAssignee = assignableUsers.find(
      (assignee) => assignee.value === selectedAssigneeId
    );

    updateIncidentAssignee.mutate({
      deviceId: incident.device_id,
      incidentId: incident.id,
      assignee_id: selectedAssignee ? selectedAssignee.value : null,
    });
  };

  return (
    <Select
      data={assigneeOptions}
      withinPortal
      value={incident.assignee?.id ?? null}
      placeholder="Assign"
      styles={assigneeSelectStyles}
      disabled={!hasEditAccess || incident.status === 'closed'}
      itemComponent={AssigneeSelectItem}
      dropdownComponent="div"
      iconWidth={avatarSize + px(theme.spacing.sm)}
      icon={
        <NameAbbreviationAvatar
          radius="xl"
          color={incident.assignee?.id ? undefined : 'gray.5'}
          size={avatarSize}
          withToolTip={false}
          name={incident.assignee?.name || ''}
        />
      }
      onClick={(e) => e.stopPropagation()}
      onChange={handleSelectAssignee}
      inputContainer={(children) => <div>{children}</div>}
      {...selectProps}
    />
  );
}

const assigneeSelectStyles: SelectProps['styles'] = (theme) => ({
  input: {
    width: '100%',
    borderWidth: 0,
    color: theme.colors.gray[9],
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});
