import { Box, BoxProps, createStyles, Group, Text, Title } from '@mantine/core';
import React, { ReactNode, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { PageBreadcrumbs, PageBreadcrumbsProps } from '@portals/core';

export interface DashboardContentLayoutProps extends BoxProps {
  pageTitle?: ReactNode;
  titleRightSection?: ReactNode;
  subtitle?: ReactNode;
  breadcrumbs?: PageBreadcrumbsProps['crumbs'];
  withBreadcrumbsEndSeparator?: boolean;
  footer?: ReactNode;
}

export function DashboardContentLayout({
  children,
  pageTitle,
  titleRightSection,
  subtitle,
  breadcrumbs,
  className,
  withBreadcrumbsEndSeparator,
  footer,
  ...boxProps
}: DashboardContentLayoutProps) {
  const { classes, cx } = useStyles({ hasBreadcrumbs: Boolean(breadcrumbs) });

  const location = useLocation();
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(
    function resetScrollOnRouteChange() {
      containerRef.current?.scrollTo(0, 0);
    },
    [location.pathname]
  );

  const shouldRenderHeader = pageTitle || subtitle || breadcrumbs;
  const titleTestId =
    typeof pageTitle === 'object' ? 'inner-page-title' : pageTitle + '-title';

  return (
    <Box className={classes.content}>
      <Box
        className={cx(classes.container, className)}
        ref={containerRef}
        {...boxProps}
      >
        {shouldRenderHeader ? (
          <header className={classes.header}>
            {breadcrumbs ? (
              <PageBreadcrumbs
                crumbs={breadcrumbs}
                withEndSeparator={withBreadcrumbsEndSeparator}
                containerClassName={classes.breadcrumbsContainer}
              />
            ) : null}

            <Group position="apart">
              {pageTitle ? (
                <Title
                  order={3}
                  className={cx('layout-title', classes.pageTitle)}
                  data-testid={titleTestId}
                >
                  {pageTitle}
                </Title>
              ) : null}

              {titleRightSection ? titleRightSection : null}
            </Group>

            {subtitle ? (
              <Text size="md" mt="xs" className={classes.subtitle}>
                {subtitle}
              </Text>
            ) : null}
          </header>
        ) : null}

        {children}
      </Box>

      {footer}
    </Box>
  );
}

const useStyles = createStyles(
  (theme, params: { hasBreadcrumbs: boolean }) => ({
    content: {
      height: '100%',
      display: 'grid',
      gridTemplateRows: '1fr max-content',
    },
    container: {
      overflowY: 'auto',
      display: 'grid',
      gridTemplateRows: 'max-content 1fr',
      height: '100%',
      paddingTop: params.hasBreadcrumbs ? theme.spacing.xl : 48,
      paddingBottom: 30,
      paddingInline: 30,

      [theme.fn.smallerThan('md')]: {
        paddingTop: theme.spacing.xl,
        paddingInline: theme.spacing.xl,
      },
    },
    header: {
      marginBottom: theme.spacing.xl,
    },
    breadcrumbsContainer: {
      marginBottom: theme.spacing.md,
    },
    pageTitle: {
      fontWeight: 500,
      color: theme.colors.blue_gray[9],
    },
    subtitle: {
      marginTop: theme.spacing.sm,
      color: theme.colors.blue_gray[6],
    },
  })
);
