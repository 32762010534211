import {
  Box,
  Checkbox,
  createStyles,
  getStylesRef,
  LoadingOverlay,
  Stack,
  Text,
} from '@mantine/core';
import React from 'react';

import { DeviceType, useSpace } from '@portals/api/organizations';
import {
  DeviceAvatar,
  DeviceCecPartnerLogoOrName,
  DeviceStatusBadge,
} from '@portals/framework';
import { useOpenRouteModal } from '@portals/framework/route-modals';

import { useDeviceDrag } from '../../../../../hooks/device-cards';
import { canEdit } from '../../../../../lib/access';
import { DeviceIncidentsCounterWithIcon } from '../../../../components/DeviceCounters';
import { DeviceRelationsIndicator } from '../../device-indicators/DeviceRelationsIndicator';
import { DeviceMenu } from '../../device-menu/DeviceMenu';
import { useOverviewDevicesContext } from '../OverviewDevicesProvider';

interface DeviceCardProps {
  device: DeviceType;
}

export function DeviceCard({ device }: DeviceCardProps) {
  const { classes, cx } = useStyles();

  const openRouteModal = useOpenRouteModal();

  const { selectedDeviceIds, toggleSelectedDeviceId } =
    useOverviewDevicesContext();

  const space = useSpace({ spaceId: device.space_id });

  const { dragRef, isLoading } = useDeviceDrag(device);

  return (
    <div
      ref={canEdit(space) ? dragRef : null}
      className={cx(classes.container, {
        [classes.error]: device.status === 'error',
      })}
      onClick={() =>
        openRouteModal({ modalId: 'device', pathParams: [device.id] })
      }
    >
      <LoadingOverlay visible={isLoading} />

      <header className={classes.header}>
        <Checkbox
          onClick={(e) => e.stopPropagation()}
          checked={selectedDeviceIds.has(device.id)}
          onChange={() => toggleSelectedDeviceId(device.id)}
          className={cx({
            [classes.showOnHover]: !selectedDeviceIds.has(device.id),
          })}
        />

        <div className={classes.partner}>
          <DeviceCecPartnerLogoOrName
            device={device}
            imgProps={{ width: 72, loading: 'lazy' }}
          />
        </div>

        <div className={classes.showOnHover}>
          <DeviceMenu device={device} />
        </div>
      </header>

      <Stack className={classes.content}>
        <Box ta="center" w="100%">
          <Text c="blue_gray.8" mb={6} truncate>
            {device.name}
          </Text>

          <Text c="blue_gray.3" truncate>
            {device.partner.model}
          </Text>
        </Box>

        <DeviceAvatar
          size={100}
          radius="lg"
          src={device.image_url}
          icon={device.model_settings?.icon}
        />

        <div className={classes.iconsGrid}>
          <Box w="fit-content">
            <DeviceIncidentsCounterWithIcon device={device} />
          </Box>

          <DeviceStatusBadge status={device.status} dark />

          <Box w="fit-content">
            <DeviceRelationsIndicator device={device} />
          </Box>
        </div>
      </Stack>
    </div>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    overflow: 'hidden',
    position: 'relative',
    padding: theme.spacing.xxl,
    border: `1px solid ${theme.colors.gray[3]}`,
    borderRadius: 24,
    transition: 'box-shadow 200ms ease-in-out',
    cursor: 'pointer',
    backgroundColor: theme.white,

    ...theme.fn.hover({
      boxShadow: theme.shadows.lg,

      [`.${getStylesRef('showOnHover')}`]: {
        opacity: 1,
      },
    }),
  },
  error: {
    borderColor: theme.colors.red[4],
  },
  showOnHover: {
    ref: getStylesRef('showOnHover'),
    opacity: 0,
    transition: 'opacity 200ms ease-in-out',
  },
  header: {
    overflow: 'hidden',
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    gap: theme.spacing.md,
    placeItems: 'center',
    marginBottom: 6,
  },
  partner: {
    overflow: 'hidden',
    width: '100%',
    textAlign: 'center',
  },
  content: {
    overflow: 'hidden',
    alignItems: 'center',
  },
  iconsGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr auto 1fr',
    gap: theme.spacing.md,
  },
}));
