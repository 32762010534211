import { Stack } from '@mantine/core';
import React, { ReactNode } from 'react';

interface TilesSkeletonProps {
  count: number;
  gap: number;
  itemSkeleton: ReactNode;
}

export function TilesSkeleton({
  count,
  gap,
  itemSkeleton,
}: TilesSkeletonProps) {
  const items = new Array(count).fill(null);

  return (
    <Stack spacing={gap}>
      {items.map((_, index) => (
        <React.Fragment key={index}>{itemSkeleton}</React.Fragment>
      ))}
    </Stack>
  );
}
