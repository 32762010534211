import {
  Box,
  Button,
  createStyles,
  Group,
  ModalProps,
  ScrollArea,
  Stack,
  Text,
  Textarea,
} from '@mantine/core';
import React from 'react';

import { DeviceModelType } from '@portals/api/organizations';
import { ModalCenteredMediaLayout } from '@portals/core';
import { getWidgetIcon } from '@portals/device-widgets';

import { ContactPartnerSupportButton } from '../../components/ContactPartnerSupportButton';

export interface DeviceClaimTroubleshootingStepsModalProps extends ModalProps {
  deviceModel: DeviceModelType | null;
}

export function DeviceClaimTroubleshootingStepsModal({
  deviceModel,
  onClose,
  ...modalProps
}: DeviceClaimTroubleshootingStepsModalProps) {
  const { classes } = useStyles();

  return (
    <ModalCenteredMediaLayout
      radius="lg"
      title="Troubleshooting guide"
      mah="80vh"
      styles={{
        root: {
          overflow: 'hidden',
          maxHeight: '90vh',
        },
      }}
      onClose={onClose}
      footer={
        <Group grow noWrap>
          {deviceModel && (
            <ContactPartnerSupportButton
              partnerId={deviceModel.partner.id}
              supportModalTitle={`Claiming device: ${deviceModel.model}`}
              variant="default"
            />
          )}

          <Button onClick={onClose}>Back</Button>
        </Group>
      }
      {...modalProps}
    >
      <Stack>
        <Text size="sm" c="blue_gray.9">
          If you're having trouble claiming your device, try the following
          steps:
        </Text>

        <ScrollArea.Autosize mah="calc(90vh - 400px)">
          <Stack spacing="xs">
            {deviceModel?.troubleshooting_steps?.map((step, index) => {
              const { Icon } = getWidgetIcon(step.icon);

              return (
                <Box key={index} className={classes.stepContainer} w="100%">
                  <Stack align="start">
                    <Icon width={24} height={24} />
                  </Stack>

                  <Stack spacing="xs">
                    <Text c="gray.9" weight={600}>
                      {step.title}
                    </Text>

                    <Textarea
                      c="gray.6"
                      autosize
                      readOnly
                      value={step.description}
                      classNames={{
                        input: classes.textAreaInput,
                      }}
                    />
                  </Stack>
                </Box>
              );
            })}
          </Stack>
        </ScrollArea.Autosize>

        <Text size="sm" c="blue_gray.9">
          If you've tried these steps and are still unable to claim your device
          please contact our Support for assistance.
        </Text>
      </Stack>
    </ModalCenteredMediaLayout>
  );
}

const useStyles = createStyles((theme) => ({
  stepContainer: {
    borderRadius: theme.radius.lg,
    padding: theme.spacing.md,
    display: 'grid',
    gridTemplateColumns: 'min-content 1fr',
    gap: theme.spacing.md,
    border: `1px solid ${theme.colors.gray[3]}`,
  },
  textAreaInput: {
    border: 'none',
    padding: 0,
    width: '100%',
    borderRadius: 0,
    overflow: 'hidden',
    minHeight: 'unset',
    color: theme.colors.gray[6],
  },
}));
