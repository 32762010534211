import { MantineColor } from '@mantine/core';
import { isNil } from 'lodash/fp';

import { DeviceStatusType } from '@portals/types';

interface DeviceConfigStatusTextParams {
  configVersion: number | null | undefined;
  appliedConfigVersion: number | null | undefined;
}

export function getDeviceConfigStatusText({
  configVersion,
  appliedConfigVersion,
}: DeviceConfigStatusTextParams) {
  if (isNil(configVersion) || isNil(appliedConfigVersion)) {
    return 'Unknown';
  }

  if (configVersion === appliedConfigVersion) {
    return 'Up to date';
  }

  const versionsDiff = configVersion - appliedConfigVersion;

  return `${versionsDiff} versions behind`;
}

export function getDeviceName(deviceName: string | null | undefined) {
  return deviceName || 'Unknown';
}

interface DeviceStatusMetadata {
  id: DeviceStatusType | 'unknown';
  displayName: string;
  baseColor: MantineColor;
  bg: MantineColor;
  indicator: MantineColor;
  darkIndicator: MantineColor;
}

const DEVICE_STATUS_METADATA = {
  online: {
    id: 'online',
    displayName: 'Online',
    baseColor: 'teal',
    bg: 'teal.0',
    indicator: 'teal_accent.4',
    darkIndicator: 'teal_accent.3',
  },
  offline: {
    id: 'offline',
    displayName: 'Offline',
    baseColor: 'gray',
    bg: 'gray.1',
    indicator: 'gray.4',
    darkIndicator: 'gray.4',
  },
  error: {
    id: 'error',
    displayName: 'Error',
    baseColor: 'red',
    bg: 'red.0',
    indicator: 'red.4',
    darkIndicator: 'red_accent.2',
  },
  unavailable: {
    id: 'unavailable',
    displayName: 'Unavailable',
    baseColor: 'amber',
    bg: 'amber.0',
    indicator: 'amber.7',
    darkIndicator: 'amber_accent.4',
  },
} as const satisfies Record<DeviceStatusType, DeviceStatusMetadata>;

export function getDeviceStatusIds() {
  return Object.keys(DEVICE_STATUS_METADATA) as DeviceStatusType[];
}

export function getDeviceStatusMetadataList() {
  return Object.values(DEVICE_STATUS_METADATA);
}

export function getDeviceStatusMetadata(
  deviceStatus: DeviceStatusType | undefined
) {
  if (!deviceStatus || !DEVICE_STATUS_METADATA[deviceStatus]) {
    return {
      id: 'unknown',
      displayName: 'N/A',
      baseColor: 'yellow_accent',
      bg: 'yellow_accent.0',
      indicator: 'yellow_accent.7',
      darkIndicator: 'yellow_accent.4',
    } as const satisfies DeviceStatusMetadata;
  }

  return DEVICE_STATUS_METADATA[deviceStatus];
}
