import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react';
import { useDebounce } from 'react-use';

import { SearchInput } from './SearchInput';

interface DebouncedSearchInputProps
  extends Omit<
    React.ComponentProps<typeof SearchInput>,
    'value' | 'onChange' | 'onClear'
  > {
  initialValue?: string;
  debounceWait?: number;
  debouncedOnChange: (debouncedValue: string) => void;
}

export interface DebouncedSearchInputHandle {
  reset: VoidFunction;
}

export const DebouncedSearchInput = forwardRef<
  DebouncedSearchInputHandle,
  DebouncedSearchInputProps
>(
  (
    {
      initialValue = '',
      debouncedOnChange,
      debounceWait = 400,
      ...searchInputProps
    },
    ref
  ) => {
    const [localValue, setLocalValue] = useState(initialValue);

    useDebounce(() => debouncedOnChange(localValue), debounceWait, [
      localValue,
    ]);

    const reset = useCallback(() => setLocalValue(''), []);

    useImperativeHandle(ref, () => ({ reset }), [reset]);

    return (
      <SearchInput
        value={localValue}
        onChange={(e) => setLocalValue(e.target.value)}
        onClear={reset}
        {...searchInputProps}
        data-testid="search-field-input"
      />
    );
  }
);
