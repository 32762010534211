import { Box, createStyles, Loader, Paper } from '@mantine/core';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { DeviceDetailsType, useDevice } from '@portals/api/organizations';

import { DevicePanelCommands } from './DevicePanelCommands';
import { DevicePanelPreview } from './DevicePanelPreview';
import { useDeviceOverviewContext } from '../DeviceOverviewContext';

export const FULL_DEVICE_PANEL_HEIGHT = 328;
export const MINIMAL_DEVICE_PANEL_HEIGHT = 278;

export function DevicePanelWrapper() {
  const { selectedDeviceId } = useDeviceOverviewContext();
  const device = useDevice(selectedDeviceId, {
    refetchInterval: 5000,
    enabled: !!selectedDeviceId,
  });
  const params = useParams<{ device_id: string }>();

  const panelHeight =
    params.device_id === selectedDeviceId
      ? MINIMAL_DEVICE_PANEL_HEIGHT
      : FULL_DEVICE_PANEL_HEIGHT;
  const { classes } = useStyles(panelHeight);

  if (!selectedDeviceId) return null;

  return (
    <AnimatePresence>
      <motion.div
        style={{
          position: 'absolute',
          bottom: 0,
          width: '80%',
          background: 'white',
          y: '100%',
          left: '50%',
          x: '-50%',
          borderRadius: 16,
          minHeight: panelHeight,
          zIndex: 1,
        }}
        initial={{ y: '100%' }}
        animate={{ y: -28 }}
        exit={{ y: '100%' }}
        transition={{ duration: 0.15 }}
      >
        <Paper w="100%" h="100%" pos="absolute" className={classes.container}>
          {device.isFetched ? (
            <DevicePanel device={device.data} />
          ) : (
            <Box className={classes.loaderContainer}>
              <Loader />
            </Box>
          )}
        </Paper>
      </motion.div>
    </AnimatePresence>
  );
}

interface DevicePanelProps {
  device?: DeviceDetailsType;
}

export function DevicePanel({ device }: DevicePanelProps) {
  const [isDisplayCommands, setIsDisplayCommands] = useState(false);

  if (!device) return null;

  return isDisplayCommands ? (
    <DevicePanelCommands
      device={device}
      onCommandsToggle={() => setIsDisplayCommands((prev) => !prev)}
    />
  ) : (
    <DevicePanelPreview
      device={device}
      onCommandsToggle={() => setIsDisplayCommands((prev) => !prev)}
    />
  );
}

const useStyles = createStyles((theme, height: number) => ({
  container: {
    borderRadius: theme.radius.lg,
    overflow: 'hidden',
    boxShadow: '0px 10px 24.6px 0px rgba(0, 0, 0, 0.14)',
  },
  loaderContainer: {
    width: '100%',
    height,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
