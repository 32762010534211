import { Button, Modal, Stack, TextInput } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import React from 'react';
import { object, string } from 'yup';

import {
  useCreateDeviceModelFile,
  useUpdateDeviceModelFile,
  useUpdateSpaceFile,
} from '@portals/api/organizations';
import { ModalFooter } from '@portals/core';
import { ModalProps } from '@portals/framework';
import { OrganizationFileResponseType } from '@portals/types';
import { ChecksumAdornment, SignatureAdornment } from '@portals/ui';

import { FileUploadSwitcher } from './FileUploadSwitcher';

interface FileInfoUploaderFormValues {
  fileUrl: string;
  name: string;
  desc: string;
  version: string;
  checksum: string;
  signature: string;
  public_notes: string;
}

export interface FileInfoUploaderProps
  extends ModalProps<{
    file?: OrganizationFileResponseType;
    deviceType?: string;
    fileType?: string;
    onSuccess?: (fileId: string) => void;
  }> {}

const schema = object({
  fileUrl: string().url('Invalid URL').required('File is required'),
  name: string().required('Name is required'),
  desc: string(),
  version: string(),
  checksum: string(),
  signature: string(),
  public_notes: string(),
});

export function FileInfoUploader({ data, closeMe }: FileInfoUploaderProps) {
  const { file, deviceType, fileType, onSuccess } = data;
  const createDeviceModelFile = useCreateDeviceModelFile();
  const updateDeviceModelFile = useUpdateDeviceModelFile();
  const updateSpaceFile = useUpdateSpaceFile();

  const isEdit = Boolean(file);

  const form = useForm<FileInfoUploaderFormValues>({
    initialValues: {
      fileUrl: file?.url ?? '',
      name: file?.name ?? '',
      desc: file?.desc ?? '',
      signature: '',
      public_notes: file?.public_notes ?? '',
      checksum: '',
      version: file?.version ?? '',
    },
    validate: yupResolver(schema),
  });

  const onSubmit = (formData: typeof form.values) => {
    if (isEdit && file) {
      handleEdit(formData);

      return;
    }

    const deviceModelId = deviceType ?? file?.device_model;

    if (!deviceModelId) return;

    createDeviceModelFile.mutate(
      {
        formData: {
          url: formData.fileUrl,
          name: formData.name,
          desc: formData.desc,
          version: formData.version,
          checksum: formData.checksum,
          public_notes: formData.public_notes,
          signature: formData.signature,
          file_type: fileType,
        },
        deviceModelId,
      },
      {
        onSuccess: (response) => {
          onSuccess?.(response.id);
          closeMe();
        },
      }
    );
  };

  const handleEdit = (updatedFile: typeof form.values) => {
    if (!file || !file.id) return;

    // If file is related to space, update via space files API
    if (file.space !== null && file.space !== undefined) {
      updateSpaceFile.mutate(
        {
          fileId: file.id,
          spaceId: file.space,
          fileData: {
            url: updatedFile.fileUrl,
            file_type: fileType,
            checksum: updatedFile.checksum,
            public_notes: updatedFile.public_notes,
            signature: updatedFile.signature,
            desc: updatedFile.desc,
            version: updatedFile.version,
            name: updatedFile.name,
          },
        },
        {
          onSuccess: (response) => {
            onSuccess?.(response.id);
            closeMe();
          },
        }
      );
    }
    // If file is related to device model, update via device model files API
    else if (file.device_model) {
      updateDeviceModelFile.mutate(
        {
          file: {
            url: updatedFile.fileUrl,
            version: updatedFile.version,
            public_notes: updatedFile.public_notes,
            name: updatedFile.name,
            desc: updatedFile.desc,
            signature: form.values.signature,
            checksum: form.values.checksum,
            file_type: fileType,
          },
          deviceModelId: file.device_model,
          fileId: file.id,
        },
        {
          onSuccess: (response) => {
            onSuccess?.(response.id);
            closeMe();
          },
        }
      );
    }
  };

  return (
    <Modal
      opened
      onClose={closeMe}
      title={isEdit ? 'Edit file' : 'Upload file'}
      padding="xl"
    >
      <form noValidate onSubmit={form.onSubmit(onSubmit)}>
        <Stack spacing="xl">
          <FileUploadSwitcher
            initialFileUrl={file?.url}
            onChangeFileUrl={(fileUrl) => {
              form.setFieldValue('fileUrl', fileUrl);
            }}
            fileUrlError={form.errors.fileUrl}
          />

          <TextInput
            required
            label="File name"
            placeholder="File name"
            {...form.getInputProps('name')}
            data-testid="file-name-text-input"
          />

          <TextInput
            label="Description"
            placeholder="Description"
            {...form.getInputProps('desc')}
            data-testid="file-description-text-input"
          />

          <TextInput
            label="Version"
            placeholder="Version"
            {...form.getInputProps('version')}
            data-testid="file-version-text-input"
          />

          <TextInput
            label="Checksum"
            placeholder="Checksum"
            rightSection={<ChecksumAdornment docsUrl="https://docs.xyte.io/" />}
            {...form.getInputProps('checksum')}
            data-testid="file-checksum-text-input"
          />

          <TextInput
            label="Signature"
            placeholder="Signature"
            rightSection={
              <SignatureAdornment docsUrl="https://docs.xyte.io/" />
            }
            {...form.getInputProps('signature')}
          />

          <TextInput
            label="Notes"
            placeholder="Notes"
            {...form.getInputProps('public_notes')}
            data-testid="file-notes-text-input"
          />

          <ModalFooter position="right">
            <Button variant="default" onClick={closeMe}>
              Cancel
            </Button>

            <Button
              type="submit"
              loading={
                createDeviceModelFile.isLoading ||
                updateDeviceModelFile.isLoading
              }
              data-testid="add-file-submit-button"
            >
              {isEdit ? 'Save' : 'Add'}
            </Button>
          </ModalFooter>
        </Stack>
      </form>
    </Modal>
  );
}
