import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import { useMemo } from 'react';

import { toastrError, toastrSuccess } from '@portals/redux/actions/toastr';
import {
  IncidentPriorityLevel,
  UsePaginatedTableApiQuery,
} from '@portals/types';

import { INCIDENTS_API_URL, incidentsQueryKeys } from './incidents.constants';
import { IncidentsCountType, IncidentType } from './incidents.types';
import { useApiQuery } from '../../hooks';
import { ServerError, ServerSuccess } from '../../types/common';
import {
  fetchApiRequest,
  usePaginatedTableApiQuery,
  useRequestOptions,
} from '../../utils';
import { globalQueryKeys } from '../global-query-keys';
import { spacesQueryKeys } from '../spaces';

export function useIncidents(
  tableState: UsePaginatedTableApiQuery<IncidentType>['tableState'],
  columns: UsePaginatedTableApiQuery<IncidentType>['columns'],
  baseUrl = INCIDENTS_API_URL,
  queryKey: UsePaginatedTableApiQuery<IncidentType>['queryKey']
) {
  return usePaginatedTableApiQuery<IncidentType>({
    baseUrl,
    queryKey: queryKey
      ? [...queryKey, baseUrl, tableState]
      : [...globalQueryKeys.incidents, baseUrl, tableState],
    tableState,
    columns,
    queryOptions: {
      cacheTime: 0,
    },
  });
}

interface UseAllIncidentsParams {
  spaceTreePathName?: string;
  deviceId?: string;
  status?: string;
  spaceId?: number;
  perPage?: number;
  sortBy?: string;
}

// Hacky way to get around the "20 per page", when we need to fetch "all" data (fetching max 500)
export const useAllIncidents = (
  {
    spaceTreePathName,
    deviceId,
    status,
    spaceId,
    perPage = 500,
    sortBy,
  }: UseAllIncidentsParams = {},
  queryKey: Array<string> = []
): UseQueryResult<IncidentType[]> => {
  const { url, options } = useRequestOptions({
    url: INCIDENTS_API_URL,
  });

  const requestUrl = useMemo(() => {
    let finalUrl = `${url}?per_page=${perPage}&page=1`;

    if (spaceTreePathName) {
      finalUrl += `&q[space_tree_path_name_cont]=${spaceTreePathName}`;
    }

    if (deviceId) {
      finalUrl += `&q[device_id_eq]=${deviceId}`;
    }

    if (status) {
      finalUrl += `&q[status_eq]=${status}`;
    }

    if (spaceId) {
      finalUrl += `&q[device_space_id_eq]=${spaceId}`;
    }

    if (sortBy) {
      finalUrl += `&q[s]=${sortBy}+asc`;
    }

    return finalUrl;
  }, [url, perPage, spaceTreePathName, deviceId, status, spaceId, sortBy]);

  return useQuery({
    queryKey: [...incidentsQueryKeys.base, ...queryKey, requestUrl],
    queryFn: () => fetchApiRequest(requestUrl, options),
    select: (data) => data.data,
    staleTime: 3000,
    meta: {
      method: 'GET',
      baseUrl: INCIDENTS_API_URL,
    },
  });
};

export function useIncident(incidentId?: string) {
  return useApiQuery<IncidentType>(
    `${INCIDENTS_API_URL}/${incidentId}`,
    incidentsQueryKeys.detail(incidentId || ''),
    {
      enabled: !!incidentId,
      staleTime: 0,
      cacheTime: 0,
    }
  );
}

interface UseUpdateIncidentsRequestPayload<BulkActionPayload> {
  incident_ids: string[];
  bulk_action: BulkActionPayload;
}

export function useUpdateIncidentsPriority() {
  const queryClient = useQueryClient();
  const method = 'POST';

  const { url, options } = useRequestOptions({
    url: '',
    method,
  });

  const endpointUrl = `${INCIDENTS_API_URL}/bulk_update`;

  return useMutation<
    ServerSuccess,
    ServerError,
    UseUpdateIncidentsRequestPayload<{
      priority: IncidentPriorityLevel;
    }>
  >({
    mutationFn: ({ incident_ids, bulk_action }) =>
      fetchApiRequest(`${url}${endpointUrl}`, {
        ...options,
        body: JSON.stringify({
          incident_ids,
          bulk_action: {
            type: 'update_priority',
            priority: bulk_action.priority,
          },
        }),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(globalQueryKeys.incidents);
      queryClient.invalidateQueries(globalQueryKeys.devices);
      queryClient.invalidateQueries(spacesQueryKeys.base);

      toastrSuccess('Priority changed successfully');
    },
    onError: ({ error }: any) => {
      toastrError(error);
    },
    meta: {
      mutationName: 'useUpdateIncidentsPriority',
      baseUrl: endpointUrl,
      method,
    },
  });
}

export function useUpdateIncidentsAssignee() {
  const queryClient = useQueryClient();
  const method = 'POST';

  const { url, options } = useRequestOptions({
    url: '',
    method,
  });

  const endpointUrl = `${INCIDENTS_API_URL}/bulk_update`;

  return useMutation<
    ServerSuccess,
    ServerError,
    UseUpdateIncidentsRequestPayload<{
      assignee_id: string | null;
    }>
  >({
    mutationFn: ({ incident_ids, bulk_action }) =>
      fetchApiRequest(`${url}${endpointUrl}`, {
        ...options,
        body: JSON.stringify({
          incident_ids,
          bulk_action: {
            type: 'assign',
            assignee_id: bulk_action.assignee_id,
          },
        }),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(globalQueryKeys.incidents);
      queryClient.invalidateQueries(globalQueryKeys.devices);
      queryClient.invalidateQueries(spacesQueryKeys.base);

      toastrSuccess('Assignee set successfully');
    },
    onError: ({ error }: any) => {
      toastrError(error);
    },
    meta: {
      mutationName: 'useUpdateIncidentsAssignee',
      baseUrl: endpointUrl,
      method,
    },
  });
}

export function useSnoozeIncidents() {
  const queryClient = useQueryClient();
  const method = 'POST';

  const { url, options } = useRequestOptions({
    url: '',
    method,
  });

  const endpointUrl = `${INCIDENTS_API_URL}/bulk_update`;

  return useMutation<
    ServerSuccess,
    ServerError,
    UseUpdateIncidentsRequestPayload<{
      snoozed_until: number | null;
    }>
  >({
    mutationFn: ({ incident_ids, bulk_action }) =>
      fetchApiRequest(`${url}${endpointUrl}`, {
        ...options,
        body: JSON.stringify({
          incident_ids,
          bulk_action: {
            type: 'snooze',
            snoozed_until: bulk_action.snoozed_until
              ? new Date(Date.now() + bulk_action.snoozed_until)
              : null,
          },
        }),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(globalQueryKeys.incidents);
      queryClient.invalidateQueries(globalQueryKeys.devices);
      queryClient.invalidateQueries(spacesQueryKeys.base);

      toastrSuccess('Snoozed set successfully');
    },
    onError: ({ error }: any) => {
      toastrError(error);
    },
    meta: {
      mutationName: 'useSnoozeIncidents',
      baseUrl: endpointUrl,
      method,
    },
  });
}

export function useResolveIncidents() {
  const queryClient = useQueryClient();
  const method = 'POST';

  const { url, options } = useRequestOptions({
    url: '',
    method,
  });

  const endpointUrl = `${INCIDENTS_API_URL}/bulk_update`;

  return useMutation<
    ServerSuccess,
    ServerError,
    UseUpdateIncidentsRequestPayload<{
      closed_reason: string;
    }>
  >({
    mutationFn: ({ incident_ids, bulk_action }) =>
      fetchApiRequest(`${url}${endpointUrl}`, {
        ...options,
        body: JSON.stringify({
          incident_ids,
          bulk_action: {
            type: 'close',
            closed_reason: bulk_action.closed_reason,
          },
        }),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(globalQueryKeys.incidents);
      queryClient.invalidateQueries(globalQueryKeys.devices);
      queryClient.invalidateQueries(spacesQueryKeys.base);

      toastrSuccess('Resolved incidents successfully');
    },
    onError: ({ error }: any) => {
      toastrError(error);
    },
    meta: {
      mutationName: 'useResolveIncidents',
      baseUrl: endpointUrl,
      method,
    },
  });
}

export const useIncidentsCount = () =>
  useApiQuery<IncidentsCountType>(
    `${INCIDENTS_API_URL}/count`,
    incidentsQueryKeys.count()
  );
