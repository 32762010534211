import {
  Badge,
  Checkbox,
  createStyles,
  Group,
  rem,
  Stack,
  Text,
  ThemeIcon,
} from '@mantine/core';
import React from 'react';

import { AssetType } from '@portals/api/organizations';
import { TextWithTooltip } from '@portals/core';

import { ASSETS_ICONS_MAP } from '../../../assets/assets-icons-map';
import { AssetMenu } from '../../asset-menu/AssetMenu';
import { useOverviewAssetsContext } from '../OverviewAssetsProvider';

interface AssetTileProps {
  asset: AssetType;
}

export function AssetTile({ asset }: AssetTileProps) {
  const { classes, cx } = useStyles();
  const {
    selectedAssetIds,
    toggleSelectedAssetId,
    space,
    setActiveAssetId,
    activeAssetId,
  } = useOverviewAssetsContext();

  const AssetIcon = ASSETS_ICONS_MAP[asset.icon_name];

  return (
    <div
      className={cx(classes.container, {
        [classes.active]: activeAssetId === asset.id,
      })}
    >
      <Checkbox
        checked={selectedAssetIds.has(asset.id)}
        onChange={() => toggleSelectedAssetId(asset.id)}
      />

      <div
        className={classes.middleContent}
        onClick={() => setActiveAssetId(asset.id)}
      >
        <Group spacing="xl" noWrap className={classes.overflowHidden}>
          <Group noWrap>
            <TextWithTooltip
              label={asset.manufacturer?.name}
              fz="xs"
              w={48}
              tooltipProps={{ disabled: !asset.manufacturer?.name }}
            >
              {asset.manufacturer?.name}
            </TextWithTooltip>

            <ThemeIcon size={48} bg="gray.2" c="gray" radius="md">
              <AssetIcon />
            </ThemeIcon>
          </Group>

          <Stack spacing={4} className={classes.overflowHidden}>
            <Text truncate>{asset.name}</Text>

            <Text truncate fz="xs" c="gray.5">
              {asset.device_model?.name}
            </Text>
          </Stack>
        </Group>

        <Group noWrap>
          {asset.device_type?.name && (
            <Badge
              variant="outline"
              className={cx(classes.badge, classes.deviceTypeBadge)}
            >
              {asset.device_type.name}
            </Badge>
          )}

          {asset.status?.name && (
            <Badge className={cx(classes.badge, classes.statusBadge)}>
              {asset.status.name}
            </Badge>
          )}

          {space.data && <AssetMenu asset={asset} space={space.data} />}
        </Group>
      </div>
    </div>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    position: 'relative',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing.md,
    paddingBlock: theme.spacing.xs,
    paddingInline: theme.spacing.xl,
    borderRadius: theme.radius.md,
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
    backgroundColor: theme.white,
    cursor: 'pointer',

    ...theme.fn.hover({
      boxShadow: theme.shadows.xs,
    }),
  },
  active: {
    border: `1px solid ${theme.fn.primaryColor()}`,
  },
  middleContent: {
    overflow: 'hidden',
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing.xl,
  },
  overflowHidden: {
    overflow: 'hidden',
  },
  badge: {
    fontSize: theme.fontSizes.xs,
    fontWeight: 400,
    height: rem(28),
  },
  deviceTypeBadge: {
    color: theme.colors.gray[5],
    borderColor: theme.colors.gray[3],
  },
  statusBadge: {
    color: theme.white,
    backgroundColor: theme.fn.rgba(theme.colors.gray[9], 0.7),
  },
}));
