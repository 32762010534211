import { createStyles, px, Transition, UnstyledButton } from '@mantine/core';
import { useClickOutside } from '@mantine/hooks';
import React, { useState } from 'react';

import { SpaceType } from '@portals/api/organizations';

import netSpeekLogoSrc from './netspeek_logo.png';
import { useCurrentSpace } from '../overview.hooks';

interface ChatContentProps {
  isOpen: boolean;
  toggleChat: () => void;
  spaceId: SpaceType['id'];
}

function ChatContent({ isOpen, toggleChat, spaceId }: ChatContentProps) {
  const { classes } = useStyles();

  return (
    <Transition
      mounted={isOpen}
      transition={{
        transitionProperty: 'transform, opacity',
        in: {
          transform: 'scale(1)',
          opacity: 1,
          transformOrigin: 'bottom right',
        },
        out: {
          transform: 'scale(0)',
          opacity: 0,
          transformOrigin: 'bottom right',
        },
      }}
      duration={300}
    >
      {(styles) => (
        <div
          className={classes.chatContent}
          style={{ ...styles, transformOrigin: 'bottom right' }}
        >
          <button
            className={classes.closeButton}
            onClick={toggleChat}
            aria-label="Close Chat"
          >
            ✕
          </button>

          <iframe
            className={classes.iframe}
            src={`https://lena-dev.azurewebsites.net/xyte?space_id=${spaceId}&api_key=00385fa19284ba80cdb48a10880f87df`}
            title="Chat"
          />
        </div>
      )}
    </Transition>
  );
}

export function ChatBubble() {
  const currentSpace = useCurrentSpace();
  const { classes, cx } = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  const toggleChat = () => setIsOpen((prev) => !prev);

  const ref = useClickOutside(() => setIsOpen(false));

  return (
    <div className={classes.chatBubble} ref={ref}>
      <UnstyledButton
        onClick={toggleChat}
        className={cx(classes.toggleButton, { active: isOpen })}
        variant="outline"
      >
        <img src={netSpeekLogoSrc} alt="NetSpeek" width={49} height={49} />
      </UnstyledButton>

      <ChatContent
        isOpen={isOpen}
        toggleChat={toggleChat}
        spaceId={currentSpace.id}
      />
    </div>
  );
}

const useStyles = createStyles((theme) => ({
  chatBubble: {
    position: 'fixed',
    bottom: theme.spacing.lg,
    right: theme.spacing.lg,
    zIndex: 1000,
  },
  toggleButton: {
    borderRadius: '50%',
    width: 50,
    height: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    backgroundColor: theme.colors.white,
    transition: 'box-shadow, transform, border-color 150ms ease',

    ...theme.fn.hover({
      boxShadow: theme.shadows.xl,
      transform: 'scale(1.05)',
      transformOrigin: 'bottom right',
      borderColor: theme.colors.blue_accent[8],
    }),

    '&.active': {
      boxShadow: theme.shadows.xl,
      transform: 'scale(1.05)',
      transformOrigin: 'bottom right',
      borderColor: theme.colors.blue_accent[8],
    },

    border: `1px solid ${theme.colors.gray[5]}`,
  },
  closeButton: {
    position: 'absolute',
    top: 8,
    right: 8,
    backgroundColor: theme.colors.gray[0],
    border: 'none',
    borderRadius: theme.radius.sm,
    cursor: 'pointer',
    padding: '4px 8px',
    fontSize: theme.fontSizes.sm,
    boxShadow: theme.shadows.sm,
  },
  chatContent: {
    position: 'fixed',
    bottom: px(theme.spacing.xl) * 4,
    right: theme.spacing.md,
    width: 400,
    height: 600,
    backgroundColor: theme.white,
    border: `1px solid ${theme.colors.gray[2]}`,
    borderRadius: theme.radius.md,
    boxShadow: theme.shadows.md,
    overflow: 'hidden',
    padding: theme.spacing.xs,
  },
  iframe: {
    width: '100%',
    height: '100%',
    border: 'none',
  },
}));
