import { createStyles, Divider, Group, Stack, Text } from '@mantine/core';
import React, { Dispatch, SetStateAction } from 'react';
import { contextMenu, Menu } from 'react-contexify';

import {
  SpaceType,
  useSpace,
  useUpdateSpaceMaintenance,
} from '@portals/api/organizations';
import { usePermissionAccess } from '@portals/framework';
import { ReactComponent as BoxAdd } from '@portals/icons/linear/box-add.svg';
import { ReactComponent as Edit } from '@portals/icons/linear/edit.svg';
import { ReactComponent as Lock } from '@portals/icons/linear/lock.svg';
import { ReactComponent as MonitorMobbile } from '@portals/icons/linear/monitor-mobbile.svg';
import { ReactComponent as Settings } from '@portals/icons/linear/setting-2.svg';
import { ReactComponent as Snooze } from '@portals/icons/linear/snooze.svg';
import { ReactComponent as Trash } from '@portals/icons/linear/trash.svg';
import { useOpenModal } from '@portals/redux';
import { suppressPropagation } from '@portals/utils';

import { canAdmin } from '../../../../../../lib/access';
import { useOpenClaimDeviceModal } from '../../../../../components/ClaimDeviceButton';
import { SpaceMaintenanceActivationModalProps } from '../../../../../modals';
import { useOverviewRouting } from '../../../overview-routing.hooks';
import { OverviewSettingsTabEnum } from '../../../overview-settings-drawer/settings-drawer.types';

interface SpaceNodeContextMenuProps {
  nodeId: SpaceType['id'];
  onEditToggle: () => void;
  onCreateSpace: () => void;
  setIsContextMenuOpen: Dispatch<SetStateAction<boolean>>;
}

export function SpaceNodeContextMenu({
  nodeId,
  onEditToggle,
  onCreateSpace,
  setIsContextMenuOpen,
}: SpaceNodeContextMenuProps) {
  const { classes } = useStyles();
  const openModal = useOpenModal();
  const { isAdmin } = usePermissionAccess();
  const openClaimDeviceModal = useOpenClaimDeviceModal();

  const space = useSpace({ spaceId: nodeId });
  const updateSpaceMaintenance = useUpdateSpaceMaintenance();
  const overviewRouting = useOverviewRouting();

  if (!space) return null;

  const isRoot = space.parent_id === null;
  const isMainUnsortedSpace =
    space.parent_id === space.root_customer_space_id &&
    space.name === 'Unsorted';

  const canRemove = !isRoot && !isMainUnsortedSpace;
  const isUnderMaintenance = space.state?.maintenance || false;

  const handleDelete = () => openModal('DeleteSpaceModal', { space });

  const handleSetMaintenance = () => {
    if (!isUnderMaintenance) {
      openModal<SpaceMaintenanceActivationModalProps['data']>(
        'SpaceMaintenanceActivationModal',
        { spaceId: space.id }
      );
    } else {
      updateSpaceMaintenance.mutate({
        spaceId: space.id,
        enabled: false,
      });
    }
  };

  return (
    <Menu
      id={nodeId}
      onVisibilityChange={(isVisible) => setIsContextMenuOpen(isVisible)}
    >
      <>
        <Stack className={classes.container} spacing="xs" pt="xs">
          <Text className={classes.title}>{space.name}</Text>

          <Divider color="gray.3" />

          <Group
            className={classes.item}
            spacing="xs"
            onClick={suppressPropagation(() => {
              handleSetMaintenance();
              contextMenu.hideAll();
            })}
            data-testid="menu-item-set-maintenance-on"
          >
            <Snooze />
            Set maintenance {isUnderMaintenance ? 'OFF' : 'ON'}
          </Group>

          <Group
            className={classes.item}
            spacing="xs"
            onClick={suppressPropagation(() => {
              openClaimDeviceModal({ spaceId: space.id });
              contextMenu.hideAll();
            })}
            data-testid="menu-item-claim-device"
          >
            <MonitorMobbile />
            Claim device
          </Group>

          <Group
            className={classes.item}
            spacing="xs"
            onClick={() => {
              onCreateSpace();
              contextMenu.hideAll();
            }}
            data-testid="add-new-space-button"
          >
            <BoxAdd />
            Add new space
          </Group>

          <Divider color="gray.3" />

          <Group
            className={classes.item}
            spacing="xs"
            onClick={suppressPropagation(() => {
              onEditToggle();
              contextMenu.hideAll();
            })}
            data-testid="menu-item-rename-space"
          >
            <Edit />
            Rename
          </Group>

          <Group
            className={classes.item}
            spacing="xs"
            onClick={suppressPropagation(() => {
              overviewRouting.navigateToSpaceSettings(
                OverviewSettingsTabEnum.Access
              );

              contextMenu.hideAll();
            })}
            data-testid="menu-item-manage-access"
          >
            <Lock />
            Manage Access
          </Group>

          {isAdmin || canAdmin(space) ? (
            <Group
              className={classes.item}
              spacing="xs"
              onClick={suppressPropagation(() => {
                overviewRouting.navigateToSpaceSettings(
                  OverviewSettingsTabEnum.General
                );

                contextMenu.hideAll();
              })}
              data-testid="menu-item-settings"
            >
              <Settings />
              Settings
            </Group>
          ) : null}

          {canRemove ? (
            <>
              <Divider color="gray.3" />

              <Group
                className={classes.item}
                spacing="xs"
                sx={(theme) => ({
                  color: theme.colors.red_accent[4],
                })}
                onClick={suppressPropagation(() => {
                  handleDelete();
                  contextMenu.hideAll();
                })}
                data-testid="menu-item-remove-space"
              >
                <Trash />
                Remove
              </Group>
            </>
          ) : null}
        </Stack>
      </>
    </Menu>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    zIndex: 999,
    padding: '0 4px',
    width: 224,
  },
  title: {
    fontSize: theme.fontSizes.sm,
    color: theme.colors.gray[9],
    fontWeight: 600,
    width: '100%',
    textAlign: 'center',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    padding: `0 ${theme.spacing.md}`,
  },
  item: {
    flexShrink: 0,
    height: 33,
    padding: `0 ${theme.spacing.lg}`,
    fontSize: theme.fontSizes.sm,
    color: theme.colors.gray[9],
    fontWeight: 300,
    transition: 'all 0.15s ease-in-out',
    borderRadius: theme.radius.sm,
    cursor: 'pointer',

    svg: {
      height: 18,
      width: 18,
    },

    ':hover': {
      backgroundColor: theme.colors.gray[1],
    },
  },
}));
