import { noop } from 'lodash/fp';
import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
} from 'react';

import { Dimension } from '@portals/device-widgets';

export interface DeviceOverviewProviderProps {
  children: ReactNode;

  isFullscreen: boolean;
  setIsFullscreen: Dispatch<SetStateAction<boolean>>;
  selectedDeviceId: string | null;
  setSelectedDeviceId: (deviceId: string | null) => void;

  activeLocationWidgetDimension?: Dimension;
  setActiveLocationWidgetDimensionId: (dimensionId: string) => void;
  activeLocationWidgetDimensionId: string;
  locationWidgetSegmentedControlData: { value: string; label: string }[];
}

const DeviceOverviewContext = createContext<
  Omit<DeviceOverviewProviderProps, 'children'>
>({
  isFullscreen: false,
  setIsFullscreen: noop,
  selectedDeviceId: null,
  setSelectedDeviceId: noop,

  activeLocationWidgetDimension: undefined,
  setActiveLocationWidgetDimensionId: noop,
  activeLocationWidgetDimensionId: '',
  locationWidgetSegmentedControlData: [],
});

export function DeviceOverviewProvider({
  children,
  isFullscreen,
  setIsFullscreen,
  selectedDeviceId,
  setSelectedDeviceId,
  activeLocationWidgetDimension,
  setActiveLocationWidgetDimensionId,
  activeLocationWidgetDimensionId,
  locationWidgetSegmentedControlData,
}: DeviceOverviewProviderProps) {
  return (
    <DeviceOverviewContext.Provider
      value={{
        isFullscreen,
        setIsFullscreen,
        selectedDeviceId,
        setSelectedDeviceId,
        activeLocationWidgetDimension,
        setActiveLocationWidgetDimensionId,
        activeLocationWidgetDimensionId,
        locationWidgetSegmentedControlData,
      }}
    >
      {children}
    </DeviceOverviewContext.Provider>
  );
}

export function useDeviceOverviewContext() {
  const context = useContext(DeviceOverviewContext);

  if (!context) {
    throw new Error(
      'useDeviceOverview must be used within a DeviceOverviewProvider'
    );
  }

  return context;
}
