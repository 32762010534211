import { createStyles, Stack } from '@mantine/core';
import React, { useMemo } from 'react';

import {
  DeviceType,
  useDeleteUserFile,
  useWithSupportCenter,
} from '@portals/api/organizations';
import { DetailsPanel, FileTypeIcon } from '@portals/core';
import {
  DetailsList,
  DetailsListProps,
  ModalButton,
  useConfirmationModal,
} from '@portals/framework';
import { ReactComponent as Edit } from '@portals/icons/linear/edit.svg';
import { ReactComponent as Import3 } from '@portals/icons/linear/import-3.svg';
import { ReactComponent as Lifebuoy } from '@portals/icons/linear/lifebuoy.svg';
import { ReactComponent as Trash } from '@portals/icons/linear/trash.svg';
import { OrganizationFileResponseType } from '@portals/types';
import { useDownloadFile } from '@portals/utils';

import { useOpenDocument } from '../../../hooks/upload-file';
import { CreateTicketModalProps, FileInfoUploaderProps } from '../../modals';
import { OwnerBadge } from '../../route-modals/Device/tabs/files/OwnerBadge';

interface FileInfoDetailsPanelProps {
  file: OrganizationFileResponseType;
  onClose: () => void;
  device?: DeviceType;
}

export function FileDetailsPanel({
  file,
  onClose,
  device,
}: FileInfoDetailsPanelProps) {
  const { classes } = useStyles();

  const asyncConfirmation = useConfirmationModal();
  const deleteFile = useDeleteUserFile();
  const downloadFile = useDownloadFile();
  const withSupportCenter = useWithSupportCenter();

  const openDocument = useOpenDocument();

  const onDeleteFile = async () => {
    const isConfirmed = await asyncConfirmation({
      title: 'Delete file?',
      description: `Are you sure you want to delete "${file.name}"`,
    });

    if (isConfirmed) {
      try {
        await deleteFile.mutateAsync(file.id);

        onClose();
      } catch (e) {
        console.error(e);
      }
    }
  };

  const detailsList: DetailsListProps['items'] = [
    {
      label: 'Owner',
      value: <OwnerBadge isPartnerFile={file.partner} />,
    },
    {
      label: 'Type',
      value: file.type,
    },
    {
      label: 'Version',
      value: file.version,
    },
    {
      label: 'ID',
      value: file.id,
    },
  ];

  const editAction = useMemo(() => {
    if (file.partner && device !== undefined) {
      if (!withSupportCenter) return null;

      return (
        <ModalButton
          variant="default"
          modalName="CreateTicketModal"
          label="Request file edit"
          leftIcon={<Lifebuoy width={16} height={16} />}
          data={
            {
              deviceId: device.id,
              description:
                'We would like to edit / remove this file and / or file metadata',
            } satisfies CreateTicketModalProps['data']
          }
        />
      );
    }

    return (
      <ModalButton
        variant="default"
        modalName="FileInfoUploader"
        label="Edit"
        data-testid="file-edit-button"
        leftIcon={<Edit width={16} height={16} />}
        data={
          {
            file,
          } satisfies FileInfoUploaderProps['data']
        }
      />
    );
  }, [device, file, withSupportCenter]);

  return (
    <DetailsPanel>
      <DetailsPanel.Header onClose={onClose} spacing={0}>
        <Stack spacing="md">
          <FileTypeIcon
            fileUrl={file.url}
            height={70}
            className={classes.fileTypeIcon}
          />

          <DetailsPanel.Title ta="center">{file.name}</DetailsPanel.Title>

          <DetailsPanel.Actions>
            {!file.partner && (
              <DetailsPanel.ActionButton
                onClick={onDeleteFile}
                leftIcon={<Trash width={16} height={16} />}
                data-testid="file-delete-button"
              >
                Delete
              </DetailsPanel.ActionButton>
            )}

            <DetailsPanel.ActionButton
              loading={downloadFile.isLoading}
              leftIcon={<Import3 width={16} height={16} />}
              data-testid="file-download-button"
              onClick={() =>
                openDocument({
                  url: file.url,
                  onConfirm: () =>
                    downloadFile.mutate({
                      fileUrl: file.url,
                      fileName: file.name,
                    }),
                })
              }
            >
              Download
            </DetailsPanel.ActionButton>

            {editAction}
          </DetailsPanel.Actions>
        </Stack>
      </DetailsPanel.Header>

      <DetailsPanel.Body>
        <DetailsPanel.Section
          title="Description"
          content={file.desc || '--'}
          data-testid="file-sidebar-description"
        />

        <DetailsPanel.Section
          title="Public notes"
          content={file.public_notes || '--'}
          data-testid="file-sidebar-public-notes"
        />

        <DetailsList title="Information" items={detailsList} />
      </DetailsPanel.Body>
    </DetailsPanel>
  );
}

const useStyles = createStyles(() => ({
  fileTypeIcon: {
    alignSelf: 'center',
  },
}));
