import {
  Box,
  createStyles,
  Drawer,
  Group,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { map } from 'lodash/fp';
import React from 'react';

import { usePortalCapabilities } from '@portals/api/organizations';

import { OVERVIEW_SETTINGS_TABS } from './settings-drawer.constants';
import { useCurrentSettingsTab } from './settings-drawer.hooks';
import {
  useOverviewRouteParams,
  useOverviewRouting,
} from '../overview-routing.hooks';
import { useCurrentSpace } from '../overview.hooks';

export function OverviewSettingsDrawer() {
  const { classes, cx } = useStyles();

  const params = useOverviewRouteParams();
  const overviewRouting = useOverviewRouting();

  const portalCapabilities = usePortalCapabilities();

  const currentSpace = useCurrentSpace();

  const currentTab = useCurrentSettingsTab();

  const onClose = () => {
    overviewRouting.closeSpaceSettingsDrawer();
  };

  const filteredTabs = map((tab) => {
    if (tab.visible && !tab.visible({ portalCapabilities })) return null;

    return (
      <Group
        key={tab.id}
        className={cx(classes.tabItem, { active: currentTab?.id === tab.id })}
        onClick={() => {
          if (!params.spaceId) return;

          overviewRouting.navigateToSpaceSettings(tab.id);
        }}
      >
        {tab.sidebarLabel}
      </Group>
    );
  }, OVERVIEW_SETTINGS_TABS);

  return (
    <Drawer
      position="right"
      opened={!!currentTab}
      withCloseButton={false}
      onClose={onClose}
      size={1154}
      padding={0}
      classNames={{
        body: classes.drawerBody,
      }}
    >
      {!currentTab ? null : (
        <Box className={cx(classes.container, 'settings-drawer-container')}>
          <Stack
            className={cx(classes.sidebar, 'settings-drawer-sidebar')}
            spacing={0}
          >
            <Stack spacing={4}>
              <Text fz="md" c="blue_gray.6">
                {currentSpace?.tree_path_name}
              </Text>

              <Title order={3} fw={500} mb="xl">
                Settings
              </Title>
            </Stack>

            {filteredTabs}
          </Stack>

          <Stack className={cx(classes.content, 'settings-drawer-content')}>
            {currentTab.component}
          </Stack>
        </Box>
      )}
    </Drawer>
  );
}

const useStyles = createStyles((theme) => ({
  drawerBody: {
    height: '100%',
  },
  container: {
    height: '100%',
    display: 'grid',
    gridTemplateColumns: '265px 1fr',
    gap: 0,
  },
  sidebar: {
    height: '100%',
    width: '100%',
    background: theme.white,
    padding: 30,
    borderRight: `1px solid ${theme.colors.gray[2]}`,
  },
  content: {
    height: '100%',
    width: '100%',
    background: theme.colors.gray[0],
    overflow: 'hidden',
  },
  tabItem: {
    height: 41,
    borderRadius: theme.radius.lg,
    padding: `0 ${theme.spacing.md}`,
    marginBottom: theme.spacing.xs,
    cursor: 'pointer',
    color: theme.colors.blue_gray[7],
    fontWeight: 300,

    '&:hover': {
      background: theme.colors.gray[0],
    },

    '&.active': {
      background: theme.colors.blue_accent[0],
      color: theme.other.primaryColor,
    },
  },
}));
