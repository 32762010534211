import { useMemo } from 'react';

import { useUsers } from '@portals/api/ui';

import { NO_ASSIGNEE_OPTION } from './assignee-select.constants';

export function useAssigneeSelect() {
  const users = useUsers();

  const assignableUsers = useMemo(() => {
    return (users.data || []).map(({ id, name, settings }) => ({
      value: id,
      label: name,
      imageUrl: settings.profile_image,
    }));
  }, [users.data]);

  const assigneeOptions = useMemo(() => {
    return [NO_ASSIGNEE_OPTION, ...assignableUsers];
  }, [assignableUsers]);

  return { assignableUsers, assigneeOptions };
}
