import { createStyles, Stack, Tabs, TabsProps, Text } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import React from 'react';

import { ChildrenTabDeviceLocationWidgetForm } from './ChildrenTabDeviceLocationWidgetForm';
import {
  DeviceLocationWidgetFormTabs,
  DeviceLocationWidgetFormType,
} from './device-location-form.types';
import { GeneralTabDeviceLocationWidgetForm } from './GeneralTabDeviceLocationWidgetForm';
import { OnAddCustomColorFn } from '../../widgets.types';

interface DeviceLocationWidgetConfigProps {
  form: UseFormReturnType<DeviceLocationWidgetFormType>;
  colors?: string[];
  onAddCustomColor?: OnAddCustomColorFn;
  shouldDisplayDimensions: boolean;
  onChangeDisplayDimensions: (shouldDisplayDimensions: boolean) => void;
  onAddDimension: () => void;
  onRemoveDimension: (index: number) => void;
  activeTab: DeviceLocationWidgetFormTabs;
  setActiveTab: (tab: DeviceLocationWidgetFormTabs) => void;
}

export function DeviceLocationWidgetConfig({
  form,
  colors,
  onAddCustomColor,
  shouldDisplayDimensions,
  onChangeDisplayDimensions,
  onAddDimension,
  onRemoveDimension,
  activeTab,
  setActiveTab,
}: DeviceLocationWidgetConfigProps) {
  const { classes } = useStyles();

  return (
    <Stack className={classes.config} spacing={0}>
      <Text size={24} color="blue_gray.9" mb="md" px="xxl">
        Add Device Location
      </Text>

      <Tabs
        value={activeTab}
        onTabChange={(value) =>
          setActiveTab(value as DeviceLocationWidgetFormTabs)
        }
        styles={tabsStyles}
      >
        <Tabs.List grow>
          <Tabs.Tab w="100%" value={DeviceLocationWidgetFormTabs.General}>
            {DeviceLocationWidgetFormTabs.General}
          </Tabs.Tab>

          <Tabs.Tab w="100%" value={DeviceLocationWidgetFormTabs.ChildDevices}>
            {DeviceLocationWidgetFormTabs.ChildDevices}
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value={DeviceLocationWidgetFormTabs.General}>
          <GeneralTabDeviceLocationWidgetForm
            onAddCustomColor={onAddCustomColor}
            form={form}
            colors={colors}
          />
        </Tabs.Panel>

        <Tabs.Panel value={DeviceLocationWidgetFormTabs.ChildDevices}>
          <ChildrenTabDeviceLocationWidgetForm
            form={form}
            shouldDisplayDimensions={shouldDisplayDimensions}
            onAddDimension={onAddDimension}
            onRemoveDimension={onRemoveDimension}
            onAddCustomColor={onAddCustomColor}
            colors={colors}
            onChangeShouldDisplayDimensions={onChangeDisplayDimensions}
          />
        </Tabs.Panel>
      </Tabs>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  config: {
    gridArea: 'config',
    overflow: 'hidden',
    justifyContent: 'stretch',
    display: 'grid',
    gridTemplateRows: 'min-content 1fr',
    paddingInline: 0,
    paddingTop: theme.spacing.xxl,
  },
}));

const tabsStyles: TabsProps['styles'] = (theme) => ({
  root: {
    height: '100%',
    display: 'grid',
    gridTemplateRows: 'min-content 1fr',
    overflow: 'hidden',
  },
  panel: {
    height: '100%',
    overflow: 'auto',
    padding: theme.spacing.xxl,
  },
});
