import {
  Button,
  Group,
  Modal,
  Stack,
  Textarea,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import React from 'react';

import { ModalProps } from '@portals/framework';
import { toastrError } from '@portals/redux/actions/toastr';

interface FormValues {
  title: string;
  message: string;
}

export interface ContactSupportModalProps
  extends ModalProps<{
    onSubmit: (values: FormValues & { partnerId?: string }) => void;
    title?: string;
    message?: string;
    partnerId?: string;
  }> {}

export function ContactSupportModal({
  closeMe,
  data,
}: ContactSupportModalProps) {
  const form = useForm<FormValues>({
    initialValues: {
      title: data?.title || '',
      message: data?.message || '',
    },
  });

  const onSubmit = (values: typeof form.values) => {
    if (!data?.onSubmit) {
      toastrError('Error submitting support request');
      return;
    }

    data?.onSubmit({ ...values, partnerId: data.partnerId });

    closeMe();
  };

  return (
    <Modal
      title="Contact Support"
      withCloseButton
      opened
      onClose={closeMe}
      padding="xxl"
      closeButtonProps={{
        size: 'md',
      }}
    >
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Stack spacing="xl">
          <TextInput
            {...form.getInputProps('title')}
            label="Subject"
            required
            data-testid="title-input"
          />

          <Textarea
            {...form.getInputProps('message')}
            label="Message"
            w="100%"
            required
            minRows={5}
            placeholder="Enter your question / issue details here"
            data-testid="description-input"
          />

          <Group position="right" w="100%">
            <Button type="submit" data-testid="submit-button">
              Submit
            </Button>
          </Group>
        </Stack>
      </form>
    </Modal>
  );
}
