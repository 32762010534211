import { devicesQueryKeys } from '../devices';
import { globalQueryKeys } from '../global-query-keys';

export const DEVICE_MODELS_API_URL = 'ui/organization/device_models';

export function getDeviceModelApiUrl(deviceModelId: string) {
  return `${DEVICE_MODELS_API_URL}/${deviceModelId}`;
}

export const deviceModelsQueryKeys = {
  global: [...globalQueryKeys.deviceModels],
  base: () => [...deviceModelsQueryKeys.global, DEVICE_MODELS_API_URL],
  detail: (deviceModelId: string) => [
    ...deviceModelsQueryKeys.base(),
    deviceModelId,
  ],
  sections: {
    all: (deviceModelId: string) => [
      ...deviceModelsQueryKeys.detail(deviceModelId),
      'sections',
    ],
  },
  widgets: {
    all: (deviceModelId: string) => [
      ...deviceModelsQueryKeys.detail(deviceModelId),
      'widgets',
    ],
    new: (deviceModelId: string) => [
      ...deviceModelsQueryKeys.widgets.all(deviceModelId),
      'new',
    ],
  },
  files: {
    global: [...globalQueryKeys.files],
    all: (deviceModelId: string) => [
      ...deviceModelsQueryKeys.files.global,
      ...deviceModelsQueryKeys.detail(deviceModelId),
    ],
    byFileType: (deviceModelId: string, fileType: string) => [
      ...deviceModelsQueryKeys.files.all(deviceModelId),
      fileType,
    ],
  },
  licenses: {
    global: [...devicesQueryKeys.licenses.global],
    unassigned: (deviceModelId: string) => [
      ...deviceModelsQueryKeys.licenses.global,
      ...deviceModelsQueryKeys.detail(deviceModelId),
      'unassigned',
    ],
  },
  partnerWithDeviceModels: (partnerId: string) => [
    ...deviceModelsQueryKeys.base(),
    'partnerWithDeviceModels',
    partnerId,
  ],
  searchDeviceModelsAndPartners: (searchTerm: string) => [
    ...deviceModelsQueryKeys.base(),
    'searchDeviceModelsAndPartners',
    searchTerm,
  ],
};
