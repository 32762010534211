import { useForm, UseFormReturnType } from '@mantine/form';
import { useSessionStorage } from '@mantine/hooks';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useEffectOnce } from 'react-use';

import {
  InfiniteVirtualizedViewDisplayType,
  useInfiniteVirtualizedViewDisplayType,
} from '@lib/infinite-virtualized-view';
import {
  DeviceType,
  useDevicesInfiniteQuery,
  useSpaceDetails,
} from '@portals/api/organizations';
import { DebouncedSearchInputHandle } from '@portals/core';
import { getDeviceStatusIds } from '@portals/framework/route-modals';
import {
  DeviceStatusType,
  PaginatedFilterTypeEnum,
  PaginatedQueryParamsType,
} from '@portals/types';

import { useOverviewRouteParams } from '../overview-routing.hooks';
import { useOverviewContext } from '../overview.context';

interface FormValues {
  searchTerm: string;
  statuses: Record<DeviceStatusType, boolean>;
  unknownStatus: boolean;
  sortByField: 'name' | 'status' | 'device_model_name';
  sortByDirection: 'asc' | 'desc';
}

interface PersistedFilters {
  formValues: FormValues;
  displayType: InfiniteVirtualizedViewDisplayType;
}

interface OverviewDevicesContextType {
  form: UseFormReturnType<FormValues>;
  displayType: InfiniteVirtualizedViewDisplayType;
  setDisplayType: (displayType: InfiniteVirtualizedViewDisplayType) => void;

  clearFilters: VoidFunction;
  searchInputRef: React.Ref<DebouncedSearchInputHandle>;

  space: ReturnType<typeof useSpaceDetails>;

  isFetchingNextPage: boolean;
  hasNextPage: ReturnType<typeof useDevicesInfiniteQuery>['hasNextPage'];
  fetchNextPage: ReturnType<typeof useDevicesInfiniteQuery>['fetchNextPage'];
  paginatedDevices: ReturnType<typeof useDevicesInfiniteQuery>['data'];
  allDevices: Array<DeviceType>;
  totalDevicesCount: number;
  isLoading: boolean;

  selectedDeviceIds: Set<string>;
  toggleSelectedDeviceId: (deviceId: string) => void;
  clearSelectedDeviceIds: VoidFunction;
}

const OverviewDevicesContext = createContext<OverviewDevicesContextType | null>(
  null
);

const FORM_INITIAL_VALUES: FormValues = {
  searchTerm: '',
  statuses: getDefaultFormStatuses(),
  unknownStatus: true,
  sortByDirection: 'asc',
  sortByField: 'name',
};

interface OverviewDevicesProviderProps {
  children: ReactNode;
}

export function OverviewDevicesProvider({
  children,
}: OverviewDevicesProviderProps) {
  const searchInputRef = useRef<DebouncedSearchInputHandle>(null);

  const [persistedFilters, setPersistedFilters] =
    useSessionStorage<PersistedFilters>({
      key: 'overview_devices_filters_v2',
      defaultValue: { formValues: FORM_INITIAL_VALUES, displayType: 'list' },
      getInitialValueInEffect: false,
    });

  const [displayType, setDisplayType] = useInfiniteVirtualizedViewDisplayType(
    persistedFilters.displayType
  );

  const [selectedDeviceIds, setSelectedDeviceIds] = useState(new Set<string>());

  const overview = useOverviewContext();
  const params = useOverviewRouteParams();
  const space = useSpaceDetails(Number(params.spaceId));

  const form = useForm<FormValues>({
    initialValues: persistedFilters.formValues,
  });

  useEffectOnce(function resetFormInitialDirtyValues() {
    form.resetDirty(FORM_INITIAL_VALUES);
  });

  useEffect(
    function syncPersistedFilters() {
      setPersistedFilters({ formValues: form.values, displayType });
    },
    [displayType, form.values, setPersistedFilters]
  );

  const composeFilters = useCallback(() => {
    if (!space.data) return;

    const filters: PaginatedQueryParamsType<DeviceType>['filters'] = [];

    const activeStatuses = getActiveStatuses(form.values.statuses);

    filters.push({
      id: 'status',
      value: activeStatuses,
      type: PaginatedFilterTypeEnum.Select,
      groupKey: 'status',
    });

    filters.push({
      id: 'status',
      value: form.values.unknownStatus ? 1 : null,
      type: PaginatedFilterTypeEnum.Null,
      groupKey: 'status',
    });

    if (form.values.searchTerm) {
      filters.push({
        id: 'name_or_device_model_name',
        value: form.values.searchTerm,
        type: PaginatedFilterTypeEnum.Text,
      });
    }

    if (overview.isLocalDataLevel) {
      filters.push({
        id: 'space_id',
        type: PaginatedFilterTypeEnum.Eq,
        value: space.data.id,
      });
    } else {
      filters.push({
        id: 'space_path',
        value: `"${encodeURIComponent(space.data.path.join('.'))}"`,
        type: PaginatedFilterTypeEnum.LtreeStartsWith,
      });
    }

    return filters;
  }, [
    form.values.searchTerm,
    form.values.statuses,
    form.values.unknownStatus,
    overview.isLocalDataLevel,
    space.data,
  ]);

  const composeSorting = useCallback(() => {
    return [
      {
        id: form.values.sortByField,
        desc: form.values.sortByDirection === 'desc',
      },
    ] satisfies PaginatedQueryParamsType<DeviceType>['sorting'];
  }, [form.values.sortByDirection, form.values.sortByField]);

  const toggleSelectedDeviceId = useCallback((deviceId: string) => {
    setSelectedDeviceIds((prevSelectedDeviceIds) => {
      const newSelectedDeviceIds = new Set(prevSelectedDeviceIds);

      if (newSelectedDeviceIds.has(deviceId)) {
        newSelectedDeviceIds.delete(deviceId);
      } else {
        newSelectedDeviceIds.add(deviceId);
      }

      return newSelectedDeviceIds;
    });
  }, []);

  const clearSelectedDeviceIds = useCallback(() => {
    setSelectedDeviceIds(new Set());
  }, []);

  const clearFilters = useCallback(() => {
    form.setValues(FORM_INITIAL_VALUES);
    searchInputRef.current?.reset();
  }, [form]);

  useEffect(
    function resetSelectedDeviceIds() {
      // Whenever the user changes the filters/sort/search, we reset the selected device ids
      clearSelectedDeviceIds();
    },
    [clearSelectedDeviceIds, form.values]
  );

  const {
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    data: paginatedDevices,
    isInitialLoading,
  } = useDevicesInfiniteQuery(
    {
      pagination: { page: 0, pageSize: 10 },
      sorting: composeSorting(),
      filters: composeFilters(),
    },
    { enabled: space.data !== undefined }
  );

  const allDevices = useMemo(() => {
    if (!paginatedDevices) return [];

    return paginatedDevices.pages.flatMap((devicesPage) => devicesPage.data);
  }, [paginatedDevices]);

  const totalDevicesCount =
    paginatedDevices?.pages?.[0]?.page_info?.total_count || 0;

  const isLoading = isInitialLoading || space.isInitialLoading;

  return (
    <OverviewDevicesContext.Provider
      value={{
        form,
        displayType,
        setDisplayType,

        clearFilters,
        searchInputRef,

        space,

        paginatedDevices,
        allDevices,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        isLoading,
        totalDevicesCount,

        selectedDeviceIds,
        toggleSelectedDeviceId,
        clearSelectedDeviceIds,
      }}
    >
      {children}
    </OverviewDevicesContext.Provider>
  );
}

function getDefaultFormStatuses() {
  const statusMap = {} as Record<DeviceStatusType, boolean>;

  getDeviceStatusIds().forEach(
    (deviceStatusName) => (statusMap[deviceStatusName] = true)
  );

  return statusMap;
}
function getActiveStatuses(statuses: FormValues['statuses']) {
  return Object.entries(statuses)
    .filter(([_deviceStatusName, isActive]) => isActive)
    .map(([deviceStatusName]) => deviceStatusName);
}

export function useOverviewDevicesContext() {
  const context = useContext(OverviewDevicesContext);

  if (context === null) {
    throw new Error(
      'useOverviewDevicesContext must be used within OverviewDevicesProvider'
    );
  }

  return context;
}
