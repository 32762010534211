import { createStyles } from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import { chunk } from 'lodash';
import React, { useMemo } from 'react';

import { ScrollToTopButton } from '@portals/core';

import { GridRowSkeletonFiller, GridSkeleton } from './GridSkeleton';
import { useVirtualizerStyles } from './infinite-virtualized-view.styles';
import { InfiniteVirtualizedViewProps } from './infinite-virtualized-view.types';
import { useRowVirtualizer } from './use-row-virtualizer';

interface GridViewProps<TItem = unknown>
  extends Pick<
    InfiniteVirtualizedViewProps<TItem>,
    | 'flatItems'
    | 'isLoading'
    | 'hasNextPage'
    | 'fetchNextPage'
    | 'isFetchingNextPage'
    | 'scrollableRef'
    | 'getItemId'
  > {
  itemRenderer: InfiniteVirtualizedViewProps<TItem>['gridItemRenderer'];
  itemSkeleton: InfiniteVirtualizedViewProps<TItem>['gridItemSkeleton'];

  gap: number;
  overscan: number;
  itemDimensions: number;
}

export function GridView<TItem = unknown>({
  flatItems,
  itemRenderer,
  isFetchingNextPage,
  hasNextPage,
  fetchNextPage,
  scrollableRef,
  itemDimensions,
  gap,
  overscan,
  isLoading,
  itemSkeleton,
  getItemId,
}: GridViewProps<TItem>) {
  const { classes } = useStyles(itemDimensions);
  const virtualizerStyles = useVirtualizerStyles();

  const { ref, width } = useElementSize<HTMLDivElement>();

  const numOfItemsPerRow = useMemo(() => {
    // The number if items that can fit without taking the gap into account
    const numOfItemsWithoutGap = Math.floor(width / itemDimensions);

    const totalGap = (numOfItemsWithoutGap - 1) * gap;

    // The number if items that can fit including gaps
    return Math.floor((width - totalGap) / itemDimensions);
  }, [gap, itemDimensions, width]);

  const gridRows = useMemo(() => {
    return chunk(flatItems, numOfItemsPerRow);
  }, [flatItems, numOfItemsPerRow]);

  const { rowVirtualizer, showScrollToTopButton } = useRowVirtualizer<TItem>({
    gap,
    overscan,
    scrollableRef,
    estimateSize: () => itemDimensions,

    flatItems: gridRows,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  });

  return (
    <>
      {isLoading && (
        <GridSkeleton
          cardWidth={itemDimensions}
          numOfItemsPerRow={numOfItemsPerRow}
          rowGap={rowVirtualizer.options.gap}
          rowCount={rowVirtualizer.options.overscan}
          itemSkeleton={itemSkeleton}
        />
      )}

      <div
        ref={ref}
        className={virtualizerStyles.classes.virtualizerContainer}
        style={{ height: rowVirtualizer.getTotalSize() }}
      >
        {rowVirtualizer.getVirtualItems().map((virtualRow) => {
          const isLoaderRow = virtualRow.index > gridRows.length - 1;
          const items = gridRows[virtualRow.index];

          const shouldFillRowWithSkeletonCards =
            isFetchingNextPage && items?.length < numOfItemsPerRow;

          return (
            <div
              key={virtualRow.index}
              className={virtualizerStyles.classes.virtualRow}
              style={{
                height: virtualRow.size,
                transform: `translateY(${virtualRow.start}px)`,
              }}
            >
              {isLoaderRow && hasNextPage ? (
                <GridSkeleton
                  cardWidth={itemDimensions}
                  numOfItemsPerRow={numOfItemsPerRow}
                  rowGap={rowVirtualizer.options.gap}
                  rowCount={rowVirtualizer.options.overscan}
                  itemSkeleton={itemSkeleton}
                />
              ) : (
                <div className={classes.gridRow}>
                  {items.map((item) => (
                    <React.Fragment key={getItemId(item)}>
                      {itemRenderer(item)}
                    </React.Fragment>
                  ))}

                  {shouldFillRowWithSkeletonCards && (
                    <GridRowSkeletonFiller
                      numOfItems={numOfItemsPerRow - items.length}
                      itemSkeleton={itemSkeleton}
                    />
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>

      <ScrollToTopButton
        visible={showScrollToTopButton}
        scrollToTop={() =>
          rowVirtualizer.scrollToIndex(0, { behavior: 'smooth' })
        }
      />
    </>
  );
}

const useStyles = createStyles((theme, gridItemDimensions: number) => ({
  gridRow: {
    display: 'grid',
    gridTemplateColumns: `repeat(auto-fill, minmax(${gridItemDimensions}px, 1fr))`,
    gridTemplateRows: gridItemDimensions,
    gap: theme.spacing.md,
  },
}));
