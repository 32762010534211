import { Box, createStyles, Text, Tooltip } from '@mantine/core';
import React from 'react';
import { Link } from 'react-router-dom';

import { SpaceType } from '@portals/api/organizations';

interface TruncatedSpaceLinkProps {
  spacePath: SpaceType['tree_path_name'];
  spaceId: SpaceType['id'];
  maxNumOfNames?: number;
}

export function TruncatedSpaceLink({
  spacePath,
  spaceId,
  maxNumOfNames = 2,
}: TruncatedSpaceLinkProps) {
  const { classes } = useStyles();
  const names = getSpaceNames({
    spacePath,
    includeCustomerSpaceInPath: true,
  });

  function getName() {
    if (maxNumOfNames === 1) return names.at(-1);

    if (names.length <= maxNumOfNames) {
      return names.join(' / ');
    }

    return `${names.at(0)} / ... / ${names.at(-1)}`;
  }

  return (
    <Tooltip label={names.join(' / ')}>
      <Box className={classes.container}>
        <Link to={`/overview/${spaceId}`}>
          <Text size="sm" color="blue_gray.7" truncate>
            {getName()}
          </Text>
        </Link>
      </Box>
    </Tooltip>
  );
}

function getSpaceNames({
  spacePath,
  includeCustomerSpaceInPath,
}: {
  spacePath: string;
  includeCustomerSpaceInPath?: boolean;
}) {
  const spaceNamesArray = spacePath.split('/');

  if (includeCustomerSpaceInPath) {
    return spaceNamesArray.slice(1);
  }

  const slicedPath = spaceNamesArray.slice(2);

  if (slicedPath.length === 0) {
    return spaceNamesArray.slice(1);
  }

  return slicedPath;
}

const useStyles = createStyles((theme) => ({
  container: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));
