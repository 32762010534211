import {
  Button,
  Center,
  CloseButton,
  createStyles,
  Divider,
  Group,
  Input,
  Paper,
  Stack,
  Switch,
  SwitchProps,
  Text,
  TextInput,
  Tooltip,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import React from 'react';

import { ReactComponent as Add } from '@portals/icons/linear/add.svg';
import { ReactComponent as QuestionCircle } from '@portals/icons/linear/question-circle.svg';

import {
  MAX_COLORING_RULES_PER_DIMENSION,
  MAX_DIMENSIONS,
} from './device-location-form.consts';
import {
  ColoringRule,
  DeviceLocationWidgetFormType,
} from './device-location-form.types';
import { PopoverColorSelector } from '../../common/PopoverColorSelector';
import { OnAddCustomColorFn, WidgetColorType } from '../../widgets.types';
import { getNextColorForListItem } from '../../widgets.utils';

interface ChildrenTabDeviceLocationWidgetFormProps {
  form: UseFormReturnType<DeviceLocationWidgetFormType>;
  onChangeShouldDisplayDimensions: (isChecked: boolean) => void;
  shouldDisplayDimensions: boolean;
  onAddDimension: () => void;
  onRemoveDimension: (index: number) => void;
  colors?: WidgetColorType[];
  onAddCustomColor?: OnAddCustomColorFn;
}

export function ChildrenTabDeviceLocationWidgetForm({
  form,
  onChangeShouldDisplayDimensions,
  shouldDisplayDimensions,
  onAddDimension,
  onRemoveDimension,
  colors,
  onAddCustomColor,
}: ChildrenTabDeviceLocationWidgetFormProps) {
  const { classes, theme } = useStyles();

  const shouldDisableAddDimension =
    form.values.dimensions && form.values.dimensions.length === MAX_DIMENSIONS;

  const handleAddDimensionColoringRule = (dimensionIndex: number) => {
    if (!form.values.dimensions) return;

    form.insertListItem(`dimensions.${dimensionIndex}.coloringRules`, {
      id: crypto.randomUUID(),
      value: '',
      color: getNextColorForListItem({
        colors,
        listLength: form.values.dimensions[dimensionIndex].coloringRules.length,
      }),
    });
  };

  const handleRemoveDimensionColoringRule = (
    dimensionIndex: number,
    coloringRuleIndex: number
  ) => {
    if (!form.values.dimensions) return;

    form.removeListItem(
      `dimensions.${dimensionIndex}.coloringRules`,
      coloringRuleIndex
    );
  };

  return (
    <Stack spacing="xl">
      <Switch
        labelPosition="left"
        label="Use custom colors"
        checked={shouldDisplayDimensions}
        onChange={(event) =>
          onChangeShouldDisplayDimensions(event.target.checked)
        }
        styles={switchStyles}
      />

      <Divider />

      {shouldDisplayDimensions && (
        <>
          <Group position="apart" align="flex-start">
            <Group spacing="xs">
              <Text size="md" weight={500}>
                Dimensions
              </Text>

              <Tooltip
                withArrow
                label="Set custom colors for child devices according to telemetry values"
              >
                <QuestionCircle width={16} color={theme.colors.gray[5]} />
              </Tooltip>
            </Group>
          </Group>

          {form.values.dimensions?.map((dimension, dimensionIndex) => {
            const shouldDisableAddValue =
              dimension.coloringRules.length ===
              MAX_COLORING_RULES_PER_DIMENSION;

            return (
              <Paper
                key={dimension.id}
                p="md"
                radius="lg"
                withBorder
                pos="relative"
              >
                <CloseButton
                  size="md"
                  variant="default"
                  onClick={() => onRemoveDimension(dimensionIndex)}
                  className={classes.dimensionCloseButton}
                />

                <Stack>
                  <TextInput
                    required
                    label="Name"
                    placeholder="Rule name"
                    {...form.getInputProps(`dimensions.${dimensionIndex}.name`)}
                  />

                  <TextInput
                    required
                    label="Telemetry key"
                    placeholder="Telemetry to address"
                    {...form.getInputProps(
                      `dimensions.${dimensionIndex}.telemetry_key`
                    )}
                  />

                  <Stack spacing="xs">
                    {dimension.coloringRules.map(
                      (coloringRule, coloringRuleIndex) => (
                        <ColoringRuleInput
                          key={coloringRule.id}
                          form={form}
                          coloringRule={coloringRule}
                          colors={colors}
                          onAddCustomColor={onAddCustomColor}
                          dimensionIndex={dimensionIndex}
                          coloringRuleIndex={coloringRuleIndex}
                          shouldDisplayCloseButton={
                            dimension.coloringRules.length > 1
                          }
                          handleRemoveDimensionColoringRule={
                            handleRemoveDimensionColoringRule
                          }
                        />
                      )
                    )}

                    <Tooltip
                      label={`You can add up to ${MAX_COLORING_RULES_PER_DIMENSION} coloring rules`}
                      disabled={!shouldDisableAddValue}
                    >
                      <div>
                        <Button
                          variant="white"
                          leftIcon={<Add width={16} />}
                          fullWidth
                          onClick={() =>
                            handleAddDimensionColoringRule(dimensionIndex)
                          }
                          disabled={shouldDisableAddValue}
                        >
                          Add value
                        </Button>
                      </div>
                    </Tooltip>
                  </Stack>
                </Stack>
              </Paper>
            );
          })}
          <Tooltip
            label={`You can add up to ${MAX_DIMENSIONS} dimensions`}
            disabled={!shouldDisableAddDimension}
          >
            <div>
              <Button
                variant="default"
                fullWidth
                onClick={onAddDimension}
                disabled={shouldDisableAddDimension}
              >
                Add new dimension
              </Button>
            </div>
          </Tooltip>
        </>
      )}
    </Stack>
  );
}

interface ColoringRuleInputProps {
  form: UseFormReturnType<DeviceLocationWidgetFormType>;
  coloringRule: ColoringRule;
  colors?: WidgetColorType[];
  onAddCustomColor?: OnAddCustomColorFn;
  dimensionIndex: number;
  coloringRuleIndex: number;
  shouldDisplayCloseButton: boolean;
  handleRemoveDimensionColoringRule: (
    dimensionIndex: number,
    coloringRuleIndex: number
  ) => void;
}

function ColoringRuleInput({
  form,
  coloringRule,
  colors,
  onAddCustomColor,
  dimensionIndex,
  coloringRuleIndex,
  shouldDisplayCloseButton,
  handleRemoveDimensionColoringRule,
}: ColoringRuleInputProps) {
  return (
    <Stack key={coloringRule.id} spacing="xs" mb="sm">
      <Group noWrap align="flex-end">
        <Center mb="xs">
          <PopoverColorSelector
            selectedColor={coloringRule.color}
            colors={colors}
            onAddCustomColor={onAddCustomColor}
            {...form.getInputProps(
              `dimensions.${dimensionIndex}.coloringRules.${coloringRuleIndex}.color`
            )}
          />
        </Center>

        <Group align="end" w="100%" noWrap>
          <Stack spacing="xs" w="100%">
            <Input.Wrapper>
              <Input.Label m={0} required>
                Value
              </Input.Label>
              <Input
                placeholder="Telemetry value"
                w="100%"
                {...form.getInputProps(
                  `dimensions.${dimensionIndex}.coloringRules.${coloringRuleIndex}.value`
                )}
              />
            </Input.Wrapper>
          </Stack>

          {shouldDisplayCloseButton && (
            <Center h={36}>
              <CloseButton
                onClick={() =>
                  handleRemoveDimensionColoringRule(
                    dimensionIndex,
                    coloringRuleIndex
                  )
                }
              />
            </Center>
          )}
        </Group>
      </Group>

      <Input.Error>
        {
          form.getInputProps(
            `dimensions.${dimensionIndex}.coloringRules.${coloringRuleIndex}.value`
          ).error
        }
      </Input.Error>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  dimensionCloseButton: {
    borderRadius: theme.radius.xl,
    position: 'absolute',
    top: 0,
    right: 0,
    transform: 'translate(50%, -50%)',

    '&:active': {
      transform: 'translate(50%, -50%)',
    },
  },
}));

const switchStyles: SwitchProps['styles'] = {
  body: {
    justifyContent: 'space-between',
  },
};
