import { Button, useMantineTheme } from '@mantine/core';
import React from 'react';

import { ReactComponent as Plus } from '@portals/icons/linear/add.svg';
import { useOpenModal } from '@portals/redux';

import { CreateCustomerSpaceModalProps } from '../../../../../modals';

export function CreateCustomerSpaceButton() {
  const theme = useMantineTheme();

  const openModal = useOpenModal();

  return (
    <Button
      fullWidth
      size="md"
      variant="default"
      onClick={() =>
        openModal<CreateCustomerSpaceModalProps['data']>(
          'CreateCustomerSpaceModal',
          {
            type: 'add',
          }
        )
      }
      leftIcon={<Plus height={18} width={18} color={theme.colors.gray[7]} />}
      data-testid="add-customer-button"
    >
      Create Customer
    </Button>
  );
}
