import {
  createStyles,
  SegmentedControl,
  SegmentedControlProps,
} from '@mantine/core';
import GoogleMapReact from 'google-map-react';
import React from 'react';

import { useDevice } from '@portals/api/organizations';
import {
  DeviceLocationWidgetFormType,
  MobileDeviceLocationWidget,
  NewWidgetType,
} from '@portals/device-widgets';
import { getDeviceStatusMetadata } from '@portals/framework/route-modals';
import { DeviceStatusType } from '@portals/types';

import { ChildDeviceMarker } from './map-markers/ChildDeviceMapMarker';
import { DeviceMarker } from './map-markers/DeviceMapMarker';
import { useDeviceLocationWidget } from '../../../../../../../../hooks/device-location-widget';
import {
  childrenToPoints,
  getChildMarkerColor,
} from '../../../../../../../../utils/device-location-widgets-wrapper.utils';
import { useDeviceOverviewContext } from '../DeviceOverviewContext';

interface DeviceLocationWidgetWrapperProps {
  widget: NewWidgetType<DeviceLocationWidgetFormType>;
  deviceId: string;

  onMapClick?: React.ComponentProps<typeof GoogleMapReact>['onClick'];
  mapActionsButtons?: React.ReactNode;
}

export function DeviceLocationWidgetWrapper({
  widget,
  deviceId,
  onMapClick,
  mapActionsButtons,
}: DeviceLocationWidgetWrapperProps) {
  const { cx, classes } = useStyles();

  const {
    selectedDeviceId,
    isFullscreen,
    setIsFullscreen,
    locationWidgetSegmentedControlData,
    activeLocationWidgetDimensionId,
    setActiveLocationWidgetDimensionId,
    activeLocationWidgetDimension,
  } = useDeviceOverviewContext();

  const widgetFields = widget?.config?.fields;
  const { color } = widgetFields ?? {};
  const device = useDevice(deviceId);

  const shouldDisplaySegmentedControls =
    locationWidgetSegmentedControlData.length > 1;

  const { devicesMapConfig } = useDeviceLocationWidget({
    deviceId,
    renderMainMarker: ({ coordinates, deviceId, status }) => (
      <DeviceMarker
        deviceId={deviceId}
        key={deviceId}
        color={color}
        status={status as DeviceStatusType}
        lat={coordinates.lat}
        lng={coordinates.lng}
      />
    ),
    renderChildMarker: ({ coordinates, deviceId, status }) => {
      const childDeviceData = device.data?.child_devices?.find(
        (device) => device.id === deviceId
      );

      const markerColor = getChildMarkerColor({
        childDeviceData,
        activeDimension: activeLocationWidgetDimension,
      });

      const shouldRenderWarningIcon =
        !!activeLocationWidgetDimension && !markerColor;

      return (
        <ChildDeviceMarker
          key={deviceId}
          deviceId={deviceId}
          color={markerColor || getDeviceStatusMetadata(status).indicator}
          shouldRenderWarningIcon={shouldRenderWarningIcon}
          lat={coordinates.lat}
          lng={coordinates.lng}
        />
      );
    },
  });

  const points = React.useMemo(
    () => childrenToPoints(devicesMapConfig),
    [devicesMapConfig]
  );

  return (
    <div
      className={cx(classes.container, {
        [classes.containerWithSegmentedControl]: shouldDisplaySegmentedControls,
        [classes.containerFullScreen]: isFullscreen,
      })}
    >
      {shouldDisplaySegmentedControls && (
        <SegmentedControl
          data={locationWidgetSegmentedControlData}
          value={activeLocationWidgetDimensionId}
          onChange={setActiveLocationWidgetDimensionId}
          styles={segmentedControlStyles}
        />
      )}

      <MobileDeviceLocationWidget
        mapWidgetId={widget.id}
        selectedChildDeviceId={selectedDeviceId}
        points={points}
        onMapClick={onMapClick}
        mapActionsButtons={mapActionsButtons}
        isFullscreen={isFullscreen}
        setIsFullscreen={setIsFullscreen}
        mapOptions={{
          disableDefaultUI: true,
          keyboardShortcuts: false,
          gestureHandling: 'greedy',
        }}
      />
    </div>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateRows: '1fr',
    gap: theme.spacing.md,
    height: '100%',
  },
  containerWithSegmentedControl: {
    gridTemplateRows: 'max-content 1fr',
  },
  containerFullScreen: {
    position: 'absolute',
    inset: 0,
    width: '100%',
    height: '100%',
    zIndex: 1,
    gap: 0,
  },
}));

const segmentedControlStyles: SegmentedControlProps['styles'] = {
  root: {
    minHeight: '2rem',
  },
  control: {
    overflow: 'hidden',
  },
};
