import { createStyles, Group, Skeleton, Stack } from '@mantine/core';
import React from 'react';

export function DeviceTileSkeleton({ cecSkeleton }: { cecSkeleton: boolean }) {
  const { classes } = useStyles();

  return (
    <Group position="apart" className={classes.container}>
      <Group spacing="xl">
        <Group>
          <div className={classes.mockCheckbox} />

          {cecSkeleton && <Skeleton radius="md" width={48} height={18} />}
          <Skeleton radius="md" width={48} height={48} />
        </Group>

        <Stack spacing={4}>
          <Skeleton radius="md" width={160} height={12} />
          <Skeleton radius="md" width={85} height={12} />
        </Stack>
      </Group>

      <Group>
        <Skeleton radius="md" width={125} height={12} />
        <Skeleton radius="xl" width={75} height={28} />
      </Group>
    </Group>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    paddingBlock: theme.spacing.xs,
    paddingInline: theme.spacing.xl,
    borderRadius: theme.radius.md,
    backgroundColor: theme.white,
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
  },
  mockCheckbox: {
    width: 20,
    height: 20,
    border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.sm,
  },
}));
