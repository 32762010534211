import {
  Box,
  Button,
  ButtonProps,
  createStyles,
  HoverCard,
  HoverCardProps,
  Paper,
  Stack,
  Text,
} from '@mantine/core';
import { useFullscreen } from '@mantine/hooks';
import React from 'react';

import { RelatedDevice } from '@portals/api/organizations';
import { DeviceAvatarWithIndicator } from '@portals/framework';
import { useOpenRouteModal } from '@portals/framework/route-modals';
import { ReactComponent as InfoCircleIcon } from '@portals/icons/bold/info-circle.svg';
import { DeviceStatusType } from '@portals/types';

export interface ChildDeviceLocationMarkerProps {
  title: string;
  status: DeviceStatusType | undefined;
  shouldRenderWarningIcon: boolean;
  color?: string;
  additionalDescriptionForHoverCard?: string;

  lat?: number;
  lng?: number;

  device: RelatedDevice;
}

export function ChildDeviceLocationMarker({
  title,
  status,
  shouldRenderWarningIcon,
  color,
  additionalDescriptionForHoverCard,
  device,
}: ChildDeviceLocationMarkerProps) {
  const { classes, theme } = useStyles();

  const openRouteModal = useOpenRouteModal();

  const { fullscreen } = useFullscreen();

  return (
    <HoverCard
      styles={hoverCardStyles}
      withArrow
      arrowSize={20}
      arrowPosition="center"
      position="top"
      withinPortal={!fullscreen}
    >
      <HoverCard.Target>
        <Box
          className={classes.childWrapper}
          data-testid={`child-device-marker-${device.device_model_name}`}
        >
          {shouldRenderWarningIcon ? (
            <InfoCircleIcon
              height={18}
              width={18}
              color={theme.colors.gray['5']}
            />
          ) : (
            <Box className={classes.child} bg={color} />
          )}
        </Box>
      </HoverCard.Target>

      <HoverCard.Dropdown>
        <Paper>
          <Stack
            mih={130}
            miw={130}
            align="center"
            justify="center"
            p="xl"
            spacing="xl"
          >
            <DeviceAvatarWithIndicator
              deviceName={title}
              deviceStatus={status}
              size="lg"
              icon={device.model_settings.icon}
              src={device.image_url}
            />

            <Stack align="center">
              <Text
                size="xs"
                color="blue_gray.9"
                weight="bold"
                data-testid="child-device-title"
              >
                {device.name}
              </Text>

              <Text size="xs" color="gray.5" data-testid="child-device-model">
                {device.device_model_name}
              </Text>

              {additionalDescriptionForHoverCard && (
                <Text
                  size="xs"
                  color="gray.5"
                  data-testid="child-device-additional-description"
                >
                  {additionalDescriptionForHoverCard}
                </Text>
              )}
            </Stack>

            <Button
              size="sm"
              fullWidth
              variant="default"
              styles={buttonStyles}
              onClick={() =>
                openRouteModal({
                  modalId: 'device',
                  pathParams: [device.id],
                })
              }
            >
              Show device
            </Button>
          </Stack>
        </Paper>
      </HoverCard.Dropdown>
    </HoverCard>
  );
}

const useStyles = createStyles((theme) => ({
  childWrapper: {
    cursor: 'default',
    width: 18,
    height: 18,
    backgroundColor: 'white',
    borderRadius: '50%',
    boxShadow: theme.shadows.md,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.colors.blue_gray[1],
    position: 'relative',
    zIndex: 200,
  },
  child: {
    width: 12,
    height: 12,
    borderRadius: '50%',
  },
}));

const hoverCardStyles: HoverCardProps['styles'] = (theme) => ({
  dropdown: {
    backgroundColor: theme.white,
    borderRadius: theme.radius.lg,
    boxShadow: '0px 11px 23.9px 0px rgba(0, 0, 0, 0.19)',
  },
});

const buttonStyles: ButtonProps['styles'] = (theme) => ({
  root: {
    '&:hover': {
      color: theme.black,
      textDecoration: 'none',
    },
  },
});
