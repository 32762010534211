import {
  absenLogo,
  acerLogo,
  appspaceLogo,
  assaLogo,
  audiocodesLogo,
  averLogo,
  avigilonLogo,
  barcoLogo,
  biampLogo,
  brightSignLogo,
  brivoLogo,
  christieLogo,
  ciscoMerakiLogo,
  clearoneLogo,
  controlbywebLogo,
  domotzLogo,
  dtenLogo,
  eloLogo,
  eposLogo,
  epsonLogo,
  fourSuitesLogo,
  honeywellLogo,
  hpeArubaLogo,
  hpLogo,
  huddlecamhdLogo,
  jabraLogo,
  lenelLogo,
  lenovoLogo,
  lgLogo,
  logitechLogo,
  maxhubLogo,
  microsoftLogo,
  mtrLogo,
  neatLogo,
  necLogo,
  novastarLogo,
  nurevaLogo,
  optomaLogo,
  panasonicLogo,
  philipsLogo,
  pointgrabLogo,
  polyLogo,
  ptiLogo,
  qSysLogo,
  saltoLogo,
  samsungLogo,
  sennheiserLogo,
  sharpLogo,
  shureLogo,
  smartLogo,
  sonosLogo,
  sonyLogo,
  twoNLogo,
  vaddioLogo,
  vdo360Logo,
  viewsonicLogo,
  visionlineLogo,
  vivitekLogo,
  webexLogo,
  xioLogo,
  yaleLogo,
  yamahaLogo,
  yealinkLogo,
  zoomLogo,
} from '@portals/assets';
import { DataType } from '@portals/types';

export type C2CIntegrationId =
  | keyof DataType['integrations']
  | 'webex'
  | 'poly'
  | 'neat'
  | 'novastar'
  | 'yale'
  | '2n'
  | 'honeywell'
  | 'lenel'
  | 'assa'
  | 'visionline'
  | 'avigilon'
  | 'brivo'
  | 'controlbyweb'
  | '4suites'
  | 'pti'
  | 'salto'
  | 'biamp_workplace'
  | 'sonos'
  | 'viewsonic'
  | 'yealink'
  | 'domotz'
  | 'appspace'
  | 'absen'
  | 'lg'
  | 'philips'
  | 'christie'
  | 'nec'
  | 'samsung'
  | 'elo'
  | 'optoma'
  | 'sharp'
  | 'epson'
  | 'panasonic'
  | 'vivitek'
  | 'acer'
  | 'audiocodes'
  | 'aver'
  | 'barco'
  | 'clearone'
  | 'dten'
  | 'epos'
  | 'hp'
  | 'huddlecamhd'
  | 'jabra'
  | 'lenovo'
  | 'logitech'
  | 'maxhub'
  | 'microsoft_surface'
  | 'sennheiser'
  | 'shure'
  | 'smart'
  | 'sony'
  | 'vaddio'
  | 'vdo360'
  | 'yamaha'
  | 'pointgrab'
  | 'cisco_meraki'
  | 'hpe_aruba'
  | 'nureva'
  | 'zoom_v2';

export interface C2CIntegrationItem {
  id: C2CIntegrationId;
  logo: string;
  name: string;
  description: string;
}

export const C2C_INTEGRATIONS = {
  mtr: {
    id: 'mtr',
    name: 'Microsoft Teams Rooms',
    logo: mtrLogo,
    description:
      'Microsoft Teams Rooms optimize meetings with integrated video conferencing, making collaboration in workspaces more efficient and engaging.',
  },
  zoom: {
    id: 'zoom',
    name: 'Zoom Rooms',
    logo: zoomLogo,
    description:
      'Zoom Rooms deliver seamless video conferencing experiences, transforming any space into a modern, collaborative meeting environment.',
  },
  zoom_v2: {
    id: 'zoom_v2',
    name: 'Zoom Rooms',
    logo: zoomLogo,
    description:
      'Zoom Rooms deliver seamless video conferencing experiences, transforming any space into a modern, collaborative meeting environment.',
  },
  xio: {
    id: 'xio',
    name: 'Crestron XiO Cloud',
    logo: xioLogo,
    description:
      'Crestron XiO Cloud provides remote management and monitoring of devices, streamlining IT operations across workspaces.',
  },
  q_sys: {
    id: 'q_sys',
    name: 'Reflect',
    logo: qSysLogo,
    description:
      'QSC Reflect offers cloud-based management and monitoring for AV systems, ensuring reliable performance and easy control in various environments.',
  },
  bright_sign: {
    id: 'bright_sign',
    name: 'BrightSign',
    logo: brightSignLogo,
    description:
      "BrightSign is a digital signage platform that allows for remote access and control of multimedia content via integration with a cloud IoT provider. This is done using BrightSign's built-in networking and APIs. Specific integration steps depend on the cloud IoT provider.",
  },
  webex: {
    id: 'webex',
    name: 'Cisco Webex',
    logo: webexLogo,
    description:
      'Cisco Webex provides a robust video conferencing solution, turning any room into a connected, collaborative meeting environment.',
  },
  poly: {
    id: 'poly',
    name: 'Poly',
    logo: polyLogo,
    description:
      'Poly enables immersive video conferencing, bringing modern collaboration experiences to any meeting space.',
  },
  neat: {
    id: 'neat',
    name: 'Neat Pulse',
    logo: neatLogo,
    description:
      'Neat redefines video conferencing, creating seamless, intuitive communication experiences in any room.',
  },
  novastar: {
    id: 'novastar',
    name: 'Novastar',
    logo: novastarLogo,
    description:
      'Novastar revolutionizes LED display technology, transforming spaces into visually stunning, collaborative environments.',
  },
  yale: {
    id: 'yale',
    name: 'Yale',
    logo: yaleLogo,
    description:
      'Yale delivers smart security solutions, making any building safer and more connected.',
  },
  '2n': {
    id: '2n',
    name: '2N',
    logo: twoNLogo,
    description:
      '2N offers advanced intercom systems, transforming building communication with cutting-edge security and control.',
  },
  honeywell: {
    id: 'honeywell',
    name: 'Honeywell',
    logo: honeywellLogo,
    description:
      'Honeywell provides integrated security solutions, ensuring safety and seamless building management.',
  },
  lenel: {
    id: 'lenel',
    name: 'Lenel',
    logo: lenelLogo,
    description:
      'Lenel delivers powerful security systems, modernizing access control and enhancing building safety.',
  },
  assa: {
    id: 'assa',
    name: 'Assa',
    logo: assaLogo,
    description:
      'Assa offers innovative locking systems, transforming any facility into a secure, smart environment.',
  },
  visionline: {
    id: 'visionline',
    name: 'ASSA ABLOY Visionline',
    logo: visionlineLogo,
    description:
      'ASSA ABLOY Visionline offers advanced access control, modernizing building security with seamless integration.',
  },
  avigilon: {
    id: 'avigilon',
    name: 'Avigilon Access Control',
    logo: avigilonLogo,
    description:
      'Avigilon provides cutting-edge access control systems, transforming any facility into a secure, streamlined environment.',
  },
  brivo: {
    id: 'brivo',
    name: 'Brivo Access Control',
    logo: brivoLogo,
    description:
      'Brivo delivers cloud-based access control, making any building safer and more connected.',
  },
  controlbyweb: {
    id: 'controlbyweb',
    name: 'ControlByWeb',
    logo: controlbywebLogo,
    description:
      'ControlByWeb offers advanced web-based monitoring, transforming facility management with real-time control and visibility.',
  },
  '4suites': {
    id: '4suites',
    name: '4Suites Locks',
    logo: fourSuitesLogo,
    description:
      '4Suites provides smart lock solutions, making any facility secure and effortlessly connected.',
  },
  pti: {
    id: 'pti',
    name: 'PTI Security Systems',
    logo: ptiLogo,
    description:
      'PTI delivers advanced security systems, transforming storage facilities into secure, controlled environments.',
  },
  salto: {
    id: 'salto',
    name: 'Salto Locks',
    logo: saltoLogo,
    description:
      'Salto offers smart locking solutions, revolutionizing access control in any modern facility.',
  },
  domotz: {
    id: 'domotz',
    name: 'Domotz',
    logo: domotzLogo,
    description:
      'Domotz provides powerful network monitoring solutions, transforming any environment into a secure, connected, and efficiently managed space.',
  },
  biamp_workplace: {
    id: 'biamp_workplace',
    name: 'Biamp Workplace',
    logo: biampLogo,
    description:
      'Biamp offers world-class audio systems, transforming any space into a clear, connected communication hub.',
  },
  sonos: {
    id: 'sonos',
    name: 'Sonos',
    logo: sonosLogo,
    description:
      'Enables businesses to connect their audio system with the Xyte platform to enhance sound management, device control, and user experience.',
  },
  viewsonic: {
    id: 'viewsonic',
    name: 'ViewSonic',
    logo: viewsonicLogo,
    description:
      'ViewSonic allows businesses to connect their display technology with the Xyte platform to enhance visual communications, device control, and presentations.',
  },
  yealink: {
    id: 'yealink',
    name: 'Yealink',
    logo: yealinkLogo,
    description:
      'Yealink provides intuitive video conferencing solutions, turning any room into a collaborative meeting environment.',
  },
  appspace: {
    id: 'appspace',
    name: 'Appspace',
    logo: appspaceLogo,
    description:
      'Appspace provides workplace management and communication solutions, streamlining collaboration and productivity in hybrid work environments.',
  },
  absen: {
    id: 'absen',
    name: 'Absen',
    logo: absenLogo,
    description:
      'Absen specializes in LED display solutions, offering high-quality digital signage and video walls for various applications, including retail and events.',
  },
  lg: {
    id: 'lg',
    name: 'LG',
    logo: lgLogo,
    description:
      'LG offers a wide range of display solutions, including digital signage, OLED displays, and video walls, for commercial and professional use.',
  },
  philips: {
    id: 'philips',
    name: 'Philips',
    logo: philipsLogo,
    description:
      'Philips produces advanced digital signage, professional displays, and monitors, offering innovative solutions for retail and healthcare.',
  },
  christie: {
    id: 'christie',
    name: 'Christie',
    logo: christieLogo,
    description:
      'Christie provides advanced projection and display technologies, including projectors and video walls, for business and entertainment environments.',
  },
  nec: {
    id: 'nec',
    name: 'NEC',
    logo: necLogo,
    description:
      'NEC delivers commercial-grade display solutions, including projectors and large-format displays, for education, retail, and corporate environments.',
  },
  samsung: {
    id: 'samsung',
    name: 'Samsung',
    logo: samsungLogo,
    description:
      'Samsung offers cutting-edge display technology, including QLED signage, video walls, and interactive displays, for professional use cases.',
  },
  elo: {
    id: 'elo',
    name: 'Elo',
    logo: eloLogo,
    description:
      'Elo designs interactive touchscreens and point-of-sale solutions, delivering user-friendly technology for retail, healthcare, and workplaces.',
  },
  optoma: {
    id: 'optoma',
    name: 'Optoma',
    logo: optomaLogo,
    description:
      'Optoma designs projectors and LED displays, providing high-quality solutions for home entertainment, education, and business use.',
  },
  sharp: {
    id: 'sharp',
    name: 'Sharp',
    logo: sharpLogo,
    description:
      'Sharp designs innovative display solutions, including interactive whiteboards and large-format displays, for offices and classrooms.',
  },
  epson: {
    id: 'epson',
    name: 'Epson',
    logo: epsonLogo,
    description:
      'Epson manufactures projectors, printers, and imaging technologies, delivering innovative solutions for business and personal use.',
  },
  panasonic: {
    id: 'panasonic',
    name: 'Panasonic',
    logo: panasonicLogo,
    description:
      'Panasonic develops professional AV solutions, including projectors, displays, and cameras, designed for live events and business environments.',
  },
  vivitek: {
    id: 'vivitek',
    name: 'Vivitek',
    logo: vivitekLogo,
    description:
      'Vivitek manufactures projectors, digital signage, and collaboration solutions, delivering reliable technology for business and education.',
  },
  acer: {
    id: 'acer',
    name: 'Acer',
    logo: acerLogo,
    description:
      'Acer develops a wide range of laptops, desktops, and monitors, delivering innovative computing solutions for personal and professional use.',
  },
  audiocodes: {
    id: 'audiocodes',
    name: 'Audiocodes',
    logo: audiocodesLogo,
    description:
      'AudioCodes specializes in voice networking solutions, offering VoIP, SBCs, and call recording tools for seamless communication.',
  },
  aver: {
    id: 'aver',
    name: 'AVer',
    logo: averLogo,
    description:
      'AVer creates video conferencing cameras and document cameras, enhancing collaboration and learning in corporate and educational spaces.',
  },
  barco: {
    id: 'barco',
    name: 'Barco',
    logo: barcoLogo,
    description:
      'Barco delivers immersive presentation and collaboration tools, transforming spaces into interactive, connected hubs.',
  },
  clearone: {
    id: 'clearone',
    name: 'ClearOne',
    logo: clearoneLogo,
    description:
      'ClearOne designs audio and video conferencing systems, offering crystal-clear communication for boardrooms and huddle spaces.',
  },
  dten: {
    id: 'dten',
    name: 'DTEN',
    logo: dtenLogo,
    description:
      'DTEN creates video conferencing devices integrated with Zoom, enhancing virtual collaboration for hybrid and remote teams.',
  },
  epos: {
    id: 'epos',
    name: 'EPOS',
    logo: eposLogo,
    description:
      'EPOS offers premium audio equipment, including headsets and speakerphones, for clear communication in business and gaming.',
  },
  hp: {
    id: 'hp',
    name: 'HP',
    logo: hpLogo,
    description:
      'HP offers high-quality AV solutions, enhancing any space with crystal-clear audio and video communication.',
  },
  huddlecamhd: {
    id: 'huddlecamhd',
    name: 'HuddleCamHD',
    logo: huddlecamhdLogo,
    description:
      'HuddleCamHD manufactures affordable video conferencing cameras, designed to improve virtual meetings in small and large spaces.',
  },
  jabra: {
    id: 'jabra',
    name: 'Jabra',
    logo: jabraLogo,
    description:
      'Jabra provides headsets, speakerphones, and video conferencing tools, enabling superior sound quality and collaboration.',
  },
  lenovo: {
    id: 'lenovo',
    name: 'Lenovo',
    logo: lenovoLogo,
    description:
      'Lenovo creates laptops, desktops, and tablets, offering powerful technology for business, education, and personal productivity.',
  },
  logitech: {
    id: 'logitech',
    name: 'Logitech',
    logo: logitechLogo,
    description:
      'Logitech designs peripherals, including webcams and conference cameras, for seamless collaboration in modern work environments.',
  },
  maxhub: {
    id: 'maxhub',
    name: 'MAXHUB',
    logo: maxhubLogo,
    description:
      'MAXHUB offers interactive displays and video conferencing solutions, facilitating collaboration in offices and classrooms.\n',
  },
  microsoft_surface: {
    id: 'microsoft_surface',
    name: 'Microsoft Surface',
    logo: microsoftLogo,
    description:
      'Microsoft Surface delivers versatile laptops, tablets, and interactive displays, enhancing productivity and creativity in any workspace.',
  },
  sennheiser: {
    id: 'sennheiser',
    name: 'Sennheiser',
    logo: sennheiserLogo,
    description:
      'Sennheiser offers high-quality microphones, headsets, and audio systems, catering to professionals and audiophiles alike.',
  },
  shure: {
    id: 'shure',
    name: 'Shure',
    logo: shureLogo,
    description:
      'Shure creates microphones and conferencing solutions, delivering exceptional sound quality for professional audio environments.\n',
  },
  smart: {
    id: 'smart',
    name: 'SMART',
    logo: smartLogo,
    description:
      'SMART designs interactive whiteboards and displays, enhancing collaboration in classrooms and corporate meeting spaces.',
  },
  sony: {
    id: 'sony',
    name: 'Sony',
    logo: sonyLogo,
    description:
      'Sony creates a wide range of technology, including cameras, displays, and audio systems, for professional and personal use.\n',
  },
  vaddio: {
    id: 'vaddio',
    name: 'Vaddio',
    logo: vaddioLogo,
    description:
      'Vaddio manufactures professional-grade PTZ cameras and AV solutions, enhancing video collaboration in conference rooms.',
  },
  vdo360: {
    id: 'vdo360',
    name: 'VDO360',
    logo: vdo360Logo,
    description:
      'VDO360 creates high-quality cameras and video solutions, enabling seamless virtual communication for remote teams.\n',
  },
  yamaha: {
    id: 'yamaha',
    name: 'Yamaha',
    logo: yamahaLogo,
    description:
      'Yamaha designs audio equipment, including soundbars and conferencing solutions, offering premium sound experiences for users.',
  },
  pointgrab: {
    id: 'pointgrab',
    name: 'PointGrab',
    logo: pointgrabLogo,
    description:
      'PointGrab develops smart sensors for detecting occupancy and activity in buildings, enhancing energy efficiency and space utilization.',
  },
  cisco_meraki: {
    id: 'cisco_meraki',
    name: 'Cisco Meraki',
    logo: ciscoMerakiLogo,
    description:
      'Cisco Meraki provides cloud-managed networking solutions, including Wi-Fi, security, and SD-WAN, enabling seamless IT management for businesses.',
  },
  hpe_aruba: {
    id: 'hpe_aruba',
    name: 'HPE Aruba',
    logo: hpeArubaLogo,
    description:
      'HPE Aruba delivers enterprise networking solutions, including secure Wi-Fi, switching, and SD-WAN, optimizing connectivity for modern workplaces.',
  },
  nureva: {
    id: 'nureva',
    name: 'Nureva',
    logo: nurevaLogo,
    description:
      'Nureva designs innovative audio conferencing solutions, including microphone and speaker systems, for seamless collaboration in meeting spaces.',
  },
} as const satisfies Partial<Record<C2CIntegrationId, C2CIntegrationItem>>;
