import { Button, createStyles } from '@mantine/core';
import React from 'react';

import { ModalBody, ModalFooter } from '@portals/core';

import { TroubleshootAndSupportMessage } from './TroubleshootAndSupportMessage';
import { FormComponentCommonProps } from '../../add-device-modal.types';
import { VendorField } from '../fields/VendorField';

export function VendorForm({
  handleClose,
  selectedPartnerId,
  setSelectedPartnerId,
  partnerModels,
}: FormComponentCommonProps) {
  const { classes } = useStyles();

  const modelInfo = partnerModels.find(({ id }) => id);

  return (
    <>
      <ModalBody px="xxl" className={classes.modalBody}>
        <VendorField
          vendor={selectedPartnerId}
          setVendor={setSelectedPartnerId}
        />

        {selectedPartnerId && (
          <TroubleshootAndSupportMessage
            modelInfo={modelInfo || null}
            handleClose={handleClose}
          />
        )}
      </ModalBody>

      <ModalFooter position="right" p="xxl" className={classes.footer}>
        <Button type="button" variant="default" onClick={handleClose}>
          Cancel
        </Button>

        <Button type="submit" disabled>
          Claim device
        </Button>
      </ModalFooter>
    </>
  );
}

const useStyles = createStyles((theme) => ({
  footer: {
    borderTop: `1px solid ${theme.colors.gray[2]}`,
  },
  modalBody: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing.md,
  },
}));
