import { useLocalStorage } from '@mantine/hooks';
import GoogleMapReact from 'google-map-react';
import { isNumber } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import Supercluster from 'supercluster';

import { MAX_ZOOM } from './device-location-widget.constants';
import {
  GoogleMapsType,
  GoogleMapType,
  MapFeature,
  SuperClusterPoint,
} from './device-location-widget.types';
import { DeviceLocationWidgetFormType } from '../form';

const MAPS_STATES_KEY = 'device-location-widgets-map-states';

const MAX_STORED_ITEMS = 1000;

interface MapState extends GoogleMapReact.ChangeEventValue {
  updatedAt: Date;
  isInitialMapData: boolean;
}

function removeOldestItem(data: Record<string, MapState>): void {
  const entries = Object.entries(data);

  if (entries.length >= MAX_STORED_ITEMS) {
    const oldestEntry = entries.reduce((oldest, current) => {
      return current[1].updatedAt < oldest[1].updatedAt ? current : oldest;
    });

    delete data[oldestEntry[0]];
  }
}

export function useDeviceLocationMapStateInLocalStorage(mapWidgetId?: string) {
  const [mapStateInLocalStorage, setMapStateInLocalStorage] = useLocalStorage<
    Record<string, MapState>
  >({
    key: MAPS_STATES_KEY,
    defaultValue: {},
    getInitialValueInEffect: false,
  });

  const currentMapState = useMemo(
    () => (mapWidgetId ? mapStateInLocalStorage[mapWidgetId] : null),
    [mapWidgetId, mapStateInLocalStorage]
  );

  const isMapDefaultView = useMemo(
    () =>
      currentMapState &&
      currentMapState.bounds.nw.lat === 70.8745454058404 &&
      currentMapState.bounds.nw.lng === -23.353398484679218 &&
      currentMapState.bounds.se.lat === -32.17288938040047 &&
      currentMapState.bounds.se.lng === 93.01378901532081,
    [currentMapState]
  );

  const setCurrentMapState = useCallback(
    (mapState: Omit<MapState, 'updatedAt'>) => {
      if (!mapWidgetId) {
        return;
      }

      removeOldestItem(mapStateInLocalStorage);

      setMapStateInLocalStorage((prev) => {
        return {
          ...prev,
          [mapWidgetId]: {
            ...mapState,
            updatedAt: new Date(),
            isInitialMapData: !currentMapState,
          },
        };
      });
    },
    [
      currentMapState,
      mapStateInLocalStorage,
      mapWidgetId,
      setMapStateInLocalStorage,
    ]
  );

  const zoomToPoints = useCallback(
    ({
      map,
      maps,
      pointsToZoom,
    }: {
      map: GoogleMapType;
      maps: GoogleMapsType;
      pointsToZoom: Array<SuperClusterPoint>;
    }) => {
      if (!pointsToZoom.length) return;

      const bounds = new maps.LatLngBounds();

      pointsToZoom.forEach((point) => {
        const lat = point.geometry.coordinates[1];
        const lng = point.geometry.coordinates[0];
        bounds.extend(new maps.LatLng(lat, lng));
      });

      setTimeout(() => {
        map.fitBounds(bounds);

        const currentBounds = map.getBounds();
        if (currentBounds && !currentBounds.equals(bounds)) {
          map.fitBounds(bounds);
        }

        const currentZoom = map.getZoom();
        if (currentZoom && currentZoom > MAX_ZOOM) {
          map.setZoom(MAX_ZOOM);
        }
      }, 100);
    },
    []
  );

  const zoomToCluster = useCallback(
    ({
      map,
      maps,
      cluster,
      superCluster,
    }: {
      map: GoogleMapType;
      maps: GoogleMapsType;
      cluster: MapFeature;
      superCluster: Supercluster;
    }) => {
      if (!superCluster || !isNumber(cluster.id)) return;

      const clusterPoints = superCluster.getLeaves(cluster.id, Infinity);
      zoomToPoints({ map, maps, pointsToZoom: clusterPoints });
    },
    [zoomToPoints]
  );

  const zoomToAllPoints = useCallback(
    ({
      map,
      maps,
      points,
    }: {
      map: GoogleMapType;
      maps: GoogleMapsType;
      points: Array<SuperClusterPoint>;
    }) => {
      zoomToPoints({ map, maps, pointsToZoom: points });
    },
    [zoomToPoints]
  );

  return {
    currentMapState,
    isMapDefaultView,
    setCurrentMapState,
    zoomToPoints,
    zoomToCluster,
    zoomToAllPoints,
  };
}

export function useDeviceLocationWidgetDimensions(
  widgetFields: DeviceLocationWidgetFormType
) {
  const deviceLocationWidgetDimensions = useMemo(
    () => widgetFields?.dimensions || [],
    [widgetFields]
  );

  const segmentedControlData = useMemo(
    () =>
      deviceLocationWidgetDimensions.map((dimension) => ({
        value: dimension.id,
        label: dimension.name,
      })),
    [deviceLocationWidgetDimensions]
  );

  const shouldRenderSegmentedControl = useMemo(
    () => segmentedControlData.length > 1,
    [segmentedControlData]
  );

  const [activeDimensionId, setActiveDimensionId] = useState(
    segmentedControlData[0]?.value || ''
  );

  const activeDimension = useMemo(
    () =>
      deviceLocationWidgetDimensions.find(
        (dimension) => dimension.id === activeDimensionId
      ),
    [deviceLocationWidgetDimensions, activeDimensionId]
  );

  return {
    segmentedControlData,
    activeDimensionId,
    shouldRenderSegmentedControl,
    setActiveDimensionId,
    activeDimension,
  };
}
