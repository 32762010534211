export const MAP_STYLE = [
  {
    featureType: 'administrative',
    elementType: 'all',
    stylers: [{ visibility: 'on' }],
  },
  {
    featureType: 'administrative',
    elementType: 'geometry.fill',
    stylers: [{ visibility: 'on' }],
  },
  {
    featureType: 'administrative',
    elementType: 'geometry.stroke',
    stylers: [{ visibility: 'on' }],
  },
  {
    featureType: 'administrative',
    elementType: 'labels',
    stylers: [{ visibility: 'on' }, { lightness: '48' }],
  },
  {
    featureType: 'administrative',
    elementType: 'labels.text',
    stylers: [{ visibility: 'on' }],
  },
  {
    featureType: 'landscape',
    elementType: 'all',
    stylers: [{ visibility: 'on' }],
  },
  {
    featureType: 'landscape',
    elementType: 'labels',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'landscape.natural',
    elementType: 'labels',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'poi',
    elementType: 'all',
    stylers: [{ visibility: 'on' }],
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [{ lightness: '0' }],
  },
  {
    featureType: 'poi',
    elementType: 'geometry.fill',
    stylers: [{ visibility: 'on' }],
  },
  {
    featureType: 'poi',
    elementType: 'labels',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'poi.medical',
    elementType: 'geometry.fill',
    stylers: [{ lightness: '-5' }],
  },
  {
    featureType: 'poi.medical',
    elementType: 'labels',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry.fill',
    stylers: [{ visibility: 'on' }, { color: '#a7ce95' }, { lightness: '45' }],
  },
  {
    featureType: 'poi.school',
    elementType: 'geometry',
    stylers: [{ color: '#be9b7b' }, { lightness: '70' }],
  },
  {
    featureType: 'poi.sports_complex',
    elementType: 'geometry',
    stylers: [{ color: '#5d4b46' }, { lightness: '60' }],
  },
  {
    featureType: 'road',
    elementType: 'all',
    stylers: [{ visibility: 'on' }],
  },
  {
    featureType: 'road',
    elementType: 'labels',
    stylers: [{ visibility: 'on' }],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels',
    stylers: [{ visibility: 'on' }],
  },
  {
    featureType: 'transit',
    elementType: 'labels',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'transit.station',
    elementType: 'geometry',
    stylers: [
      { saturation: '23' },
      { lightness: '10' },
      { gamma: '0.8' },
      { hue: '#b000ff' },
    ],
  },
  {
    featureType: 'water',
    elementType: 'all',
    stylers: [{ visibility: 'on' }],
  },
  {
    featureType: 'water',
    elementType: 'geometry.fill',
    stylers: [{ color: '#a2daf2' }],
  },
  {
    featureType: 'water',
    elementType: 'labels',
    stylers: [{ lightness: '-95' }, { gamma: '4.70' }, { saturation: '-58' }],
  },
];

export const MAX_ZOOM = 20;
export const DEFAULT_ZOOM = 4;

export const MAP_INITIAL_BOUNDS = {
  nw: {
    lat: 70.08664435002368,
    lng: -150.59989924999996,
  },
  se: {
    lat: -8.563365936737256,
    lng: 154.90791325000004,
  },
  sw: {
    lat: -8.563365936737256,
    lng: -150.59989924999996,
  },
  ne: {
    lat: 70.08664435002368,
    lng: 154.90791325000004,
  },
};
