import { Box, createStyles } from '@mantine/core';
import GoogleMapReact, { MapOptions } from 'google-map-react';
import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  DEFAULT_ZOOM,
  MAP_STYLE,
  MAX_ZOOM,
} from './device-location-widget.constants';
import { useDeviceLocationMapStateInLocalStorage } from './device-location-widget.hooks';
import {
  GoogleMapsType,
  GoogleMapType,
  MapFeature,
  SuperClusterPoint,
} from './device-location-widget.types';
import {
  convertGoogleMapsReactBoundsToLatLngBounds,
  createClusterMap,
} from './device-location-widget.utils';
import { DeviceLocationCluster } from './DeviceLocationCluster';
import { DeviceLocationMapProvider } from './DeviceLocationMapContext';
import { DeviceLocationEmptyState } from './DeviceLocationWidget';

export interface MobileDeviceLocationWidgetProps {
  mapWidgetId?: string;
  points: Array<SuperClusterPoint>;
  mapOptions?: Partial<MapOptions>;
  onMapClick?: React.ComponentProps<typeof GoogleMapReact>['onClick'];
  mapActionsButtons?: ReactNode;
  selectedChildDeviceId: string | null;
  setIsFullscreen: Dispatch<SetStateAction<boolean>>;
  isFullscreen: boolean;
}

const MAP_OPTIONS: Partial<MapOptions> = {
  maxZoom: MAX_ZOOM,
  styles: MAP_STYLE,
};

export function MobileDeviceLocationWidget({
  mapWidgetId,
  points,
  onMapClick,
  mapOptions = {},
  mapActionsButtons = null,
  selectedChildDeviceId,
  isFullscreen,
  setIsFullscreen,
}: MobileDeviceLocationWidgetProps) {
  const { classes, theme, cx } = useStyles();
  const mapRef = useRef(null);
  const isInitial = useRef(true);

  const {
    currentMapState,
    setCurrentMapState,
    zoomToCluster,
    zoomToAllPoints,
  } = useDeviceLocationMapStateInLocalStorage(mapWidgetId);

  const [{ map, maps }, setMapState] = useState<{
    map: GoogleMapType | null;
    maps: GoogleMapsType | null;
  }>({
    map: null,
    maps: null,
  });

  const googleMapDefaultValues = useRef({
    zoom: currentMapState?.zoom || DEFAULT_ZOOM,
    center: {
      lng:
        currentMapState?.center.lng ||
        points?.[0]?.geometry.coordinates[0] ||
        0,
      lat:
        currentMapState?.center.lat ||
        points?.[0]?.geometry.coordinates[1] ||
        0,
    },
  });

  const onSetFullscreen = useCallback(() => {
    if (!isFullscreen) {
      setIsFullscreen(true);
    }
  }, [setIsFullscreen, isFullscreen]);

  const onCenterMap = useCallback(() => {
    if (!map || !maps) return;

    // Use zoomToAllPoints to center the map and show all points
    zoomToAllPoints({ map, maps, points });
  }, [map, maps, points, zoomToAllPoints]);

  const zoomToMarker = useCallback(
    (lat: number, lng: number) => {
      if (map && maps) {
        const latLng = new maps.LatLng(lat, lng);
        const projection = map.getProjection();

        if (projection) {
          setTimeout(() => {
            map.setZoom(MAX_ZOOM);
          });

          // Get the map's height
          const mapHeight = map.getDiv().clientHeight;

          // Calculate the pixel offset based on the markerOffsetPercentage
          const pixelOffset = mapHeight * 0.7 - mapHeight / 2;

          // Calculate the pixel coordinates of the marker
          const scale = Math.pow(2, MAX_ZOOM);
          const worldCoordinateCenter = projection.fromLatLngToPoint(latLng);

          if (worldCoordinateCenter === null) return;

          const offsetPoint = new maps.Point(0, pixelOffset / scale);

          // Calculate new center point
          const newWorldCoordinateCenter = new maps.Point(
            worldCoordinateCenter.x,
            worldCoordinateCenter.y + offsetPoint.y
          );

          const newCenter = projection.fromPointToLatLng(
            newWorldCoordinateCenter
          );

          if (newCenter === null) return;

          setTimeout(() => map.panTo(newCenter));
        }
      }
    },
    [map, maps]
  );

  useEffect(
    function centerOnSelectedMarker() {
      if (selectedChildDeviceId) {
        const selectedPoint = points.find(
          (clusterPoint) =>
            clusterPoint.properties?.id === selectedChildDeviceId
        );

        if (selectedPoint) {
          const [lng, lat] = selectedPoint.geometry.coordinates;

          zoomToMarker(lat, lng);
        }
      }
    },
    [selectedChildDeviceId, points, zoomToMarker, onCenterMap]
  );

  useEffect(
    function setInitialZoom() {
      if (!map || !maps) return;
      const convertedBounds = convertGoogleMapsReactBoundsToLatLngBounds(
        currentMapState?.bounds
      );

      if (currentMapState && convertedBounds) {
        map.setCenter(currentMapState.center);
        map.setZoom(currentMapState.zoom);
      } else if (isInitial.current) {
        isInitial.current = false;

        onCenterMap();
      }
    },
    [map, maps, currentMapState, onCenterMap]
  );

  const [clusters, superCluster] = useMemo(() => {
    if (!currentMapState) {
      return [[], undefined];
    }

    return createClusterMap(
      currentMapState.bounds,
      points,
      currentMapState.zoom
    );
  }, [points, currentMapState]);

  const handleMapLoaded = useCallback(
    ({ map, maps }: { map: GoogleMapType; maps: GoogleMapsType }) => {
      if (currentMapState?.isInitialMapData) {
        zoomToAllPoints({ map, maps, points });
      }

      setMapState((state) => ({ ...state, map, maps }));
    },
    [currentMapState, zoomToAllPoints, points]
  );

  const handleClusterClick = useCallback(
    ({
      cluster,
      map,
      maps,
    }: {
      cluster: MapFeature;
      map: GoogleMapType;
      maps: GoogleMapsType;
    }) => {
      if (!superCluster) return;
      zoomToCluster({ cluster, map, maps, superCluster });
    },
    [zoomToCluster, superCluster]
  );

  const adjustedMapOptions = useMemo(
    () => ({ ...MAP_OPTIONS, ...mapOptions }),
    [mapOptions]
  );

  const markers = useMemo(() => {
    if (!clusters?.length || !map || !maps) return null;

    return clusters.map((item) => {
      const [longitude, latitude] = item.geometry.coordinates;

      return item.properties?.cluster ? (
        <DeviceLocationCluster
          key={item.id}
          item={item}
          superCluster={superCluster}
          onClick={(cluster) => handleClusterClick({ cluster, map, maps })}
          zoom={currentMapState?.zoom || DEFAULT_ZOOM}
          lat={latitude}
          lng={longitude}
        />
      ) : (
        item.properties?.['renderSinglePoint']()
      );
    });
  }, [
    clusters,
    map,
    maps,
    superCluster,
    currentMapState?.zoom,
    handleClusterClick,
  ]);

  if (!points?.length) {
    return <DeviceLocationEmptyState />;
  }

  return (
    <div
      className={cx(classes.container, {
        [classes.containerFullScreen]: isFullscreen,
      })}
    >
      <Box
        w="100%"
        h="100%"
        sx={{
          borderRadius: theme.radius.md,
        }}
        onPointerDown={onSetFullscreen}
      >
        <DeviceLocationMapProvider onCenterMap={onCenterMap}>
          <GoogleMapReact
            ref={mapRef}
            bootstrapURLKeys={{
              key: process.env.NX_GOOGLE_KEY || 'MISSING_GOOGLE_MAPS_API_KEY',
            }}
            defaultZoom={googleMapDefaultValues.current?.zoom}
            defaultCenter={googleMapDefaultValues.current?.center}
            resetBoundsOnResize
            yesIWantToUseGoogleMapApiInternals
            options={adjustedMapOptions}
            onGoogleApiLoaded={handleMapLoaded}
            onChange={setCurrentMapState}
            draggable={isFullscreen}
            onClick={onMapClick}
          >
            {markers}
          </GoogleMapReact>

          {mapActionsButtons}
        </DeviceLocationMapProvider>
      </Box>
    </div>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    borderRadius: theme.radius.lg,
    overflow: 'hidden',
    height: '100%',
    position: 'relative',

    '.gm-style': {
      '>div': {
        border: 'none !important',
      },
    },
  },

  containerFullScreen: {
    borderRadius: 0,
  },
}));
