import React, { useState } from 'react';

import { GridView } from './GridView';
import { InfiniteVirtualizedViewProps } from './infinite-virtualized-view.types';
import { TilesView } from './TilesView';

export function InfiniteVirtualizedView<TItem = unknown>({
  displayType,
  scrollableRef,
  getItemId,

  tilesGap = 8,
  tileHeight = 64,
  tilesOverscan = 5,
  tileItemRenderer,
  tileItemSkeleton,

  gridGap = 16,
  gridOverscan = 2,
  gridItemDimensions = 305,
  gridItemRenderer,
  gridItemSkeleton,

  isLoading,
  flatItems,
  hasNextPage,
  isFetchingNextPage,
  fetchNextPage,
}: InfiniteVirtualizedViewProps<TItem>) {
  if (displayType === 'list') {
    return (
      <TilesView<TItem>
        scrollableRef={scrollableRef}
        flatItems={flatItems}
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
        fetchNextPage={fetchNextPage}
        itemRenderer={tileItemRenderer}
        isLoading={isLoading}
        itemSkeleton={tileItemSkeleton}
        tileHeight={tileHeight}
        gap={tilesGap}
        overscan={tilesOverscan}
      />
    );
  }

  return (
    <GridView<TItem>
      scrollableRef={scrollableRef}
      flatItems={flatItems}
      hasNextPage={hasNextPage}
      isLoading={isLoading}
      isFetchingNextPage={isFetchingNextPage}
      fetchNextPage={fetchNextPage}
      itemRenderer={gridItemRenderer}
      itemDimensions={gridItemDimensions}
      gap={gridGap}
      overscan={gridOverscan}
      itemSkeleton={gridItemSkeleton}
      getItemId={getItemId}
    />
  );
}

export type InfiniteVirtualizedViewDisplayType = 'list' | 'grid';

export function useInfiniteVirtualizedViewDisplayType(
  initialDisplayType?: InfiniteVirtualizedViewDisplayType
) {
  return useState<InfiniteVirtualizedViewDisplayType>(
    initialDisplayType ?? 'list'
  );
}
