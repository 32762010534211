import { useVirtualizer } from '@tanstack/react-virtual';
import { useEffect } from 'react';

import { InfiniteVirtualizedViewProps } from './infinite-virtualized-view.types';

interface UseInfiniteScrollParams<TItem = unknown>
  extends Pick<
    InfiniteVirtualizedViewProps<TItem>,
    'fetchNextPage' | 'isFetchingNextPage' | 'hasNextPage' | 'scrollableRef'
  > {
  estimateSize: (index: number) => number;
  gap: number;
  overscan: number;

  flatItems:
    | InfiniteVirtualizedViewProps<TItem>['flatItems']
    | Array<InfiniteVirtualizedViewProps<TItem>['flatItems']>;
}

export function useRowVirtualizer<TItem = unknown>({
  scrollableRef,
  estimateSize,
  overscan,
  gap,

  flatItems,
  hasNextPage,
  isFetchingNextPage,
  fetchNextPage,
}: UseInfiniteScrollParams<TItem>) {
  const rowVirtualizer = useVirtualizer({
    count: hasNextPage ? flatItems.length + 1 : flatItems.length,
    getScrollElement: () => scrollableRef.current,
    estimateSize,
    overscan,
    gap,
  });

  const lastItem = rowVirtualizer.getVirtualItems().at(-1);

  const showScrollToTopButton = Boolean(
    rowVirtualizer.scrollOffset && rowVirtualizer.scrollOffset > 0
  );

  useEffect(
    function fetchNextPageIfNeeded() {
      if (!lastItem) return;

      if (
        lastItem.index >= flatItems.length - 1 &&
        hasNextPage &&
        !isFetchingNextPage
      ) {
        fetchNextPage();
      }
    },
    [fetchNextPage, flatItems.length, hasNextPage, isFetchingNextPage, lastItem]
  );

  return { rowVirtualizer, showScrollToTopButton };
}
