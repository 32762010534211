import { useCallback } from 'react';
import {
  generatePath,
  useMatch,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import { SpaceType } from '@portals/api/organizations';

import { OverviewSettingsTabEnum } from './overview-settings-drawer/settings-drawer.types';
import { SETTINGS_TAB_SEARCH_PARAM } from '../../../constants/global-search-params.constants';
import { OVERVIEW_PATHS, OVERVIEW_TAB, OverviewTabType } from '../../routes';

export function useOverviewRouteParams() {
  return useParams<{ spaceId: string }>();
}

export function useCurrentOverviewTab() {
  const match = useMatch({
    path: `${OVERVIEW_PATHS.space}/:overviewTab`,
    end: false,
  });

  return match?.params.overviewTab;
}

export function useOverviewRouting() {
  const navigate = useNavigate();
  const [, setSearchParams] = useSearchParams();

  const currentOverviewTab = useCurrentOverviewTab();
  const overviewRouteParams = useOverviewRouteParams();

  const generateOverviewTabPath = useCallback(
    (spaceId: SpaceType['id'], overviewTab?: OverviewTabType) => {
      return generatePath(`${OVERVIEW_PATHS.space}/:overviewTab?`, {
        spaceId: String(spaceId),
        overviewTab:
          overviewTab ?? currentOverviewTab ?? OVERVIEW_TAB.dashboard,
      });
    },
    [currentOverviewTab]
  );

  const navigateToOverviewTab = useCallback(
    (
      params: { spaceId: SpaceType['id']; overviewTab?: OverviewTabType },
      navigationOptions?: { search?: string; replace?: boolean }
    ) => {
      const { spaceId, overviewTab } = params;

      const pathname = generateOverviewTabPath(spaceId, overviewTab);

      navigate(
        { pathname, search: navigationOptions?.search },
        { replace: navigationOptions?.replace }
      );
    },
    [generateOverviewTabPath, navigate]
  );

  const navigateToSpaceSettings = useCallback(
    (settingsTab: OverviewSettingsTabEnum) => {
      setSearchParams(
        (prevSearchParams) => {
          prevSearchParams.set(SETTINGS_TAB_SEARCH_PARAM, settingsTab);

          return prevSearchParams;
        },
        { replace: true }
      );
    },
    [setSearchParams]
  );

  const closeSpaceSettingsDrawer = useCallback(() => {
    const spaceId = overviewRouteParams.spaceId;

    if (!spaceId) return;

    setSearchParams(
      (prevSearchParams) => {
        prevSearchParams.delete(SETTINGS_TAB_SEARCH_PARAM);

        return prevSearchParams;
      },
      { replace: true }
    );
  }, [overviewRouteParams.spaceId, setSearchParams]);

  return {
    generateOverviewTabPath,
    navigateToOverviewTab,
    navigateToSpaceSettings,
    closeSpaceSettingsDrawer,
  };
}
