import React from 'react';

import { devicesEmptyStateSrc } from '@portals/assets';
import { EmptyState } from '@portals/core';

import { useOverviewAssetsContext } from './OverviewAssetsProvider';
import { AddAssetButton } from '../../assets/AddAssetButton';

export function OverviewAssetsEmptyState() {
  const { space } = useOverviewAssetsContext();

  return (
    <EmptyState
      media={<img src={devicesEmptyStateSrc} width={400} height="auto" />}
      messageTitle="No assets yet"
      messageDescription={
        <div>
          It looks like you haven’t added any assets to this space.
          <br />
          Once you add assets, they’ll appear here for easy management.
        </div>
      }
      action={
        <AddAssetButton
          initialSpaceId={space.data?.id}
          data-testid="overview-add-asset-button"
        >
          Add asset
        </AddAssetButton>
      }
    />
  );
}
