import {
  BackgroundImage,
  Box,
  createStyles,
  Tabs,
  TabsProps,
} from '@mantine/core';
import { find, get } from 'lodash/fp';
import React, { useMemo } from 'react';

import { useSpaces } from '@portals/api/organizations';
import { spaceCoverPhoto } from '@portals/assets';

import { PanelWrapper } from './overview-header-panel/PanelWrapper';
import { UpdateCoverPhotoToggle } from './UpdateCoverPhotoToggle';
import { useIsDisplayAssetsManagementTab } from '../../../../hooks/assets-management';
import { useCurrentOverviewTab } from '../overview-routing.hooks';
import { useOverviewContext } from '../overview.context';
import { useCurrentSpace } from '../overview.hooks';

export function OverviewHeader() {
  const overview = useOverviewContext();

  const { classes } = useStyles(overview.isConfigMode);

  const spaces = useSpaces();
  const space = useCurrentSpace();

  const currentOverviewTab = useCurrentOverviewTab();

  const isDisplayAssetsManagementTab = useIsDisplayAssetsManagementTab();

  const spaceImage = useMemo(() => {
    const isInherited = get(['config', 'inherit_img'], space);

    if (!isInherited) return get(['config', 'img'], space);

    const parentSpace = find({ id: space?.parent_id }, spaces.data);

    return get(['config', 'img'], parentSpace);
  }, [spaces.data, space]);

  return (
    <div className={classes.container}>
      {!overview.isConfigMode && (
        <BackgroundImage src={spaceImage || spaceCoverPhoto}>
          <Box className={classes.backgroundPlaceholder}>
            <UpdateCoverPhotoToggle space={space} />
          </Box>
        </BackgroundImage>
      )}

      <PanelWrapper />

      {!overview.isConfigMode && (
        <Tabs
          styles={tabsStyles}
          value={currentOverviewTab}
          onTabChange={(tab) => {
            if (tab === 'dashboard') {
              overview.navigateToDashboard();
            } else if (tab === 'devices') {
              overview.navigateToDevices();
            } else if (tab === 'assets') {
              overview.navigateToAssets();
            }
          }}
        >
          <Tabs.List>
            <Tabs.Tab value="dashboard" data-testid="overview-tab-dashboard">
              Dashboard
            </Tabs.Tab>

            <Tabs.Tab value="devices" data-testid="overview-tab-devices">
              Devices
            </Tabs.Tab>

            {isDisplayAssetsManagementTab && (
              <Tabs.Tab value="assets" data-testid="overview-tab-assets">
                Assets
              </Tabs.Tab>
            )}
          </Tabs.List>
        </Tabs>
      )}
    </div>
  );
}

const useStyles = createStyles((_, isConfigMode: boolean) => ({
  container: {
    width: '100%',
    position: 'sticky',
    top: isConfigMode ? 0 : -184,
    zIndex: 3,
  },
  backgroundPlaceholder: {
    height: 184,
    width: '100%',
    zIndex: 3,
    position: 'relative',
    opacity: 0,
    transition: 'opacity 0.15s ease-in-out',

    '&:hover': {
      opacity: 1,
    },
  },
}));

const TAB_BORDER_WIDTH = 3;

const tabsStyles: TabsProps['styles'] = (theme) => ({
  root: {
    borderBottom: `1px solid ${theme.colors.gray[3]}`,
    backgroundColor: theme.white,
  },
  tabsList: {
    borderColor: theme.white,
    borderBottomWidth: TAB_BORDER_WIDTH,
  },
  tab: {
    paddingBlock: theme.spacing.md,
    paddingInline: theme.spacing.xxl,
    marginBottom: -TAB_BORDER_WIDTH,
    borderBottomWidth: TAB_BORDER_WIDTH,

    '&:not([data-active])': {
      borderColor: theme.colors.gray[3],
    },
  },
  tabLabel: {
    fontWeight: 500,
    fontSize: theme.fontSizes.md,
  },
});
