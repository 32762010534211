import { createStyles, Stack, Text } from '@mantine/core';
import React, { ReactNode } from 'react';

interface DisplayTypeCardProps {
  icon: ReactNode;
  label: string;
  isActive: boolean;
  onClick: VoidFunction;
}

export function DisplayTypeCard({
  label,
  icon,
  isActive,
  onClick,
}: DisplayTypeCardProps) {
  const { classes, cx } = useStyles();

  return (
    <Stack
      spacing="xs"
      align="center"
      onClick={onClick}
      className={cx(classes.card, { [classes.active]: isActive })}
    >
      {icon}

      <Text c="primary">{label}</Text>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  card: {
    padding: theme.spacing.md,
    borderRadius: theme.radius.lg,
    border: `1px solid ${theme.colors.gray[2]}`,
    color: theme.colors.gray[5],
    cursor: 'pointer',
    userSelect: 'none',
  },
  active: {
    backgroundColor: theme.colors.gray[0],
    color: theme.fn.primaryColor(),
    borderColor: theme.fn.primaryColor(),
  },
}));
