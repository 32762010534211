import { useForm } from '@mantine/form';
import React, { useMemo } from 'react';

import {
  SpaceType,
  useCreateSpace,
  useSpaces,
  useUpdateSpace,
} from '@portals/api/organizations';
import { ModalProps } from '@portals/framework';
import { UploadImageModalProps } from '@portals/framework/modals';
import { useOpenModal } from '@portals/redux';

import { AddCustomer } from './AddCustomer';
import { CreateCustomerFormValues } from './create-customer.types';
import { EditCustomer } from './EditCustomer';
import { findRoot } from '../../../lib/spaces';

export interface CreateCustomerSpaceModalProps
  extends ModalProps<{
    customerName?: string;
    logoUrl?: string | null;
    spaceId?: SpaceType['id'];
    type: 'add' | 'edit';
    handleCustomerCreation?: (res: SpaceType) => void;
  }> {}

export function CreateCustomerSpaceModal({
  closeMe,
  data: { logoUrl, customerName, type, spaceId, handleCustomerCreation },
}: CreateCustomerSpaceModalProps) {
  const spaces = useSpaces();
  const createSpace = useCreateSpace();
  const updateSpace = useUpdateSpace();
  const openModal = useOpenModal();

  const form = useForm<CreateCustomerFormValues>({
    initialValues: {
      customerName: customerName || '',
      logoUrl: logoUrl || null,
    },
  });

  const rootSpace = useMemo(() => {
    if (!spaces.data) {
      return;
    }

    return findRoot(spaces.data);
  }, [spaces.data]);

  const onUploadLogo = () => {
    openModal<UploadImageModalProps['data']>('UploadImageModal', {
      onUpload: (logoUrl: string) => {
        form.setFieldValue('logoUrl', logoUrl);
      },
      cropConfig: {
        aspectRatio: 1,
        width: 560,
        height: 560,
      },
    });
  };

  const onSubmit = (values: typeof form.values) => {
    if (!rootSpace) {
      return;
    }

    createSpace.mutate(
      {
        parentSpaceId: rootSpace.id,
        logoUrl: values.logoUrl,
        newSpace: {
          name: values.customerName,
        },
      },
      {
        onSuccess: (res) => {
          handleCustomerCreation?.(res);

          closeMe();
        },
      }
    );
  };

  const onEdit = (values: typeof form.values) => {
    if (!spaceId) {
      return;
    }

    updateSpace.mutate(
      {
        spaceId,
        updatedSpace: {
          name: values.customerName,
          icon_url: values.logoUrl,
        },
      },
      {
        onSuccess: () => closeMe(),
      }
    );
  };

  if (type === 'edit') {
    return (
      <EditCustomer
        closeMe={closeMe}
        onUploadLogo={onUploadLogo}
        form={form}
        onSubmit={onEdit}
        isLoading={updateSpace.isLoading}
      />
    );
  }

  return (
    <AddCustomer
      closeMe={closeMe}
      onUploadLogo={onUploadLogo}
      form={form}
      onSubmit={onSubmit}
      isLoading={createSpace.isLoading}
    />
  );
}
