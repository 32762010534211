import { Button, Stack, Title } from '@mantine/core';
import React from 'react';
import { Link } from 'react-router-dom';

export function Page500() {
  return (
    <Stack p="xxl">
      <Title size="7.25rem">500</Title>

      <Title>Internal server error.</Title>

      <Title order={2} weight={400}>
        The server encountered something unexpected that didn't allow it to
        complete the request.
      </Title>

      <div>
        <Button size="xl" onClick={() => window.location.reload()}>
          Refresh
        </Button>
      </div>

      <Link to="/">Return to main page</Link>
    </Stack>
  );
}
