import { useMemo } from 'react';

import { DeviceType, useDeviceModels } from '@portals/api/organizations';

/**
 * Hook to get the common device model for a list of devices.
 *
 * @returns The common device model if all the devices have the same model, `undefined` otherwise
 */
export function useDevicesCommonDeviceModel(devices: Array<DeviceType>) {
  const deviceModels = useDeviceModels();

  const commonDeviceModelId = useMemo(() => {
    if (devices.length === 0) {
      return;
    }

    if (!devices[0]?.partner) {
      return;
    }

    const deviceModelId = devices[0].partner.type_id;

    const areAllSameModelId = devices.every(
      (device) => device.partner?.type_id === deviceModelId
    );

    if (areAllSameModelId) {
      return deviceModelId;
    }
  }, [devices]);

  return useMemo(() => {
    if (!commonDeviceModelId || !deviceModels.data) {
      return;
    }

    return deviceModels.data.find(
      (deviceModel) => deviceModel.id === commonDeviceModelId
    );
  }, [commonDeviceModelId, deviceModels.data]);
}
