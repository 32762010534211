import { Indicator, IndicatorProps, Text, Tooltip } from '@mantine/core';
import React, { ReactNode } from 'react';

import { useExpiredWarranties } from '@portals/api/organizations';

export function WarrantiesIndicator({ children }: { children: ReactNode }) {
  const expiredWarranties = useExpiredWarranties();

  if (!expiredWarranties.data || expiredWarranties.data.expiring_count === 0) {
    return null;
  }

  return (
    <Tooltip
      label="You have warranties expiring soon. Click to review."
      withinPortal
    >
      <Indicator
        label={
          <Text size={10} color="gray.8">
            {expiredWarranties.data.expiring_count}
          </Text>
        }
        color="gray.3"
        size={24}
        styles={indicatorStyles}
      >
        {children}
      </Indicator>
    </Tooltip>
  );
}

const indicatorStyles: IndicatorProps['styles'] = {
  indicator: {
    right: '-13px !important',
  },
  root: {
    fontWeight: 300,
  },
};
