import { Button, Modal, Stack, Text, TextInput } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import React from 'react';

import { ModalFooter } from '@portals/core';

import { CreateCustomerFormValues } from './create-customer.types';
import { CustomerLogoWrapper } from './CustomerLogoWrapper';

interface AddCustomerProps {
  closeMe: () => void;
  onUploadLogo: () => void;
  form: UseFormReturnType<CreateCustomerFormValues>;
  onSubmit: (values: CreateCustomerFormValues) => void;
  isLoading: boolean;
}

export function AddCustomer({
  closeMe,
  onUploadLogo,
  form,
  onSubmit,
  isLoading,
}: AddCustomerProps) {
  return (
    <Modal opened onClose={closeMe} title="Create new customer" padding="xxl">
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Stack spacing="xxl">
          <Text>
            Creating multiple customers lets you separate devices belonging to
            different organizations. You can create separate integrations for
            each customer, but you cannot move devices or spaces between
            customers.
          </Text>

          <CustomerLogoWrapper form={form} onUploadLogo={onUploadLogo} />

          <Stack spacing="xl">
            <Text
              c="gray.6"
              fz="lg"
              ta="center"
              data-testid="what-is-customer-name-title"
            >
              What is the customer’s name?
            </Text>

            <TextInput
              required
              data-autofocus
              placeholder="Customer's name"
              {...form.getInputProps('customerName')}
              data-testid="customer-name-input"
            />
          </Stack>
        </Stack>

        <ModalFooter position="right" pt="xxl">
          <Button variant="default" onClick={closeMe}>
            Cancel
          </Button>

          <Button
            type="submit"
            disabled={!form.values.customerName.trim()}
            data-testid="create-customer-button"
            loading={isLoading}
          >
            Create customer
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
