import {
  Box,
  Center,
  createStyles,
  MantineColor,
  Tooltip,
} from '@mantine/core';
import React from 'react';

export interface MockChildDeviceLocationMarkerProps {
  lat?: number;
  lng?: number;
  color?: MantineColor;
  tooltipLabel?: string;
}

export function MockChildDeviceLocationMapMarker({
  color,
  tooltipLabel,
}: MockChildDeviceLocationMarkerProps) {
  const { classes } = useStyles();

  return (
    <Tooltip label={tooltipLabel} withArrow>
      <Center className={classes.childWrapper}>
        <Box className={classes.child} bg={color || 'gray.5'} />
      </Center>
    </Tooltip>
  );
}

const useStyles = createStyles((theme) => ({
  childWrapper: {
    cursor: 'default',
    width: 18,
    height: 18,
    backgroundColor: 'white',
    borderRadius: '50%',
    boxShadow: theme.shadows.md,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.colors.blue_gray[1],
    position: 'relative',
    zIndex: 200,
  },
  child: {
    width: 12,
    height: 12,
    borderRadius: '50%',
  },
}));
