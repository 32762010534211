import { LoadingOverlay } from '@mantine/core';
import React, { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

import { usePortalConfig } from '@portals/api/organizations';
import { Responsive } from '@portals/core';
import { Enable2FANotification } from '@portals/framework';

import { AuthDesktopLayout } from './AuthDesktopLayout';
import { OrganizationDashboardLayout } from './OrganizationDashboardLayout';
import {
  AuthMobileLayout,
  DashboardMobileLayout,
} from '../../../mobile/layouts/MobileLayout';

interface LayoutProps {
  children: ReactNode;
}

export function ResponsiveDashboardLayout({ children }: LayoutProps) {
  return (
    <Responsive>
      <Responsive.Desktop>
        <Enable2FANotification />

        <OrganizationDashboardLayout>{children}</OrganizationDashboardLayout>
      </Responsive.Desktop>

      <Responsive.Mobile>
        <DashboardMobileLayout>{children}</DashboardMobileLayout>
      </Responsive.Mobile>
    </Responsive>
  );
}

export function ResponsiveAuthLayout({ children }: LayoutProps) {
  const location = useLocation();
  const portalConfig = usePortalConfig();

  if (!portalConfig.data) {
    return <LoadingOverlay visible />;
  }

  const { custom_domain } = portalConfig.data;

  if (custom_domain && custom_domain !== window.location.origin) {
    const url = new URL(custom_domain);

    url.search = location.search;

    window.location.replace(url);

    return null;
  }

  return (
    <Responsive>
      <Responsive.Desktop>
        <AuthDesktopLayout>{children}</AuthDesktopLayout>
      </Responsive.Desktop>

      <Responsive.Mobile>
        <AuthMobileLayout>{children}</AuthMobileLayout>
      </Responsive.Mobile>
    </Responsive>
  );
}
