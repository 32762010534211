import { SimpleGrid } from '@mantine/core';
import { sum } from 'lodash/fp';
import React, { FC } from 'react';

import { DeviceType, useDeviceCommands } from '@portals/api/organizations';
import { KpiWidget } from '@portals/core';
import { getDeviceStatusMetadata } from '@portals/framework/route-modals';
import { ReactComponent as Danger } from '@portals/icons/bulk/danger.svg';
import { PaginatedFilterTypeEnum } from '@portals/types';

import { ReactComponent as Code } from '../../../../../../assets/img/icons/code.svg';
import { ReactComponent as Flash } from '../../../../../../assets/img/icons/flash.svg';

interface OverviewRowProps {
  device: DeviceType;
}

export const OverviewRow: FC<OverviewRowProps> = ({ device }) => {
  const deviceStatusMetadata = getDeviceStatusMetadata(device.status);
  const deviceCommands = useDeviceCommands(device.id, {
    pagination: {
      page: 0,
      pageSize: 1,
    },
    filters: [
      {
        id: 'status',
        value: 'pending',
        type: PaginatedFilterTypeEnum.Eq,
      },
    ],
  });

  const numOfPendingCommands = deviceCommands.data?.page_info?.total_count || 0;

  return (
    <SimpleGrid cols={3}>
      <KpiWidget
        kpiWidget={{
          title: 'Status',
          avatarColor: deviceStatusMetadata.baseColor,
          label: deviceStatusMetadata.displayName,
          icon: <Flash />,
        }}
      />

      <KpiWidget
        kpiWidget={{
          title: 'Active Incidents',
          avatarColor: 'red',
          label: sum(device?.incidents),
          icon: <Danger />,
        }}
      />

      <KpiWidget
        kpiWidget={{
          title: 'Pending Commands',
          avatarColor: 'indigo_accent',
          label: numOfPendingCommands,
          icon: <Code />,
        }}
      />
    </SimpleGrid>
  );
};
