import {
  Box,
  createStyles,
  Group,
  Stack,
  StackProps,
  Text,
  Tooltip,
} from '@mantine/core';
import GoogleMapReact, { Coords } from 'google-map-react';
import React, {
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';

import { DeviceStatusType, MapCoordinatesType } from '@portals/types';

import {
  DEFAULT_ZOOM,
  MAP_INITIAL_BOUNDS,
  MAP_STYLE,
  MAX_ZOOM,
} from './device-location-widget.constants';
import { useDeviceLocationMapStateInLocalStorage } from './device-location-widget.hooks';
import {
  GoogleMapsType,
  GoogleMapType,
  MapFeature,
  SuperClusterPoint,
} from './device-location-widget.types';
import { createClusterMap } from './device-location-widget.utils';
import { DeviceLocationCluster } from './DeviceLocationCluster';
import { OfflineDeviceStateTooltip } from '../../common/OfflineDeviceStateTooltip';
import { DeviceLocationType } from '../../widgets.constants';
import { WidgetColorType } from '../../widgets.types';

export interface DeviceMapConfig {
  id: string;
  type: DeviceLocationType;
  coordinates: Coords | null;
  name: string;
  status: DeviceStatusType | undefined;
  renderSinglePoint: () => ReactNode;
}

export interface DeviceLocationWidgetProps {
  mapWidgetId?: string;
  title: string;
  showLocationName: boolean;
  parentDeviceMarkerColor: WidgetColorType;

  stackProps?: StackProps;

  isDeviceOffline?: boolean;
  lastUpdateTimestamp?: string;

  points: Array<SuperClusterPoint>;
  base: MapCoordinatesType;
}

const MAP_OPTIONS = {
  maxZoom: MAX_ZOOM,
  styles: MAP_STYLE,
};

export function DeviceLocationWidget({
  mapWidgetId,
  points,
  base,
  stackProps,
  isDeviceOffline,
  lastUpdateTimestamp,
}: DeviceLocationWidgetProps) {
  const { classes, theme } = useStyles();

  const [map, setMap] = useState<GoogleMapType | null>(null);
  const [maps, setMaps] = useState<GoogleMapsType | null>(null);

  const {
    currentMapState,
    setCurrentMapState,
    zoomToCluster,
    zoomToAllPoints,
  } = useDeviceLocationMapStateInLocalStorage(mapWidgetId);

  const googleMapDefaultValues = useRef({
    zoom: currentMapState?.zoom || DEFAULT_ZOOM - 2,
    center: {
      lng: currentMapState
        ? currentMapState.center.lng
        : points[0]?.geometry.coordinates[0] ?? base.lng,
      lat: currentMapState
        ? currentMapState.center.lat
        : points[0]?.geometry.coordinates[1] ?? base.lat,
    },
  });

  const [clusters, superCluster] = useMemo(() => {
    const bounds = currentMapState
      ? currentMapState.bounds
      : MAP_INITIAL_BOUNDS;

    return createClusterMap(
      bounds,
      points,
      currentMapState?.zoom || DEFAULT_ZOOM
    );
  }, [points, currentMapState]);

  const handleMapLoaded = useCallback(
    ({ map, maps }: { map: GoogleMapType; maps: GoogleMapsType }) => {
      if (currentMapState?.isInitialMapData) {
        zoomToAllPoints({ map, maps, points });
      }

      setMap(map);
      setMaps(maps);
    },
    [currentMapState, zoomToAllPoints, points]
  );

  const handleClusterClick = useCallback(
    ({
      cluster,
      map,
      maps,
    }: {
      cluster: MapFeature;
      map: GoogleMapType;
      maps: GoogleMapsType;
    }) => {
      if (!superCluster) return;

      zoomToCluster({ cluster, map, maps, superCluster });
    },
    [zoomToCluster, superCluster]
  );

  if (!points?.length) {
    return <DeviceLocationEmptyState stackProps={stackProps} />;
  }

  return (
    <Stack
      className={classes.container}
      p="xl"
      spacing="lg"
      pos="relative"
      h="100%"
      w="100%"
      bg="white"
      justify="center"
      {...stackProps}
    >
      {isDeviceOffline ? (
        <OfflineDeviceStateTooltip lastUpdateTimestamp={lastUpdateTimestamp} />
      ) : null}

      <Box
        w="100%"
        h="100%"
        sx={{
          borderRadius: theme.radius.md,
          overflow: 'hidden',
        }}
      >
        <GoogleMapReact
          bootstrapURLKeys={{
            key: process.env.NX_GOOGLE_KEY || 'MISSING_GOOGLE_MAPS_API_KEY',
          }}
          defaultZoom={googleMapDefaultValues.current?.zoom}
          defaultCenter={googleMapDefaultValues.current?.center}
          yesIWantToUseGoogleMapApiInternals
          options={MAP_OPTIONS}
          onGoogleApiLoaded={handleMapLoaded}
          onChange={setCurrentMapState}
        >
          {clusters.map((item) => {
            const [longitude, latitude] = item.geometry.coordinates;

            return item.properties?.cluster ? (
              <DeviceLocationCluster
                key={item.id}
                item={item}
                superCluster={superCluster}
                onClick={(cluster) => {
                  if (!map || !maps) return;

                  handleClusterClick({ cluster, map, maps });
                }}
                zoom={currentMapState?.zoom || DEFAULT_ZOOM}
                lat={latitude}
                lng={longitude}
              />
            ) : (
              item.properties?.['renderSinglePoint']()
            );
          })}
        </GoogleMapReact>
      </Box>
    </Stack>
  );
}

interface DeviceLocationEmptyStateProps {
  stackProps?: StackProps;
}

export function DeviceLocationEmptyState({
  stackProps = {},
}: DeviceLocationEmptyStateProps) {
  const { classes } = useStyles();

  return (
    <Stack
      className={classes.container}
      p="xl"
      spacing="lg"
      pos="relative"
      h="100%"
      w="100%"
      bg="white"
      justify="center"
      {...stackProps}
    >
      <Group
        bg="gray.2"
        grow
        sx={{
          width: '100%',
          height: '100%',
        }}
      >
        <Tooltip
          label={<Text size="xs">Unknown location</Text>}
          px="sm"
          withinPortal={false}
          opened={true}
          withArrow
          bg="blue_gray.9"
          arrowSize={8}
        >
          <Box mt="xl" />
        </Tooltip>
      </Group>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    borderRadius: theme.radius.lg,
    flex: 1,
  },
}));
