import { Button, LoadingOverlay } from '@mantine/core';
import { useForm } from '@mantine/form';
import React from 'react';

import { useAddInternalUsers, useGroup } from '@portals/api/organizations';
import { useUsers } from '@portals/api/ui';
import { MembersSVG } from '@portals/assets';
import {
  ModalBody,
  ModalCenteredMediaLayout,
  ModalFooter,
} from '@portals/core';
import { ModalProps, UsersMultiSelect } from '@portals/framework';

export interface AddInternalUsersToExistingGroupModalProps
  extends ModalProps<{
    groupId: string;
  }> {}

export function AddInternalUsersToExistingGroupModal({
  closeMe,
  data: { groupId },
}: AddInternalUsersToExistingGroupModalProps) {
  const users = useUsers();
  const group = useGroup(groupId);
  const addInternalUsers = useAddInternalUsers();

  const form = useForm<{ userIds: string[] }>({
    initialValues: {
      userIds: [],
    },
  });

  const options = composeGroupUserOptions(users.data, group.data);

  const onSubmit = async (values: typeof form.values) => {
    try {
      await addInternalUsers.mutateAsync({
        groupId,
        userIds: values.userIds,
      });

      closeMe();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ModalCenteredMediaLayout
      opened
      onClose={closeMe}
      title="Add Internal Members"
      media={<MembersSVG />}
    >
      <LoadingOverlay visible={users.isFetching || group.isFetching} />

      <form noValidate onSubmit={form.onSubmit(onSubmit)}>
        <ModalBody>
          <UsersMultiSelect data={options} {...form.getInputProps('userIds')} />
        </ModalBody>

        <ModalFooter grow>
          <Button variant="default" onClick={closeMe}>
            Cancel
          </Button>
          <Button type="submit" disabled={form.values.userIds.length === 0}>
            Add Member
          </Button>
        </ModalFooter>
      </form>
    </ModalCenteredMediaLayout>
  );
}

function composeGroupUserOptions(
  users: ReturnType<typeof useUsers>['data'],
  group: ReturnType<typeof useGroup>['data']
) {
  if (!users || !group) return [];

  const groupUserIds = (group.users ?? []).map((user) => user.id);

  return users
    .filter((user) => !groupUserIds.includes(user.id))
    .map((user) => ({
      value: user.id,
      label: user.name,
      email: user.email,
    }));
}
