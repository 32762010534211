import {
  LoadingOverlay,
  SegmentedControl,
  Switch,
  Tooltip,
} from '@mantine/core';
import classNames from 'classnames';
import { get, isUndefined, noop } from 'lodash/fp';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import {
  IncidentRoutingConfigType,
  SpaceType,
} from '@portals/api/organizations';
import { EntityAccess } from '@portals/framework/EntityAccess';
import { AccessLevelEnum } from '@portals/types';
import { getStyledThemeColor } from '@portals/utils';

import { ServiceCardType, ServiceStateEnum } from './incident-routing.types';

interface ServiceCardProps extends ServiceCardType {
  disabled?: boolean;
  isInherited: boolean;
  isRoot: boolean;
  space: SpaceType;
  incidentRoutingConfig: IncidentRoutingConfigType;
  parentId: string;
  onToggleSubEvent: (
    mainEventId: string,
    subEventId: string,
    isToggled: boolean
  ) => Promise<void>;
  onToggleSubInheritance: (
    parentId: string,
    serviceId: string,
    isInherit: boolean
  ) => Promise<void>;
  onSubEventSubmit: (
    parentId: string,
    serviceId: string,
    data: Record<string, any>
  ) => Promise<void>;
}

export function ServiceCard({
  name,
  id,
  component: Component,
  incidentRoutingConfig,
  disabled = false,
  isRoot,
  isInherited,
  parentId,
  logoSrc,
  space,

  onToggleSubEvent,
  onToggleSubInheritance,
  onSubEventSubmit,
}: ServiceCardProps) {
  const [isLoading, setIsLoading] = useState(false);

  const isLocalInherited = useMemo(() => {
    if (isRoot) return false;

    const inheritance = incidentRoutingConfig?.[`inherit_${parentId}.${id}`];

    return isUndefined(inheritance) || inheritance;
  }, [id, isRoot, parentId, incidentRoutingConfig]);

  const isServiceOn = Boolean(incidentRoutingConfig.tree?.[parentId]?.[id]);

  const onChangeWrapper = async (asyncCb: Promise<void>) => {
    setIsLoading(true);

    try {
      await asyncCb;
    } catch (err) {
      console.error(err);
    }

    setIsLoading(false);
  };

  return (
    <Container
      className={classNames('service-card', {
        disabled: !isServiceOn,
      })}
      key={name}
    >
      <LoadingOverlay visible={isLoading} />

      <div className="actions">
        <Tooltip
          disabled={!isInherited}
          label="Settings are inherited from parent area"
        >
          <div className="d-inline-block d-flex justify-content-end">
            <Tooltip
              disabled={!isInherited}
              label="Settings are inherited from parent area"
            >
              <div>
                <EntityAccess
                  id="editEvents"
                  minLevel={AccessLevelEnum.Admin}
                  entity={space}
                >
                  <SegmentedControl
                    size="xs"
                    radius="sm"
                    disabled={isInherited || disabled}
                    value={
                      isServiceOn ? ServiceStateEnum.On : ServiceStateEnum.Off
                    }
                    onChange={(value: ServiceStateEnum) =>
                      onChangeWrapper(
                        onToggleSubEvent(
                          parentId,
                          id,
                          value === ServiceStateEnum.On
                        )
                      )
                    }
                    data={[
                      { label: 'Off', value: ServiceStateEnum.Off },
                      { label: 'On', value: ServiceStateEnum.On },
                    ]}
                  />
                </EntityAccess>
              </div>
            </Tooltip>
          </div>
        </Tooltip>
      </div>

      <div className="header">
        <div className="service">
          <img src={logoSrc} className="service-logo" />

          <div className="service-name">{name}</div>
        </div>

        {!isRoot && !isInherited ? (
          <Tooltip
            withArrow
            label={
              isInherited
                ? 'Settings are inherited from parent area'
                : 'Turn ON to adjust settings'
            }
          >
            <Switch
              label="Inherit settings"
              disabled={isInherited || !isServiceOn}
              checked={isLocalInherited}
              onChange={(event) =>
                onChangeWrapper(
                  onToggleSubInheritance(parentId, id, event.target.checked)
                )
              }
            />
          </Tooltip>
        ) : null}
      </div>

      <div className="config">
        <Component
          data={get([`${parentId}.${id}`], incidentRoutingConfig) || {}}
          onChange={noop}
          onSubmit={(formData) =>
            onChangeWrapper(onSubEventSubmit(parentId, id, formData))
          }
          readOnly={isInherited || isLocalInherited}
        />
      </div>
    </Container>
  );
}

const Container = styled.div`
  transform: translateY(50px);
  padding: 15px 20px 25px;
  box-shadow: 0 0 0 1px ${getStyledThemeColor('gray300')};
  border-radius: 12px 0 12px 12px;
  display: flex;
  flex-direction: column;
  height: 250px;
  justify-content: space-between;
  position: relative;
  transition: all 0.1s ease-in-out;
  background-color: ${getStyledThemeColor('white')};

  &.disabled {
    filter: grayscale(1);

    .config {
      cursor: not-allowed;
      opacity: 0.5;

      a,
      input {
        pointer-events: none;
      }
    }
  }

  &:not(.disabled) {
    box-shadow: 0 0 0 1px ${getStyledThemeColor('primary')};

    .actions {
      border-color: ${getStyledThemeColor('primary')};
    }
  }

  .actions {
    position: absolute;
    top: 0;
    right: -1px;
    width: 135px;
    height: 40px;
    background-color: ${getStyledThemeColor('white')};
    border: 1px solid ${getStyledThemeColor('gray300')};
    transform: translateY(-100%);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 15px;
    border-radius: 12px 12px 0 0;
    transition: border 0.15s ease-in-out;

    .button-group-container {
      padding: 3px 0;
      background-color: ${getStyledThemeColor('gray300')};

      .button-group-option {
        padding: 0 15px;
      }
    }
  }

  .header {
    border-bottom: 1px solid ${getStyledThemeColor('gray200')};
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .service {
      display: flex;
      align-items: center;
      margin-right: 10px;

      .service-logo {
        height: 30px;
        max-width: 80%;
      }

      .service-name {
        color: ${getStyledThemeColor('dark')};
        font-weight: bold;
        margin-left: 10px;
      }
    }

    .custom-switch {
      margin: 0 !important;
      padding: 0 !important;
      border: none !important;
    }
  }

  .config {
    margin-top: 5px;
    flex: 1;
    display: flex;

    form {
      width: 100%;
      display: grid;
      grid-template-rows: 1fr max-content;

      input,
      select {
        height: 45px;
      }
    }

    .modal-body,
    .modal-footer {
      padding: 0;
      margin: 0;
    }

    .modal-footer {
      border-top: none;

      > div {
        margin: 0;

        .btn {
          &:last-of-type {
            margin-right: 0 !important;
          }
        }
      }
    }

    .modal-body {
      display: flex;
      align-items: center;

      > div {
        width: 100%;
        margin-bottom: 0;
      }
    }
  }
`;

export default ServiceCard;
