import {
  compact,
  filter,
  get,
  includes,
  isEmpty,
  isNil,
  isUndefined,
  map,
  toNumber,
  toString,
} from 'lodash/fp';
import React from 'react';
import { DateUtils } from 'react-day-picker';
import {
  FilterProps,
  FilterValue,
  UseFiltersColumnProps,
  UseGroupByRowProps,
} from 'react-table';

import {
  TableColumn,
  TableFilterType,
  TableFilterTypeEnum,
} from '@portals/types';

import {
  DateFilter,
  NumberFilter,
  SelectFilter,
  SingleSelectFilter,
  TextFilter,
} from './components/Filters';
import BooleanFilter from './components/Filters/BooleanFilter';

type FilterRendererProps = {
  column: FilterProps<any>['column'] & UseFiltersColumnProps<any>;
};

const { isDate, isSameDay, isDayAfter, isDayBefore } = DateUtils;

export const getFilter = <TData extends object>(
  dataField: keyof TData,
  filter?: TableFilterType
) => {
  if (!filter) return () => null;

  const { type } = filter;

  switch (type) {
    case TableFilterTypeEnum.Number:
      return getFilteredByNum(dataField);

    case TableFilterTypeEnum.Date:
      return getFilteredByDate(dataField);

    case TableFilterTypeEnum.Select:
      return getFilteredBySelect(dataField);

    case TableFilterTypeEnum.Boolean:
    case TableFilterTypeEnum.SingleSelect:
    case TableFilterTypeEnum.Text:
    default:
      return 'text';
  }
};

export const getSortType = (filter?: TableFilterType) => {
  if (filter?.type === TableFilterTypeEnum.Date)
    return (rowA, rowB, dataField) => {
      const rowADate = get(['original', dataField], rowA);
      const rowBDate = get(['original', dataField], rowB);

      return (
        new Date(rowADate || null).getTime() -
        new Date(rowBDate || null).getTime()
      );
    };

  if (filter?.type === TableFilterTypeEnum.Text)
    return (rowA, rowB, dataField) => {
      const rowAValue = get(['original', dataField], rowA);
      const rowBValue = get(['original', dataField], rowB);

      if (rowAValue?.localeCompare) {
        return rowAValue.localeCompare?.(rowBValue);
      } else {
        return 'alphanumeric';
      }
    };

  return 'alphanumeric';
};

export const getFilterRenderer = (filter?: TableFilterType) => {
  if (!filter) return () => null;

  const { type, hidden } = filter;

  if (hidden) return () => null;

  switch (type) {
    case TableFilterTypeEnum.Boolean:
      return ({ column }: FilterRendererProps) => (
        <BooleanFilter
          column={column}
          placeholder={filter?.placeholder}
          options={filter?.options}
        />
      );

    case TableFilterTypeEnum.Select:
      return ({ column }: FilterRendererProps) => (
        <SelectFilter
          column={column}
          placeholder={filter?.placeholder}
          options={filter?.options}
        />
      );

    case TableFilterTypeEnum.SingleSelect:
      return ({ column }: FilterRendererProps) => (
        <SingleSelectFilter
          column={column}
          placeholder={filter?.placeholder}
          options={filter?.options}
        />
      );

    case TableFilterTypeEnum.Number:
      return ({ column }: FilterRendererProps) => (
        <NumberFilter column={column} />
      );

    case TableFilterTypeEnum.Date:
      return ({ column }: FilterRendererProps) => (
        <DateFilter column={column} />
      );

    case TableFilterTypeEnum.Text:
      return ({ column }: FilterRendererProps) => (
        <TextFilter column={column} placeholder={filter?.placeholder} />
      );

    default:
      return null;
  }
};

export const getFilteredBySelect =
  <TData extends object>(dataField: keyof TData) =>
  (
    rows: Array<UseGroupByRowProps<any>>,
    id: string,
    filterValue?: FilterValue
  ) => {
    if (isEmpty(filterValue)) return rows;

    return filter((row) => {
      const cellValue = toString(get(['values', dataField], row));

      return includes(cellValue, filterValue);
    }, rows);
  };

export const getFilteredByNum =
  <TData extends object>(dataField: keyof TData) =>
  (
    rows: Array<UseGroupByRowProps<any>>,
    id: string,
    filterValue?: FilterValue
  ) => {
    const gte = filterValue?.gte;
    const lte = filterValue?.lte;

    if (!lte && !gte) return rows;

    const adjustedGte = gte ? toNumber(gte) : undefined;
    const adjustedLte = lte ? toNumber(lte) : undefined;

    const hasGte = !isUndefined(adjustedGte);
    const hasLte = !isUndefined(adjustedLte);

    return filter((row) => {
      const cellValue = get(['values', dataField], row);

      if (hasGte && hasLte) {
        return cellValue >= adjustedGte && cellValue <= adjustedLte;
      } else if (hasGte) {
        return cellValue >= adjustedGte;
      } else {
        return cellValue <= adjustedLte;
      }
    }, rows);
  };

export const getFilteredByDate =
  <TData extends object>(dataField: keyof TData) =>
  (
    rows: Array<UseGroupByRowProps<any>>,
    id: string,
    filterValue?: FilterValue
  ) => {
    const gte = filterValue?.gte;
    const lte = filterValue?.lte;

    if (!lte && !gte) return rows;

    const hasGte = !isNil(gte);
    const hasLte = !isNil(lte);

    return filter((row) => {
      const cellValue = new Date(get(['values', dataField], row));

      if (!isDate(cellValue)) return false;

      if (hasGte && hasLte) {
        return (
          (isSameDay(cellValue, gte) || isDayAfter(cellValue, gte)) &&
          (isSameDay(cellValue, lte) || isDayBefore(cellValue, lte))
        );
      } else if (hasGte) {
        return isSameDay(cellValue, gte) || isDayAfter(cellValue, gte);
      } else {
        return isSameDay(cellValue, lte) || isDayBefore(cellValue, lte);
      }
    }, rows);
  };

export const getDefaultHiddenColumns = (
  columns: Array<Record<string, unknown>>
) => {
  if (!columns) return [];

  return compact(
    map(
      ({ dataField, hidden }: { dataField: string; hidden?: boolean }) =>
        hidden ? dataField : null,
      columns
    )
  );
};

export function hasStickyColumn<TData extends object>(
  columns: Array<TableColumn<TData>>
) {
  return columns.some((column) => column.isSticky);
}
