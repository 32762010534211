import {
  Checkbox,
  createStyles,
  Group,
  LoadingOverlay,
  Stack,
  Text,
} from '@mantine/core';
import React from 'react';

import { DeviceType, useSpace } from '@portals/api/organizations';
import {
  DeviceAvatar,
  DeviceCecPartnerLogoOrName,
  DeviceStatusBadge,
} from '@portals/framework';
import {
  getDeviceName,
  useOpenRouteModal,
} from '@portals/framework/route-modals';
import { timeFromNow } from '@portals/utils';

import { useDeviceDrag } from '../../../../../hooks/device-cards';
import { canEdit } from '../../../../../lib/access';
import { DeviceIncidentsCounterWithIcon } from '../../../../components/DeviceCounters';
import { DeviceRelationsIndicator } from '../../device-indicators/DeviceRelationsIndicator';
import { DeviceSnoozeIndicator } from '../../device-indicators/DeviceSnoozeIndicator';
import { DeviceMenu } from '../../device-menu/DeviceMenu';
import { useOverviewDevicesContext } from '../OverviewDevicesProvider';

interface DeviceTileProps {
  device: DeviceType;
}

export function DeviceTile({ device }: DeviceTileProps) {
  const { classes, cx } = useStyles();

  const space = useSpace({ spaceId: device.space_id });

  const openRouteModal = useOpenRouteModal();

  const { dragRef, isLoading } = useDeviceDrag(device);

  const { selectedDeviceIds, toggleSelectedDeviceId } =
    useOverviewDevicesContext();

  return (
    <div
      ref={canEdit(space) ? dragRef : null}
      className={cx(classes.container, {
        [classes.error]: device.status === 'error',
      })}
    >
      <LoadingOverlay
        visible={isLoading}
        loaderProps={{ size: 'sm' }}
        radius="md"
      />

      <Checkbox
        checked={selectedDeviceIds.has(device.id)}
        onChange={() => toggleSelectedDeviceId(device.id)}
      />

      <div
        className={classes.middleContent}
        onClick={() =>
          openRouteModal({ modalId: 'device', pathParams: [device.id] })
        }
      >
        <Group spacing="xl" noWrap className={classes.overflow}>
          <Group noWrap>
            <DeviceCecPartnerLogoOrName
              device={device}
              imgProps={{ loading: 'lazy', width: 48 }}
              textProps={{ size: 'xs', w: 48, truncate: true }}
            />

            <DeviceAvatar
              src={device.image_url}
              icon={device.model_settings?.icon}
              size={48}
              radius="md"
            />
          </Group>

          <Stack spacing={4} className={classes.overflow}>
            <Text truncate>{getDeviceName(device.name)}</Text>

            <Text className={classes.deviceModel}>
              <Text truncate>{device.partner.model}</Text>

              {device.partner.sub_model && (
                <>
                  <span className={classes.centerDot} />
                  <Text truncate>{device.partner.sub_model}</Text>
                </>
              )}
            </Text>
          </Stack>
        </Group>

        <Group noWrap>
          <DeviceSnoozeIndicator device={device} />

          <DeviceRelationsIndicator device={device} />

          <DeviceIncidentsCounterWithIcon device={device} />

          <Text className={classes.lastSeen}>
            Last seen {timeFromNow(device.last_seen)}
          </Text>

          <Group position="right" w={105}>
            <DeviceStatusBadge status={device.status} dark />
          </Group>

          <DeviceMenu device={device} />
        </Group>
      </div>
    </div>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    position: 'relative',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing.md,
    paddingBlock: theme.spacing.xs,
    paddingInline: theme.spacing.xl,
    borderRadius: theme.radius.md,
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
    backgroundColor: theme.white,
    cursor: 'pointer',

    ...theme.fn.hover({
      boxShadow: theme.shadows.xs,
    }),
  },
  error: {
    border: `1px solid ${theme.colors.red[4]}`,
  },
  middleContent: {
    overflow: 'hidden',
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing.xl,
  },
  lastSeen: {
    fontSize: theme.fontSizes.xs,
    color: theme.colors.gray[5],
    whiteSpace: 'nowrap',
  },
  overflow: {
    overflow: 'hidden',
  },
  deviceModel: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 0,
    fontSize: theme.fontSizes.xs,
    color: theme.colors.gray[5],
  },
  centerDot: {
    display: 'block',
    flexShrink: 0,
    marginInline: '0.7ch',
    width: 5,
    height: 5,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}));
