import { MultiSelect, MultiSelectProps } from '@mantine/core';
import React, { useMemo } from 'react';

import { EntityLabelType, useEntityLabelsByCategory } from '@portals/api/ui';

interface EntityLabelMultiSelectProps extends Omit<MultiSelectProps, 'data'> {
  category: string;
}

export function EntityLabelMultiSelect({
  category,
  ...multiSelectProps
}: EntityLabelMultiSelectProps) {
  const entityLabels = useEntityLabelsByCategory(category);

  const options = useMemo(
    () => composeOptions(entityLabels.data),
    [entityLabels.data]
  );

  return (
    <MultiSelect data={options} clearable searchable {...multiSelectProps} />
  );
}

function composeOptions(entityLabels: EntityLabelType[] | undefined) {
  if (!entityLabels || entityLabels.length === 0) {
    return [];
  }

  return entityLabels.map((entityLabel) => ({
    label: entityLabel.name,
    value: entityLabel.id,
  }));
}
