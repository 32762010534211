import { createStyles, Stack, Text } from '@mantine/core';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { FileTypeIcon } from '@portals/core';
import { OrganizationFileResponseType } from '@portals/types';

interface SpaceFileCardProps {
  file: OrganizationFileResponseType;
}

export function SpaceFileCard({ file }: SpaceFileCardProps) {
  const styles = useStyles();
  const navigate = useNavigate();

  const onClick = () => navigate(`/files/${file.id}`);

  return (
    <Stack className={styles.classes.container} onClick={onClick}>
      <FileTypeIcon fileUrl={file.url} height={90} />

      <Text
        size="sm"
        className={styles.classes.name}
        truncate
        data-testid="dashboard-files"
      >
        {file.name}
      </Text>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    height: 161,
    cursor: 'pointer',
    padding: theme.spacing.xl,
    gap: theme.spacing.xs,
    alignItems: 'center',
  },
  name: {
    color: theme.colors.gray[8],
    textAlign: 'center',
    overflow: 'hidden',
    maxWidth: 100,
  },
}));
