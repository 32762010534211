import { Button, createStyles, Menu, Tooltip } from '@mantine/core';
import React, { useMemo } from 'react';

import { ReactComponent as FlashIcon } from '@portals/icons/linear/flash.svg';
import { ReactComponent as TrashIcon } from '@portals/icons/linear/trash.svg';
import { useOpenModal } from '@portals/redux';
import { SupportedCommandType } from '@portals/types';

import { useOverviewDevicesContext } from './OverviewDevicesProvider';
import { DeleteDeviceModalProps, RunCommandProps } from '../../../modals';
import { useDevicesCommonDeviceModel } from '../../devices/devices.hooks';
import { BulkActions } from '../bulk-actions/BulkActions';

export const DevicesBulkActions = React.memo(() => {
  const { classes } = useStyles();
  const openModal = useOpenModal();

  const { clearSelectedDeviceIds, selectedDeviceIds, allDevices } =
    useOverviewDevicesContext();

  const selectedDevices = useMemo(
    () => allDevices.filter((device) => selectedDeviceIds.has(device.id)),
    [allDevices, selectedDeviceIds]
  );

  const commonDeviceModel = useDevicesCommonDeviceModel(selectedDevices);

  function scheduleCommand(command: SupportedCommandType) {
    if (!commonDeviceModel) return;

    openModal<RunCommandProps['data']>('RunCommand', {
      deviceType: commonDeviceModel.id,
      command,
      deviceIds: Array.from(selectedDeviceIds),
      onSuccess: clearSelectedDeviceIds,
    });
  }

  function onDelete() {
    openModal<DeleteDeviceModalProps['data']>('DeleteDeviceModal', {
      devices: selectedDevices,
      onFinished: clearSelectedDeviceIds,
    });
  }

  return (
    <BulkActions
      selectedCount={selectedDeviceIds.size}
      onClearSelected={clearSelectedDeviceIds}
    >
      <BulkActions.Action>
        <Tooltip
          withArrow
          offset={10}
          label="Selected devices should share same model & vendor"
          disabled={commonDeviceModel !== undefined}
        >
          <div>
            <Menu>
              <Menu.Target>
                <Button
                  px="sm"
                  className={classes.commandsButton}
                  disabled={!commonDeviceModel}
                  leftIcon={<FlashIcon width={16} height={16} />}
                >
                  Commands
                </Button>
              </Menu.Target>

              <Menu.Dropdown>
                {(commonDeviceModel?.supported_commands || []).map(
                  (supportedCommand) => (
                    <Menu.Item
                      key={supportedCommand.id}
                      onClick={() => scheduleCommand(supportedCommand)}
                    >
                      {supportedCommand.friendly_name}
                    </Menu.Item>
                  )
                )}
              </Menu.Dropdown>
            </Menu>
          </div>
        </Tooltip>
      </BulkActions.Action>

      <BulkActions.Action>
        <Button
          px="sm"
          onClick={onDelete}
          leftIcon={<TrashIcon width={16} height={16} />}
        >
          Delete
        </Button>
      </BulkActions.Action>
    </BulkActions>
  );
});

const useStyles = createStyles({
  commandsButton: {
    '&[data-disabled]': {
      opacity: 0.5,
      backgroundColor: 'unset',
    },
  },
});
