import { Button, createStyles, LoadingOverlay } from '@mantine/core';
import { includes } from 'lodash/fp';
import React, { useEffect, useMemo, useRef } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import {
  GettingStartedToursNamesEnum,
  SpaceType,
  useCecPartners,
  useFeatureFlags,
  useOnboardingTours,
  useOrganizationConfig,
  usePortalCapabilities,
  usePortalConfig,
  useSpace,
  useSpaces,
} from '@portals/api/organizations';
import { FeatureNotificationEnum, useCurrentUser } from '@portals/api/ui';
import {
  isCustomerFeatureAll,
  isCustomerFeatureLabOnly,
  Page403,
  TOUR_STEPS_IDS,
  usePermissionAccess,
} from '@portals/framework';
import { useOpenRouteModal } from '@portals/framework/route-modals';
import { useOpenModal } from '@portals/redux';

import { ChatBubble } from './chat-bubble/ChatBubble';
import { WidgetsPanel } from './overview-dashboard/widgets-panel/WidgetsPanel';
import { OverviewHeader } from './overview-header/OverviewHeader';
import {
  useCurrentOverviewTab,
  useOverviewRouteParams,
  useOverviewRouting,
} from './overview-routing.hooks';
import { OverviewSettingsDrawer } from './overview-settings-drawer/OverviewSettingsDrawer';
import { OverviewSidebar } from './overview-sidebar/OverviewSidebar';
import { OverviewContextProvider } from './overview.context';
import { OverviewMainContent } from './OverviewMainContent';
import { canView, noAccess } from '../../../lib/access';
import {
  findAccessible,
  findRoot,
  findSpaceFirstChild,
} from '../../../lib/spaces';
import { ClaimDeviceTour } from '../../components/setup-tours/ClaimDeviceTour';
import { SetupSpacesTour } from '../../components/setup-tours/SetupSpacesTour';
import { OVERVIEW_TAB, OverviewTabType } from '../../routes';

export function Overview() {
  const spaces = useSpaces();
  const location = useLocation();
  const params = useOverviewRouteParams();
  const { classes } = useStyles();

  const overviewRouting = useOverviewRouting();
  const currentOverviewTab = useCurrentOverviewTab();

  const [searchParams] = useSearchParams();
  const initialTab = searchParams.get('tab');

  const onboardingTours = useOnboardingTours();
  const currentUser = useCurrentUser();
  const openModal = useOpenModal();
  const openRouteModal = useOpenRouteModal();
  const { isAdmin } = usePermissionAccess();
  const portalCapabilities = usePortalCapabilities();
  const organizationConfig = useOrganizationConfig();
  const portalConfig = usePortalConfig();
  const cecPartners = useCecPartners();
  const featureFlags = useFeatureFlags();

  const adminEmail = organizationConfig?.admin_user_email || '';

  const { rootSpace, accessibleNode } = useMemo(() => {
    return {
      rootSpace: findSpaceFirstChild({
        parentSpaceId: findRoot(spaces.data || [])?.id,
        spaces: spaces.data || [],
      }),
      accessibleNode: findAccessible(spaces.data || []),
    };
  }, [spaces.data]);
  const selected = useSpace({ spaceId: Number(params.spaceId) });

  const afterLoginRef = useRef(location.state?.['afterLogin']);
  const scrollableRef = useRef<HTMLDivElement | null>(null);

  const showWelcomeVideoModal =
    !isAdmin &&
    !currentUser.data?.settings.is_welcome_videos_played &&
    (isCustomerFeatureAll(portalCapabilities?.onboarding_video) ||
      isCustomerFeatureLabOnly(
        portalCapabilities?.onboarding_video,
        organizationConfig?.lab
      ));

  const hasConnectedCecPartners = cecPartners.data?.some(
    (cecPartner) => cecPartner.connected
  );

  const isTrialNotificationDismissed = includes(
    FeatureNotificationEnum.FourteenDaysTrial,
    currentUser?.data?.feature_notifications
  );

  const showCecPartnersModal =
    portalConfig.isFetched &&
    cecPartners.isFetched &&
    portalConfig.data?.cec_enabled &&
    !hasConnectedCecPartners &&
    isAdmin &&
    afterLoginRef.current &&
    !isTrialNotificationDismissed;

  useEffect(() => {
    if (showCecPartnersModal) {
      openRouteModal({ modalId: 'connect' });
    }

    if (showWelcomeVideoModal) {
      openModal('WelcomeVideoModal');
    }
  }, [openModal, openRouteModal, showCecPartnersModal, showWelcomeVideoModal]);

  useEffect(
    function redirectIfNoSelectedSpace() {
      function redirectToDashboard(spaceId: SpaceType['id']) {
        const initialOverviewTab: OverviewTabType =
          OVERVIEW_TAB[initialTab] || OVERVIEW_TAB.dashboard;

        overviewRouting.navigateToOverviewTab(
          { spaceId, overviewTab: initialOverviewTab },
          {
            search: location.search.replace(`tab=${initialTab}`, ''),
            replace: true,
          }
        );
      }

      function getSpaceIdToRedirectTo() {
        if (rootSpace && canView(rootSpace)) {
          return rootSpace.id;
        } else if (accessibleNode?.id) {
          return accessibleNode.id;
        }
      }

      if (selected && canView(selected) && !currentOverviewTab) {
        redirectToDashboard(selected.id);
      } else if ((!selected && !!spaces.data) || noAccess(selected)) {
        const spaceIdToRedirectTo = getSpaceIdToRedirectTo();

        if (spaceIdToRedirectTo) {
          redirectToDashboard(spaceIdToRedirectTo);
        }
      }
    },
    [
      accessibleNode?.id,
      currentOverviewTab,
      initialTab,
      location.search,
      overviewRouting,
      rootSpace,
      selected,
      spaces.data,
    ]
  );

  const getTourToStart = () => {
    const activeTour = searchParams.get('active_tour');

    if (activeTour === GettingStartedToursNamesEnum.SetupSpaces) {
      return <SetupSpacesTour />;
    } else if (
      activeTour === GettingStartedToursNamesEnum.ClaimDevice &&
      onboardingTours?.getting_started?.setup_spaces_completed
    ) {
      return <ClaimDeviceTour />;
    } else {
      return null;
    }
  };

  if (noAccess(rootSpace) && noAccess(selected) && noAccess(accessibleNode)) {
    return (
      <Page403
        action={
          <Button onClick={() => window.open(`mailto:${adminEmail}`, '_blank')}>
            Contact Admin
          </Button>
        }
      />
    );
  }

  if (!selected) return <LoadingOverlay visible />;

  return (
    <OverviewContextProvider scrollableRef={scrollableRef}>
      {getTourToStart()}

      <div className={classes.container}>
        <aside className={classes.sidebar}>
          <OverviewSidebar />
        </aside>

        <div
          ref={scrollableRef}
          className={classes.content}
          data-tour-step-id={TOUR_STEPS_IDS.overview}
        >
          <OverviewHeader />

          <div className={classes.mainContentWrapper}>
            <OverviewMainContent />
          </div>
        </div>

        <OverviewSettingsDrawer />
        <WidgetsPanel />
      </div>

      {featureFlags?.ai_chatbot_demo ? <ChatBubble /> : null}
    </OverviewContextProvider>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    overflow: 'hidden',
    display: 'grid',
    gridTemplateColumns: '300px 1fr',
    width: '100%',
    height: '100%',
    backgroundColor: theme.colors.gray[0],
  },
  sidebar: {
    background: theme.white,
    borderRight: `1px solid ${theme.colors.gray[2]}`,
  },
  content: {
    overflowY: 'auto',
    overflowX: 'hidden',
    display: 'grid',
    gridTemplateRows: 'max-content 1fr',
  },
  mainContentWrapper: {
    paddingBlock: theme.spacing.xl,
  },
}));
