import { createEmotionCache, MantineProvider } from '@mantine/core';
import React from 'react';

import { RandomColorsSeedProvider } from './RandomColorsSeedProvider';
import { THEME } from './theme';

const emotionCache = createEmotionCache({
  key: 'mantine',
  prefix: false,
  stylisPlugins: [],
});

export function MantineThemeProvider({
  children,
  ...mantineProviderProps
}: React.ComponentProps<typeof MantineProvider>) {
  return (
    <MantineProvider
      emotionCache={emotionCache}
      theme={THEME}
      {...mantineProviderProps}
    >
      <RandomColorsSeedProvider>{children}</RandomColorsSeedProvider>
    </MantineProvider>
  );
}
