import { LoadingOverlay } from '@mantine/core';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import { useSearchParam } from 'react-use';

import {
  useSendMessage,
  useTicket,
  useUpdateSeen,
  useUpdateTicket,
} from '@portals/api/organizations';
import { DetailsPanel } from '@portals/core';
import { useOpenRouteModal } from '@portals/framework/route-modals';
import { ReactComponent as Danger } from '@portals/icons/linear/danger.svg';
import { ReactComponent as TickSquare } from '@portals/icons/linear/tick-square.svg';
import { TenantType } from '@portals/types';
import { Chat } from '@portals/ui';

import { TicketDeviceDetailsList } from './TicketDeviceDetailsList';
import { TicketInfoDetailsList } from './TicketInfoDetailsList';

interface TicketDetailsPanelProps {
  ticketId: string;
  showOpenDeviceButton?: boolean;
  onClose: () => void;
  chatWrapperProps?: React.ComponentProps<typeof motion.div>;
}

export function TicketDetailsPanel({
  ticketId,
  showOpenDeviceButton = true,
  onClose,
  chatWrapperProps,
}: TicketDetailsPanelProps) {
  const openRouteModal = useOpenRouteModal();

  const chatOpen = useSearchParam('chat_open');

  const [isChatOpen, setIsChatOpen] = useState(chatOpen === 'true');

  const ticket = useTicket(ticketId);
  const updateTicket = useUpdateTicket(ticketId);
  const sendMessage = useSendMessage(ticketId);
  const updateSeen = useUpdateSeen(ticketId);

  const onSendMessage = async (message: string) => {
    try {
      await sendMessage.mutateAsync(message);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const onMarkResolved = async () => {
    try {
      await updateTicket.mutateAsync({ status: 'resolved' });

      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <AnimatePresence initial={false}>
        {isChatOpen && (
          <motion.div
            initial={{ x: '100%' }}
            animate={{ x: 0 }}
            exit={{ x: '100%' }}
            transition={{ type: 'linear' }}
            {...chatWrapperProps}
          >
            <Chat
              autoFocusOnInput
              title={`Chat with ${ticket.data?.partner_display_name}`}
              messages={ticket.data?.chat || []}
              sendMessage={onSendMessage}
              seen={!!ticket.data?.org_seen}
              updateSeen={(seen) => updateSeen.mutate(seen)}
              pollingFn={ticket.refetch}
              chatOwner={TenantType.Organization}
              onClose={() => setIsChatOpen(false)}
            />
          </motion.div>
        )}
      </AnimatePresence>

      <DetailsPanel pos="relative" bg="white">
        <LoadingOverlay visible={ticket.isFetching} />

        <DetailsPanel.Header title={ticket.data?.title} onClose={onClose}>
          <DetailsPanel.Actions>
            <DetailsPanel.ActionButton
              onClick={() => setIsChatOpen((prev) => !prev)}
              data-testid="open-chat-button"
            >
              {isChatOpen ? 'Close Chat' : 'Open Chat'}
            </DetailsPanel.ActionButton>

            {showOpenDeviceButton && (
              <DetailsPanel.ActionButton
                onClick={() => {
                  if (!ticket.data?.device?.id) return;

                  openRouteModal({
                    modalId: 'device',
                    pathParams: [ticket.data?.device?.id],
                  });
                }}
                data-testid="see-device-button"
              >
                See Device
              </DetailsPanel.ActionButton>
            )}
          </DetailsPanel.Actions>
        </DetailsPanel.Header>

        <DetailsPanel.Body>
          <DetailsPanel.Section
            title="Description"
            content={ticket.data?.description}
          />

          <TicketInfoDetailsList ticket={ticket.data} />

          <TicketDeviceDetailsList ticket={ticket.data} />
        </DetailsPanel.Body>

        <DetailsPanel.Footer>
          <DetailsPanel.Actions mt="auto">
            <DetailsPanel.ActionButton
              disabled={ticket.data?.status === 'resolved'}
              variant="default"
              leftIcon={<TickSquare width={16} height={16} />}
              loading={updateTicket.isLoading}
              onClick={onMarkResolved}
              data-testid="mark-resolved-button"
            >
              Mark Resolved
            </DetailsPanel.ActionButton>

            {ticket.data?.status === 'review' && (
              <DetailsPanel.ActionButton
                variant="default"
                leftIcon={<Danger width={16} height={16} />}
                loading={updateTicket.isLoading}
                onClick={() => updateTicket.mutateAsync({ status: 'open' })}
                data-testid="mark-not-resolved-button"
              >
                Mark Not Resolved
              </DetailsPanel.ActionButton>
            )}
          </DetailsPanel.Actions>
        </DetailsPanel.Footer>
      </DetailsPanel>
    </>
  );
}
