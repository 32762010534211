import { Button, Modal, Stack, TextInput } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import React from 'react';
import { object, string } from 'yup';

import { SpaceType, useCreateSpaceFile } from '@portals/api/organizations';
import { ModalFooter } from '@portals/core';
import { ModalProps } from '@portals/framework';

import { FileUploadSwitcher } from './FileUploadSwitcher';

interface SpaceFileUploadFormValues {
  name: string;
  desc: string;
  file_type: string;
  fileUrl: string;
}

const schema = object({
  fileUrl: string().url('Invalid URL').required('File is required'),
  name: string().required('Name is required'),
  desc: string(),
  file_type: string(),
});

export function SpaceFileUploader({
  data,
  closeMe,
}: ModalProps<{ space: SpaceType }>) {
  const spaceId = data.space.id;
  const createSpaceFile = useCreateSpaceFile();

  const form = useForm<SpaceFileUploadFormValues>({
    initialValues: {
      name: '',
      desc: '',
      file_type: '',
      fileUrl: '',
    },
    validate: yupResolver(schema),
  });

  const onSubmit = (fileData: typeof form.values) => {
    createSpaceFile.mutate(
      {
        spaceId,
        fileData: {
          file_type: fileData.file_type,
          desc: fileData.desc,
          name: fileData.name,
          url: fileData.fileUrl,
        },
      },
      {
        onSuccess: () => {
          closeMe();
        },
      }
    );
  };

  return (
    <Modal opened onClose={closeMe} title="Upload new file" padding="xxl">
      <form noValidate onSubmit={form.onSubmit(onSubmit)}>
        <Stack spacing="xl">
          <FileUploadSwitcher
            onChangeFileUrl={(fileUrl) =>
              form.setFieldValue('fileUrl', fileUrl)
            }
            fileUrlError={form.errors.fileUrl}
          />

          <TextInput
            required
            label="File name"
            placeholder="File name"
            {...form.getInputProps('name')}
            data-testid="file-name-text-input"
          />

          <TextInput
            label="Description"
            placeholder="Description"
            {...form.getInputProps('desc')}
            data-testid="file-description-text-input"
          />

          <TextInput
            label="File type"
            placeholder="File type"
            {...form.getInputProps('file_type')}
            data-testid="file-type-text-input"
          />
        </Stack>

        <ModalFooter position="right">
          <Button variant="default" onClick={closeMe}>
            Cancel
          </Button>

          <Button
            type="submit"
            loading={createSpaceFile.isLoading}
            data-testid="add-file-submit-button"
          >
            Add
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
