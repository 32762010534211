import {
  ActionIcon,
  Badge,
  createStyles,
  Group,
  Loader,
  Menu,
  Text,
  Tooltip,
} from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import { size, uniq } from 'lodash/fp';
import React, { useEffect, useMemo } from 'react';

import {
  useCreateSpace,
  useUpdateSpaceMaintenance,
} from '@portals/api/organizations';
import { TOUR_STEPS_IDS } from '@portals/framework';
import { ReactComponent as Add } from '@portals/icons/linear/add.svg';
import { ReactComponent as Edit } from '@portals/icons/linear/edit-2.svg';
import { ReactComponent as Maintenance } from '@portals/icons/linear/maintenance.svg';
import { ReactComponent as More } from '@portals/icons/linear/more.svg';
import { ReactComponent as Setting2 } from '@portals/icons/linear/setting-2.svg';
import { ReactComponent as Snooze } from '@portals/icons/linear/snooze.svg';
import { useOpenModal } from '@portals/redux';
import {
  generateUniqueDefaultSpaceNameInSiblings,
  searchTree,
} from '@portals/utils';

import { AddNewButton } from './AddNewButton';
import { canAdmin, canEdit } from '../../../../../lib/access';
import { SpaceMaintenanceActivationModalProps } from '../../../../modals';
import { useOverviewRouting } from '../../overview-routing.hooks';
import { OverviewSettingsTabEnum } from '../../overview-settings-drawer/settings-drawer.types';
import { useOverviewContext } from '../../overview.context';
import { useCurrentSpace } from '../../overview.hooks';
import {
  DEFAULT_FONT_SIZE,
  getTextWidth,
  handleTextResize,
  handleTruncateSize,
  PATH_LENGTH_TO_TRUNCATE,
} from '../../overview.utils';
import { SpacePath } from '../overview-header-path';

export function OverviewHeaderPanel() {
  const { classes, theme } = useStyles();
  const openModal = useOpenModal();

  const overviewRouting = useOverviewRouting();

  const space = useCurrentSpace();

  const { ref: containerRef, width: containerWidth } = useElementSize();
  const { ref: rightButtonsRef, width: rightButtonsWidth } = useElementSize();

  const pathWidth = useMemo(() => {
    return getTextWidth({
      text: space?.tree_path_name,
      font: `${DEFAULT_FONT_SIZE}px ${theme.fontFamily}`,
      containerWidth,
      rightButtonsWidth,
    });
  }, [
    space?.tree_path_name,
    theme.fontFamily,
    containerWidth,
    rightButtonsWidth,
  ]);

  const fontSize = useMemo(() => {
    return handleTextResize({
      pathWidth,
      containerWidth,
      rightButtonsWidth,
    });
  }, [containerWidth, rightButtonsWidth, pathWidth]);

  const shouldTruncate = useMemo(
    () =>
      size(space?.path) > PATH_LENGTH_TO_TRUNCATE ||
      (size(space?.path) > PATH_LENGTH_TO_TRUNCATE &&
        handleTruncateSize({
          pathWidth,
          containerWidth,
          rightButtonsWidth,
        })),
    [space?.path, pathWidth, containerWidth, rightButtonsWidth]
  );

  const {
    setSpaceEditModeId,
    spaceEditModeId,
    isAdmin,
    organizationTree: { tree, setExpandedNodes },
  } = useOverviewContext();

  const updateSpaceMaintenance = useUpdateSpaceMaintenance();
  const createSpace = useCreateSpace();

  const onSetMaintenance = () => {
    if (!space) return;

    if (!space.state?.maintenance) {
      openModal<SpaceMaintenanceActivationModalProps['data']>(
        'SpaceMaintenanceActivationModal',
        { spaceId: space.id }
      );
    } else {
      updateSpaceMaintenance.mutate({
        spaceId: space.id,
        enabled: false,
      });
    }
  };

  const onAddSpace = async () => {
    const parentSpace = searchTree(tree[0], space.id);

    if (!parentSpace) return;

    try {
      const newSpace = await createSpace.mutateAsync({
        parentSpaceId: space.id,
        newSpace: {
          name: generateUniqueDefaultSpaceNameInSiblings(parentSpace.children),
        },
      });

      setExpandedNodes((curr) => uniq([...curr, ...newSpace.path]));

      overviewRouting.navigateToOverviewTab({ spaceId: newSpace.id });
      setSpaceEditModeId(newSpace.id);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (spaceEditModeId && space?.id !== spaceEditModeId) {
      setSpaceEditModeId(null);
    }
  }, [space?.id, spaceEditModeId, setSpaceEditModeId]);

  return (
    <Group
      className={classes.container}
      position="apart"
      noWrap
      px="xxl"
      ref={containerRef}
    >
      <Group
        align="center"
        data-testid="overview-header-title"
        spacing="md"
        noWrap
      >
        <SpacePath
          containerWidth={containerWidth}
          space={space}
          fontSize={fontSize}
          pathWidth={pathWidth}
          truncate={shouldTruncate}
          rightButtonsWidth={rightButtonsWidth}
        />

        {spaceEditModeId || !canAdmin(space) ? null : (
          <Group spacing="md" noWrap>
            <Tooltip label="Update space name">
              <ActionIcon
                color="gray.6"
                variant="subtle"
                size="lg"
                className="space-name-action-button"
                onClick={() => setSpaceEditModeId(space.id)}
              >
                <Edit />
              </ActionIcon>
            </Tooltip>

            <Tooltip label="Add new space">
              <ActionIcon
                color="gray.6"
                variant="light"
                size="lg"
                className="space-name-action-btn"
                onClick={onAddSpace}
              >
                <Add />
              </ActionIcon>
            </Tooltip>
          </Group>
        )}
      </Group>

      <Group noWrap position="right" ref={rightButtonsRef}>
        {space?.state?.maintenance && canEdit(space) ? (
          <Tooltip label="Set maintenance OFF" withinPortal>
            <Badge
              color="amber"
              size="xl"
              radius="md"
              leftSection={<Maintenance />}
              rightSection={
                updateSpaceMaintenance.isLoading && <Loader size="xs" />
              }
              onClick={onSetMaintenance}
              data-testid="overview-maintenance-set-button"
              classNames={{
                root: classes.maintenanceRoot,
                leftSection: classes.maintenanceLeftSection,
                inner: classes.maintenanceRightSection,
              }}
            >
              Maintenance Mode
            </Badge>
          </Tooltip>
        ) : null}

        {space?.state?.snoozed &&
        !space.state?.maintenance &&
        canEdit(space) ? (
          <Badge
            color="amber"
            size="lg"
            leftSection={<Snooze />}
            classNames={{
              root: classes.maintenanceRoot,
              leftSection: classes.maintenanceLeftSection,
              inner: classes.maintenanceRightSection,
            }}
          >
            Snooze Mode
          </Badge>
        ) : null}

        {isAdmin || canAdmin(space) ? (
          <>
            {space?.space_type !== 'root' ? <AddNewButton /> : null}

            <Menu position="bottom-end">
              <Menu.Target>
                <ActionIcon
                  color="blue_gray.4"
                  data-tour-step-id={TOUR_STEPS_IDS.overviewHeaderMenu}
                >
                  <More />
                </ActionIcon>
              </Menu.Target>

              <Menu.Dropdown>
                {space?.state?.maintenance ? null : (
                  <Menu.Item
                    onClick={onSetMaintenance}
                    icon={<Maintenance width={16} height={16} />}
                  >
                    <Tooltip withinPortal label="Set maintenance ON">
                      <Text color="blue_gray.8">Set Maintenance ON</Text>
                    </Tooltip>
                  </Menu.Item>
                )}

                <Menu.Item
                  icon={<Setting2 width={16} height={16} />}
                  onClick={() =>
                    overviewRouting.navigateToSpaceSettings(
                      OverviewSettingsTabEnum.General
                    )
                  }
                >
                  <Tooltip withinPortal label="Configure space">
                    <Text color="blue_gray.8">Settings</Text>
                  </Tooltip>
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </>
        ) : null}
      </Group>
    </Group>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    backgroundColor: theme.white,
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
    alignItems: 'center',
    minHeight: 75,
    zIndex: 2,

    '.space-name-action-btn': {
      opacity: 0,
      transition: 'opacity 0.15s ease-in-out',
    },

    '&:hover': {
      '.space-name-action-btn': {
        opacity: 1,
      },
    },
  },

  maintenanceRoot: {
    color: theme.colors.gray[9],
    fontWeight: 300,
    overflow: 'unset',
    fontSize: theme.fontSizes.sm,
    cursor: 'pointer',
  },

  maintenanceRightSection: {
    [theme.fn.smallerThan('xl')]: {
      display: 'none',
    },
  },

  maintenanceLeftSection: {
    width: 18,

    svg: {
      width: 15,
      height: 15,
    },
  },
}));
