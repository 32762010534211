import {
  Box,
  Button,
  createStyles,
  Group,
  Loader,
  LoadingOverlay,
  Paper,
  Stack,
  Text,
} from '@mantine/core';
import { find, isEmpty } from 'lodash/fp';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useKey } from 'react-use';

import {
  AwsDeviceTelemetryType,
  DeviceTelemetryType,
  DeviceType,
  useDeviceTelemetries,
} from '@portals/api/organizations';
import { ReactComponent as Refresh2 } from '@portals/icons/linear/refresh-2.svg';
import { VerticalScrollBar } from '@portals/scrollbar';

import { AwsDeviceTelemetriesDetailsPanel } from './AwsDeviceTelemetriesDetailsPanel';
import { AwsTelemetryList } from './AwsTelemetryList';
import { DeviceTelemetriesDetailsPanel } from './DeviceTelemetriesDetailsPanel';
import { TelemetryList } from './TelemetryList';

interface DeviceLogProps {
  device: DeviceType;
}

export function DeviceTelemetries({ device }: DeviceLogProps) {
  const telemetries = useDeviceTelemetries(device.id);
  const scrollRef = useRef<Scrollbars | null>(null);

  // const features = useFeatures();

  const deviceTelemetries = useMemo(() => {
    return device.migrated_to_aws
      ? telemetries.data || []
      : telemetries.data?.[device.id]?.telemetries || [];
  }, [device.id, device.migrated_to_aws, telemetries.data]);

  const { classes } = useStyles({
    withTelemetries: !isEmpty(deviceTelemetries),
  });

  const [activeTelemetry, setActiveTelemetry] = useState<
    DeviceTelemetryType | AwsDeviceTelemetryType | null
  >(null);

  useEffect(
    function setActiveTelemetryOnceDataIsFetched() {
      if (telemetries.isFetched && !activeTelemetry) {
        setActiveTelemetry(deviceTelemetries[0] || null);
      } else if (activeTelemetry) {
        const currentTelemetry = find(
          device.migrated_to_aws
            ? {
                timestamp: (activeTelemetry as AwsDeviceTelemetryType)
                  ?.timestamp,
              }
            : { id: (activeTelemetry as DeviceTelemetryType)?.id },
          deviceTelemetries
        );

        if (!currentTelemetry) {
          setActiveTelemetry(deviceTelemetries[0] || null);

          if (scrollRef.current) {
            scrollRef.current.scrollToTop();
          }
        }
      }
    },
    [
      activeTelemetry,
      device.migrated_to_aws,
      deviceTelemetries,
      telemetries.isFetched,
    ]
  );

  useKey(
    'ArrowUp',
    () => {
      const selectedTelemetryIndex = deviceTelemetries.findIndex((telemetry) =>
        device.migrated_to_aws
          ? telemetry.timestamp ===
            (activeTelemetry as AwsDeviceTelemetryType)?.timestamp
          : telemetry.id === (activeTelemetry as DeviceTelemetryType)?.id
      );

      const firstIndex = 0;

      if (selectedTelemetryIndex === firstIndex) {
        return;
      }

      const prevIndex = selectedTelemetryIndex - 1;
      setActiveTelemetry(deviceTelemetries[prevIndex]);

      const scrollHeight = scrollRef?.current?.getScrollHeight() ?? 0;

      const prevTelemetryHeight = Math.round(
        scrollHeight / deviceTelemetries.length
      );

      const prevTelemetryOffset = prevIndex * prevTelemetryHeight;

      const scrollTop = scrollRef?.current?.getValues().scrollTop ?? 0;

      if (prevTelemetryOffset < scrollTop) {
        scrollRef?.current?.scrollTop(scrollTop - prevTelemetryHeight);
      }
    },
    { event: 'keydown' },
    [activeTelemetry, deviceTelemetries]
  );

  useKey(
    'ArrowDown',
    () => {
      const selectedTelemetryIndex = deviceTelemetries.findIndex((telemetry) =>
        device.migrated_to_aws
          ? telemetry.timestamp ===
            (activeTelemetry as AwsDeviceTelemetryType)?.timestamp
          : telemetry.id === (activeTelemetry as DeviceTelemetryType)?.id
      );

      const lastIndex = deviceTelemetries.length - 1;

      if (selectedTelemetryIndex === lastIndex) {
        return;
      }

      const nextIndex = selectedTelemetryIndex + 1;
      setActiveTelemetry(deviceTelemetries[nextIndex]);

      const scrollHeight = scrollRef?.current?.getScrollHeight() ?? 0;

      const currentHeight = scrollRef?.current?.getClientHeight() ?? 0;

      const nextTelemetryHeight = Math.round(
        scrollHeight / deviceTelemetries.length
      );

      const nextNextTelemetryOffset =
        (selectedTelemetryIndex + 2) * nextTelemetryHeight;

      const scrollTop = scrollRef?.current?.getValues().scrollTop ?? 0;

      if (nextNextTelemetryOffset > currentHeight) {
        scrollRef?.current?.scrollTop(scrollTop + nextTelemetryHeight);
      }
    },
    { event: 'keydown' },
    [activeTelemetry, deviceTelemetries]
  );

  if (!telemetries.isFetched) return <LoadingOverlay visible />;

  return (
    <Stack className={classes.container}>
      {/*{features.insights ? <TelemetriesAnomalies device={device} /> : null}*/}

      <Paper radius="md" withBorder className={classes.paper}>
        <div className={classes.telemetryListContainer}>
          <header className={classes.header}>
            <Group
              className={classes.headerContent}
              align="center"
              position="apart"
            >
              <Text color="dimmed">
                Showing last {telemetries.data?.[device.id]?.showing}
                &nbsp;telemetries
              </Text>

              <Group align="center">
                {telemetries.isFetching ? <Loader size="xs" /> : null}

                <Button
                  size="xs"
                  loading={telemetries.isRefetching}
                  leftIcon={<Refresh2 width={16} height={16} />}
                  onClick={() => telemetries.refetch()}
                >
                  Reload
                </Button>
              </Group>
            </Group>
          </header>

          <VerticalScrollBar
            innerRef={scrollRef}
            renderView={(props) => <Box sx={{ paddingRight: 32 }} {...props} />}
          >
            {device.migrated_to_aws ? (
              <AwsTelemetryList
                telemetries={deviceTelemetries}
                selectedTelemetryId={
                  (activeTelemetry as AwsDeviceTelemetryType)?.timestamp
                }
                onSelectTelemetry={setActiveTelemetry}
              />
            ) : (
              <TelemetryList
                telemetries={deviceTelemetries}
                selectedTelemetryId={
                  (activeTelemetry as DeviceTelemetryType)?.id
                }
                onSelectTelemetry={setActiveTelemetry}
              />
            )}
          </VerticalScrollBar>
        </div>

        {activeTelemetry &&
          (device.migrated_to_aws ? (
            <AwsDeviceTelemetriesDetailsPanel
              telemetry={activeTelemetry as AwsDeviceTelemetryType}
            />
          ) : (
            <DeviceTelemetriesDetailsPanel
              telemetry={activeTelemetry as DeviceTelemetryType}
            />
          ))}
      </Paper>
    </Stack>
  );
}

const useStyles = createStyles(
  (theme, { withTelemetries }: { withTelemetries: boolean }) => ({
    container: {
      height: '100%',
      paddingBlock: 32,
      borderRadius: theme.radius.sm,
    },
    paper: {
      overflow: 'hidden',
      position: 'relative',
      height: '100%',
    },
    header: {
      paddingInline: 32,
    },
    headerContent: {
      paddingBlock: theme.spacing.md,
      borderBottom: `1px solid ${theme.colors.gray[2]}`,
    },
    telemetryListContainer: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      width: withTelemetries ? '60%' : '100%',
    },
  })
);
