import { Stack } from '@mantine/core';
import GoogleMapReact from 'google-map-react';
import { find } from 'lodash/fp';
import React, { useEffect, useState } from 'react';

import {
  DeviceDetailsType,
  useNewDeviceWidgets,
} from '@portals/api/organizations';
import {
  DeviceLocationWidgetFormType,
  NewWidgetType,
  useDeviceLocationWidgetDimensions,
} from '@portals/device-widgets';

import { DeviceLocationMapActions } from './device-location-widget/DeviceLocationMapActions';
import { DeviceLocationWidgetWrapper } from './device-location-widget/DeviceLocationWidgetWrapper';
import { DevicePanelWrapper } from './device-panel/DevicePanel';
import { DeviceOverviewProvider } from './DeviceOverviewContext';

interface DeviceOverviewProps {
  device: DeviceDetailsType;
}

export function DeviceOverview({ device }: DeviceOverviewProps) {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [selectedDeviceId, setSelectedDeviceId] = useState<string | null>(null);

  const deviceWidgets = useNewDeviceWidgets(device.partner.type_id);

  const locationWidget = find(
    { config: { id: 'device_location' } },
    deviceWidgets.data
  ) as NewWidgetType<DeviceLocationWidgetFormType>;

  const {
    activeDimension,
    setActiveDimensionId,
    activeDimensionId,
    segmentedControlData,
  } = useDeviceLocationWidgetDimensions(locationWidget.config.fields);

  useEffect(
    function resetSelectedDeviceOnFullscreenToggle() {
      if (!isFullScreen && selectedDeviceId) {
        setSelectedDeviceId(null);
      }
    },
    [isFullScreen, selectedDeviceId]
  );

  const onMapClick: React.ComponentProps<typeof GoogleMapReact>['onClick'] = (
    clickEvent
  ) => {
    if (!isFullScreen) {
      setIsFullScreen(true);

      return;
    }

    if (
      clickEvent.event.target.getAttribute('id') ===
      'device-location-map-marker'
    ) {
      return;
    }

    if (selectedDeviceId) {
      setSelectedDeviceId(null);
    }
  };

  if (!locationWidget) return null;

  return (
    <Stack bg="gray.0" p="md" mah="100%">
      <DeviceOverviewProvider
        selectedDeviceId={selectedDeviceId}
        setSelectedDeviceId={setSelectedDeviceId}
        isFullscreen={isFullScreen}
        setIsFullscreen={setIsFullScreen}
        activeLocationWidgetDimension={activeDimension}
        setActiveLocationWidgetDimensionId={setActiveDimensionId}
        activeLocationWidgetDimensionId={activeDimensionId}
        locationWidgetSegmentedControlData={segmentedControlData}
      >
        <DeviceLocationWidgetWrapper
          key={isFullScreen ? 'fullscreen' : 'normal'}
          widget={locationWidget}
          deviceId={device.id}
          onMapClick={onMapClick}
          mapActionsButtons={<DeviceLocationMapActions />}
        />

        <DevicePanelWrapper />
      </DeviceOverviewProvider>
    </Stack>
  );
}
