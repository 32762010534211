import { createStyles, Group, Paper, Stack, Text } from '@mantine/core';
import React from 'react';

import {
  IncidentClosedMethodEnum,
  IncidentType,
} from '@portals/api/organizations';
import { ReactComponent as BoldTicketCircleIcon } from '@portals/icons/bold/tick-circle.svg';
import { formatDuration, formatDateTime } from '@portals/utils';

interface IncidentDetailsPanelResolvedSectionProps {
  incident: IncidentType;
}

export function IncidentDetailsPanelResolvedSection({
  incident,
}: IncidentDetailsPanelResolvedSectionProps) {
  const { classes, theme } = useStyles();

  return (
    <Paper px="md" className={classes.resolveReasonContainer}>
      <Group align="start" noWrap>
        <span>
          <BoldTicketCircleIcon
            color={theme.colors.teal_accent[4]}
            width={24}
            height={24}
          />
        </span>

        <Stack mt={4}>
          <Stack spacing="xs">
            <Text color="gray.9" data-testid="resolve-by-title">
              Resolved {getClosedMethod(incident)}
              {incident.closed_by && (
                <Text span color="gray.7">
                  {' '}
                  (by {incident.closed_by.name})
                </Text>
              )}
            </Text>

            {incident.closed_at && (
              <Text color="gray.5" size="xs">
                <Text span>{formatDateTime(incident.closed_at)} </Text>
                <Text span>
                  (Duration{' '}
                  {formatDuration({
                    startDate: incident.created_at,
                    endDate: incident.closed_at,
                  })}
                  )
                </Text>
              </Text>
            )}
          </Stack>

          {incident.closed_reason && (
            <Text
              className={classes.resolveReasonText}
              data-testid="resolve-reason-preview"
            >
              {incident.closed_reason}
            </Text>
          )}
        </Stack>
      </Group>
    </Paper>
  );
}

const useStyles = createStyles((theme) => ({
  resolveReasonContainer: {
    backgroundColor: theme.colors.gray[1],
    borderRadius: `0 0 ${theme.radius.lg}`,
    borderTop: '2px solid white',

    paddingTop: theme.spacing.md,
    paddingBottom: 0,
  },
  resolveReasonText: {
    whiteSpace: 'pre-wrap',
  },
}));

function getClosedMethod(incident: IncidentType) {
  if (incident.closed_method === IncidentClosedMethodEnum.Manually) {
    return 'manually';
  } else if (
    incident.closed_method === IncidentClosedMethodEnum.Automatically
  ) {
    return 'automatically';
  } else if (incident.closed_method === IncidentClosedMethodEnum.Integration) {
    return 'via integration';
  } else {
    return 'by device';
  }
}
