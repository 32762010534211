import { useCallback } from 'react';
import { string } from 'yup';

import { useConfirmationModal } from '@portals/framework';

export function useOpenDocument() {
  const asyncConfirmation = useConfirmationModal();

  return useCallback(
    async ({ onConfirm, url }: { url: string; onConfirm: () => void }) => {
      const isUrlValid = string().url().isValidSync(url);

      if (!isUrlValid) {
        return;
      }

      const newUrl = new URL(url);
      const isUploadedFileOnXyteServer = newUrl.hostname.endsWith('xyte.io');

      if (isUploadedFileOnXyteServer) {
        onConfirm();

        return;
      }

      const isConfirmed = await asyncConfirmation({
        title: "You're about to leave Xyte",
        description:
          "You're being redirected to an external website. Please note that its content and policies are managed independently.",
        confirmationLabel: 'Continue',
        confirmButtonProps: {
          color: 'primary',
        },
      });

      if (isConfirmed) {
        onConfirm();
      }
    },
    [asyncConfirmation]
  );
}
