import { Group } from '@mantine/core';
import React from 'react';

import { DebouncedSearchInput } from '@portals/core';

import { DeviceFilters } from './device-filters/DeviceFilters';
import { useOverviewDevicesContext } from './OverviewDevicesProvider';

export function DevicesControls() {
  const { isLoading, form, totalDevicesCount, searchInputRef } =
    useOverviewDevicesContext();

  if (!isLoading && !form.isDirty() && totalDevicesCount === 0) {
    return null;
  }

  return (
    <Group position="apart">
      <DebouncedSearchInput
        ref={searchInputRef}
        size="sm"
        initialValue={form.values.searchTerm}
        debouncedOnChange={(value) => form.setFieldValue('searchTerm', value)}
      />

      <DeviceFilters />
    </Group>
  );
}
