import { LoadingOverlay, Text } from '@mantine/core';
import { GeoJSON, Point } from 'geojson';
import { capitalize } from 'lodash/fp';
import React from 'react';

import {
  DeviceType,
  useDevicesBySpaceId,
  useSpaces,
} from '@portals/api/organizations';
import {
  DeviceIncidentMarker,
  Map,
  MapListItem,
  ORGANIZATIONS_PRIORITY_COLORS,
} from '@portals/framework';
import {
  RouteModalParams,
  useOpenRouteModal,
} from '@portals/framework/route-modals';
import { DeviceStatusType } from '@portals/types';
import {
  findSpaceChildren,
  useGetSpaceLocation,
  spaceDevicesToPoints,
} from '@portals/utils';

import { useCurrentSpace } from '../../../overview.hooks';
import { WidgetCardWrapper } from '../common/WidgetCardWrapper';

const DEVICE_STATE_MAPPING = {
  error: 1,
  unavailable: 2,
  offline: 3,
  online: 5,
} as const;

const mapper =
  (
    openRouteModal: ({
      modalId,
      pathParams,
      backgroundPath,
    }: RouteModalParams) => void
  ) =>
  (device: DeviceType) => {
    const severityCategory: DeviceStatusType = device.status || 'offline';
    const priority = DEVICE_STATE_MAPPING[severityCategory];
    const color = ORGANIZATIONS_PRIORITY_COLORS[priority - 1];
    const onClick = () =>
      openRouteModal({
        modalId: 'device',
        pathParams: [device.id],
      });

    return {
      id: device.id,
      priority,
      severityCategory: capitalize(severityCategory),
      renderSinglePoint: () => (
        <DeviceIncidentMarker
          title={device.name}
          description={device.partner.model}
          color={color}
          onClick={onClick}
        />
      ),
      renderClusterItem: () => (
        <MapListItem
          key={device.id}
          title={device.name}
          description={device.partner.model}
          color={color}
          onClick={onClick}
        />
      ),
    };
  };

export function DeviceMap() {
  const space = useCurrentSpace();
  const openRouteModal = useOpenRouteModal();

  const spaces = useSpaces();
  const devices = useDevicesBySpaceId({ spaceId: space?.id || 0 });

  const baseLocation = useGetSpaceLocation(space);

  const includedSpaces =
    space && spaces.isFetched ? findSpaceChildren(spaces.data, space) : [];

  const points =
    devices.data &&
    spaceDevicesToPoints(devices.data, includedSpaces, mapper(openRouteModal));

  if (!devices.data && devices.isLoading) {
    return <LoadingOverlay visible />;
  }

  return (
    <WidgetCardWrapper
      header={
        <Text
          sx={(theme) => ({ color: theme.colors.blue_gray[6] })}
          size="md"
          color="gray.5"
        >
          Devices Map
        </Text>
      }
    >
      <Map
        points={points as Array<GeoJSON.Feature<Point>>}
        base={baseLocation}
        emptyStateText="No devices"
      />
    </WidgetCardWrapper>
  );
}
