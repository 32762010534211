import {
  Badge,
  Box,
  createStyles,
  Menu,
  Paper,
  Stack,
  Text,
} from '@mantine/core';
import React, { forwardRef, useMemo } from 'react';

import { useDesign, useOrganizationConfig } from '@portals/api/organizations';
import { usePermissionAccess } from '@portals/framework';
import { useSwitchTenant } from '@portals/framework/modals';
import { ReactComponent as CloudChange } from '@portals/icons/linear/cloud-change.svg';
import { ReactComponent as Flag } from '@portals/icons/linear/flag.svg';
import { useOpenModal } from '@portals/redux';
import { ComponentRendererType } from '@portals/types';

import { UserAvatar, UserName } from './common';

interface TenantMenuContentProps {
  logoSrc?: string;
  label: string;
  onClose: () => void;
}

const TenantMenuContent = forwardRef<HTMLDivElement, TenantMenuContentProps>(
  ({ logoSrc, label, onClose }, ref) => {
    const { classes, cx } = useStyles();

    const { isSuperAdmin } = usePermissionAccess();
    const openModal = useOpenModal();
    const organization = useOrganizationConfig();

    const { canSwitchTenant, openSwitchTenantModal } = useSwitchTenant();

    const items = useMemo(() => {
      const itemsList: Array<{
        Icon: ComponentRendererType;
        label: string;
        onClick: () => void;
      }> = [];

      if (isSuperAdmin) {
        itemsList.push({
          Icon: Flag,
          label: 'Feature Flags',
          onClick: () => openModal('FeatureFlags'),
        });
      }

      if (canSwitchTenant) {
        itemsList.push({
          Icon: CloudChange,
          label: 'Switch Tenant',
          onClick: openSwitchTenantModal,
        });
      }

      return itemsList;
    }, [canSwitchTenant, isSuperAdmin, openModal, openSwitchTenantModal]);

    return (
      <Paper
        ref={ref}
        p="md"
        sx={(theme) => ({
          width: '100%',
          background: theme.white,
        })}
      >
        <Box mb="md" className={cx(classes.header, { lab: organization?.lab })}>
          <UserAvatar logoSrc={logoSrc} />

          <Box className={classes.tenantDetails} data-testid="tenant-name">
            <UserName name={label} />

            {organization?.partner_display_name && (
              <Text fz="xs" c="gray.5" truncate>
                by {organization?.partner_display_name}
              </Text>
            )}
          </Box>

          {organization?.lab ? (
            <Badge variant="filled" color="gray.9" fw="300" h={24} radius="8px">
              LAB
            </Badge>
          ) : null}
        </Box>

        <Menu.Divider />

        <Stack mt="md" spacing="xs" px={0}>
          {items.map(({ Icon, label, onClick }) => (
            <Menu.Item
              key={label}
              data-testid={`tenant-menu-item-${label}`}
              onClick={() => {
                onClick();
                onClose();
              }}
              className={classes.menuItemWrapper}
            >
              <Box className={classes.menuItem}>
                <Icon />

                <Text size="sm">{label}</Text>
              </Box>
            </Menu.Item>
          ))}
        </Stack>
      </Paper>
    );
  }
);

const useStyles = createStyles((theme) => ({
  header: {
    display: 'grid',
    gridTemplateColumns: '55px 1fr',
    gap: theme.spacing.md,
    alignItems: 'center',
    height: 55,
    paddingInline: theme.spacing.xs,

    '&.lab': {
      gridTemplateColumns: '55px 1fr min-content',
    },
  },

  tenantDetails: {
    overflow: 'hidden',
  },

  menuItemWrapper: {
    height: 44,
    padding: `0 ${theme.spacing.md}`,
    transition: 'background 0.1s ease-in-out',
    borderRadius: theme.radius.md,

    '&:hover': {
      backgroundColor: theme.colors.gray[1],
    },
  },
  menuItem: {
    display: 'grid',
    gridTemplateColumns: '24px 1fr',
    gap: theme.spacing.md,
    color: theme.colors.blue_gray[6],
    alignItems: 'center',
  },
}));

export const OrganizationMenuContent = forwardRef<
  HTMLDivElement,
  { onClose: () => void }
>(({ onClose }, ref) => {
  const design = useDesign();
  const organization = useOrganizationConfig();

  return (
    <TenantMenuContent
      ref={ref}
      onClose={onClose}
      label={organization?.name || ''}
      logoSrc={design?.navigation_menu?.logo ?? undefined}
    />
  );
});
