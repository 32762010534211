import { AuthProvider } from '@descope/react-sdk';
import { LoadingOverlay } from '@mantine/core';
import { captureException } from '@sentry/react';
import React from 'react';
import { useMatch } from 'react-router-dom';

import { usePortalConfig } from '@portals/api/organizations';
import { Page500 } from '@portals/framework';

export function DescopeAuthProvider({ children }: { children: JSX.Element }) {
  const portalConfig = usePortalConfig();

  const isAutoSigninRoute = useMatch('/auth/auto-sign-in');

  if (isAutoSigninRoute) {
    return (
      <AuthProvider projectId={process.env.NX_DESCOPE_PROJECT_ID as string}>
        {children}
      </AuthProvider>
    );
  }

  if (portalConfig.isInitialLoading) {
    return <LoadingOverlay visible />;
  }

  if (portalConfig.isError) {
    return <Page500 />;
  }

  if (!portalConfig.data?.descope_project_id) {
    captureException(`Missing descope_project_id`);

    localStorage.removeItem('auth');
    localStorage.removeItem('last_tenant_id');

    window.location.reload();

    return null;
  }

  return (
    <AuthProvider projectId={portalConfig.data.descope_project_id}>
      {children}
    </AuthProvider>
  );
}
