import { createStyles, Stack } from '@mantine/core';
import React, { ReactNode } from 'react';

interface GridSkeletonProps {
  numOfItemsPerRow: number;
  rowCount: number;
  rowGap: number;
  cardWidth: number;
  itemSkeleton: ReactNode;
}

export function GridSkeleton({
  numOfItemsPerRow,
  rowCount,
  rowGap,
  cardWidth,
  itemSkeleton,
}: GridSkeletonProps) {
  const rows = new Array(rowCount).fill(null);

  return (
    <Stack spacing={rowGap}>
      {rows.map((_, index) => (
        <RowSkeleton
          key={index}
          numOfItemsPerRow={numOfItemsPerRow}
          cardWidth={cardWidth}
          itemSkeleton={itemSkeleton}
        />
      ))}
    </Stack>
  );
}

interface RowSkeletonProps {
  numOfItemsPerRow: number;
  cardWidth: number;
  itemSkeleton: ReactNode;
}

function RowSkeleton({
  numOfItemsPerRow,
  cardWidth,
  itemSkeleton,
}: RowSkeletonProps) {
  const { classes } = useRowStyles(cardWidth);

  const cards = new Array(numOfItemsPerRow).fill(null);

  return (
    <div className={classes.gridRow}>
      {cards.map((_, index) => (
        <React.Fragment key={index}>{itemSkeleton}</React.Fragment>
      ))}
    </div>
  );
}

const useRowStyles = createStyles((theme, cardWidth: number) => ({
  gridRow: {
    display: 'grid',
    gridTemplateColumns: `repeat(auto-fill, minmax(${cardWidth}px, 1fr))`,
    gridTemplateRows: cardWidth,
    gap: theme.spacing.md,
  },
}));

interface GridRowSkeletonFillerProps {
  numOfItems: number;
  itemSkeleton: ReactNode;
}

export function GridRowSkeletonFiller({
  numOfItems,
  itemSkeleton,
}: GridRowSkeletonFillerProps) {
  const cards = new Array(numOfItems).fill(null);

  return (
    <>
      {cards.map((_, index) => (
        <React.Fragment key={index}>{itemSkeleton}</React.Fragment>
      ))}
    </>
  );
}
