import { LoadingOverlay } from '@mantine/core';
import React, { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { AfterAuthRedirectManager } from '@lib/after-auth-redirect-manager';
import { usePortalConfig } from '@portals/api/organizations';
import { AuthenticationGuard } from '@portals/framework';

interface OrganizationAuthenticationGuardProps {
  children?: ReactNode;
}

export function OrganizationAuthenticationGuard({
  children,
}: OrganizationAuthenticationGuardProps) {
  return (
    <AuthenticationGuard redirectComponent={<OrganizationAuthGuardRedirect />}>
      {children}
    </AuthenticationGuard>
  );
}

function OrganizationAuthGuardRedirect() {
  const location = useLocation();
  const portalConfig = usePortalConfig();

  if (!portalConfig.data) {
    return <LoadingOverlay visible />;
  }

  const { custom_domain } = portalConfig.data;

  if (custom_domain && custom_domain !== window.location.origin) {
    const url = new URL(custom_domain);

    url.search = location.search;

    window.location.replace(url);

    return null;
  }

  AfterAuthRedirectManager.setPathname(location.pathname + location.search);

  return <Navigate to="/auth/sign-in" replace />;
}
