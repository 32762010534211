import {
  Alert,
  Button,
  createStyles,
  Modal,
  ModalProps as MantineModalProps,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { SpaceType, useDeleteSpace } from '@portals/api/organizations';
import { ModalFooter } from '@portals/core';
import { CustomerAvatarAndName, ModalProps } from '@portals/framework';

export interface DeleteCustomerSpaceModalProps
  extends ModalProps<{
    space: SpaceType;
  }> {}

const CONFIRMATION_TEXT = 'I understand';

export function DeleteCustomerSpaceModal({
  closeMe,
  data: { space },
}: DeleteCustomerSpaceModalProps) {
  const { classes } = useStyles();

  const navigate = useNavigate();
  const deleteSpace = useDeleteSpace();

  const form = useForm({
    initialValues: {
      confirmation: '',
    },
  });

  const onSubmit = () => {
    deleteSpace.mutate(
      {
        spaceId: space.id,
      },
      {
        onSuccess: () => {
          closeMe();
          navigate('/overview/');
        },
      }
    );
  };

  return (
    <Modal
      opened
      onClose={closeMe}
      title="Delete Customer"
      padding="xxl"
      styles={modalStyles}
    >
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Stack spacing="xxl">
          <Stack spacing="xxl" align="center">
            <CustomerAvatarAndName
              src={space.icon_url}
              name={space.name}
              size={85}
              radius={100}
              className={classes.customerAvatar}
            />
            <Alert ta="center" radius="lg" color="red" p="xl">
              <Text>
                Please note that deleting this customer is a permanent
                irreversible action. All associated data will be lost forever.
              </Text>

              <Text fw={600}>
                To proceed, type '{CONFIRMATION_TEXT}' below.
              </Text>
            </Alert>
            <TextInput
              w={250}
              data-autofocus
              placeholder="Type “I understand” to continue"
              {...form.getInputProps('confirmation')}
              data-testid="i-understand-text-input"
            />
          </Stack>

          <ModalFooter position="right" pt="xl">
            <Button
              variant="default"
              onClick={closeMe}
              data-testid="cancel-button"
            >
              Cancel
            </Button>

            <Button
              type="submit"
              disabled={form.values.confirmation !== CONFIRMATION_TEXT}
              data-testid="delete-customer-confirm-button"
            >
              Delete customer
            </Button>
          </ModalFooter>
        </Stack>
      </form>
    </Modal>
  );
}

const modalStyles: MantineModalProps['styles'] = (theme) => ({
  header: {
    paddingRight: theme.spacing.xxl,
  },
});

const useStyles = createStyles({
  customerAvatar: {
    flexDirection: 'column',
  },
});
