import { Box } from '@mantine/core';
import React, { useMemo } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';

import { DeviceDetailsType } from '@portals/api/organizations';
import { DeviceWidgetsSectionType } from '@portals/device-widgets';

import { DeviceWidgetWrapper } from '../device-widgets/DeviceWidgetWrapper';

const ResponsiveGridLayout = WidthProvider(Responsive);

interface DeviceWidgetsSectionProps {
  modelId: string;
  device: DeviceDetailsType;
  section: DeviceWidgetsSectionType;
}

export function DeviceWidgetsSection({
  modelId,
  section,
  device,
}: DeviceWidgetsSectionProps) {
  const layout = useMemo(
    () =>
      section.widgets.map(({ id, config }) => {
        return {
          x: 0,
          y: 0,
          w: config.width,
          h: config.height || 1,
          // `config.layout` holds the x & y coordinates of the widget
          ...config.layout,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          i: id!,
          config,
          id,
        };
      }),
    [section.widgets]
  );

  return (
    <Box key={section.id}>
      <ResponsiveGridLayout
        breakpoint="lg"
        cols={{ lg: 24, md: 24, sm: 24, xs: 24, xxs: 24 }}
        layouts={{ lg: layout }}
        margin={[16, 16]}
        rowHeight={25}
        containerPadding={[0, 10]}
        className="layout"
        isResizable={false}
        isDraggable={false}
        measureBeforeMount
        useCSSTransforms={false}
      >
        {section.widgets.map((widget) => {
          return (
            <div key={widget.id}>
              <DeviceWidgetWrapper
                widget={widget}
                deviceId={device.id}
                modelId={modelId}
              />
            </div>
          );
        })}
      </ResponsiveGridLayout>
    </Box>
  );
}
