import { Button } from '@mantine/core';
import React from 'react';

import { ReactComponent as TrashIcon } from '@portals/icons/linear/trash.svg';

import { useOverviewAssetsContext } from './OverviewAssetsProvider';
import { useConfirmBulkRemoveAssets } from '../../assets/assets.hooks';
import { BulkActions } from '../bulk-actions/BulkActions';

export function AssetsBulkActions() {
  const { selectedAssetIds, clearSelectedAssetIds } =
    useOverviewAssetsContext();

  const { onBulkRemove } = useConfirmBulkRemoveAssets();

  return (
    <BulkActions
      selectedCount={selectedAssetIds.size}
      onClearSelected={clearSelectedAssetIds}
    >
      <BulkActions.Action>
        <Button
          leftIcon={<TrashIcon width={16} height={16} />}
          onClick={() =>
            onBulkRemove({
              selectedAssetIds: Array.from(selectedAssetIds),
              onSuccess: clearSelectedAssetIds,
            })
          }
        >
          Delete
        </Button>
      </BulkActions.Action>
    </BulkActions>
  );
}
