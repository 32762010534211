import { Alert, Button, Group, Modal, Radio, Stack, Text } from '@mantine/core';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { SpaceType, useDeleteSpace } from '@portals/api/organizations';
import { ModalFooter } from '@portals/core';
import { ModalProps } from '@portals/framework';

interface DeleteSpaceModalProps extends ModalProps<{ space: SpaceType }> {}

type DeviceHandlingOption = 'move' | 'delete';

export function DeleteSpaceModal({ closeMe, data }: DeleteSpaceModalProps) {
  const { space } = data || {};

  const navigate = useNavigate();
  const deleteSpace = useDeleteSpace();

  const [deviceOption, setDeviceOption] = useState<DeviceHandlingOption>();

  const shouldDeleteSpaceContent = deviceOption === 'delete';

  if (!space) {
    return null;
  }

  const onSubmit = () => {
    deleteSpace.mutate(
      {
        spaceId: space.id,
        deleteSpaceContent: shouldDeleteSpaceContent,
      },
      {
        onSuccess: () => {
          closeMe();
          if (space.parent_id) {
            navigate(`/overview/${space.parent_id}`);
          } else {
            navigate('/overview/');
          }
        },
      }
    );
  };

  return (
    <Modal
      opened
      onClose={closeMe}
      title={`Delete Space "${space.name}"`}
      padding="xxl"
    >
      <Stack spacing="xl">
        <Text data-testid="delete-space-description">
          "{space.name}" or one of it's sub spaces might contains devices or
          assets. Please select what you'd like to do with them before deleting
          the space.
        </Text>

        <Radio.Group
          value={deviceOption}
          onChange={(value) => setDeviceOption(value as DeviceHandlingOption)}
          name="deviceOption"
          withAsterisk
        >
          <Stack mt="xs">
            <Radio
              value="delete"
              label={
                <Text>
                  Delete all devices and assets{' '}
                  <Text fw={700} span>
                    (This action can't be undone)
                  </Text>
                </Text>
              }
              data-testid="delete-all-devices-radio-button"
            />

            <Radio
              value="move"
              label='Move them to "Unsorted"'
              data-testid="move-to-unsorted-radio-button"
            />
          </Stack>
        </Radio.Group>

        {shouldDeleteSpaceContent && (
          <Alert color="red" radius="lg">
            <Text size="sm" c="gray.8">
              If this space includes parent devices, all associated child
              devices will also be removed from your account.
            </Text>
          </Alert>
        )}
      </Stack>

      <ModalFooter position="right" pt="xxl">
        <Group position="right">
          <Button variant="default" onClick={closeMe}>
            Cancel
          </Button>

          <Button
            type="submit"
            color="red"
            disabled={!deviceOption}
            loading={deleteSpace.isLoading}
            onClick={onSubmit}
            data-testid="delete-space-confirm-button"
          >
            Delete space
          </Button>
        </Group>
      </ModalFooter>
    </Modal>
  );
}
