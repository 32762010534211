import {
  createStyles,
  Input,
  InputWrapperProps,
  SegmentedControl,
  Stack,
  TextInput,
} from '@mantine/core';
import { DropzoneProps } from '@mantine/dropzone';
import React, { ReactNode, useEffect, useState } from 'react';
import { string } from 'yup';

import { FileUploaderDropzone, useFileUploaderDropzone } from '@portals/core';
import { useConfirmationModal } from '@portals/framework';

interface FileUploadSwitcherProps {
  initialFileUrl?: string | undefined;
  onChangeFileUrl: (fileUrl: string) => void;
  fileUrlError: ReactNode;
  acceptFileTypes?: DropzoneProps['accept'];
}

type FileUrlType = 'uploadFile' | 'embedLink';

export function FileUploadSwitcher({
  initialFileUrl,
  onChangeFileUrl,
  fileUrlError,
  acceptFileTypes = ['*'],
}: FileUploadSwitcherProps) {
  const { classes, cx } = useStyles();

  const asyncConfirmationCheck = useConfirmationModal();

  const [fileUrlType, setFileUrlType] = useState<FileUrlType>(
    getFileUrlType(initialFileUrl)
  );
  const [embedLink, setEmbedLink] = useState(initialFileUrl || '');

  const onDeleteUploadedFile = (fileToDeleteUrl: string) => {
    const withoutFileToDelete = uploadedFileUrls?.filter(
      (fileUrl) => fileUrl !== fileToDeleteUrl
    );

    onChangeFileUrl('');
    setUploadedFileUrls(withoutFileToDelete);
  };

  const { files, setFiles, uploadedFileUrls, setUploadedFileUrls } =
    useFileUploaderDropzone({
      initialFileUrls:
        initialFileUrl && fileUrlType === 'uploadFile'
          ? [initialFileUrl]
          : undefined,
    });

  const onChangeSegmentedControl = async (value: FileUrlType) => {
    const fileUrl = files[0]?.fileUrl || embedLink;

    if (!fileUrl) {
      setFileUrlType(value);
      return;
    }

    const isSwitchingToEmbedLink = value === 'embedLink';
    const confirmationMessage = isSwitchingToEmbedLink
      ? {
          title: 'Switching to embed link',
          description:
            'Switching to embed link will remove the uploaded file. Continue?',
        }
      : {
          title: 'Switching to upload file',
          description:
            'Switching to upload file will remove the embedded link. Continue?',
        };

    const confirmSwitch = await asyncConfirmationCheck({
      ...confirmationMessage,
      confirmationLabel: 'Continue',
    });

    if (!confirmSwitch) return;

    onChangeFileUrl('');
    setFileUrlType(value);

    onDeleteUploadedFile(fileUrl);
    setFiles([]);
    setEmbedLink('');
  };

  useEffect(
    function updateFileUrlValuePostUploadFile() {
      if (fileUrlType !== 'uploadFile') return;

      const fileUrl = files[0]?.fileUrl;

      if (!fileUrl) return;

      if (fileUrl !== initialFileUrl) {
        onChangeFileUrl(fileUrl);
      }
    },
    [fileUrlType, files, initialFileUrl, onChangeFileUrl]
  );

  return (
    <Stack spacing="xxl">
      <SegmentedControl
        fullWidth
        value={fileUrlType}
        onChange={onChangeSegmentedControl}
        data-testid="file-upload-switcher"
        data={[
          {
            label: 'Upload file',
            value: 'uploadFile',
          },
          {
            label: 'Embed link',
            value: 'embedLink',
          },
        ]}
      />

      {fileUrlType === 'uploadFile' ? (
        <Input.Wrapper
          label="File"
          required
          error={fileUrlError}
          styles={inputWrapperStyles}
        >
          <FileUploaderDropzone
            files={files}
            setFiles={setFiles}
            uploadedFileUrls={uploadedFileUrls}
            onDeleteUploadedFile={onDeleteUploadedFile}
            dropzoneProps={{
              accept: acceptFileTypes,
              radius: 'xl',
              className: cx(
                classes.dropzone,
                fileUrlError && classes.fileUploaderError
              ),
            }}
          />
        </Input.Wrapper>
      ) : (
        <TextInput
          type="url"
          required
          placeholder="Paste the file link"
          label="File link"
          value={embedLink}
          onChange={(event) => setEmbedLink(event.currentTarget.value)}
          error={fileUrlError}
          onBlur={(event) => onChangeFileUrl(event.currentTarget.value)}
          data-testid="file-link-text-area"
        />
      )}
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  dropzone: {
    height: 260,
  },

  fileUploaderError: {
    borderColor: theme.colors.red[6],
  },
}));

const inputWrapperStyles: InputWrapperProps['styles'] = {
  error: {
    marginTop: 4,
  },
};

function getFileUrlType(url: string | undefined) {
  if (!url) {
    return 'uploadFile';
  }

  const isUrlValid = string().url().isValidSync(url);

  if (!isUrlValid) {
    return 'embedLink';
  }

  const newUrl = new URL(url);
  const isUploadedFileOnXyteServer = newUrl.hostname.endsWith('xyte.io');

  return isUploadedFileOnXyteServer ? 'uploadFile' : 'embedLink';
}
