import { createStyles, Skeleton, Stack } from '@mantine/core';
import React from 'react';

export function AssetCardSkeleton() {
  const { classes } = useCardStyles();

  return (
    <div className={classes.cardContainer}>
      <Stack align="center">
        <Stack spacing="xs" align="center">
          <Skeleton width={85} height={30} radius="md" />
          <Skeleton width={120} height={12} radius="md" />
          <Skeleton width={64} height={12} radius="md" />
        </Stack>

        <Skeleton width={100} height={100} radius="lg" />

        <Skeleton width={75} height={30} radius="lg" />
      </Stack>
    </div>
  );
}

const useCardStyles = createStyles((theme) => ({
  cardContainer: {
    padding: theme.spacing.xxl,
    borderRadius: 24,
    border: `1px solid ${theme.colors.gray[3]}`,
    backgroundColor: theme.white,
  },
}));
