import { Button, createStyles, Stack, Text } from '@mantine/core';
import { last } from 'lodash/fp';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { DeviceDetailsType } from '@portals/api/organizations';
import { DeviceAvatar, DeviceStatusBadge } from '@portals/framework';

import { useDeviceOverviewContext } from '../DeviceOverviewContext';

interface ChildDevicePreviewProps {
  device: DeviceDetailsType;
  onCommandsToggle: () => void;
}

export function DevicePanelPreview({
  device,
  onCommandsToggle,
}: ChildDevicePreviewProps) {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const params = useParams<{ device_id: string }>();
  const { activeLocationWidgetDimension } = useDeviceOverviewContext();

  const deviceActiveDimensionData =
    activeLocationWidgetDimension &&
    (device.state?.[activeLocationWidgetDimension?.telemetry_key] ||
      device.last_known_state?.state?.[
        activeLocationWidgetDimension?.telemetry_key
      ]);

  return (
    <Stack className={classes.container} spacing="md">
      <DeviceAvatar
        src={device.image_url}
        icon={device.model_settings?.icon}
        size={68}
      />

      <Stack spacing="xs" align="center">
        <Text size="lg" color="gray.9" weight="500">
          {device.name}
        </Text>

        <Text size="sm" color="gray.6">
          {last(device.space_tree_path_name.split('/'))}
        </Text>
      </Stack>

      {activeLocationWidgetDimension && (
        <Text size="sm" color="gray.6">
          {`${activeLocationWidgetDimension.telemetry_key}: ${
            deviceActiveDimensionData || '--'
          }`}
        </Text>
      )}

      <DeviceStatusBadge status={device.status} />

      {params.device_id !== device.id ? (
        <Button
          color="gray.9"
          fullWidth
          onClick={() => navigate(`/devices/${device.id}`)}
        >
          See device
        </Button>
      ) : null}

      <Button color="gray.1" fullWidth onClick={onCommandsToggle}>
        <Text color="gray.9" weight="normal">
          Commands
        </Text>
      </Button>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    padding: `${theme.spacing.xl} ${theme.spacing.md}`,
    alignItems: 'center',
    position: 'relative',
  },
  backButton: {
    position: 'absolute',
    top: theme.spacing.xxl,
    left: theme.spacing.xl,
  },
}));
