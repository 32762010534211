import { useCallback } from 'react';

import { useBulkRemoveAsset } from '@portals/api/organizations';
import { useConfirmationModal } from '@portals/framework';

export function useConfirmBulkRemoveAssets() {
  const asyncConfirmationCheck = useConfirmationModal();
  const { mutate: bulkRemove, isLoading } = useBulkRemoveAsset();

  const onBulkRemove = useCallback(
    async ({
      selectedAssetIds,
      onSuccess,
    }: {
      selectedAssetIds: string[];
      onSuccess?: VoidFunction;
    }) => {
      const description =
        selectedAssetIds.length === 1
          ? `Are you sure you want to delete this asset?`
          : `Are you sure you want to delete ${selectedAssetIds.length} assets?`;

      const isConfirmed = await asyncConfirmationCheck({
        description,
      });

      if (isConfirmed) {
        bulkRemove(selectedAssetIds, { onSuccess });
      }
    },
    [asyncConfirmationCheck, bulkRemove]
  );

  return {
    onBulkRemove,
    isPendingBulkRemoveAssets: isLoading,
  };
}
