import { useHotkeys } from '@mantine/hooks';
import { size } from 'lodash/fp';
import { useCallback, useMemo } from 'react';

import { useOpenModal } from '@portals/redux';

import { useCurrentUserAccessibleTenants } from '../../hooks/users';

export function useSwitchTenant() {
  const openModal = useOpenModal();

  const accessibleTenants = useCurrentUserAccessibleTenants();

  const canSwitchTenant = useMemo(
    () => size(accessibleTenants) > 1,
    [accessibleTenants]
  );

  const openSwitchTenantModal = useCallback(() => {
    if (canSwitchTenant) {
      openModal('SwitchTenantModal');
    }
  }, [canSwitchTenant, openModal]);

  useHotkeys([['mod+alt+t', openSwitchTenantModal]]);

  return {
    canSwitchTenant,
    openSwitchTenantModal,
  };
}
