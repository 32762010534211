import { Button, Divider, Group, Modal, Stack, Text } from '@mantine/core';
import { get, isArray, isEmpty } from 'lodash/fp';
import React, { useRef } from 'react';

import { useDevice, useDeviceModelFiles } from '@portals/api/organizations';
import { AutoFormik } from '@portals/autoformik';
import { ModalProps, useSendCommandWithApproval } from '@portals/framework';
import { useOpenModal } from '@portals/redux';
import {
  FieldRendererProps,
  FieldTypeEnum,
  SupportedCommandFieldType,
  SupportedCommandType,
} from '@portals/types';

import {
  CustomParentChildSelectField,
  isParentChildSelectSchema,
} from './CustomParentChildSelectField';
import { FileSelector } from './FileSelector';

function normalizeValue(field: SupportedCommandFieldType, value: any) {
  switch (field.type) {
    // Currently we don't truly support datetime but rather Date only.
    case FieldTypeEnum.Datetime:
      return value?.toDateString();
    case FieldTypeEnum.JSON:
      return JSON.parse(value);
    default:
      return value;
  }
}

export interface RunCommandProps
  extends ModalProps<{
    deviceType: string;
    command: SupportedCommandType;
    deviceIds: string[];
    onSuccess?: () => void;
    onError?: (error: any) => void;
    prepopulatedParams?: Record<string, unknown>;
  }> {}

export function RunCommand({
  closeMe,
  data: {
    deviceType,
    command,
    deviceIds,
    onSuccess,
    prepopulatedParams,
    onError,
  },
}: RunCommandProps) {
  const openModal = useOpenModal();

  const device = useDevice(deviceIds[0]);

  let formFields: SupportedCommandFieldType[] = [];

  //Store normalized version of dynamic options values
  const normalizedDynamicListOptionsValues = useRef({});

  const { friendly_name, file_type, description, with_file, custom_fields } =
    command;

  const sendCommandWithApproval = useSendCommandWithApproval();
  const files = useDeviceModelFiles(deviceType, file_type);

  const onSendCommand = async (formData: Record<string, any> = {}) => {
    const extra_params = (custom_fields || []).reduce((acc, field) => {
      let finalValue;

      // Before sending the command, take the original values from the normalized dynamic list options values
      if (normalizedDynamicListOptionsValues.current[field.name]) {
        // If the type of the field is a multi select, we need to normalize each value and save it in array
        if (Array.isArray(formData[field.name])) {
          finalValue = formData[field.name].map((selectedOption) =>
            normalizeValue(
              field,
              normalizedDynamicListOptionsValues.current[field.name][
                selectedOption
              ]
            )
          );
        } else {
          finalValue = normalizeValue(
            field,
            normalizedDynamicListOptionsValues.current[field.name][
              formData[field.name]
            ]
          );
        }
      } else {
        finalValue = normalizeValue(field, formData[field.name]);
      }
      return {
        ...acc,
        [field.name]: finalValue,
      };
    }, {});

    try {
      await sendCommandWithApproval({
        command,
        deviceIds,
        file_id: formData.file_id,
        extra_params,
      });

      onSuccess?.();
      closeMe();
    } catch (error) {
      onError?.(error);
      console.error(error);
    }
  };

  // Build the command params form
  if (with_file) {
    const fileSelectorProps = {
      files: files.data || [],
      file_type,
      openModal,
      deviceType,
    };

    formFields.push({
      name: 'file_id',
      title: 'File',
      required: true,
      type: FieldTypeEnum.Custom,
      component: FileSelector,
      inputProps: fileSelectorProps,
    });
  }

  if (custom_fields && custom_fields.length > 0) {
    const adjustedCustomFields = custom_fields.map((field) => ({
      ...field,
      disabled: !!prepopulatedParams?.[field.name],
    }));

    // Only dynamic list (single/multi) field type has `path` property
    const dynamicListCommands = adjustedCustomFields.filter(
      (field) => !!field.path
    );

    if (dynamicListCommands && dynamicListCommands.length > 0) {
      dynamicListCommands.forEach((dynamicListCommand) => {
        if (!dynamicListCommand.path) return;

        const selectOptions = get(dynamicListCommand.path, device.data);

        if (isParentChildSelectSchema(selectOptions)) {
          formFields.push({
            name: dynamicListCommand.name,
            title: dynamicListCommand.title,
            type: FieldTypeEnum.Custom,
            required: dynamicListCommand.required,
            disabled: !!prepopulatedParams?.[dynamicListCommand.name],
            component: (field: FieldRendererProps) => (
              <CustomParentChildSelectField
                {...field}
                selectOptions={selectOptions}
              />
            ),
          });
        } else {
          // Create a normalized object with the select options array
          const normalizedSelectOptions = !isArray(selectOptions)
            ? []
            : (selectOptions as Array<any>)?.reduce((acc, value) => {
                acc[String(value)] = value;
                return acc;
              }, {});

          // Save the normalized object in the ref for later use (when sending the command)
          normalizedDynamicListOptionsValues.current[dynamicListCommand.name] =
            normalizedSelectOptions;

          if (normalizedSelectOptions) {
            formFields.push({
              name: dynamicListCommand.name,
              title: dynamicListCommand.title,
              type: dynamicListCommand.type,
              required: dynamicListCommand.required,
              options: normalizedSelectOptions,
              disabled: !!prepopulatedParams?.[dynamicListCommand.name],
            });
          }
        }
      });

      const fieldsWithoutDynamicListCommands = adjustedCustomFields?.filter(
        (field) => !field.path
      );

      formFields = [...formFields, ...fieldsWithoutDynamicListCommands];
    } else {
      formFields = [...formFields, ...adjustedCustomFields];
    }
  }

  return (
    <Modal
      opened
      onClose={closeMe}
      title="Send Command"
      sx={(theme) => ({
        '.modal-body': {
          padding: 0,
          marginBottom: theme.spacing.md,
        },
        '.modal-footer': {
          paddingRight: 0,
          paddingLeft: 0,
        },
      })}
    >
      <Stack spacing="sm">
        <Text data-testid="friendly-name-title" size="lg">
          {friendly_name}
        </Text>

        <Text data-testid="command-description" size="sm" color="blue_gray">
          {description}
        </Text>

        {!isEmpty(formFields) ? (
          <>
            <Divider mt="md" label="COMMAND PARAMS" />

            <AutoFormik
              fields={formFields}
              inProgress={false}
              handleSubmit={onSendCommand}
              modal={closeMe}
              submitTitle="Send"
              requireChange={false}
              initialValues={prepopulatedParams || {}}
              formikProps={{
                validateOnMount: true,
              }}
            />
          </>
        ) : (
          <>
            <Divider my="md" />

            <Group position="right">
              <Button
                data-testid="cancel-button"
                variant="default"
                onClick={closeMe}
              >
                Cancel
              </Button>

              <Button onClick={onSendCommand}>Send</Button>
            </Group>
          </>
        )}
      </Stack>
    </Modal>
  );
}
