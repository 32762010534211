import { Text } from '@mantine/core';
import React from 'react';

import { devicesEmptyStateSrc } from '@portals/assets';
import { EmptyState } from '@portals/core';

import { useOverviewDevicesContext } from './OverviewDevicesProvider';
import { ClaimDeviceButton } from '../../../components/ClaimDeviceButton';

export function OverviewDevicesEmptyState() {
  const { space } = useOverviewDevicesContext();

  return (
    <EmptyState
      media={<img src={devicesEmptyStateSrc} width={400} height="auto" />}
      messageTitle="No devices yet"
      messageDescription={
        <Text fz="xs" c="gray.5">
          It looks like you haven’t added any devices to this space.
          <br />
          Once you add devices, they’ll appear here for easy management.
        </Text>
      }
      action={
        <ClaimDeviceButton
          spaceId={space.data?.id}
          data-testid="add-device-button"
        >
          Add device
        </ClaimDeviceButton>
      }
    />
  );
}
