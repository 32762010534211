import { LoadingOverlay, Text } from '@mantine/core';
import { GeoJSON, Point } from 'geojson';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  IncidentType,
  useAllIncidents,
  useSpaces,
} from '@portals/api/organizations';
import {
  DeviceIncidentMarker,
  Map,
  MapListItem,
  ORGANIZATIONS_PRIORITY_COLORS,
} from '@portals/framework';
import {
  spaceIncidentsToPoints,
  findSpaceChildren,
  PRIORITY_OPTIONS,
  useGetSpaceLocation,
} from '@portals/utils';

import { useOverviewContext } from '../../../overview.context';
import { useCurrentSpace } from '../../../overview.hooks';
import { WidgetCardWrapper } from '../common/WidgetCardWrapper';

const mapper =
  (navigate: ReturnType<typeof useNavigate>) => (incident: IncidentType) => {
    const severityCategory = PRIORITY_OPTIONS[incident.priority];
    const color = ORGANIZATIONS_PRIORITY_COLORS[incident.priority - 1];
    const onClick = () => navigate(`/incident/${incident.id}`);

    return {
      id: incident.id,
      priority: incident.priority,
      category: 'temp',
      severityCategory,
      renderSinglePoint: () => (
        <DeviceIncidentMarker
          title={incident.title}
          description={incident.device_name}
          color={color}
          onClick={onClick}
        />
      ),
      renderClusterItem: () => (
        <MapListItem
          key={incident.id}
          title={incident.title}
          description={incident.device_name}
          color={color}
          onClick={onClick}
        />
      ),
    };
  };

export function IncidentMap() {
  const navigate = useNavigate();
  const overview = useOverviewContext();

  const space = useCurrentSpace();
  const spaces = useSpaces();

  const incidents = useAllIncidents(
    overview.isLocalDataLevel
      ? {
          status: 'active',
          spaceId: space?.id,
        }
      : {
          status: 'active',
          spaceTreePathName: space?.tree_path_name,
        }
  );

  const baseLocation = useGetSpaceLocation(space);

  const points = useMemo(() => {
    if (!incidents.isFetched || !spaces.isFetched || !space) return [];

    const filteredSpaces = overview.isLocalDataLevel
      ? [space]
      : findSpaceChildren(spaces.data, space);

    return spaceIncidentsToPoints(
      incidents.data || [],
      filteredSpaces,
      mapper(navigate)
    );
  }, [
    incidents.isFetched,
    incidents.data,
    spaces.isFetched,
    spaces.data,
    overview.isLocalDataLevel,
    space,
    navigate,
  ]);

  return (
    <WidgetCardWrapper
      header={
        <Text size="md" color="gray.5">
          Incidents Map
        </Text>
      }
    >
      <LoadingOverlay
        visible={
          !incidents.isFetched ||
          !spaces.isFetched ||
          incidents.isLoading ||
          spaces.isLoading
        }
      />

      {incidents.isFetched && spaces.isFetched ? (
        <Map
          points={points as Array<GeoJSON.Feature<Point>>}
          base={baseLocation}
          emptyStateText="No open incidents"
        />
      ) : null}
    </WidgetCardWrapper>
  );
}
