import {
  castArray,
  forEach,
  isNil,
  isNumber,
  isUndefined,
  map,
  size,
  toString,
} from 'lodash/fp';

import {
  PaginatedFilterTypeEnum,
  PaginatedQueryFilterType,
  PaginatedQueryParamsType,
} from '@portals/types';

export function getFilterSearchParams<TData extends object>(
  filter: PaginatedQueryFilterType<TData>
) {
  const isGrouped = !!filter?.groupKey;
  const queryPrefix = isGrouped ? `q[groupings][${filter.groupKey}]` : 'q';

  switch (filter?.type) {
    case PaginatedFilterTypeEnum.Date: {
      const gte = filter.value?.gte;
      const lte = filter.value?.lte;

      if (!gte && !lte) return null;

      return [
        {
          id: `${queryPrefix}[${filter.id}_gteq]`,
          value: isNil(gte) ? '' : gte.toISOString(),
        },
        {
          id: `${queryPrefix}[${filter.id}_lteq]`,
          value: isNil(lte) ? '' : lte.toISOString(),
        },
      ];
    }

    case PaginatedFilterTypeEnum.Number: {
      const gte = filter.value?.gte;
      const lte = filter.value?.lte;

      if (!isNumber(gte) && !isNumber(lte)) return null;

      return [
        {
          id: `${queryPrefix}[${filter.id}_gteq]`,
          value: isUndefined(gte) ? '' : gte,
        },
        {
          id: `${queryPrefix}[${filter.id}_lteq]`,
          value: isUndefined(lte) ? '' : lte,
        },
      ];
    }

    case PaginatedFilterTypeEnum.SingleSelect: {
      if (!filter.value) return null;

      return {
        id: `${queryPrefix}[${filter.id}_eq]`,
        value: filter.value[0],
      };
    }

    case PaginatedFilterTypeEnum.Select: {
      if (!filter.value) return null;

      if (size(filter.value) > 1) {
        return map(
          (value) => ({
            id: `${queryPrefix}[${filter.id}_in][]`,
            value,
          }),
          filter.value
        );
      } else {
        return {
          id: `${queryPrefix}[${filter.id}_eq]`,
          value: filter.value[0],
        };
      }
    }

    case PaginatedFilterTypeEnum.Boolean: {
      if (isNil(filter.value)) return null;

      return {
        id: `${queryPrefix}[${filter.id}]`,
        value: filter.value,
      };
    }

    case PaginatedFilterTypeEnum.Null: {
      if (isNil(filter.value)) return null;

      return {
        id: `${queryPrefix}[${filter.id}_null]`,
        value: filter.value,
      };
    }

    case PaginatedFilterTypeEnum.NotEq: {
      if (isNil(filter.value)) return null;

      return {
        id: `${queryPrefix}[${filter.id}_not_eq]`,
        value: filter.value,
      };
    }

    case PaginatedFilterTypeEnum.Eq: {
      if (isNil(filter.value)) return null;

      return {
        id: `${queryPrefix}[${filter.id}_eq]`,
        value: filter.value,
      };
    }

    case PaginatedFilterTypeEnum.Contains: {
      if (isNil(filter.value)) return null;

      return {
        id: `${queryPrefix}[${filter.id}_i_cont]`,
        value: filter.value,
      };
    }

    case PaginatedFilterTypeEnum.NotContains: {
      if (isNil(filter.value)) return null;

      if (size(filter.value) > 1) {
        return map(
          (value) => ({
            id: `${queryPrefix}[${filter.id}_not_in][]`,
            value,
          }),
          filter.value
        );
      } else {
        return {
          id: `${queryPrefix}[${filter.id}_not_eq]`,
          value: filter.value,
        };
      }
    }

    case PaginatedFilterTypeEnum.LtreeStartsWith: {
      if (isNil(filter.value)) return null;

      return {
        id: `${queryPrefix}[${filter.id}_ltree_starts_with]`,
        value: filter.value,
      };
    }

    case PaginatedFilterTypeEnum.Text:
    default: {
      return {
        id: `${queryPrefix}[${filter.id}_i_cont]`,
        value: filter.value,
      };
    }
  }
}

// Returns URL search params for a given table state
// Columns are used to determine filter types - text, date, number...
export function getSearchParams<TData extends object>({
  filters,
  sorting,
  pagination,
}: PaginatedQueryParamsType<TData>) {
  const searchParams = new URLSearchParams();
  const groupedFilters: Record<string, URLSearchParams> = {};

  // Column filters params
  forEach((filter) => {
    const filterSearchParams = castArray(getFilterSearchParams(filter));

    forEach((currFilter) => {
      if (!currFilter) return;

      if (filter.groupKey) {
        if (!groupedFilters[filter.groupKey]) {
          groupedFilters[filter.groupKey] = new URLSearchParams();
        }

        groupedFilters[filter.groupKey].append(currFilter.id, currFilter.value);
      } else {
        searchParams.append(currFilter.id, currFilter.value);
      }
    }, filterSearchParams);
  }, filters);

  // Add grouped filters
  Object.keys(groupedFilters).forEach((groupKey) => {
    // Add the `or` operator between the group's values
    searchParams.append(`q[groupings][${groupKey}][m]`, 'or');

    groupedFilters[groupKey].forEach((value, key) => {
      searchParams.append(key, value);
    });
  });

  // Columns sort by params
  forEach(({ id, desc }) => {
    searchParams.append('q[s]', `${id} ${desc ? 'desc' : 'asc'}`);
  }, sorting);

  searchParams.append('page', toString(pagination.page + 1));
  searchParams.append('per_page', toString(pagination.pageSize));

  return searchParams;
}

export function buildUrlFromFilters<TData extends object>({
  url,
  filters = [],
  sorting = [],
  pagination,
}: {
  url: string;
} & PaginatedQueryParamsType<TData>) {
  const requestUrl = new URL(url);

  const tableStateSearchParams = getSearchParams<TData>({
    filters,
    sorting,
    pagination,
  });

  tableStateSearchParams.forEach((value, key) => {
    requestUrl.searchParams.append(key, value);
  });

  return requestUrl.toString();
}
