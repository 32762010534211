import { SimpleGrid } from '@mantine/core';
import { sum } from 'lodash/fp';
import React, { FC } from 'react';

import {
  DeviceDetailsType,
  useActiveDeviceCommands,
  useConfig,
} from '@portals/api/organizations';
import { NumericMetricWidget, StatusWidget } from '@portals/device-widgets';
import { getDeviceStatusMetadata } from '@portals/framework/route-modals';

interface OverviewRowProps {
  device: DeviceDetailsType;
}

export const OverviewRow: FC<OverviewRowProps> = ({ device }) => {
  const deviceStatusMetadata = getDeviceStatusMetadata(device.status);

  const configuration = useConfig();

  const activeDeviceCommands = useActiveDeviceCommands(device.id);

  const hasSupportCenter =
    configuration.data?.portal_capabilities?.support_center;

  return (
    <SimpleGrid cols={hasSupportCenter ? 4 : 3} spacing="xl">
      <StatusWidget
        title="Status"
        color={deviceStatusMetadata.indicator}
        value={deviceStatusMetadata.displayName}
      />

      <NumericMetricWidget
        title="Active Incidents"
        value={sum(device?.incidents)}
      />

      <NumericMetricWidget
        title="Pending Commands"
        value={activeDeviceCommands?.data?.length || 0}
      />

      {hasSupportCenter ? (
        <NumericMetricWidget
          title="Open Tickets"
          value={device?.unresolved_tickets_count || 0}
        />
      ) : null}
    </SimpleGrid>
  );
};
