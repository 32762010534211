import { createStyles, Stack, Text } from '@mantine/core';
import React from 'react';

import { InfiniteVirtualizedView } from '@lib/infinite-virtualized-view';
import { DeviceType, useIsCecEnabled } from '@portals/api/organizations';

import { DeviceCard } from './device-grid/DeviceCard';
import { DeviceCardSkeleton } from './device-grid/DeviceCardSkeleton';
import { DeviceTile } from './device-tiles/DeviceTile';
import { DeviceTileSkeleton } from './device-tiles/DeviceTileSkeleton';
import { DevicesBulkActions } from './DevicesBulkActions';
import { DevicesControls } from './DevicesControls';
import { OverviewDevicesEmptyState } from './OverviewDevicesEmptyState';
import { useOverviewDevicesContext } from './OverviewDevicesProvider';
import { FilteredResultsEmptyState } from '../FilteredResultsEmptyState';
import { useOverviewContext } from '../overview.context';
import { SpaceDataLevelControl } from '../SpaceDataLevelControl';

export function OverviewDevices() {
  const { classes } = useStyles();

  const { totalDevicesCount, isLoading, form } = useOverviewDevicesContext();

  if (!isLoading && !form.isDirty() && totalDevicesCount === 0) {
    return (
      <div className={classes.container}>
        <Stack h="100%">
          <Stack>
            <SpaceDataLevelControl />

            <DevicesControls />
          </Stack>

          <OverviewDevicesEmptyState />
        </Stack>
      </div>
    );
  }

  return (
    <Stack spacing="xl" className={classes.container}>
      <Stack>
        <SpaceDataLevelControl />

        <DevicesControls />

        <Text c="gray.7" data-testid="devices-counter">
          Showing {totalDevicesCount} devices
        </Text>
      </Stack>

      <MainContent />

      <DevicesBulkActions />
    </Stack>
  );
}

function MainContent() {
  const isCecEnabled = useIsCecEnabled();

  const overview = useOverviewContext();

  const {
    form,
    totalDevicesCount,
    displayType,
    isLoading,
    allDevices,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    clearFilters,
  } = useOverviewDevicesContext();

  if (!isLoading && form.isDirty() && totalDevicesCount === 0) {
    return <FilteredResultsEmptyState onClearFilters={clearFilters} />;
  }

  return (
    <InfiniteVirtualizedView<DeviceType>
      displayType={displayType}
      scrollableRef={overview.scrollableRef}
      getItemId={(device) => device.id}
      tileItemRenderer={(device) => <DeviceTile device={device} />}
      tileItemSkeleton={
        <DeviceTileSkeleton cecSkeleton={Boolean(isCecEnabled)} />
      }
      flatItems={allDevices}
      isLoading={isLoading}
      hasNextPage={hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
      fetchNextPage={fetchNextPage}
      gridItemSkeleton={<DeviceCardSkeleton />}
      gridItemRenderer={(device) => (
        <DeviceCard key={device.id} device={device} />
      )}
    />
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    position: 'relative',
    height: '100%',
    paddingInline: theme.spacing.xxl,
  },
}));
