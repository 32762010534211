import { createStyles, Stack, Text } from '@mantine/core';
import { AnimatePresence } from 'framer-motion';
import React from 'react';

import { InfiniteVirtualizedView } from '@lib/infinite-virtualized-view';
import { AssetType } from '@portals/api/organizations';
import { TableDetailsPanel } from '@portals/table';

import { AssetCard } from './asset-grid/AssetCard';
import { AssetCardSkeleton } from './asset-grid/AssetCardSkeleton';
import { AssetTile } from './asset-tiles/AssetTile';
import { AssetTileSkeleton } from './asset-tiles/AssetTileSkeleton';
import { AssetsBulkActions } from './AssetsBulkActions';
import { AssetsControls } from './AssetsControls';
import { OverviewAssetsEmptyState } from './OverviewAssetsEmptyState';
import { useOverviewAssetsContext } from './OverviewAssetsProvider';
import { AssetDetailsPanel } from '../../assets/AssetDetailsPanel';
import { FilteredResultsEmptyState } from '../FilteredResultsEmptyState';
import { useOverviewContext } from '../overview.context';
import { SpaceDataLevelControl } from '../SpaceDataLevelControl';

export function OverviewAssets() {
  const { classes } = useStyles();

  const { isLoading, totalAssetsCount, form } = useOverviewAssetsContext();

  if (!isLoading && !form.isDirty() && totalAssetsCount === 0) {
    return (
      <div className={classes.container}>
        <Stack h="100%">
          <Stack>
            <SpaceDataLevelControl />

            <AssetsControls />
          </Stack>

          <OverviewAssetsEmptyState />
        </Stack>
      </div>
    );
  }

  return (
    <Stack spacing="xl" className={classes.container}>
      <Stack>
        <SpaceDataLevelControl />

        <AssetsControls />

        <Text c="gray.7">Showing {totalAssetsCount} assets</Text>
      </Stack>

      <MainContent />
    </Stack>
  );
}

function MainContent() {
  const overview = useOverviewContext();

  const {
    form,
    displayType,
    allAssets,
    hasNextPage,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    totalAssetsCount,
    clearFilters,
    activeAssetId,
    setActiveAssetId,
  } = useOverviewAssetsContext();

  if (!isLoading && form.isDirty() && totalAssetsCount === 0) {
    return <FilteredResultsEmptyState onClearFilters={clearFilters} />;
  }

  return (
    <>
      <AnimatePresence>
        {activeAssetId && (
          <TableDetailsPanel onClose={() => setActiveAssetId(null)} type="page">
            <AssetDetailsPanel
              assetId={activeAssetId}
              onClose={() => setActiveAssetId(null)}
            />
          </TableDetailsPanel>
        )}
      </AnimatePresence>

      <InfiniteVirtualizedView<AssetType>
        displayType={displayType}
        scrollableRef={overview.scrollableRef}
        tileItemSkeleton={<AssetTileSkeleton />}
        tileItemRenderer={(asset) => <AssetTile asset={asset} />}
        getItemId={(asset) => asset.id}
        gridItemSkeleton={<AssetCardSkeleton />}
        flatItems={allAssets}
        hasNextPage={hasNextPage}
        isLoading={isLoading}
        isFetchingNextPage={isFetchingNextPage}
        fetchNextPage={fetchNextPage}
        gridItemRenderer={(asset) => <AssetCard asset={asset} />}
      />

      <AssetsBulkActions />
    </>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    position: 'relative',
    height: '100%',
    paddingInline: theme.spacing.xxl,
  },
}));
