import { useSearchParams } from 'react-router-dom';

import { OVERVIEW_SETTINGS_TABS } from './settings-drawer.constants';
import { OverviewSettingsTabEnum } from './settings-drawer.types';
import { SETTINGS_TAB_SEARCH_PARAM } from '../../../../constants/global-search-params.constants';

export function useCurrentSettingsTab() {
  const [searchParams] = useSearchParams();

  const settingsTab = searchParams.get(SETTINGS_TAB_SEARCH_PARAM);

  if (settingsTab) {
    return OVERVIEW_SETTINGS_TABS[settingsTab as OverviewSettingsTabEnum];
  }
}
