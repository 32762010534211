import { Button, createStyles, Stack, Text } from '@mantine/core';
import { find } from 'lodash/fp';
import React, {
  useCallback,
  useDeferredValue,
  useEffect,
  useState,
} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
  SUPPORTED_C2C_CONNECTORS,
  SupportedC2cConnectorName,
  useCecPartners,
  useClaimDeviceByQrCode,
  useConnectors,
  useOrganizationConfig,
  useSendInternalSupportEmail,
} from '@portals/api/organizations';
import { useCurrentUser } from '@portals/api/ui';
import { C2CIntegrationId } from '@portals/framework';
import { RouteModal, useRouteModals } from '@portals/framework/route-modals';
import { useIntegrations, useOpenModal } from '@portals/redux';
import { CecPartnerType } from '@portals/types';

import { useConnectRouteModalIntegrationGroups } from './connect-route-modal.hooks';
import { C2CIntegrationConnectionCard } from './connection-card/C2CIntegrationConnectionCard';
import { CecPartnerConnectionCard } from './connection-card/CecPartnerConnectionCard';
import { ConnectRouteModalEmptyState } from './ConnectRouteModalEmptyState';
import { ConnectRouteModalFooter } from './ConnectRouteModalFooter';
import { ConnectRouteModalHeader } from './ConnectRouteModalHeader';
import {
  CLOUD_ID_SEARCH_PARAM,
  PARTNER_SEARCH_PARAM,
} from '../../../constants/global-search-params.constants';
import {
  OrgC2CIntegrationItem,
  useIntegrationsList,
} from '../../../hooks/c2c-integrations';
import { useOpenTrialAnnouncementModal } from '../../../hooks/trial-announcement';
import { useOpenClaimDeviceModal } from '../../components/ClaimDeviceButton';
import {
  AddC2cConnectorModalProps,
  ComingSoonC2CIntegrationModalProps,
  ConfirmCecConnectionModalProps,
  ConnectionSuccessModalProps,
  ThirdPartyC2CConnectorsModalProps,
} from '../../modals';

export function ConnectRouteModal() {
  return (
    <RouteModal modalId="connect">
      <ConnectRouteModalContent />
    </RouteModal>
  );
}

function ConnectRouteModalContent() {
  const { classes } = useStyles();

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [searchTerm, setSearchTerm] = useState('');
  const deferredSearchTerm = useDeferredValue(searchTerm);
  const isStale = searchTerm !== deferredSearchTerm;

  const routeModals = useRouteModals();
  const openModal = useOpenModal();
  const openClaimDeviceModal = useOpenClaimDeviceModal();
  const openTrialAnnouncementModal = useOpenTrialAnnouncementModal();

  const currentUser = useCurrentUser();
  const organizationConfig = useOrganizationConfig();

  const cecPartners = useCecPartners();
  const connectors = useConnectors();
  const integrations = useIntegrations();
  const integrationsMetadata = useIntegrationsList();

  const sendSupportEmail = useSendInternalSupportEmail();
  const claimDeviceByQrCode = useClaimDeviceByQrCode();

  const { groups, areAllGroupsEmpty } = useConnectRouteModalIntegrationGroups({
    integrationsMetadata,
    cecPartners: cecPartners.data,
    searchTerm: deferredSearchTerm,
  });

  const showConnectedDevicesSection =
    groups.connectedDevices.length > 0 || groups.comingSoon.length > 0;

  function getIsC2cIntegrationConnected(integrationId: C2CIntegrationId) {
    if (isC2CConnector(integrationId)) {
      const activeConnector = find(
        (connector) =>
          connector.state === 'active' && connector.name === integrationId,
        connectors.data
      );

      return activeConnector !== undefined;
    }

    return integrations?.[integrationId]?.state === 'active';
  }

  const openSuggestBrandModal = useCallback(() => {
    openModal('SuggestBrandModal');
  }, [openModal]);

  const onConnectionSuccess = useCallback(
    (cecPartner: CecPartnerType) => {
      const cloudIdSearchParam = searchParams.get(CLOUD_ID_SEARCH_PARAM);

      if (cloudIdSearchParam) {
        claimDeviceByQrCode.mutate(
          { qr: cloudIdSearchParam },
          {
            onSuccess: () => {
              setSearchParams(
                (prev) => {
                  prev.delete(CLOUD_ID_SEARCH_PARAM);
                  return prev;
                },
                { replace: true }
              );
              routeModals.onClose();
            },
          }
        );
      } else {
        openModal<ConnectionSuccessModalProps['data']>(
          'ConnectionSuccessModal',
          {
            cecPartner,
            onOpenClaimDevice: () => {
              routeModals.onClose();
              openClaimDeviceModal({ partnerId: cecPartner.id });
            },
          }
        );
      }
    },
    [
      claimDeviceByQrCode,
      openClaimDeviceModal,
      openModal,
      routeModals,
      searchParams,
      setSearchParams,
    ]
  );

  const onContinueWithTrial = useCallback(
    (cecPartner: CecPartnerType) => {
      openModal<ConfirmCecConnectionModalProps['data']>(
        'ConfirmCecConnectionModal',
        {
          cecPartner,
          onConnectionSuccess: () => onConnectionSuccess(cecPartner),
        }
      );
    },
    [onConnectionSuccess, openModal]
  );

  const openConfirmCecConnectionModal = useCallback(
    (cecPartner: CecPartnerType) => {
      openTrialAnnouncementModal({
        onContinueWithTrial: () => onContinueWithTrial(cecPartner),
      });
    },
    [onContinueWithTrial, openTrialAnnouncementModal]
  );

  function onAskToConnect(integration: OrgC2CIntegrationItem) {
    openModal<ComingSoonC2CIntegrationModalProps['data']>(
      'ComingSoonC2cIntegrationModal',
      { integration }
    );

    sendSupportEmail.mutate({
      title: `${organizationConfig?.name} is asking to integrate with ${integration.name}`,
      message: `Dear Support team member,

${organizationConfig?.name} has asked to integrate with ${integration.name}.
Please get back to the requesting user: ${currentUser.data?.name} at ${currentUser.data?.email} to understand their need.

The Xyte team`,
    });
  }

  function openThirdPartyConnectorsModal(integration: OrgC2CIntegrationItem) {
    openModal<ThirdPartyC2CConnectorsModalProps['data']>(
      'ThirdPartyC2CConnectorsModal',
      {
        integration,
        onAskToConnect: () => onAskToConnect(integration),
        onConnectWithThirdParty: openC2cConnectorModal,
      }
    );
  }

  function openC2cConnectorModal(connectorName: SupportedC2cConnectorName) {
    openModal<AddC2cConnectorModalProps['data']>('AddC2cConnectorModal', {
      connectorName,
    });
  }

  function onC2cConnectClick(integration: OrgC2CIntegrationItem) {
    openTrialAnnouncementModal({
      onContinueWithTrial: () => {
        if (integration.isComingSoon) {
          onAskToConnect(integration);
        } else if (integration.thirdPartyConnectors) {
          openThirdPartyConnectorsModal(integration);
        } else if (isC2CConnector(integration.id)) {
          openC2cConnectorModal(integration.id);
        } else if (integration.href) {
          navigate(integration.href);
        }
      },
    });
  }

  useEffect(
    function initSelectedPartnerFromSearchParams() {
      if (!cecPartners.data) return;

      const partnerSearchParam = searchParams.get(PARTNER_SEARCH_PARAM);

      if (!partnerSearchParam) return;

      const partner = cecPartners.data.find(
        (cecPartner) => cecPartner.name === partnerSearchParam
      );

      // If there's a partner query param, and it's not connected, open the `ConfirmCecConnectionModal`
      if (partner && !partner.connected) {
        openConfirmCecConnectionModal(partner);
      }

      // Clear the partner query param
      setSearchParams(
        (prevParams) => {
          prevParams.delete(PARTNER_SEARCH_PARAM);

          return prevParams;
        },
        { replace: true }
      );
    },
    [
      cecPartners.data,
      openConfirmCecConnectionModal,
      searchParams,
      setSearchParams,
    ]
  );

  if (areAllGroupsEmpty) {
    return (
      <div className={classes.contentWrapper}>
        <ConnectRouteModalHeader
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          isStale={isStale}
        />
        <ConnectRouteModalEmptyState
          searchTerm={deferredSearchTerm}
          onSuggestBrandClick={openSuggestBrandModal}
          onConnectDomotzClick={() =>
            openTrialAnnouncementModal({
              onContinueWithTrial: () => openC2cConnectorModal('domotz'),
            })
          }
        />
      </div>
    );
  }

  return (
    <div className={classes.contentWrapper}>
      <ConnectRouteModalHeader
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        isStale={isStale}
      />

      <div className={classes.bodyContent}>
        <Stack spacing="xxl" w="100%">
          {groups.prioritized.length > 0 && (
            <Stack spacing="sm">
              <Text c="gray.6">Unified collaboration</Text>

              <div className={classes.grid}>
                {groups.prioritized.map((integration) => (
                  <C2CIntegrationConnectionCard
                    key={integration.id}
                    integration={integration}
                    highlightText={deferredSearchTerm}
                    isConnected={getIsC2cIntegrationConnected(integration.id)}
                    onActionClick={() => onC2cConnectClick(integration)}
                  />
                ))}
              </div>
            </Stack>
          )}

          {showConnectedDevicesSection && (
            <Stack spacing="sm">
              <Text c="gray.6">Connected devices</Text>

              <div className={classes.grid}>
                {groups.connectedDevices.map((item) => {
                  if (isCecPartner(item)) {
                    return (
                      <CecPartnerConnectionCard
                        key={item.id}
                        cecPartner={item}
                        highlightText={deferredSearchTerm}
                        action={
                          <Button
                            onClick={() => openConfirmCecConnectionModal(item)}
                            data-testid="connect-brand-button"
                          >
                            Connect
                          </Button>
                        }
                      />
                    );
                  }

                  return (
                    <C2CIntegrationConnectionCard
                      key={item.id}
                      integration={item}
                      highlightText={deferredSearchTerm}
                      isConnected={getIsC2cIntegrationConnected(item.id)}
                      onActionClick={() => onC2cConnectClick(item)}
                    />
                  );
                })}

                {groups.comingSoon.map((integration) => (
                  <C2CIntegrationConnectionCard
                    key={integration.id}
                    integration={integration}
                    highlightText={deferredSearchTerm}
                    isConnected={getIsC2cIntegrationConnected(integration.id)}
                    onActionClick={() => onC2cConnectClick(integration)}
                  />
                ))}
              </div>
            </Stack>
          )}
        </Stack>

        <ConnectRouteModalFooter onSuggestBrandClick={openSuggestBrandModal} />
      </div>
    </div>
  );
}

const useStyles = createStyles((theme) => ({
  contentWrapper: {
    overflow: 'auto',
    height: '100%',
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    backgroundColor: theme.colors.gray[0],
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(290px, 1fr))',
    gridAutoRows: 'minmax(290px, auto)',
    gap: theme.spacing.xl,
    placeContent: 'start',
  },
  bodyContent: {
    display: 'grid',
    gridTemplateRows: '1fr auto',
    gap: theme.spacing.xxl,
    height: '100%',
    maxWidth: 1280,
    width: '100%',
    marginInline: 'auto',
    paddingBlock: theme.spacing.xxl,
    paddingInline: theme.spacing.lg,
  },
}));

function isC2CConnector(
  connectorName: SupportedC2cConnectorName | C2CIntegrationId
): connectorName is SupportedC2cConnectorName {
  const connector = SUPPORTED_C2C_CONNECTORS.find(
    (connector) => connector === connectorName
  );

  return connector !== undefined;
}

function isCecPartner(
  item: CecPartnerType | OrgC2CIntegrationItem
): item is CecPartnerType {
  return 'organization_signup_enabled' in item;
}
