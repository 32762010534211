import { uiGlobalQueryKeys } from '../global-query-keys';

export const USERS_API_URL = 'ui/users';

export const usersQueryKeys = {
  global: [...uiGlobalQueryKeys.users],
  all: () => [...usersQueryKeys.global, USERS_API_URL],
  excludeDeactivated: () => [...usersQueryKeys.all(), 'exclude_deactivated'],
  self: () => [...usersQueryKeys.all(), 'self'],
  welcomeDetails: () => ['welcome_details'],
  userAssociationsAndAccess: (userId: string) => [
    ...usersQueryKeys.global,
    'user_associations_and_access',
    userId,
  ],
};
