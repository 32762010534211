import { find, findIndex, groupBy, keys, reduce } from 'lodash/fp';
import React, { useMemo } from 'react';

import {
  SpaceType,
  useDevice,
  useSpace,
  useSpaces,
} from '@portals/api/organizations';
import { DeviceLocationType, DeviceMapConfig } from '@portals/device-widgets';
import { DeviceStatusType } from '@portals/types';
import { jitterPosition } from '@portals/utils';

import {
  getDeviceMarkerCoordinates,
  getCoordinatesKey,
} from '../utils/device-location-widgets-wrapper.utils';

interface DeviceCoordinates {
  id: string;
  coordinates: {
    lat: number;
    lng: number;
  } | null;
}

interface MarkerProps {
  coordinates: { lat: number; lng: number } | null;
  deviceId: string;
  deviceName: string;
  status: DeviceStatusType;
  onClick?: () => void;
}

interface UseDeviceLocationWidgetProps {
  /**
   * Tolerance for grouping nearby coordinate points (in map degrees, which is a distance unit).
   * Coordinates within this distance will be considered the same group and will be jittered.
   * Default: 0.00005 (approximately 5-6 meters depending on latitude)
   */
  proximityTolerance?: number;
  deviceId: string;
  renderMainMarker: (props: MarkerProps) => React.ReactNode;
  renderChildMarker: (props: MarkerProps) => React.ReactNode;
  onChildClick?: (childDeviceId: string) => void;
}

export function useDeviceLocationWidget({
  deviceId,
  renderMainMarker,
  renderChildMarker,
  onChildClick,
  proximityTolerance = 0.00005, // Default value provides proximity grouping of ~5-6 meters
}: UseDeviceLocationWidgetProps) {
  const device = useDevice(deviceId);
  const space = useSpace({ spaceId: device.data?.space_id });
  const spaces = useSpaces();

  const mainDeviceCoordinates = getDeviceMarkerCoordinates({
    deviceLastKnowState: device.data?.last_known_state?.state,
    deviceState: device.data?.state,
    space,
  });

  const devicesMapConfig = useMemo<(DeviceMapConfig | null)[]>(() => {
    if (!device.data) return [];

    const currentDevice = {
      type: DeviceLocationType.Main,
      id: device.data?.id,
      coordinates: mainDeviceCoordinates,
      name: device.data?.name,
      status: device.data?.state?.status,
      renderSinglePoint: () =>
        renderMainMarker({
          coordinates: mainDeviceCoordinates,
          deviceId: device.data.id,
          deviceName: device.data.name,
          status: device.data.state?.status ?? 'error',
        }),
    };

    // Device has no children, render only the main device marker on map
    if (!device.data.child_devices_count) {
      return [currentDevice];
    }

    const childDevicesCoordinates: DeviceCoordinates[] =
      device.data.child_devices?.map((childDevice) => {
        const childSpaceData = find(
          { id: childDevice.space_id },
          spaces.data
        ) as SpaceType;

        return {
          id: childDevice.id,
          coordinates: getDeviceMarkerCoordinates({
            deviceLastKnowState: childDevice.last_known_state?.state,
            deviceState: childDevice.state,
            space: childSpaceData,
          }),
        };
      }) || [];

    const allDevicesCoordinates: DeviceCoordinates[] = [
      // Main device
      {
        id: device.data.id,
        coordinates: mainDeviceCoordinates,
      },

      // Child devices
      ...childDevicesCoordinates,
    ];

    const groupedByCoordinates: Record<string, DeviceCoordinates[]> = groupBy(
      ({ coordinates }) =>
        coordinates ? getCoordinatesKey(coordinates, proximityTolerance) : null,
      allDevicesCoordinates
    );

    const numOfDevicesPerCoord: Record<string, number> = reduce(
      (acc, coordinate) => ({
        ...acc,
        [coordinate]: groupedByCoordinates[coordinate].length,
      }),
      {} as Record<string, number>,
      keys(groupedByCoordinates)
    );

    const childDevices: (DeviceMapConfig | null)[] =
      device.data?.child_devices?.map((childDevice) => {
        const childSpaceData = find(
          { id: childDevice.space_id },
          spaces.data
        ) as SpaceType;

        let coordinates = getDeviceMarkerCoordinates({
          deviceLastKnowState: childDevice?.last_known_state?.state,
          deviceState: childDevice?.state,
          space: childSpaceData,
        });

        if (!coordinates) {
          return null;
        }

        const coordKey = getCoordinatesKey(coordinates, proximityTolerance);

        if (numOfDevicesPerCoord[coordKey] > 1) {
          const deviceIndexInCoordGroup = findIndex(
            { id: childDevice.id },
            groupedByCoordinates[coordKey]
          );

          coordinates = jitterPosition(
            coordinates,
            deviceIndexInCoordGroup,
            numOfDevicesPerCoord[coordKey]
          );
        }

        return {
          type: DeviceLocationType.Child,
          id: childDevice.id,
          coordinates,
          name: childDevice?.name,
          status: childDevice?.state?.status,
          onClick: onChildClick
            ? () => onChildClick(childDevice.id)
            : undefined,
          renderSinglePoint: () =>
            renderChildMarker({
              coordinates,
              deviceId: childDevice.id,
              deviceName: childDevice.name,
              status: childDevice?.state?.status ?? 'error',
              onClick: onChildClick
                ? () => onChildClick(childDevice.id)
                : undefined,
            }),
        };
      }) || [];

    return [currentDevice, ...childDevices];
  }, [
    device.data,
    mainDeviceCoordinates,
    onChildClick,
    renderChildMarker,
    renderMainMarker,
    spaces.data,
    proximityTolerance,
  ]);

  return {
    device,
    space,
    devicesMapConfig,
  };
}
