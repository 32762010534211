import { Box, createStyles } from '@mantine/core';
import React from 'react';

import { ReactComponent as InfoCircleIcon } from '@portals/icons/bold/info-circle.svg';

import { useDeviceOverviewContext } from '../../DeviceOverviewContext';

export interface ChildDeviceMarkerProps {
  deviceId: string;
  shouldRenderWarningIcon: boolean;
  color?: string;
  lat?: number;
  lng?: number;
}

export function ChildDeviceMarker({
  deviceId,
  color,
  shouldRenderWarningIcon,
}: ChildDeviceMarkerProps) {
  const { selectedDeviceId, setSelectedDeviceId, isFullscreen } =
    useDeviceOverviewContext();
  const { classes, theme } = useStyles();

  return (
    <Box
      className={classes.childWrapper}
      onClick={() => isFullscreen && setSelectedDeviceId(deviceId)}
      id="device-location-map-marker"
    >
      {shouldRenderWarningIcon ? (
        <InfoCircleIcon className={classes.infoCircleIcon} />
      ) : (
        <Box
          className={classes.child}
          bg={color}
          id="device-location-map-marker"
        />
      )}

      <Box
        className={classes.childBackground}
        id="device-location-map-marker"
      />

      <Box
        className={classes.selectedChildIndicator}
        bg={
          selectedDeviceId === deviceId
            ? theme.fn.rgba(theme.black, 0.25)
            : 'transparent'
        }
        id="device-location-map-marker"
      />
    </Box>
  );
}

const useStyles = createStyles((theme) => ({
  childWrapper: {
    cursor: 'pointer',
    width: 18,
    height: 18,
    boxShadow: theme.shadows.md,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    position: 'relative',
    zIndex: 999,
    transition: 'transform 0.2s ease-in-out',
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
  selectedChildIndicator: {
    width: 36,
    height: 36,
    borderRadius: '50%',
    position: 'absolute',
    zIndex: 1,
    transition: 'background-color 0.15s ease-in-out',
  },
  child: {
    width: 12,
    height: 12,
    borderRadius: '50%',
    zIndex: 3,
  },
  infoCircleIcon: {
    width: 16,
    height: 16,
    borderRadius: '50%',
    zIndex: 3,
    color: theme.colors.gray[5],
  },
  childBackground: {
    zIndex: 2,
    width: 18,
    height: 18,
    background: theme.white,
    borderRadius: '50%',
    position: 'absolute',
    borderStyle: 'solid',
    borderColor: theme.colors.blue_gray[1],
  },
}));
