import { Anchor, Group, LoadingOverlay } from '@mantine/core';
import { find, keys } from 'lodash/fp';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { useDeviceModels } from '@portals/api/organizations';
import { DeviceAvatar, DeviceModelIconProps } from '@portals/framework';
import { SmartTable } from '@portals/table';
import { TableColumn } from '@portals/types';

import { useCurrentSpace } from '../../../overview.hooks';

const COLUMNS: Array<TableColumn<DevicesByModelType>> = [
  {
    dataField: 'model',
    text: 'Model',
    sort: true,
    minWidth: 200,
    maxWidth: 200,
    formatter: (_, { model, icon, img, space_tree_path_name }) => (
      <Anchor
        component={Link}
        to={`/devices?q[device_model_name_i_cont]=${encodeURIComponent(
          model
        )}&q[space_tree_path_name_i_cont]=${encodeURIComponent(
          space_tree_path_name
        )}`}
      >
        <Group align="center">
          <DeviceAvatar radius="md" color="indigo" icon={icon} src={img} />

          {model || 'Unknown'}
        </Group>
      </Anchor>
    ),
  },
  {
    dataField: 'claimed_devices',
    text: 'Claimed Devices',
    minWidth: 200,
    maxWidth: 200,
    sort: true,
  },
  {
    dataField: 'online_devices',
    text: 'Online Devices',
    minWidth: 200,
    maxWidth: 200,
    sort: true,
  },
];

interface DevicesByModelType {
  model: string;
  space_tree_path_name: string;
  claimed_devices: number;
  online_devices: number;
  icon?: DeviceModelIconProps['iconName'];
  img?: string;
}

interface DevicesByModelTableProps {
  isLocal?: boolean;
}

export function DevicesByModelTable({ isLocal }: DevicesByModelTableProps) {
  const space = useCurrentSpace();
  const deviceModels = useDeviceModels();

  const devicesInfo = useMemo(() => {
    if (!deviceModels.isFetched) return [];

    const devicesStatusByModel =
      space?.state?.[
        isLocal
          ? 'local_devices_status_by_device_model'
          : 'devices_status_by_device_model'
      ];

    return keys(devicesStatusByModel).map((key) => {
      // Find device model by name - not ideal, but models can't have same names
      const deviceModel = find({ model: key }, deviceModels.data);

      return {
        model: key,
        space_tree_path_name: space.tree_path_name,
        icon: deviceModel?.user_settings?.icon,
        img: deviceModel?.image_url,
        claimed_devices: devicesStatusByModel?.[key].claimed_devices,
        online_devices: devicesStatusByModel?.[key].online_devices,
      };
    });
  }, [
    deviceModels.data,
    deviceModels.isFetched,
    isLocal,
    space?.state,
    space?.tree_path_name,
  ]);

  if (!deviceModels.isFetched) return <LoadingOverlay visible />;

  return (
    <SmartTable<DevicesByModelType>
      keyField="model"
      name="devicesByModel"
      data={devicesInfo}
      columns={COLUMNS}
      isCompact
      noDataIndication={{ title: 'No devices' }}
      noHeader
      isUrlSyncEnabled={false}
    />
  );
}
