import { Button } from '@mantine/core';
import React from 'react';

import { binocularsEmptyStateSrc } from '@portals/assets';
import { EmptyState } from '@portals/core';

interface FilteredResultsEmptyStateProps {
  onClearFilters: VoidFunction;
}

export function FilteredResultsEmptyState({
  onClearFilters,
}: FilteredResultsEmptyStateProps) {
  return (
    <EmptyState
      media={<img width={250} height="auto" src={binocularsEmptyStateSrc} />}
      messageTitle="No results that matches these filters"
      messageDescription="Try adjusting or clearing your filters to display better results"
      action={
        <Button
          variant="default"
          onClick={onClearFilters}
          data-testid="reset-filters-button"
        >
          Reset filters
        </Button>
      }
    />
  );
}
