import { createStyles, SegmentedControl, Stack } from '@mantine/core';
import React from 'react';

import { RelatedDevice, useDevice } from '@portals/api/organizations';
import {
  DeviceLocationMarker,
  DeviceLocationWidget,
  DeviceLocationWidgetFormType,
  useDeviceLocationWidgetDimensions,
} from '@portals/device-widgets';
import {
  getDeviceStatusMetadata,
  useOpenRouteModal,
} from '@portals/framework/route-modals';
import { useGetSpaceLocation } from '@portals/utils';

import { ChildDeviceLocationMarker } from './ChildDeviceLocationMapMarker';
import { useDeviceLocationWidget } from '../../../../../../../../hooks/device-location-widget';
import {
  childrenToPoints,
  getChildMarkerColor,
} from '../../../../../../../../utils/device-location-widgets-wrapper.utils';
import { WidgetProps } from '../../device-widgets.types';

export function DeviceLocationWidgetWrapper({
  widget,
  deviceId,
  isDeviceOffline,
  lastUpdateTimestamp,
}: WidgetProps<DeviceLocationWidgetFormType>) {
  const { classes } = useStyles();
  const openRouteModal = useOpenRouteModal();

  const widgetFields = widget?.config?.fields;
  const { color: parentColor, show_location_name } = widgetFields ?? {};
  const device = useDevice(deviceId);

  const {
    segmentedControlData,
    activeDimensionId,
    setActiveDimensionId,
    activeDimension,
    shouldRenderSegmentedControl,
  } = useDeviceLocationWidgetDimensions(widgetFields);

  const { space, devicesMapConfig } = useDeviceLocationWidget({
    deviceId,
    renderMainMarker: ({ coordinates, deviceId, deviceName }) => (
      <DeviceLocationMarker
        key={deviceId}
        title={deviceName}
        showLocationName={show_location_name}
        color={parentColor}
        isDeviceOffline={isDeviceOffline}
        lastUpdateTimestamp={lastUpdateTimestamp}
        lat={coordinates.lat}
        lng={coordinates.lng}
      />
    ),
    renderChildMarker: ({ coordinates, deviceId: childDeviceId, status }) => {
      const childDeviceData = device.data?.child_devices?.find(
        (childDevice) => childDevice.id === childDeviceId
      );

      if (!childDeviceData) {
        return null;
      }

      const childDevice: RelatedDevice = {
        id: childDeviceId,
        name: childDeviceData.name,
        space_id: space?.id ?? 0,
        display_path: childDeviceData.display_path,
        device_model_name: childDeviceData.device_model_name || '',
        last_known_state: childDeviceData.last_known_state ?? null,
        state: childDeviceData.state ?? null,
        model_settings: {
          commands: [],
          file_dumps: false,
        },
        image_url: childDeviceData.image_url ?? null,
      };

      const childDeviceMarkerColor = getChildMarkerColor({
        childDeviceData,
        activeDimension,
      });

      const childDeviceActiveDimensionData =
        activeDimension &&
        (childDeviceData?.state?.[activeDimension?.telemetry_key] ||
          childDeviceData?.last_known_state?.state?.[
            activeDimension?.telemetry_key
          ]);

      const childDeviceAdditionalDescription = activeDimension
        ? `${activeDimension?.telemetry_key}: ${
            childDeviceActiveDimensionData || '--'
          }`
        : undefined;

      const shouldRenderWarningIcon =
        !!activeDimension && !childDeviceMarkerColor;

      return (
        <ChildDeviceLocationMarker
          key={childDeviceId}
          title={childDeviceId}
          device={childDevice}
          status={status}
          shouldRenderWarningIcon={shouldRenderWarningIcon}
          additionalDescriptionForHoverCard={childDeviceAdditionalDescription}
          color={
            childDeviceMarkerColor || getDeviceStatusMetadata(status).indicator
          }
          lat={coordinates.lat}
          lng={coordinates.lng}
        />
      );
    },
    onChildClick: (childDeviceId) => {
      return openRouteModal({ modalId: 'device', pathParams: [childDeviceId] });
    },
  });

  const baseLocation = useGetSpaceLocation(space);

  const points = React.useMemo(
    () => childrenToPoints(devicesMapConfig),
    [devicesMapConfig]
  );

  return (
    <Stack
      bg="white"
      h="100%"
      spacing="sm"
      p="xl"
      className={classes.mapContainerStyle}
    >
      {shouldRenderSegmentedControl && (
        <SegmentedControl
          data={segmentedControlData}
          value={activeDimensionId}
          onChange={setActiveDimensionId}
          fullWidth
        />
      )}

      <DeviceLocationWidget
        mapWidgetId={widget.id}
        title={space?.tree_path_name || 'Unnamed Space'}
        parentDeviceMarkerColor={parentColor}
        showLocationName={show_location_name}
        isDeviceOffline={isDeviceOffline}
        lastUpdateTimestamp={lastUpdateTimestamp}
        points={points}
        base={baseLocation}
        stackProps={{
          h: 387,
          w: '100%',
          p: 0,
        }}
      />
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  mapContainerStyle: {
    borderRadius: theme.radius.lg,
  },
}));
