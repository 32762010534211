import {
  createStyles,
  Group,
  MediaQuery,
  Paper,
  SegmentedControl,
  Stack,
  Text,
} from '@mantine/core';
import React, { useState } from 'react';

import { OrderType } from '@portals/api/organizations';
import { useGenericContext } from '@portals/framework';
import { TableDetailsPanel } from '@portals/table';

import { OrderProductsBreakdown } from './order-products-breakdown/OrderProductsBreakdown';
import { OrderProductsSummary } from './order-products-summary/OrderProductsSummary';
import { PurchasedProductDetailsPanel } from '../../../pages/products/pages/purchased-products/PurchasedProductDetailsPanel';

export function OrderProductsCard() {
  const { classes } = useStyles();
  const [viewType, setViewType] = useState<'summary' | 'breakdown'>('summary');
  const [purchasedProductId, setPurchasedProductId] = useState<string>(null);
  const order = useGenericContext<OrderType>();

  const onSelectProduct = (productId: string) =>
    setPurchasedProductId(purchasedProductId === productId ? null : productId);

  return (
    <>
      {purchasedProductId ? (
        <TableDetailsPanel
          key={purchasedProductId}
          width={400}
          type="inner"
          onClose={() => setPurchasedProductId(null)}
          className={classes.sidePanelContainer}
        >
          <PurchasedProductDetailsPanel
            purchasedProductId={purchasedProductId}
            onClose={() => setPurchasedProductId(null)}
          />
        </TableDetailsPanel>
      ) : null}

      <Paper withBorder p="xl" radius="md">
        <Stack>
          <Group align="center" position="apart">
            <Text size="md" color="gray.8">
              Products
            </Text>

            {order?.payment_status === 'paid' ? (
              <MediaQuery smallerThan="md" styles={{ display: 'none' }}>
                <SegmentedControl
                  w={230}
                  size="xs"
                  data-testid="order-products-view-type"
                  data={[
                    { label: 'Summary', value: 'summary' },
                    { label: 'Breakdown', value: 'breakdown' },
                  ]}
                  value={viewType}
                  onChange={(viewType: 'summary' | 'breakdown') =>
                    setViewType(viewType)
                  }
                />
              </MediaQuery>
            ) : null}
          </Group>

          {viewType === 'summary' ? (
            <OrderProductsSummary />
          ) : (
            <OrderProductsBreakdown
              onSelectPurchasedProductId={onSelectProduct}
              selectedPurchasedProductId={purchasedProductId}
            />
          )}
        </Stack>
      </Paper>
    </>
  );
}

const useStyles = createStyles((theme) => ({
  sidePanelContainer: {
    border: 'none',
  },
}));
