export const globalQueryKeys = {
  files: ['files'],
  devices: ['devices'],
  incidents: ['incidents'],
  licenses: ['licenses'],
  warranties: ['warranties'],
  integrations: ['integrations'],
  assets: ['assets'],
  deviceModels: ['deviceModels'],
};
