import { Select, Stack } from '@mantine/core';
import React, { useState } from 'react';
import { array, InferType, object, string } from 'yup';

import { FieldRendererProps } from '@portals/types';

export const parentChildSelectSchema = array(
  object({
    label: string().required(),
    children: object({
      key: string().required(),
      label: string().required(),
      options: array(string().required()).required(),
    }).required(),
  }).required()
).required();

export type ParentChildSelectSchemaType = InferType<
  typeof parentChildSelectSchema
>;

export function isParentChildSelectSchema(
  selectOptions: any
): selectOptions is ParentChildSelectSchemaType {
  try {
    // This call will throw if the variable doesn't satisfy the schema
    parentChildSelectSchema.validateSync(selectOptions);
    return true;
  } catch (e) {
    return false;
  }
}

interface CustomParentChildSelectFieldProps extends FieldRendererProps {
  selectOptions: ParentChildSelectSchemaType;
}

export function CustomParentChildSelectField({
  field,
  value,
  required,
  setFieldValue,
  selectOptions,
}: CustomParentChildSelectFieldProps) {
  const [selectedChildOption, setSelectedChildOption] = useState('');

  const parentSelectOptions = selectOptions.map((option) => option.label);

  const selectedParentOption = selectOptions.find(
    (option) => option.label === value
  );

  const childOptions = selectedParentOption?.children?.options ?? [];
  const selectedParentChildrenKey = selectedParentOption?.children?.key;

  function onChangeParentSelection(selectedValue: string) {
    setFieldValue?.(field.name, selectedValue);

    // Clear selected child option whenever we change the parent selection
    const childFieldKey = selectOptions.find(
      (option) => option.label === selectedValue
    )?.children?.key;

    if (childFieldKey) {
      setFieldValue?.(childFieldKey, '');
      setSelectedChildOption('');
    }
  }

  function onChangeChildSelection(selectedValue: string) {
    setSelectedChildOption(selectedValue ?? '');

    if (selectedParentChildrenKey) {
      setFieldValue?.(selectedParentChildrenKey, selectedValue);
    }
  }

  return (
    <Stack>
      <Select
        required={required}
        name={field.name}
        label={field.title}
        data={parentSelectOptions}
        value={value}
        onChange={onChangeParentSelection}
      />

      {value && (
        <Select
          required={required}
          name={selectedParentOption?.children.key}
          label={selectedParentOption?.children?.label}
          data={childOptions}
          value={selectedChildOption}
          onChange={onChangeChildSelection}
        />
      )}
    </Stack>
  );
}
