import {
  useOrganizationConfig,
  usePortalCapabilities,
} from '@portals/api/organizations';
import {
  isCustomerFeatureAll,
  isCustomerFeatureLabOnly,
} from '@portals/framework';

export function useIsDisplayAssetsManagementTab() {
  const portalCapabilities = usePortalCapabilities();
  const organizationConfig = useOrganizationConfig();

  return (
    isCustomerFeatureAll(portalCapabilities?.asset_management) ||
    isCustomerFeatureLabOnly(
      portalCapabilities?.asset_management,
      organizationConfig?.lab
    )
  );
}
