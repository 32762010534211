import {
  Box,
  Button,
  ButtonProps,
  CloseButton,
  createStyles,
  Divider,
  Grid,
  Group,
  Indicator,
  Popover,
  Select,
  Stack,
  Switch,
  SwitchProps,
  Text,
} from '@mantine/core';
import React, { useState } from 'react';

import {
  getDeviceStatusMetadata,
  getDeviceStatusMetadataList,
} from '@portals/framework/route-modals';
import { ReactComponent as Element3Icon } from '@portals/icons/bold/element-3.svg';
import { ReactComponent as ListIcon } from '@portals/icons/linear/list.svg';
import { ReactComponent as Setting4Icon } from '@portals/icons/linear/setting-4.svg';

import { DisplayTypeCard } from './DisplayTypeCard';
import { useOverviewDevicesContext } from '../OverviewDevicesProvider';

// const priorityList = getPrioritiesList();
const deviceStatusMetadataList = getDeviceStatusMetadataList();

const SORT_BY_OPTIONS = [
  { value: 'name', label: 'Name' },
  { value: 'device_model_name', label: 'Model' },
  { value: 'status', label: 'Status' },
];

const SORT_DIRECTION_OPTIONS = [
  { value: 'asc', label: 'Ascending' },
  { value: 'desc', label: 'Descending' },
];

export function DeviceFilters() {
  const { classes, theme } = useStyles();

  const [isOpen, setIsOpen] = useState(false);
  const overviewDevices = useOverviewDevicesContext();

  return (
    <Popover
      opened={isOpen}
      onChange={setIsOpen}
      withinPortal
      position="left-start"
      width={470}
      radius="lg"
      shadow="xl"
      trapFocus
    >
      <Popover.Target>
        <Indicator offset={2} disabled={!overviewDevices.form.isDirty()}>
          <Button
            variant="default"
            leftIcon={<Setting4Icon width={16} height={16} />}
            onClick={() => setIsOpen((prev) => !prev)}
            data-testid="overview-devices-filters-button"
          >
            Filters
          </Button>
        </Indicator>
      </Popover.Target>

      <Popover.Dropdown py="xl" px="xxl">
        <Stack spacing="xl">
          <Group position="apart">
            <Text fw={500} fz="md">
              Filters
            </Text>

            <CloseButton
              onClick={() => setIsOpen(false)}
              data-testid="close-modal-button"
            />
          </Group>

          <Group grow>
            <DisplayTypeCard
              icon={<ListIcon data-testid="devices-list-preview" />}
              label="List"
              isActive={overviewDevices.displayType === 'list'}
              onClick={() => overviewDevices.setDisplayType('list')}
            />

            <DisplayTypeCard
              icon={<Element3Icon data-testid="devices-grid-preview" />}
              label="Grid"
              isActive={overviewDevices.displayType === 'grid'}
              onClick={() => overviewDevices.setDisplayType('grid')}
            />
          </Group>

          <Stack>
            <Text fw={500} c="gray.5">
              Sort by
            </Text>

            <Grid columns={10}>
              <Grid.Col span={6}>
                <Select
                  placeholder="Sort by"
                  data={SORT_BY_OPTIONS}
                  {...overviewDevices.form.getInputProps('sortByField')}
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <Select
                  placeholder="Sort order"
                  data={SORT_DIRECTION_OPTIONS}
                  {...overviewDevices.form.getInputProps('sortByDirection')}
                />
              </Grid.Col>
            </Grid>
          </Stack>

          <div className={classes.switchesGrid}>
            {/*@todo: leave this here for when we revert the incidents switches*/}
            {/*<Switch.Group*/}
            {/*  label="Incidents"*/}
            {/*  styles={switchGroupStyles}*/}
            {/*  value={overviewDevices.form.values.incidentsPriority.map(String)}*/}
            {/*  onChange={(values) =>*/}
            {/*    overviewDevices.form.setFieldValue(*/}
            {/*      'incidentsPriority',*/}
            {/*      values.map(Number) as PriorityNumericValue[]*/}
            {/*    )*/}
            {/*  }*/}
            {/*>*/}
            {/*  <Stack>*/}
            {/*    {priorityList.map((priority) => (*/}
            {/*      <Switch*/}
            {/*        key={priority.name}*/}
            {/*        value={String(priority.id)}*/}
            {/*        labelPosition="left"*/}
            {/*        styles={switchStyles}*/}
            {/*        label={*/}
            {/*          <Group spacing="xs">*/}
            {/*            <DangerIcon*/}
            {/*              width={18}*/}
            {/*              height={18}*/}
            {/*              color={theme.fn.themeColor(priority.color)}*/}
            {/*            />*/}
            {/*            <Text>{priority.name}</Text>*/}
            {/*          </Group>*/}
            {/*        }*/}
            {/*      />*/}
            {/*    ))}*/}
            {/*  </Stack>*/}
            {/*</Switch.Group>*/}

            <Stack>
              <Text c="gray.5" fw={500}>
                Status
              </Text>

              {deviceStatusMetadataList.map((deviceStatusMetadata) => (
                <Switch
                  key={deviceStatusMetadata.id}
                  labelPosition="left"
                  styles={switchStyles}
                  label={
                    <Group spacing="xs">
                      <Box
                        className={classes.statusDot}
                        bg={theme.fn.themeColor(deviceStatusMetadata.indicator)}
                      />
                      <Text
                        data-testid={`status-${deviceStatusMetadata.displayName}`}
                      >
                        {deviceStatusMetadata.displayName}
                      </Text>
                    </Group>
                  }
                  {...overviewDevices.form.getInputProps(
                    `statuses.${deviceStatusMetadata.id}`,
                    { type: 'checkbox' }
                  )}
                />
              ))}

              <Switch
                labelPosition="left"
                styles={switchStyles}
                label={
                  <Group spacing="xs">
                    <Box
                      className={classes.statusDot}
                      bg={getDeviceStatusMetadata(undefined).indicator}
                    />
                    <Text>
                      {getDeviceStatusMetadata(undefined).displayName}
                    </Text>
                  </Group>
                }
                {...overviewDevices.form.getInputProps('unknownStatus', {
                  type: 'checkbox',
                })}
              />
            </Stack>
          </div>

          <Divider color="gray.3" />

          <Button
            variant="white"
            styles={resetButtonStyles}
            onClick={overviewDevices.clearFilters}
            data-testid="reset-to-defaut-button"
          >
            Reset to default
          </Button>
        </Stack>
      </Popover.Dropdown>
    </Popover>
  );
}

const useStyles = createStyles((theme) => ({
  switchesGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridAutoFlow: 'column',
    gridAutoColumns: '1fr',

    // @todo: leave this here for when we revert the incidents switches
    // '>:nth-of-type(odd)': {
    //   paddingRight: theme.spacing.xxl,
    //   borderRight: `1px solid ${theme.colors.gray[1]}`,
    // },
    //
    // '>:nth-of-type(even)': {
    //   paddingLeft: theme.spacing.xxl,
    //   borderLeft: `1px solid ${theme.colors.gray[1]}`,
    // },
  },
  statusDot: {
    width: 15,
    height: 15,
    borderRadius: '50%',
  },
}));

const switchStyles: SwitchProps['styles'] = {
  body: {
    justifyContent: 'space-between',
  },
};

const resetButtonStyles: ButtonProps['styles'] = {
  root: {
    height: 20,
    padding: 0,
    alignSelf: 'flex-start',
  },
  label: {
    fontWeight: 500,
  },
};
