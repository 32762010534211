import { Group } from '@mantine/core';
import React from 'react';

import { DebouncedSearchInput } from '@portals/core';
import { ReactComponent as AddIcon } from '@portals/icons/linear/add.svg';

import { AssetFilters } from './AssetFilters';
import { useOverviewAssetsContext } from './OverviewAssetsProvider';
import { AddAssetButton } from '../../assets/AddAssetButton';

export function AssetsControls() {
  const { form, searchInputRef, space, totalAssetsCount, isLoading } =
    useOverviewAssetsContext();

  if (!isLoading && !form.isDirty() && totalAssetsCount === 0) {
    return null;
  }

  return (
    <Group position="apart">
      <DebouncedSearchInput
        ref={searchInputRef}
        size="sm"
        initialValue={form.values.searchTerm}
        debouncedOnChange={(value) => form.setFieldValue('searchTerm', value)}
      />

      <Group>
        {space.data?.space_type !== 'root' && (
          <AddAssetButton
            variant="default"
            leftIcon={<AddIcon width={16} height={16} />}
            initialSpaceId={space.data?.id}
          >
            Add asset
          </AddAssetButton>
        )}

        <AssetFilters />
      </Group>
    </Group>
  );
}
