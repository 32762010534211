import {
  Button,
  createStyles,
  Menu,
  SimpleGrid,
  Stack,
  Text,
} from '@mantine/core';
import React from 'react';

import { TOUR_STEPS_IDS } from '@portals/framework';
import { ReactComponent as Box } from '@portals/icons/bulk/box-1.svg';
import { ReactComponent as Radar } from '@portals/icons/bulk/radar.svg';
import { ReactComponent as Add } from '@portals/icons/linear/add.svg';

import { useIsDisplayAssetsManagementTab } from '../../../../../hooks/assets-management';
import {
  ClaimDeviceButton,
  useOpenClaimDeviceModal,
} from '../../../../components/ClaimDeviceButton';
import { useOpenAddAssetsModal } from '../../../assets/AddAssetButton';
import { useCurrentSpace } from '../../overview.hooks';

export function AddNewButton() {
  const { classes } = useStyles();
  const currentSpace = useCurrentSpace();
  const openAddAssetModal = useOpenAddAssetsModal();
  const openClaimDeviceModal = useOpenClaimDeviceModal();

  const isDisplayAssetsManagementTab = useIsDisplayAssetsManagementTab();

  if (!isDisplayAssetsManagementTab) {
    return (
      <ClaimDeviceButton
        size="sm"
        spaceId={currentSpace?.id}
        data-testid="claim-device-button"
        leftIcon={<Add color="white" />}
        data-tour-step-id={TOUR_STEPS_IDS.claimDeviceButton}
      >
        Claim device
      </ClaimDeviceButton>
    );
  }

  return (
    <Menu position="bottom-end" withinPortal width={370} radius="lg">
      <Menu.Target>
        <Button
          leftIcon={<Add color="white" width={16} height={16} />}
          data-testid="add-new-dropdown-button"
        >
          Add new
        </Button>
      </Menu.Target>

      <Menu.Dropdown>
        <Stack p="md" spacing="xs">
          <Menu.Item
            onClick={() => openClaimDeviceModal({ spaceId: currentSpace?.id })}
          >
            <SimpleGrid cols={2} className={classes.item}>
              <Radar width={32} height={32} />

              <Stack spacing="xs" pt="xs">
                <Text
                  size="sm"
                  weight={500}
                  data-testid="connected-device-option"
                >
                  Connected device
                </Text>

                <Text size="xs" color="gray.6">
                  Devices which are connected and send live telemetries for
                  monitoring and management.
                </Text>
              </Stack>
            </SimpleGrid>
          </Menu.Item>

          <Menu.Divider />

          <Menu.Item
            onClick={() =>
              openAddAssetModal({ initialSpaceId: currentSpace?.id })
            }
          >
            <SimpleGrid cols={2} className={classes.item}>
              <Box width={32} height={32} />

              <Stack spacing="xs" pt="xs">
                <Text size="sm" weight={500} data-testid="asset-option">
                  Asset
                </Text>

                <Text size="xs" color="gray.6">
                  Non-connected items that are manually tracked for location,
                  status and service information.
                </Text>
              </Stack>
            </SimpleGrid>
          </Menu.Item>
        </Stack>
      </Menu.Dropdown>
    </Menu>
  );
}

const useStyles = createStyles(() => ({
  item: {
    gridTemplateColumns: 'min-content 1fr',
    alignItems: 'center',
  },
}));
