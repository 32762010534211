import { useMantineTheme } from '@mantine/core';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import seedrandom from 'seedrandom';

interface RandomColorsSeedContextType {
  getRandomColor: (seed: string) => string;
}

const RandomColorsSeedContext =
  createContext<RandomColorsSeedContextType | null>(null);

interface RandomColorsSeedProviderProps {
  children: ReactNode;
}

export function RandomColorsSeedProvider({
  children,
}: RandomColorsSeedProviderProps) {
  const theme = useMantineTheme();

  const usableColors = useMemo(() => {
    const allColorNames = Object.keys(theme.colors);

    /** colors with all 10 hex values */
    return allColorNames.filter((colorName) =>
      theme.colors[colorName].every((hex) => hex !== '')
    );
  }, [theme.colors]);

  const getRandomColor = useCallback(
    (seed: string) => {
      const rng = seedrandom(seed);
      const randomPositiveNumber = Math.abs(rng.int32());

      return usableColors[randomPositiveNumber % usableColors.length];
    },
    [usableColors]
  );

  return (
    <RandomColorsSeedContext.Provider value={{ getRandomColor }}>
      {children}
    </RandomColorsSeedContext.Provider>
  );
}

export function useRandomColorsSeed() {
  const context = useContext(RandomColorsSeedContext);

  if (context === null) {
    throw new Error(
      'useRandomColorsSeed must be used within RandomColorsSeedProvider'
    );
  }

  return context;
}
