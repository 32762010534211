import { FunctionComponent, SVGProps } from 'react';

import { AssetIconNameType } from '@portals/api/organizations';
import { ReactComponent as Book } from '@portals/icons/linear/book.svg';
import { ReactComponent as Box } from '@portals/icons/linear/box.svg';
import { ReactComponent as Briefcase } from '@portals/icons/linear/briefcase.svg';
import { ReactComponent as CallCalling } from '@portals/icons/linear/call-calling.svg';
import { ReactComponent as Coffee } from '@portals/icons/linear/coffee.svg';
import { ReactComponent as Cpu } from '@portals/icons/linear/cpu.svg';
import { ReactComponent as Driver } from '@portals/icons/linear/driver.svg';
import { ReactComponent as Electricity } from '@portals/icons/linear/electricity.svg';
import { ReactComponent as Element } from '@portals/icons/linear/element-3.svg';
import { ReactComponent as ExternalDrive } from '@portals/icons/linear/external-drive.svg';
import { ReactComponent as Headphone } from '@portals/icons/linear/headphone.svg';
import { ReactComponent as Keyboard } from '@portals/icons/linear/keyboard.svg';
import { ReactComponent as LampCharge } from '@portals/icons/linear/lamp-charge.svg';
import { ReactComponent as Microphone } from '@portals/icons/linear/microphone-2.svg';
import { ReactComponent as Mobile } from '@portals/icons/linear/mobile.svg';
import { ReactComponent as Monitor } from '@portals/icons/linear/monitor.svg';
import { ReactComponent as Mouse } from '@portals/icons/linear/mouse.svg';
import { ReactComponent as Printer } from '@portals/icons/linear/printer.svg';
import { ReactComponent as Radio } from '@portals/icons/linear/radio.svg';
import { ReactComponent as Setting } from '@portals/icons/linear/setting-2.svg';
import { ReactComponent as Speaker } from '@portals/icons/linear/speaker.svg';
import { ReactComponent as TaskSquare } from '@portals/icons/linear/task-square.svg';
import { ReactComponent as VolumeHigh } from '@portals/icons/linear/volume-high.svg';
import { ReactComponent as Watch } from '@portals/icons/linear/watch.svg';
import { ReactComponent as Wifi } from '@portals/icons/linear/wifi.svg';

export const ASSETS_ICONS_MAP: Record<
  AssetIconNameType,
  FunctionComponent<SVGProps<SVGSVGElement>>
> = {
  'microphone-2': Microphone,
  watch: Watch,
  driver: Driver,
  monitor: Monitor,
  cpu: Cpu,
  mouse: Mouse,
  speaker: Speaker,
  'external-drive': ExternalDrive,
  mobile: Mobile,
  printer: Printer,
  'task-square': TaskSquare,
  'volume-high': VolumeHigh,
  'lamp-charge': LampCharge,
  headphone: Headphone,
  'keyboard-2': Keyboard,
  wifi: Wifi,
  'setting-2': Setting,
  box: Box,
  electricity: Electricity,
  coffee: Coffee,
  book: Book,
  'call-calling': CallCalling,
  radio: Radio,
  briefcase: Briefcase,
  'element-3': Element,
};
